import React from 'react';
import { useDispatch } from 'react-redux';
import { setShowMenu } from '../redux/controls';
import SideMenu from '../components/SideMenu/SideMenu';
import TopMenu from '../components/TopMenu/TopMenu';
import ContentBlock from '../components/ContentBlock/ContentBlock';
import AccordionComponent from '../components/AccordionComponent/AccordionComponent';
import Footer from '../components/Footer/Footer';
import '../styles/standardPageStyle.css';
import '../styles/miniComponents.css';

import { UNBALANCE_PAGE as content } from '../data/PAGE_TEXT_CONTENT';
import techLiterature from '../assets/pdf-docs/unbalance-page/balance_quality_requirements_of_rigid_rotors.pdf';
import b10 from '../assets/images/b10_endthrust.png';
import coupleUnbal from '../assets/images/unbalance-page/couple-unbalance.png';
import dynamicUnbal from '../assets/images/unbalance-page/dynamic-unbalance.png';
import forceCalc from '../assets/images/unbalance-page/force-calculation.png';
import poundsCentForceSetup from '../assets/images/unbalance-page/pounds-centrifugal-force-setup.png';
import poundsCentrifugForce from '../assets/images/unbalance-page/pounds-centrifugal-force.png';
import staticUnbal from '../assets/images/unbalance-page/static-unbalance.png';
import staticUnbalFix from '../assets/images/unbalance-page/static-unbalance-fix.png';
import velocityCalc from '../assets/images/unbalance-page/velocity-calculation.png';

export default function Unbalance() {
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  const CauseOfUnbalance = () => (
    <div className="mini-component-container">
      <div>
        <h2 className="mini-h2">{content.causes_of_unbalanced_motor}</h2>
        <p className="mini-p-text">{content.causes_paragraph}</p>
      </div>
      <ul className="mini-ul">
        <li className="mini-li-title">{content.cause_one}</li>
        <li className="mini-li-text li-indent">
          {content.cause_one_paragraph}
        </li>
        <li className="mini-li-title">{content.cause_two}</li>
        <li className="mini-li-text li-indent">
          {content.cause_two_paragraph}
        </li>
        <li className="mini-li-title">{content.cause_three}</li>
        <li className="mini-li-text li-indent">
          {content.cause_three_paragraph}
        </li>
        <li className="mini-li-title">{content.cause_four}</li>
        <li className="mini-li-text li-indent">
          {content.cause_four_paragraph}
        </li>
        <li className="mini-li-title">{content.cause_five}</li>
        <li className="mini-li-text li-indent">
          {content.cause_five_paragraph}
        </li>
        <li className="mini-li-title">{content.cause_six}</li>
        <li className="mini-li-text li-indent">
          {content.cause_six_paragraph}
        </li>
        <li className="mini-li-title">{content.cause_seven}</li>
        <li className="mini-li-text li-indent">
          {content.cause_seven_paragraph}
        </li>
        <li className="mini-li-title">{content.cause_eight}</li>
        <li className="mini-li-text li-indent">
          {content.cause_eight_paragraph}
        </li>
        <li className="mini-li-title">{content.cause_nine}</li>
        <li className="mini-li-text li-indent">
          {content.cause_nine_paragraph}
        </li>
      </ul>
      <p className="mini-p-text">{content.causes_summary}</p>
    </div>
  );

  const UnitsOfUnbal = () => (
    <div className="mini-component-container">
      <div className="mini-content-wrapper-flex-col">
        <h2 className="mini-h2">{content.units_of_unbalance}</h2>
        <p className="mini-p-text">{content.units_of_unbal_paragraph_one}</p>
        <p className="mini-p-text center-text">{content.unbalance_formula}</p>
        <p className="mini-p-text">{content.units_of_unbal_paragraph_two}</p>

        <ul className="mini-ul">
          <li className="mini-li-title">{content.units_of_unbal_imperial}</li>
          <li className="mini-li-text li-indent">
            {content.imperial_unit_gram_inches}
          </li>
          <li className="mini-li-text li-indent">
            {content.imperial_unit_ounce_inches}
          </li>
          <li className="mini-li-title">{content.units_of_unbal_metric}</li>
          <li className="mini-li-text li-indent">
            {content.metric_unit_gram_mm}
          </li>
          <li className="mini-li-text li-indent">
            {content.metric_unit_gram_cm}
          </li>
        </ul>
        <p className="mini-p-text">{content.units_of_unbal_final_paragraph}</p>
      </div>
    </div>
  );

  const TypesOfRotors = () => (
    <div className="mini-component-container">
      <div className="mini-content-wrapper-flex-col">
        <h2 className="mini-h2">{content.types_of_rotors}</h2>
        <p className="mini-p-text">{content.types_of_rotors_paragraph_one}</p>
        <p className="mini-p-text">{content.types_of_rotors_paragraph_two}</p>
        <p className="mini-p-text">{content.types_of_rotors_paragraph_three}</p>
      </div>
    </div>
  );

  const BalancingLimits = () => (
    <div className="mini-component-container">
      <div className="mini-content-wrapper-flex-col">
        <h2 className="mini-h2">{content.balancing_limits}</h2>
        <p className="mini-p-text">{content.balancing_limits_paragraph_one}</p>
        <a
          href={techLiterature}
          rel="noreferrer"
          target="_blank"
          className="link-text"
        >
          <p className="mini-p-text">
            {content.balancing_limits_paragraph_one_link_text}
          </p>
        </a>
        <p className="mini-p-text">{content.balancing_limits_paragraph_two}</p>
      </div>
    </div>
  );

  const StaticUnbalance = () => (
    <div className="mini-component-container">
      <div className="mini-content-wrapper-flex-col">
        <h2 className="mini-h2">{content.static_unbalance}</h2>
        <div className="mini-content-wrapper-flex-row">
          <div className="mini-image-wrapper width-fourty-percent">
            <img
              src={staticUnbal}
              alt="static unbalance"
              className="mini-image-seventy-percent"
            />
          </div>
          <div className="mini-content-wrapper-flex-col width-sixty-percent">
            <p className="mini-p-text">
              {content.static_unbalance_paragraph_one}
            </p>
            <p className="mini-p-text">
              {content.static_unbalance_paragraph_two}
            </p>
          </div>
        </div>

        <div className="mini-content-wrapper-flex-row">
          <div className="mini-content-wrapper-flex-col width-sixty-percent">
            <p className="mini-p-text">
              {content.static_unbalance_paragraph_three}
            </p>
            <p className="mini-p-text">
              {content.static_unbalance_paragraph_four}
            </p>
          </div>
          <div className="mini-image-wrapper width-fourty-percent">
            <img
              src={staticUnbalFix}
              alt="static unbalance fix"
              className="mini-image-seventy-percent"
            />
          </div>
        </div>
      </div>
    </div>
  );

  const CoupleUnbalance = () => (
    <div className="mini-component-container">
      <div className="mini-content-wrapper-flex-col">
        <h2 className="mini-h2">{content.couple_unbalance}</h2>
        <div className="mini-content-wrapper-flex-row">
          <div className="mini-image-wrapper width-fourty-percent">
            <img
              src={coupleUnbal}
              alt="couple unbalance"
              className="mini-image-seventy-percent"
            />
          </div>
          <div className="mini-content-wrapper-flex-col width-sixty-percent">
            <p className="mini-p-text">
              {content.couple_unbalance_paragraph_one}
            </p>
            <p className="mini-p-text">
              {content.couple_unbalance_paragraph_two}
            </p>
          </div>
        </div>

        <p className="mini-p-text">
          {content.couple_unbalance_paragraph_three}
        </p>
      </div>
    </div>
  );

  const DynamicOfUnbal = () => (
    <div className="mini-component-container">
      <div className="mini-content-wrapper-flex-col">
        <h2 className="mini-h2">{content.dynamic_unbalance}</h2>
        <div className="mini-content-wrapper-flex-row">
          <div className="mini-content-wrapper-flex-col width-sixty-percent">
            <p className="mini-p-text">
              {content.dynamic_unbalance_paragraph_one}
            </p>
            <p className="mini-p-text">
              {content.dynamic_unbalance_paragraph_two}
            </p>
          </div>
          <div className="mini-image-wrapper width-fourty-percent">
            <img
              src={dynamicUnbal}
              alt="dynamic unbalance fix"
              className="mini-image-seventy-percent"
            />
          </div>
        </div>
      </div>
    </div>
  );

  const MethodsOfCorrecting = () => (
    <div className="mini-component-container">
      <div className="mini-content-wrapper-flex-col">
        <h2 className="mini-h2">{content.methods_of_correcting_unbalance}</h2>
        <p className="mini-p-text">
          {content.methods_of_correcting_unbal_paragraph_one}
        </p>
        <p className="mini-p-text">
          {content.methods_of_correcting_unbal_paragraph_two}
        </p>
        <p className="mini-p-text">
          {content.methods_of_correcting_unbal_paragraph_three}
        </p>
      </div>
    </div>
  );

  const ImportanceOfDynamicBal = () => (
    <div className="mini-component-container">
      <div className="mini-content-wrapper-flex-col">
        <h2 className="mini-h2">{content.importance_of_dynamic_balancing}</h2>
        <div className="mini-content-wrapper-flex-row">
          <div className="mini-content-wrapper-flex-col width-sixty-percent">
            <p className="mini-p-text">
              {content.importance_of_dynamic_bal_paragraph_one}
            </p>
            <p className="mini-p-text">
              {content.importance_of_dynamic_bal_paragraph_two}
            </p>
          </div>
          <div className="mini-image-wrapper width-fourty-percent">
            <img
              src={poundsCentForceSetup}
              alt="cross section view of rotor"
              className="mini-image-fourty-percent"
            />
          </div>
        </div>

        <div className="mini-image-wrapper center-div">
          <img
            src={velocityCalc}
            alt="velocity calculation"
            className="mini-image-thirty-percent"
          />
        </div>

        <p className="mini-p-text">
          {content.importance_of_dynamic_bal_paragraph_three}
        </p>

        <div className="mini-image-wrapper center-div">
          <img
            src={forceCalc}
            alt="force calculation"
            className="mini-image-fourty-percent"
          />
        </div>

        <p className="mini-p-text">
          {content.importance_of_dynamic_bal_paragraph_four}
        </p>

        <div className="mini-image-wrapper center-div">
          <img
            src={poundsCentrifugForce}
            alt="chart"
            className="mini-image-thirty-percent"
          />
        </div>
      </div>
    </div>
  );

  return (
    <main className="container-fluid stnd-container">
      <SideMenu />
      <TopMenu />

      <div className="row stnd-content" onClick={toggleMenu}>
        <div className="row stnd-top-content">
          <ContentBlock
            title={content.page_header_text}
            text={content.intro_paragraph}
            image={b10}
          />
        </div>

        <div className="row stnd-middle-content-flex-col accordion-width-wrapper-eighty-percent">
          <AccordionComponent
            defaultActiveKey={'01'}
            eventKeyOne={'01'}
            titleOne={content.causes_of_unbalanced_motor}
            contentOneFlexCol={false}
            contentOne={<CauseOfUnbalance />}
            eventKeyTwo={'02'}
            titleTwo={content.units_of_unbalance}
            contentTwo={<UnitsOfUnbal />}
            eventKeyThree={'03'}
            titleThree={content.types_of_rotors}
            contentThree={<TypesOfRotors />}
            eventKeyFour={'04'}
            titleFour={content.balancing_limits}
            contentFour={<BalancingLimits />}
            eventKeyFive={'05'}
            titleFive={content.static_unbalance}
            contentFive={<StaticUnbalance />}
            eventKeySix={'06'}
            titleSix={content.couple_unbalance}
            contentSix={<CoupleUnbalance />}
            eventKeySeven={'07'}
            titleSeven={content.dynamic_unbalance}
            contentSeven={<DynamicOfUnbal />}
            eventKeyEight={'08'}
            titleEight={content.methods_of_correcting_unbalance}
            contentEight={<MethodsOfCorrecting />}
            eventKeyNine={'09'}
            titleNine={content.importance_of_dynamic_balancing}
            contentNine={<ImportanceOfDynamicBal />}
          />
        </div>

        <div className="row stnd-bottom-content"></div>
      </div>

      <Footer />
    </main>
  );
}
