import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowMenu } from '../redux/controls';
import SideMenu from '../components/SideMenu/SideMenu';
import TopMenu from '../components/TopMenu/TopMenu';
import ContactForm from '../components/ContactForm/ContactForm';
import Footer from '../components/Footer/Footer';
import '../styles/standardPageStyle.css';

export default function BalancingTraining() {
  const { has_paid } = useSelector((state) => state.controls);
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  return (
    <div className="container-fluid stnd-container">
      {has_paid && <SideMenu />}
      {has_paid && <TopMenu />}
      <div className="stnd-header">
        <h1 className="stnd-h1 margin-left-two-percent">Balancing Training</h1>
      </div>
      <div className="row stnd-content" onClick={toggleMenu}>
        <div className="row stnd-top-content">
          {/* <span className="stnd-text stnd-align-left">
            Click{' '}
            <a
              href={links.balancing_training_request}
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{' '}
            to request balancing training.
          </span> */}
          <span className="stnd-text stnd-align-left">
            Please use the following form to request Balancing Training
          </span>
        </div>

        <div className="row stnd-middle-content-flex-col">
          <ContactForm usingOnPage={'balancing-training'} />
        </div>

        <div className="row stnd-bottom-content"></div>
      </div>

      <Footer />
    </div>
  );
}
