import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setShowMenu } from '../redux/controls';
import SideMenu from '../components/SideMenu/SideMenu';
import TopMenu from '../components/TopMenu/TopMenu';
import AccordionComponent from '../components/AccordionComponent/AccordionComponent';
import ContentBlock from '../components/ContentBlock/ContentBlock';
import ReversibleContentBlock from '../components/ContentBlock/ReversibleContentBlock';
import FeaturedProduct from '../components/FeaturedProduct/FeaturedProduct';
import YouTubeContainer from '../components/YouTubeContainer/YouTubeContainer';
import ApplicationList from '../components/ApplicationList/ApplicationList';
import Footer from '../components/Footer/Footer';
import '../styles/standardPageStyle.css';

import { IRD_BALANCING_PAGE as content } from '../data/PAGE_TEXT_CONTENT';

import coreLossTestingImg from '../assets/images/core-loss-testing.jpg';
import irdSpotlight from '../assets/images/ird-spotlight.png';
import instrument295 from '../assets/images/295-instrument.png';

const ModelTwoNineFiveCaption = () => (
  <>
    <span className="image-caption-text-bold">
      {content.model_two_nine_five_caption_line_one}
    </span>
    <span className="image-caption-text">
      {content.model_two_nine_five_caption_line_two}
    </span>
    <span className="image-caption-text">
      {content.model_two_nine_five_caption_line_three}
    </span>
  </>
);

const BenefitsOfBalancing = () => (
  <ul className="home-page-list">
    <li className="home-page-list-item">
      {content.benefits_of_bal_list_item_one}
    </li>
    <li className="home-page-list-item">
      {content.benefits_of_bal_list_item_two}
    </li>
    <li className="home-page-list-item">
      {content.benefits_of_bal_list_item_three}
    </li>
    <li className="home-page-list-item">
      {content.benefits_of_bal_list_item_four}
    </li>
    <li className="home-page-list-item">
      {content.benefits_of_bal_list_item_five}
    </li>
    <li className="home-page-list-item">
      {content.benefits_of_bal_list_item_six}
    </li>
    <li className="home-page-list-item">
      {content.benefits_of_bal_list_item_seven}
    </li>
    <li className="home-page-list-item">
      {content.benefits_of_bal_list_item_eight}
    </li>
    <li className="home-page-list-item">
      {content.benefits_of_bal_list_item_nine}
    </li>
    <li className="home-page-list-item">
      {content.benefits_of_bal_list_item_ten}
    </li>
    <li className="home-page-list-item">
      {content.benefits_of_bal_list_item_eleven}
    </li>
  </ul>
);

export default function IrdBalancing() {
  const [irdVideo, setIrdVideo] = useState(null);
  const { featured_product, ird_videos, has_paid } = useSelector(
    (state) => state.controls
  );
  const dispatch = useDispatch();

  let length;
  if (ird_videos) {
    length = ird_videos.length;
  }

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  useEffect(() => {
    if (ird_videos) {
      const result = ird_videos.filter(
        (video) =>
          video.video_title === `Soft-Bearing Balancing Guide with IRD's 295+`
      );
      setIrdVideo(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length]);

  return (
    <div className="container-fluid stnd-container">
      {has_paid && <SideMenu />}
      {has_paid && <TopMenu />}
      <div className="row stnd-content margin-top-thirty" onClick={toggleMenu}>
        <div className="row stnd-top-content">
          <ContentBlock
            title={`IRD Balancing`}
            text={content.intro_para}
            image={irdSpotlight}
            imageSize="standard"
          />
        </div>

        <div className="row stnd-middle-content-flex-col">
          {featured_product &&
            featured_product.map((product) => {
              return (
                <FeaturedProduct
                  id={product.id}
                  key={product.id}
                  title={product.featured_prod_name}
                  productDescription={product.featured_prod_desc}
                  productImage={product.featured_prod_img}
                  linkToProduct={product.featured_prod_link}
                  ableToPurchaseOnline={product.show_feat_prod_link}
                />
              );
            })}

          <ReversibleContentBlock
            blockVersion="reverse"
            title="Benefits of Balancing"
            text={<BenefitsOfBalancing />}
            image={instrument295}
            caption={<ModelTwoNineFiveCaption />}
          />
        </div>

        <div className="row stnd-bottom-content">
          <div className="row balancing-page-row hide-on-mobile">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 balancing-page-col">
              <AccordionComponent
                defaultActiveKey={null}
                eventKeyOne="0"
                titleOne="Balancing Applications"
                contentOne={<ApplicationList />}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 balancing-page-col bal-img-col">
              <img
                src={coreLossTestingImg}
                alt="core loss testing"
                className="balancing-page-col-img"
              />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 balancing-page-col">
            {irdVideo &&
              irdVideo.map((video) => {
                return (
                  <YouTubeContainer
                    key={video.id}
                    smallContainer={false}
                    embedId={video.video_link_id}
                    title={video.video_title}
                  />
                );
              })}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
