import React from 'react';
import { useDispatch } from 'react-redux';
import { setShowMenu } from '../redux/controls';
import SideMenu from '../components/SideMenu/SideMenu';
import Footer from '../components/Footer/Footer';
import '../styles/blogPageStyle.css';

export default function Blog() {
  // const { show_menu } = useSelector((state) => state.controls);
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  return (
    <div className="blog-page-container">
      <SideMenu />
      <div className="blog-page-header">
        <h1 className="blog-page-header-text">Blog</h1>
      </div>
      <div className="blog-page-content" onClick={toggleMenu}>
        <div className="blog-page-left"></div>
        <div className="blog-page-right"></div>
      </div>
      <Footer />
    </div>
  );
}
