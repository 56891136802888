import React, { useState, useEffect } from 'react';
import { doc, deleteDoc } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import { db } from '../../firebase';
import { onSnapshot } from 'firebase/firestore';
import { useDispatch } from 'react-redux';
import { setProductServiceReminders } from '../../redux/controls';
import { productServRemRef } from '../../firebase';
import { Button } from 'react-bootstrap';
import BalanceMachineAppView from './BalanceMachineAppView';
import YouTubeContainer from '../YouTubeContainer/YouTubeContainer';
import ContactAndRequestViewer from './ContactAndRequestViewer';
import 'react-toastify/dist/ReactToastify.css';
import './adminInfoViewer.css';

import trashIcon from '../../assets/icons/trash.svg';

export default function AdminInfoViewer({ mode, data }) {
  const [dataTitle, setDataTitle] = useState('');
  const [sortedData, setSortedData] = useState(null);
  const [resetPage, setResetPage] = useState(false);
  // const dispatch = useDispatch();

  // const fetchProductServiceReminders = async () => {
  //   onSnapshot(productServRemRef, (snapshot) => {
  //     const results = snapshot.docs.map((doc) => {
  //       return { ...doc.data(), key: doc.id };
  //     });
  //     // console.log('results', results);
  //     dispatch(setProductServiceReminders(results));
  //   });
  // };

  const notifyDeletePost = () =>
    toast.success('Deleted successfully', {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });

  const notifyEmailSuccess = () =>
    toast.success('Reminder emails sent', {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });

  async function handleDeleteJobPost(postId) {
    const jobRef = doc(db, `job_postings`, postId);
    await deleteDoc(jobRef)
      .then(notifyDeletePost)
      .catch((error) => {
        alert(error);
      });
  }

  async function handleDeleteVideo(ref, postId) {
    const videoRef = doc(db, ref, postId);
    await deleteDoc(videoRef)
      .then(notifyDeletePost)
      .catch((error) => {
        alert(error);
      });
  }

  async function handleDeleteReminder(postId) {
    const productRemindersRef = doc(db, 'product_service_reminders', postId);
    await deleteDoc(productRemindersRef)
      .then(notifyDeletePost)
      .catch((error) => {
        alert(error);
      });
  }

  // eslint-disable-next-line no-unused-vars
  const localServer = 'http://127.0.0.1:5000/reminders';
  const irdServer = 'https://irdserver.onrender.com/reminders';

  async function sendEmailReminder(e) {
    e.preventDefault();
    console.log('sending request to server');
    let data = 'requesting email reminder';
    await fetch(irdServer, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      body: JSON.stringify(data),
    })
      .then((result) => {
        console.log(result.json());
      })
      .then(() => {
        alert('Reminder emails will be sent shortly');
      })
      .catch((error) => {
        console.log('Errors:', error);
      });
    setResetPage(true);
  }

  useEffect(() => {
    if (mode === 'featured') {
      setDataTitle('Current Featured Product Information');
    } else if (mode === 'job-postings') {
      setDataTitle('Current Job Postings');
    } else if (mode === 'bal-mach-apps') {
      setDataTitle('Current Balance Machine Applications');
    } else if (mode === 'ird-youtube-videos') {
      setDataTitle('Current IRD Videos');
    } else if (mode === 'customer-youtube-videos') {
      setDataTitle('Current Customer Videos');
    } else if (mode === 'extended-surveys') {
      setDataTitle('Extended Surveys');
    } else if (mode === 'feedback-surveys') {
      setDataTitle('Feedback Surveys');
    } else if (mode === 'rma-requests') {
      setDataTitle('RMA Requests');
    } else if (mode === 'shop-ird-surveys') {
      setDataTitle('IRD eCommerce Surveys');
    } else if (mode === 'product-service-reminders') {
      setDataTitle('Reminders');
    }
  }, [mode]);

  useEffect(() => {
    const dataForSorting = [...data];
    if (dataForSorting) {
      const sorted = dataForSorting.sort((a, b) => {
        return b.date - a.date;
      });
      setSortedData(sorted);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length]);

  useEffect(() => {
    if (resetPage) {
      notifyEmailSuccess();
      setResetPage(false);
      console.log('resetPage', resetPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPage]);

  // TODO: DELETE THIS CONSOLE LOG
  // console.log('sortedData', sortedData);

  const FeaturedData = ({ title, content, image, link }) => (
    <div className="admin-info-content-wrapper">
      <div className="aiv-wrapper">
        <h2 className="aiv-sub-title-attention">Title: </h2>
        <h2 className="aiv-sub-title">{title}</h2>
      </div>

      <h2 className="aiv-sub-title-attention">Description: </h2>
      <span className="aiv-text">{content}</span>
      <div className="aiv-wrapper">
        <h2 className="aiv-sub-title-attention">Link: </h2>
        <span className="aiv-link">{link ? link : 'no link'}</span>
      </div>
      <div className="aiv-img-wrapper">
        <img src={image} alt="data" className="aiv-img" />
      </div>
    </div>
  );

  const JobPostingData = ({ id, title, content }) => (
    <div className="admin-info-content-wrapper bottom-border" id={id}>
      <div className="aiv-wrapper">
        <h2 className="aiv-sub-title-attention">Title: </h2>
        <h2 className="aiv-sub-title">{title}</h2>
      </div>
      <div className="aiv-icon-wrapper">
        <img
          src={trashIcon}
          alt="delete icon"
          className="aiv-icon"
          onClick={() => handleDeleteJobPost(id)}
        />
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
      <h2 className="aiv-sub-title-attention">Description: </h2>
      <span className="aiv-text">{content}</span>
    </div>
  );

  const ReminderData = ({
    id,
    name,
    email,
    product,
    last_calib_date,
    reminder_date,
    reminder_email_sent,
  }) => {
    return (
      <div className="admin-info-content-wrapper" id={id}>
        <div className="row rem-row pd-10">
          <div className="rem-col col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <span className="rem-txt-bld">Name:</span>
            <span className="rem-txt-bld">Email:</span>
            <span className="rem-txt-bld">Product:</span>
            <span className="rem-txt-bld">Last Calibration Date:</span>
            <span className="rem-txt-bld">Reminder Date:</span>
            <span className="rem-txt-bld">Reminder Email Sent:</span>
          </div>
          <div className="rem-col col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <span className="rem-txt">{name}</span>
            <span className="rem-txt">{email}</span>
            <span className="rem-txt">{product}</span>
            <span className="rem-txt">
              {last_calib_date.toDate().toLocaleDateString()}
            </span>
            <span className="rem-txt">
              {reminder_date.toDate().toLocaleDateString()}
            </span>
            <span
              className={
                reminder_email_sent
                  ? 'rem-txt email-sent'
                  : 'rem-txt email-not-sent'
              }>
              {reminder_email_sent ? 'Yes' : 'No'}
            </span>
          </div>
          <div className="rem-col col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <div className="aiv-icon-wrapper">
              <img
                src={trashIcon}
                alt="delete icon"
                className="aiv-icon"
                onClick={() => handleDeleteReminder(id)}
              />
            </div>
          </div>
        </div>

        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    );
  };

  return (
    <div className="admin-info-container" key={mode}>
      <div className="row">
        <div className="aiv-data-title-wrapper">
          <h2 className="aiv-data-title">{dataTitle}</h2>
        </div>
      </div>
      <div className="row mb-20">
        {mode === 'product-service-reminders' && data.length > 0 && (
          <Button
            className="job-posting-submit-button"
            type="submit"
            onClick={sendEmailReminder}>
            Send Email Reminders
          </Button>
        )}
      </div>

      {mode === 'featured' &&
        data.map((item) => {
          return (
            <FeaturedData
              title={item.featured_prod_name}
              content={item.featured_prod_desc}
              link={item.featured_prod_link}
              image={item.featured_prod_img}
            />
          );
        })}
      {mode === 'job-postings' &&
        sortedData &&
        sortedData.map((item) => {
          return (
            <JobPostingData
              key={item.id}
              id={item.id}
              title={item.job_title}
              content={item.job_description}
            />
          );
        })}

      {mode === 'ird-youtube-videos' &&
        sortedData &&
        sortedData.map((video) => {
          return (
            <YouTubeContainer
              key={video.id}
              id={video.id}
              smallContainer={true}
              embedId={video.video_link_id}
              title={video.video_title}
              admin={true}
              handleDeleteVideo={handleDeleteVideo}
              arg="ird_videos"
            />
          );
        })}

      {mode === 'customer-youtube-videos' &&
        sortedData &&
        sortedData.map((video) => {
          return (
            <YouTubeContainer
              key={video.id}
              id={video.id}
              smallContainer={true}
              embedId={video.video_link_id}
              title={video.video_title}
              admin={true}
              handleDeleteVideo={handleDeleteVideo}
              arg="customer_util_videos"
            />
          );
        })}

      {mode === 'extended-surveys' &&
        sortedData &&
        sortedData.map((item) => {
          return (
            <ContactAndRequestViewer
              key={item.form_id}
              mode={mode}
              formId={item.form_id}
              date={item.date}
              name={item.first_name}
              companyName={item.company_name}
              jobDetails={item.job_details}
              equipmentDetails={item.equipment}
              email={item.email}
              likeBefore={item.like_before}
              problemsToSolve={item.problems_to_solve}
              standOut={item.stand_out}
              obstacles={item.obstacles}
              madeHappiest={item.made_happiest}
              exceededExpectations={item.exceeded_expectations}
              achievements={item.achievements}
              recommendedReasons={item.recommended_reasons}
              anywhereToImprove={item.anywhere_to_improve}
              startSearchAndHearAboutUs={item.start_search_and_hear_about_us}
              message={item.message}
            />
          );
        })}

      {mode === 'feedback-surveys' &&
        sortedData &&
        sortedData.map((item) => {
          return (
            <ContactAndRequestViewer
              key={item.form_id}
              mode={mode}
              formId={item.form_id}
              likelyToRecommend={item.likely_to_recommend}
              satisfaction={item.satisfaction}
              describeProducts={item.describe_products}
              productsMeetNeeds={item.products_meet_needs}
              moneyValueOfProducts={item.money_value_of_products}
              responsive={item.responsive}
              howLongACustomer={item.how_long_a_customer}
              howLikelyToPurchaseAgain={item.how_likely_to_purchase_again}
              message={item.message}
              createdOn={item.created_on}
            />
          );
        })}

      {mode === 'rma-requests' &&
        sortedData &&
        sortedData.map((item) => {
          return (
            <ContactAndRequestViewer
              key={item.form_id}
              mode={mode}
              formId={item.form_id}
              firstName={item.first_name}
              companyName={item.company_name}
              email={item.email}
              phone={item.phone}
              shippingInfo={item.shipping_info}
              po={item.po}
              sendingInstruments={item.sending_instruments}
              quantityInstruments={item.quantity_instruments}
              serialNumbers={item.serial_numbers}
              sendingSensors={item.sending_sensors}
              sensorsToBeSent={item.sensors_to_be_sent}
              quantitySensors={item.quantity_sensors}
              sendingAccessories={item.sending_accessories}
              accessoriesToBeSent={item.accessories_to_be_sent}
              disclaimerOneAcknowledged={item.disclaimer_one_acknowledged}
              disclaimerTwoAcknowledged={item.disclaimer_two_acknowledged}
              disclaimerThreeAcknowledged={item.diclaimer_three_acknowledged}
              interestedInServices={item.interested_in_services}
              issuesOrConcerns={item.issues_or_concerns}
              wantRentalUnit={item.want_rental_unit}
              rentalModel={item.rental_model}
              wantAccessoryKit={item.want_accessory_kit}
              message={item.message}
              createdOn={item.created_on}
            />
          );
        })}

      {mode === 'shop-ird-surveys' &&
        sortedData &&
        sortedData.map((item) => {
          return (
            <ContactAndRequestViewer
              key={item.form_id}
              mode={mode}
              formId={item.form_id}
              howHappyWithExperiences={item.how_happy_with_experience}
              siteDesignRating={item.site_design_rating}
              siteFindNeedRating={item.site_find_need_rating}
              siteProductDescriptionRating={
                item.site_product_description_rating
              }
              placedOrderFrom={item.placed_order_from}
              likelyToUseStoreAgain={item.likely_to_use_store_again}
              howLongACustomer={item.how_long_a_customer}
              likelyToRecommend={item.likely_to_recommend}
              message={item.message}
              createdOn={item.created_on}
            />
          );
        })}

      {mode === 'product-service-reminders' &&
        sortedData &&
        sortedData.map((item) => {
          return (
            <ReminderData
              key={item.id}
              id={item.id}
              last_calib_date={item.last_calib_date}
              reminder_date={item.reminder_date}
              reminder_email_sent={item.reminder_email_sent}
              name={item.customer_name}
              email={item.email}
              product={item.product}
            />
          );
        })}

      {mode === 'bal-mach-apps' &&
        sortedData &&
        sortedData.map((item) => {
          return (
            <BalanceMachineAppView
              key={item.form_id}
              form_id={item.form_id}
              date={item.date}
              contact_person={item.contact_person}
              title={item.title}
              email={item.email}
              phone={item.phone}
              fax={item.fax}
              company_name={item.company_name}
              company_address={item.company_address}
              city={item.city}
              state={item.state}
              postal_code={item.postal_code}
              country={item.country}
              sec_2_1_fan={item.sec_2_1_fan}
              sec_2_1_armature={item.sec_2_1_armature}
              sec_2_1_turbine={item.sec_2_1_turbine}
              sec_2_1_roll={item.sec_2_1_roll}
              sec_2_1_impeller={item.sec_2_1_impeller}
              sec_2_1_spindle={item.sec_2_1_spindle}
              sec_2_1_disk={item.sec_2_1_disk}
              sec_2_1_crankshaft={item.sec_2_1_crankshaft}
              sec_2_1_flywheel={item.sec_2_1_flywheel}
              sec_2_1_other={item.sec_2_1_other}
              sec_2_1_other_details={item.sec_2_1_other_detail}
              sec_2_2_between_bearings={item.sec_2_2_between_bearings}
              sec_2_2_overhung={item.sec_2_2_overhung}
              sec_2_2_between_bearings_and_overhung={
                item.sec_2_2_between_bearings_and_overhun
              }
              sec_2_3_max_upward_force={item.sec_2_3_max_upward_force}
              sec_2_3_unit_kg={item.sec_2_3_unit_kg}
              sec_2_3_unit_lb={item.sec_2_3_unit_lb}
              sec_2_3_not_sure={item.sec_2_3_not_sure}
              sec_2_4_yes={item.sec_2_4_yes}
              sec_2_4_no={item.sec_2_4_no}
              sec_2_4_both={item.sec_2_4_both}
              sec_2_5_max_rotor_weight={item.sec_2_5_max_rotor_weight}
              sec_2_5_unit_kg={item.sec_2_5_unit_kg}
              sec_2_5_unit_lb={item.sec_2_5_unit_lb}
              sec_2_6_min_rotor_weight={item.sec_2_6_min_rotor_weight}
              sec_2_6_unit_kg={item.sec_2_6_unit_kg}
              sec_2_6_unit_lb={item.sec_2_6_unit_lb}
              sec_2_7_max_swing_diameter={item.sec_2_7_max_swing_diameter}
              sec_2_7_unit_mm={item.sec_2_7_unit_mm}
              sec_2_7_unit_in={item.sec_2_7_unit_in}
              sec_2_8_max_rotor_length={item.sec_2_8_max_rotor_length}
              sec_2_8_unit_mm={item.sec_2_8_unit_mm}
              sec_2_8_unit_in={item.sec_2_8_unit_in}
              sec_2_9_min_rotor_length={item.sec_2_9_min_rotor_length}
              sec_2_9_unit_mm={item.sec_2_9_unit_mm}
              sec_2_9_unit_in={item.sec_2_9_unit_in}
              sec_2_10_max_dist_between_journals={
                item.sec_2_10_max_dist_between_journals
              }
              sec_2_10_unit_mm={item.sec_2_10_unit_mm}
              sec_2_10_unit_in={item.sec_2_10_unit_in}
              sec_2_11_min_dist_between_journals={
                item.sec_2_11_min_dist_between_journals
              }
              sec_2_11_unit_mm={item.sec_2_11_unit_mm}
              sec_2_11_unit_in={item.sec_2_11_unit_in}
              sec_2_12_own_bearing={item.sec_2_12_own_bearing}
              sec_2_12_journal_surface={item.sec_2_12_journal_surface}
              sec_2_13_max_journal_diam={item.sec_2_13_max_journal_diam}
              sec_2_13_unit_mm={item.sec_2_13_unit_mm}
              sec_2_13_unit_in={item.sec_2_13_unit_in}
              sec_2_14_min_journal_diam={item.sec_2_14_min_journal_diam}
              sec_2_14_unit_mm={item.sec_2_14_unit_mm}
              sec_2_14_unit_in={item.sec_2_14_unit_in}
              sec_2_15_min_journal_width={item.sec_2_15_min_journal_width}
              sec_2_15_unit_mm={item.sec_2_15_unit_mm}
              sec_2_15_unit_in={item.sec_2_15_unit_in}
              sec_2_16_max_moment_of_inertia={
                item.sec_2_16_max_moment_of_inertia
              }
              sec_2_16_unit_kgm2={item.sec_2_16_unit_kgm2}
              sec_2_16_unit_lbft2={item.sec_2_16_unit_lbft2}
              sec_2_16_unit_not_sure={item.sec_2_16_unit_not_sure}
              sec_2_17_speed_range_rotors={item.sec_2_17_speed_range_rotors}
              sec_2_18_max_power_absorbed={item.sec_2_18_max_power_absorbed}
              sec_2_18_unit_kw={item.sec_2_18_unit_kw}
              sec_2_18_unit_hp={item.sec_2_18_unit_hp}
              sec_2_18_unit_not_sure={item.sec_2_18_unit_not_sure}
              sec_2_19_max_bal_speed_rolls={item.sec_2_19_max_bal_speed_rolls}
              sec_2_19_unit_mmin={item.sec_2_19_unit_mmin}
              sec_2_19_unit_ftmin={item.sec_2_19_unit_ftmin}
              sec_2_20_high_int_yes={item.sec_2_20_high_int_yes}
              sec_2_20_high_int_no={item.sec_2_20_high_int_no}
              sec_3_1_one_plane={item.sec_3_1_one_plane}
              sec_3_1_two_plane={item.sec_3_1_two_plane}
              sec_3_1_both_planes={item.sec_3_1_both_planes}
              sec_3_2_tolerance_amount={item.sec_3_2_tolerance_amount}
              sec_3_2_unit_iso={item.sec_3_2_unit_iso}
              sec_3_2_unit_api={item.sec_3_2_unit_api}
              sec_3_2_unit_gmm={item.sec_3_2_unit_gmm}
              sec_3_2_unit_ozin={item.sec_3_2_unit_ozin}
              sec_3_3_max_init_unbal={item.sec_3_3_max_init_unbal}
              sec_3_3_unit_gin={item.sec_3_3_unit_gin}
              sec_3_3_unit_gmm={item.sec_3_3_unit_gmm}
              sec_3_3_unit_ozin={item.sec_3_3_unit_ozin}
              sec_3_3_unit_not_sure={item.sec_3_3_unit_not_sure}
              sec_3_4_num_rotors_bal={item.sec_3_4_num_rotors_bal}
              sec_4_5_ac_volt={item.sec_4_5_ac_volt}
              sec_4_5_phase={item.sec_4_5_phase}
              sec_4_5_hz={item.sec_4_5_hz}
              sec_4_6_belt={item.sec_4_6_belt}
              sec_4_6_end_drive={item.sec_4_6_end_drive}
              sec_4_7_drive_power_req={item.sec_4_7_drive_power_req}
              sec_4_7_unit_kw={item.sec_4_7_unit_kw}
              sec_4_7_unit_hp={item.sec_4_7_unit_hp}
              sec_4_7_unit_not_sure={item.sec_4_7_unit_not_sure}
              sec_4_8_var_speed={item.sec_4_8_var_speed}
              sec_4_9_portable={item.sec_4_9_portable}
              sec_4_9_digital={item.sec_4_9_digital}
              sec_4_9_computer={item.sec_4_9_computer}
              sec_4_10_cert_yes={item.sec_4_10_cert_yes}
              sec_4_10_cert_no={item.sec_4_10_cert_no}
              sec_4_10_cert_not_sure={item.sec_4_10_cert_not_sure}
              sec_4_11_safety_none={item.sec_4_11_safety_none}
              sec_4_11_safety_perimeter_fence={
                item.sec_4_11_safety_perimeter_fence
              }
              sec_5_2_bearing_details={item.sec_5_2_bearing_details}
              sec_5_3_other_info={item.sec_5_3_other_info}
              sec_5_4_additional_req={item.sec_5_4_additional_req}
            />
          );
        })}
    </div>
  );
}
