import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowMenu } from '../redux/controls';
import SideMenu from '../components/SideMenu/SideMenu';
import NewBalancingMachineForm from '../components/NewBalancingMachineAppForm/NewBalancingMachineForm';
import Footer from '../components/Footer/Footer';
import '../styles/newBalanceMachineAppPageStyle.css';

export default function NewBalanceMachineApp() {
  const { has_paid } = useSelector((state) => state.controls);
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  return (
    <div className="new-balance-machine-app-page-container">
      {has_paid && <SideMenu />}
      <div className="new-balance-machine-app-page-header">
        <h1 className="new-balance-machine-app-page-header-text">
          IRD New Balancing Machine Application Requirements Form
        </h1>
      </div>
      <div
        className="new-balance-machine-app-page-content"
        onClick={toggleMenu}>
        {has_paid && <NewBalancingMachineForm />}
      </div>

      <Footer />
    </div>
  );
}
