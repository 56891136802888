import React from 'react';
import { useDispatch } from 'react-redux';
import { setShowMenu } from '../redux/controls';
import SideMenu from '../components/SideMenu/SideMenu';
import TopMenu from '../components/TopMenu/TopMenu';
import ShopIrdSurvey from '../components/ContactForm/ShopIrdSurvey';
import Footer from '../components/Footer/Footer';
import '../styles/standardPageStyle.css';

export default function ShopIrdSurveyPage() {
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  return (
    <div className="container-fluid stnd-container">
      <SideMenu />
      <TopMenu />

      <div className="stnd-header">
        <h1 className="stnd-h1 margin-left-two-percent">Shop IRD Survey</h1>
      </div>
      <div className="row stnd-content" onClick={toggleMenu}>
        <div className="row stnd-top-content flex-row-center">
          <ShopIrdSurvey />
        </div>

        <div className="row stnd-middle-content-flex-col"></div>

        <div className="row stnd-bottom-content"></div>
      </div>

      <Footer />
    </div>
  );
}
