import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowMenu } from '../redux/controls';
import SideMenu from '../components/SideMenu/SideMenu';
import TopMenu from '../components/TopMenu/TopMenu';
import YouTubeContainer from '../components/YouTubeContainer/YouTubeContainer';
import Footer from '../components/Footer/Footer';
import '../styles/standardPageStyle.css';

export default function TrainingVideos() {
  const { ird_videos } = useSelector((state) => state.controls);
  const [balancingVideos, setBalancingVideos] = useState(null);
  const [cltVideos, setCltVideos] = useState(null);

  let length;
  if (ird_videos) {
    length = ird_videos.length;
  }

  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  useEffect(() => {
    if (ird_videos) {
      const balVids = ird_videos.filter(
        (vid) => vid.category === 'dynamic-balancing'
      );
      const cltVids = ird_videos.filter(
        (vid) => vid.category === 'core-loss-testing'
      );
      setCltVideos(cltVids);
      setBalancingVideos(balVids);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length]);

  return (
    <div className="container-fluid stnd-container">
      <SideMenu />
      <TopMenu />
      <div className="stnd-header">
        <h1 className="stnd-h1 margin-left-two-percent">Training Videos</h1>
      </div>
      <div className="row stnd-content" onClick={toggleMenu}>
        <div className="row stnd-top-content">
          <span className="stnd-text stnd-align-left"></span>
        </div>

        <div className="row stnd-middle-content-flex-col">
          <h2 className="stnd-h2 margin-left-two-percent">
            Balancing Training
          </h2>
          <div className="row stnd-content-wrapper-flex-row">
            {balancingVideos &&
              balancingVideos.map((video) => {
                return (
                  <YouTubeContainer
                    key={video.id}
                    smallContainer={true}
                    embedId={video.video_link_id}
                    title={video.video_title}
                  />
                );
              })}
          </div>

          <h2 className="stnd-h2 margin-left-two-percent mt-ten-percent margin-bottom-thirty">
            CLT Training
          </h2>
          <div className="row stnd-content-wrapper-flex-row">
            {cltVideos &&
              cltVideos.map((video) => {
                return (
                  <YouTubeContainer
                    key={video.id}
                    smallContainer={true}
                    embedId={video.video_link_id}
                    title={video.video_title}
                  />
                );
              })}
          </div>
        </div>

        <div className="row stnd-bottom-content flex-col"></div>
      </div>

      <Footer />
    </div>
  );
}
