import magneticPaperImg from '../assets/images/magnetic-paper.png';
import cartIcon from '../assets/icons/shopping-cart-black.svg';
import magneticPaperDoc from '../assets/pdf-docs/magnetic-paper/magnetic-paper.pdf';

export const MAGNETIC_PAPER = [
  {
    part_num: 'L00400',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/157/421/12_in._by_specified_length_magnetic_paper__77660.1542646106.png?c=2',
    description: 'Details',
    description_details: `Magnetic Paper, 12" x 12" (305mm x 305mm)`,
    href_to_model_doc: magneticPaperDoc,
    purchase: 'Purchase',
    purchase_link:
      'https://shop.irdproducts.com/lexseco-magnetic-paper-12-x-specified-length/',
    purchase_icon: cartIcon,
  },
  {
    part_num: 'L00401',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/157/421/12_in._by_specified_length_magnetic_paper__77660.1542646106.png?c=2',
    description: 'Details',
    description_details: `Magnetic Paper, 12" (305mm) wide, sold in
        user specified increment lengths of 12" (305mm)
        `,
    href_to_model_doc: magneticPaperDoc,
    purchase: 'Purchase',
    purchase_link:
      'https://shop.irdproducts.com/lexseco-magnetic-paper-12-x-specified-length/',
    purchase_icon: cartIcon,
  },
  {
    part_num: 'L00402',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/157/421/12_in._by_specified_length_magnetic_paper__77660.1542646106.png?c=2',
    description: 'Details',
    description_details: `Magnetic Paper, 18" (457mm) wide, sold in
        user specified increment lengths of 12" (305mm)`,
    href_to_model_doc: magneticPaperDoc,
    purchase: 'Purchase',
    purchase_link:
      'https://shop.irdproducts.com/lexseco-magnetic-paper-12-x-specified-length/',
    purchase_icon: cartIcon,
  },
  {
    part_num: 'L00450',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/115/508/L00450-Magnetic-Wand__61685.1542731172.jpg?c=2',
    description: 'Details',
    description_details: 'Magnetic Wand - used to reset magnetic paper',
    href_to_model_doc: magneticPaperDoc,
    purchase: 'Purchase',
    purchase_link:
      'https://shop.irdproducts.com/magnetic-wand-for-lexseco-magnetic-paper/',
    purchase_icon: cartIcon,
  },
];
