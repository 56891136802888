import React from 'react';
import { useSelector } from 'react-redux';
import SideMenu from '../components/SideMenu/SideMenu';
import LoginForm from '../components/LoginForm/LoginForm';
import Footer from '../components/Footer/Footer';
import '../styles/loginStyle.css';

export default function Login() {
  const { has_paid } = useSelector((state) => state.controls);
  return (
    <div className="container-fluid login-container">
      {has_paid && <SideMenu />}
      <div className="login-page-content">{has_paid && <LoginForm />}</div>

      <Footer />
    </div>
  );
}
