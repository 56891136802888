import React from 'react';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './contactAndRequestViewer.css';
import trashIcon from '../../assets/icons/trash.svg';
// import { type } from '@testing-library/user-event/dist/type';

const notifyDelete = () =>
  toast.success('Delete successful', {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  });

async function handleDelete(collection_ref, postId) {
  const postRef = doc(db, collection_ref, postId);
  await deleteDoc(postRef)
    .then(() => notifyDelete())
    .catch((error) => {
      alert(error);
    });
}

const ExtendedSurveyViewer = ({
  formId,
  name,
  companyName,
  createdOn,
  jobDetails,
  email,
  equipmentDetails,
  likeBefore,
  problemsToSolve,
  standOut,
  obstacles,
  madeHappiest,
  exceededExpectations,
  achievements,
  recommendedReasons,
  anywhereToImprove,
  startSearchAndHearAboutUs,
  message,
}) => (
  <div className="crv-container">
    <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
    <div className="row">
      <div className="crv-icon-wrapper">
        <img
          src={trashIcon}
          alt="trash icon"
          className="crv-trash-icon"
          onClick={() => handleDelete('extended_surveys', formId)}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Form ID:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {formId}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Date:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {createdOn}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Name:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">{name}</div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Company Name:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {companyName}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Job Details:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {jobDetails}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Email:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">{email}</div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">IRD Equipment:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {equipmentDetails}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">What was it like before?</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {likeBefore}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">
          Trying to solve the following problems:
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {problemsToSolve}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">What made IRD stand out?</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {standOut}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">
          Obstacles that almost prevented from buying products/services:
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {obstacles}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">
          What made you happiest about working with IRD?
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {madeHappiest}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">
          What exceeded your expectations since working with IRD?
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {exceededExpectations}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">
          What have you been able to achieve since using IRD products/services?
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {achievements}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">
          Main reason to recommend IRD products/services:
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {recommendedReasons}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Anywhere to improve:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {anywhereToImprove}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Started search or hear about IRD:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {startSearchAndHearAboutUs}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Other comments:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {message}
      </div>
    </div>
  </div>
);

const FeedbackViewer = ({
  formId,
  likelyToRecommend,
  satisfaction,
  describeProducts,
  productsMeetNeeds,
  moneyValueOfProducts,
  responsive,
  howLongACustomer,
  howLikelyToPurchaseAgain,
  message,
  createdOn,
}) => (
  <div className="crv-container">
    <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
    <div className="crv-icon-wrapper">
      <img
        src={trashIcon}
        alt="trash icon"
        className="crv-trash-icon"
        onClick={() => handleDelete('feedback_surveys', formId)}
      />
    </div>
    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Form ID:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {formId}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Date:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {createdOn}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Likeliness to recommend:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {likelyToRecommend}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Satisfaction:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {satisfaction}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Words to describe products:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {describeProducts}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">How well products meet needs:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {productsMeetNeeds}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Money value for product:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {moneyValueOfProducts}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Responsiveness:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {responsive}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Been a customer for:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {howLongACustomer}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Likeliness to purchase again:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {howLikelyToPurchaseAgain}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Message:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {message}
      </div>
    </div>
  </div>
);

const RmaRequestsViewer = ({
  mode,
  formId,
  firstName,
  companyName,
  email,
  phone,
  shippingInfo,
  po,
  sendingInstruments,
  quantityInstruments,
  serialNumbers,
  sendingSensors,
  sensorsToBeSent,
  quantitySensors,
  sendingAccessories,
  accessoriesToBeSent,
  disclaimerOneAcknowledged,
  disclaimerTwoAcknowledged,
  disclaimerThreeAcknowledged,
  interestedInServices,
  issuesOrConcerns,
  wantRentalUnit,
  rentalModel,
  wantAccessoryKit,
  message,
  createdOn,
}) => {
  const qtyInstrumentsArr = Object.keys(quantityInstruments);
  const qtyInstrumentsObj = qtyInstrumentsArr.map((key) => {
    return { [key]: quantityInstruments[key] };
  });
  const serialNumsArr = Object.keys(serialNumbers);
  const serialNumsObj = serialNumsArr.map((key) => {
    return { [key]: serialNumbers[key] };
  });
  const qtySensorsArr = Object.keys(quantitySensors);
  const qtySensorsObj = qtySensorsArr.map((key) => {
    return { [key]: quantitySensors[key] };
  });

  const qtyInstrumentsArrToStr = [];
  const serialNumsArrToStr = [];
  const qtySensorsArrToStr = [];

  qtyInstrumentsObj.forEach((item) => {
    let tempStr = '';
    const keys = Object.keys(item);
    // const values = Object.values(item);
    // console.log('keys', keys);
    // console.log('values', values);

    if (keys.includes('qty_206')) {
      tempStr = `Qty 206: ${item.qty_206},`;
      qtyInstrumentsArrToStr.push(tempStr);
    }

    if (keys.includes('qty_258')) {
      tempStr = `Qty 258: ${item.qty_258},`;
      qtyInstrumentsArrToStr.push(tempStr);
    }

    if (keys.includes('qty_236')) {
      tempStr = `Qty 236: ${item.qty_236},`;
      qtyInstrumentsArrToStr.push(tempStr);
    }

    if (keys.includes('qty_246')) {
      tempStr = `Qty 246: ${item.qty_246},`;
      qtyInstrumentsArrToStr.push(tempStr);
    }

    if (keys.includes('qty_290_III')) {
      tempStr = `Qty 290 III: ${item.qty_290_III},`;
      qtyInstrumentsArrToStr.push(tempStr);
    }

    if (keys.includes('qty_290_295_plus')) {
      tempStr = `Qty 290/295+: ${item.qty_290_295_plus},`;
      qtyInstrumentsArrToStr.push(tempStr);
    }

    if (keys.includes('qty_other')) {
      tempStr = `Qty Other: ${item.qty_other},`;
      qtyInstrumentsArrToStr.push(tempStr);
    }
  });

  serialNumsObj.forEach((item) => {
    let tempStr = '';
    const keys = Object.keys(item);
    // const values = Object.values(item);
    // console.log('keys', keys);
    // console.log('values', values);

    if (keys.includes('206')) {
      tempStr = `206 s/n: ${item['206']},`;
      serialNumsArrToStr.push(tempStr);
    }

    if (keys.includes('258')) {
      tempStr = `258 s/n: ${item['258']},`;
      serialNumsArrToStr.push(tempStr);
    }

    if (keys.includes('236')) {
      tempStr = `236 s/n: ${item['236']},`;
      serialNumsArrToStr.push(tempStr);
    }

    if (keys.includes('246')) {
      tempStr = `246 s/n: ${item['246']},`;
      serialNumsArrToStr.push(tempStr);
    }

    if (keys.includes('290_III')) {
      tempStr = `290 III s/n: ${item['290_III']},`;
      serialNumsArrToStr.push(tempStr);
    }

    if (keys.includes('290_295_plus')) {
      tempStr = `290/295+ s/n: ${item['290_295_plus']},`;
      serialNumsArrToStr.push(tempStr);
    }

    if (keys.includes('other')) {
      tempStr = `Other s/n: ${item['other']},`;
      serialNumsArrToStr.push(tempStr);
    }
  });

  qtySensorsObj.forEach((item) => {
    let tempStr = '';
    const keys = Object.keys(item);
    // const values = Object.values(item);
    // console.log('keys', keys);
    // console.log('values', values);

    if (keys.includes('qty_546')) {
      tempStr = `Qty 546: ${item['qty_546']},`;
      qtySensorsArrToStr.push(tempStr);
    }

    if (keys.includes('qty_544')) {
      tempStr = `Qty 544: ${item['qty_544']},`;
      qtySensorsArrToStr.push(tempStr);
    }

    if (keys.includes('qty_544M')) {
      tempStr = `Qty 544M: ${item['qty_544M']},`;
      qtySensorsArrToStr.push(tempStr);
    }

    if (keys.includes('qty_9000a')) {
      tempStr = `Qty 9000a: ${item['qty_9000a']},`;
      qtySensorsArrToStr.push(tempStr);
    }

    if (keys.includes('290_III')) {
      tempStr = `290 III s/n: ${item['290_III']},`;
      qtySensorsArrToStr.push(tempStr);
    }

    if (keys.includes('qty_other')) {
      tempStr = `Qty other: ${item['qty_other']},`;
      qtySensorsArrToStr.push(tempStr);
    }
  });
  // console.log('qtyInstrumentsArrToStr', qtyInstrumentsArrToStr);

  return (
    <div className="crv-container">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="crv-icon-wrapper">
        <img
          src={trashIcon}
          alt="trash icon"
          className="crv-trash-icon"
          onClick={() => handleDelete('rma_requests', formId)}
        />
      </div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Form ID:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {formId}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Date:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {createdOn}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">First name:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {firstName}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Company name:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {companyName}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Email:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {email}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Phone:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {phone}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Shipping info:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {shippingInfo}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">PO:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">{po}</div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Sending instruments:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {sendingInstruments}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Quantity of instruments:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {qtyInstrumentsArrToStr.join(' ')}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Serial numbers:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {serialNumsArrToStr.join(' ')}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Sending sensors:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {sendingSensors ? 'Yes' : 'No'}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Sensors to be sent:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {sensorsToBeSent}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Quantity of sensors:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {qtySensorsArrToStr.join(' ')}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Sending accessories:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {sendingAccessories ? 'Yes' : 'No'}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Accessories to be sent:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {accessoriesToBeSent}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Disclaimer one acknowledged:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {disclaimerOneAcknowledged ? 'Yes' : 'No'}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Disclaimer two acknowledged:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {disclaimerTwoAcknowledged ? 'Yes' : 'No'}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Disclaimer three acknowledged:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {disclaimerThreeAcknowledged ? 'Yes' : 'No'}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Interested in services:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {interestedInServices}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Issues or concerns:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {issuesOrConcerns}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Customer wants rental unit:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {wantRentalUnit ? 'Yes' : 'No'}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Rental model:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {rentalModel}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Customer wants accessory kit:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {wantAccessoryKit ? 'Yes' : 'No'}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <span className="crv-bold-text">Message:</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          {message}
        </div>
      </div>

      {/* END */}
    </div>
  );
};

const EcommerceSurveyViewer = ({
  formId,
  createdOn,
  howHappyWithExperiences,
  siteDesignRating,
  siteFindNeedRating,
  siteProductDescriptionRating,
  placedOrderFrom,
  likelyToUseStoreAgain,
  howLongACustomer,
  likelyToRecommend,
}) => (
  <div className="crv-container">
    <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
    <div className="crv-icon-wrapper">
      <img
        src={trashIcon}
        alt="trash icon"
        className="crv-trash-icon"
        onClick={() => handleDelete('shop_ird_surveys', formId)}
      />
    </div>
    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Form ID:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {formId}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Date:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {createdOn}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">
          Experience rating at shop.irdproducts.com:
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {howHappyWithExperiences}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Site design rating:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {siteDesignRating}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">
          Rating on finding what customer needed:
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {siteFindNeedRating}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Product description rating:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {siteProductDescriptionRating}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Placed order from device:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {placedOrderFrom}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">How likely to use store again:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {likelyToUseStoreAgain}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">Been a customer for:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {howLongACustomer}
      </div>
    </div>

    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <span className="crv-bold-text">How likely to recommend IRD:</span>
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        {likelyToRecommend}
      </div>
    </div>
  </div>
);

export default function ContactAndRequestViewer(props) {
  if (props.mode === 'extended-surveys') {
    return (
      <ExtendedSurveyViewer
        formId={props.formId}
        createdOn={props.createdOn}
        name={props.name}
        companyName={props.companyName}
        jobDetails={props.jobDetails}
        equipmentDetails={props.equipmentDetails}
        email={props.email}
        likeBefore={props.likeBefore}
        problemsToSolve={props.problemsToSolve}
        standOut={props.standOut}
        obstacles={props.obstacles}
        madeHappiest={props.madeHappiest}
        exceededExpectations={props.exceededExpectations}
        achievements={props.achievements}
        recommendedReasons={props.recommendedReasons}
        anywhereToImprove={props.anywhereToImprove}
        startSearchAndHearAboutUs={props.startSearchAndHearAboutUs}
        message={props.message}
      />
    );
  } else if (props.mode === 'feedback-surveys') {
    return (
      <FeedbackViewer
        formId={props.formId}
        likelyToRecommend={props.likelyToRecommend}
        satisfaction={props.satisfaction}
        describeProducts={props.describeProducts}
        productsMeetNeeds={props.productsMeetNeeds}
        moneyValueOfProducts={props.moneyValueOfProducts}
        responsive={props.responsive}
        howLongACustomer={props.howLongACustomer}
        howLikelyToPurchaseAgain={props.howLikelyToPurchaseAgain}
        message={props.message}
        createdOn={props.createdOn}
      />
    );
  } else if (props.mode === 'rma-requests') {
    return (
      <RmaRequestsViewer
        formId={props.formId}
        firstName={props.firstName}
        companyName={props.companyName}
        email={props.email}
        phone={props.phone}
        shippingInfo={props.shippingInfo}
        po={props.po}
        sendingInstruments={props.sendingInstruments}
        quantityInstruments={props.quantityInstruments}
        serialNumbers={props.serialNumbers}
        sendingSensors={props.sendingSensors}
        sensorsToBeSent={props.sensorsToBeSent}
        quantitySensors={props.quantitySensors}
        sendingAccessories={props.sendingAccessories}
        accessoriesToBeSent={props.accessoriesToBeSent}
        disclaimerOneAcknowledged={props.disclaimerOneAcknowledged}
        disclaimerTwoAcknowledged={props.disclaimerTwoAcknowledged}
        disclaimerThreeAcknowledged={props.disclaimerThreeAcknowledged}
        interestedInServices={props.interestedInServices}
        issuesOrConcerns={props.issuesOrConcerns}
        wantRentalUnit={props.wantRentalUnit}
        rentalModel={props.rentalModel}
        wantAccessoryKit={props.wantAccessoryKit}
        message={props.message}
        createdOn={props.createdOn}
        handleDelete={props.handleDelete}
      />
    );
  } else if (props.mode === 'shop-ird-surveys') {
    return (
      <EcommerceSurveyViewer
        formId={props.formId}
        howHappyWithExperiences={props.howHappyWithExperiences}
        siteDesignRating={props.siteDesignRating}
        siteFindNeedRating={props.siteFindNeedRating}
        siteProductDescriptionRating={props.siteProductDescriptionRating}
        placedOrderFrom={props.placedOrderFrom}
        likelyToUseStoreAgain={props.likelyToUseStoreAgain}
        howLongACustomer={props.howLongACustomer}
        likelyToRecommend={props.likelyToRecommend}
        message={props.message}
        createdOn={props.createdOn}
      />
    );
  } else {
    return <div className="crv-container">content</div>;
  }
}
