export const LINKS = {
  home: '/',
  login: '/login',
  about: '/about',
  contact: '/contact',
  admin: '/admin',
  career: '/careers',
  disclaimer_and_privacty_agreement: '/disclaimer-and-privacy-agreement',
  ird_balancing: '/ird-balancing',
  ird_balancing_products: '/ird-balancing-products',
  new_balance_machine_app: '/new-balance-machine-app',
  soft_bearing_balance_machines: '/soft-bearing-balance-machines',
  balancing_instruments: '/balancing-instruments',
  balancing_training: '/balancing-training',
  balancing_equipment: '/balancing-equipment',
  balancing_machines_info: '/balancing-machines-info',
  unbalance: '/unbalance',
  service: '/service',
  rentals: '/rentals',
  meter_exchange: '/meter-exchange',
  customer_utilization: '/customer-utilization',
  refurbished: '/refurbished',
  refurbished_machines: '/refurbished-machines',
  new_products: '/new-products',
  sensors: '/sensors',
  parts_and_accessories: '/parts-and-accessories',
  lexseco_advantage: '/lexseco-advantage',
  lexseco_products: '/lexseco-products',
  core_loss_testing: '/core-loss-testing',
  core_evaluation: '/core-evaluation',
  clt_upgrades: 'http://try.irdproducts.com/lexseco-clt-upgrade/',
  clt_training: '/clt-training',
  faq: '/faq',
  legacy_products: `/legacy-products`,
  training_videos: `/training-videos`,
  lexseco_clt_upgrades: `/lexseco-clt-upgrades`,
  shop_ird_survey: `/shop-ird-survey`,
  in_depth_survey: '/extended-survey',
  feedback_survey: '/feedback-survey',
  rma_request: '/rma-request',
  shop: 'https://shop.irdproducts.com/',
  blog: 'https://shop.irdproducts.com/blog/',
  clt_training_request: `https://irdproducts.typeform.com/to/eBzna2?mc_cid=768f5c7827&mc_eid=da56f8dde3`,
  balancing_training_request: `https://irdproducts.typeform.com/to/eBzna2?mc_cid=768f5c7827&mc_eid=da56f8dde3`,
  file_transfer: 'https://irdproducts.wetransfer.com',
  no_link_available: '/#',
};
