import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import './modalWindow.css';

export default function ModalWindow({
  modalMode,
  show,
  setShow,
  setFormReset,
  setContinueDelete,
  message,
}) {
  const handleClose = () => {
    if (modalMode === 'nbm-form') {
      setFormReset(true);
    } else if (modalMode === 'bal-mach-app-view') {
      setContinueDelete(true);
    }
    setShow(false);
  };

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        {modalMode === 'bal-mach-app-view' && (
          <span className="warning-text">WARNING</span>
        )}
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        {modalMode === 'nbm-form' && (
          <Button variant="primary" onClick={handleClose}>
            Ok
          </Button>
        )}
        {modalMode === 'bal-mach-app-view' && (
          <>
            <Button variant="primary" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button variant="warning" onClick={handleClose}>
              Continue
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}
