import React, { useState, useEffect } from 'react';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import ModalWindow from '../../components/Modal/ModalWindow';
import LargeModalWindow from '../Modal/LargeModalWindow';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './balanceMachineAppView.css';

import trashIcon from '../../assets/icons/trash.svg';
import checked from '../../assets/icons/check-square.svg';
import square from '../../assets/icons/square.svg';
import moreIcon from '../../assets/icons/more-horizontal.svg';

import { NEW_BALANCE_MACHINE_FORM as content } from '../../data/PAGE_TEXT_CONTENT';

export default function BalanceMachineAppView({
  form_id,
  date,
  contact_person,
  title,
  email,
  phone,
  fax,
  company_name,
  company_address,
  city,
  state,
  postal_code,
  country,
  sec_2_1_fan,
  sec_2_1_armature,
  sec_2_1_turbine,
  sec_2_1_roll,
  sec_2_1_impeller,
  sec_2_1_spindle,
  sec_2_1_disk,
  sec_2_1_crankshaft,
  sec_2_1_flywheel,
  sec_2_1_other,
  sec_2_1_other_details,
  sec_2_2_between_bearings,
  sec_2_2_overhung,
  sec_2_2_between_bearings_and_overhung,
  sec_2_3_max_upward_force,
  sec_2_3_unit_kg,
  sec_2_3_unit_lb,
  sec_2_3_not_sure,
  sec_2_4_yes,
  sec_2_4_no,
  sec_2_4_both,
  sec_2_5_max_rotor_weight,
  sec_2_5_unit_kg,
  sec_2_5_unit_lb,
  sec_2_6_min_rotor_weight,
  sec_2_6_unit_kg,
  sec_2_6_unit_lb,
  sec_2_7_max_swing_diameter,
  sec_2_7_unit_mm,
  sec_2_7_unit_in,
  sec_2_8_max_rotor_length,
  sec_2_8_unit_mm,
  sec_2_8_unit_in,
  sec_2_9_min_rotor_length,
  sec_2_9_unit_mm,
  sec_2_9_unit_in,
  sec_2_10_max_dist_between_journals,
  sec_2_10_unit_mm,
  sec_2_10_unit_in,
  sec_2_11_min_dist_between_journals,
  sec_2_11_unit_mm,
  sec_2_11_unit_in,
  sec_2_12_own_bearing,
  sec_2_12_journal_surface,
  sec_2_13_max_journal_diam,
  sec_2_13_unit_mm,
  sec_2_13_unit_in,
  sec_2_14_min_journal_diam,
  sec_2_14_unit_mm,
  sec_2_14_unit_in,
  sec_2_15_min_journal_width,
  sec_2_15_unit_mm,
  sec_2_15_unit_in,
  sec_2_16_max_moment_of_inertia,
  sec_2_16_unit_kgm2,
  sec_2_16_unit_lbft2,
  sec_2_16_unit_not_sure,
  sec_2_17_speed_range_rotors,
  sec_2_18_max_power_absorbed,
  sec_2_18_unit_kw,
  sec_2_18_unit_hp,
  sec_2_18_unit_not_sure,
  sec_2_19_max_bal_speed_rolls,
  sec_2_19_unit_mmin,
  sec_2_19_unit_ftmin,
  sec_2_20_high_int_yes,
  sec_2_20_high_int_no,
  sec_3_1_one_plane,
  sec_3_1_two_plane,
  sec_3_1_both_planes,
  sec_3_2_tolerance_amount,
  sec_3_2_unit_iso,
  sec_3_2_unit_api,
  sec_3_2_unit_gmm,
  sec_3_2_unit_ozin,
  sec_3_3_max_init_unbal,
  sec_3_3_unit_gin,
  sec_3_3_unit_gmm,
  sec_3_3_unit_ozin,
  sec_3_3_unit_not_sure,
  sec_3_4_num_rotors_bal,
  sec_4_5_ac_volt,
  sec_4_5_phase,
  sec_4_5_hz,
  sec_4_6_belt,
  sec_4_6_end_drive,
  sec_4_7_drive_power_req,
  sec_4_7_unit_kw,
  sec_4_7_unit_hp,
  sec_4_7_unit_not_sure,
  sec_4_8_var_speed,
  sec_4_9_portable,
  sec_4_9_digital,
  sec_4_9_computer,
  sec_4_10_cert_yes,
  sec_4_10_cert_no,
  sec_4_10_cert_not_sure,
  sec_4_11_safety_none,
  sec_4_11_safety_perimeter_fence,
  sec_5_2_bearing_details,
  sec_5_3_other_info,
  sec_5_4_additional_req,
}) {
  const [show, setShow] = useState(false);
  const [showLargeModal, setShowLargeModal] = useState(false);
  const [message, setMessage] = useState('');
  const [continueDelete, setContinueDelete] = useState(false);
  const [idOfAppToDelete, setIdOfAppToDelete] = useState(null);
  const notifyAppDel = () =>
    toast.warn('Applicationn has been deleted', {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });

  async function deleteBalMachApp(postId) {
    const docRef = doc(db, `app_for_bal_mach`, postId);
    await deleteDoc(docRef)
      .then(notifyAppDel)
      .catch((error) => {
        alert(error);
      });
  }

  const processDeleteBalMachApp = (postId) => {
    setIdOfAppToDelete(postId);
    setMessage(
      'Are you sure you want to delete this app? This cannot be undone.'
    );
    setShow(true);
  };

  // const isChecked = <img src={checked} alt="checked" className="bmad-icon" />;
  // const notChecked = <img src={square} alt="checked" className="bmad-icon" />;
  const formatPhoneNum = (value) => {
    const formattedPhone =
      '(' + value.slice(0, 3) + ') ' + value.slice(3, 6) + '-' + value.slice(6);

    return formattedPhone;
  };

  const dateArr = date.split('-');
  const year = dateArr[0];
  const month = dateArr[1];
  const day = dateArr[2];

  const formattedDate = `${month}/${day}/${year}`;

  const handleOpenLargeModal = (id) => {
    if (id === form_id) {
      setShowLargeModal(true);
    }
  };

  useEffect(() => {
    if (continueDelete) {
      deleteBalMachApp(idOfAppToDelete);
    }
    setContinueDelete(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [continueDelete]);

  return (
    <div
      className="container-fluid balance-machine-app-data-container"
      id={form_id}
    >
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="row bmad-row">
        <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 bmad-col">
          <div className="flex-row-opt-one">
            <h2 className="bmad-header-text">Customer: </h2>
            <span className="bmad-text">{company_name}</span>
          </div>
          <div className="flex-row-opt-one">
            <h2 className="bmad-header-text">Form ID: </h2>
            <span className="bmad-text">{form_id}</span>
          </div>
        </div>

        <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 bmad-col">
          <div className="bmad-icon-wrapper">
            <img
              src={moreIcon}
              alt="trash"
              className="bmad-more-icon"
              onClick={() => handleOpenLargeModal(form_id)}
            />
            <img
              src={trashIcon}
              alt="trash"
              className="bmad-trash-icon"
              onClick={() => processDeleteBalMachApp(form_id)}
            />
          </div>
        </div>
      </div>

      <div className="row bmad-row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 bmad-col">
          <div className="bmad-content-container">
            <span className="bmad-text bmad-bold">Date:</span>
            <span className="bmad-text">{formattedDate}</span>
          </div>
          <div className="bmad-content-container">
            <span className="bmad-text bmad-bold">Contact Person:</span>
            <span className="bmad-text">{contact_person}</span>
          </div>
          <div className="bmad-content-container">
            <span className="bmad-text bmad-bold">Title:</span>
            <span className="bmad-text">{title}</span>
          </div>
          <div className="bmad-content-container">
            <span className="bmad-text bmad-bold">Email:</span>
            <span className="bmad-text">{email}</span>
          </div>
          <div className="bmad-content-container">
            <span className="bmad-text bmad-bold">Phone:</span>
            <span className="bmad-text">{formatPhoneNum(phone)}</span>
          </div>
          <div className="bmad-content-container">
            <span className="bmad-text bmad-bold">Fax:</span>
            <span className="bmad-text">{formatPhoneNum(fax)}</span>
          </div>
        </div>

        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 bmad-col">
          <div className="bmad-content-container">
            <span className="bmad-text bmad-bold">Company Name:</span>
            <span className="bmad-text">{company_name}</span>
          </div>
          <div className="bmad-content-container">
            <span className="bmad-text bmad-bold">Company Address:</span>
            <span className="bmad-text">{company_address}</span>
          </div>
          <div className="bmad-content-container">
            <span className="bmad-text bmad-bold">City:</span>
            <span className="bmad-text">{city}</span>
          </div>
          <div className="bmad-content-container">
            <span className="bmad-text bmad-bold">State:</span>
            <span className="bmad-text">{state}</span>
          </div>
          <div className="bmad-content-container">
            <span className="bmad-text bmad-bold">Postal Code:</span>
            <span className="bmad-text">{postal_code}</span>
          </div>
          <div className="bmad-content-container">
            <span className="bmad-text bmad-bold">Country:</span>
            <span className="bmad-text">{country}</span>
          </div>
        </div>
      </div>

      {/* <div className="row bmad-row nbm-text-content-row">
        <h2 className="bmad-header-text">1. Introduction</h2>
        <span className="bmad-text">{content.intro}</span>
      </div> */}

      {/* <div className="row bmad-row">
        <h2 className="bmad-header-text">
          2. Rotor Details (If necessary, reproduce this page and enter data for
          more rotors)
        </h2>
      </div> */}

      {/* SEC 2.1 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">
            1. List All Types of Rotors to Balance
          </span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col-flex-row">
          <div className="bmad-group-wrapper-col">
            <div className="bmad-selected-container">
              {sec_2_1_fan ? isChecked : notChecked}
              <span className="bmad-text">Fan</span>
            </div>
            <div className="bmad-selected-container">
              {sec_2_1_roll ? isChecked : notChecked}
              <span className="bmad-text">Roll</span>
            </div>
            <div className="bmad-selected-containerr">
              {sec_2_1_crankshaft ? isChecked : notChecked}
              <span className="bmad-text">Crankshaft</span>
            </div>
          </div>

          <div className="bmad-group-wrapper-col">
            <div className="bmad-selected-containerr">
              {sec_2_1_armature ? isChecked : notChecked}
              <span className="bmad-text">Armature</span>
            </div>

            <div className="bmad-selected-container">
              {sec_2_1_impeller ? isChecked : notChecked}
              <span className="bmad-text">Impeller</span>
            </div>
            <div className="bmad-selected-containerr">
              {sec_2_1_flywheel ? isChecked : notChecked}
              <span className="bmad-text">Flywheel</span>
            </div>
          </div>

          <div className="bmad-group-wrapper-col">
            <div className="bmad-selected-container">
              {sec_2_1_turbine ? isChecked : notChecked}
              <span className="bmad-text">Gas/Steam Turbine</span>
            </div>
            <div className="bmad-selected-container">
              {sec_2_1_spindle ? isChecked : notChecked}
              <span className="bmad-text">Spindle</span>
            </div>

            <div className="bmad-selected-container">
              {sec_2_1_disk ? isChecked : notChecked}
              <span className="bmad-text">Disk</span>
            </div>
          </div>

          <div className="bmad-group-wrapper-col">
            <div className="bmad-selected-container">
              {sec_2_1_other ? isChecked : notChecked}
              <span className="bmad-text">Other</span>
            </div>
            <div className="bmad-selected-container">
              <span className="bmad-text">Other Detail:</span>

              {sec_2_1_other_details !== null ? (
                <span className="bmad-text">{sec_2_1_other_details}</span>
              ) : (
                <span className="bmad-text">n/a</span>
              )}
            </div>
          </div>
        </div>
      </div> */}

      {/* SEC 2.2 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">2. Rotor Mass Symmetry</span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_2_2_between_bearings ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">
              Between Bearings
            </span>

            {sec_2_2_overhung ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">Overhung</span>

            {sec_2_2_between_bearings_and_overhung ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">Both</span>
          </div>
        </div>
      </div> */}

      {/* SEC 2.3 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">
            3. If Overhung Rotor, estimated max upward force
          </span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_2_3_not_sure ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">Not Sure</span>

            {sec_2_3_max_upward_force !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Max Upward Force:</span>
                <span className="bmad-text bmad-margin-left-10">
                  {sec_2_3_max_upward_force}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Max Upward Force:</span>
                <span className="bmad-text bmad-margin-left-10">unknown</span>
              </div>
            )}

            {sec_2_3_unit_kg ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">kg</span>

            {sec_2_3_unit_lb ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">lb</span>
          </div>
        </div>
      </div> */}

      {/* SEC 2.4 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">
            4. Do Rotors have their own shaft with journals?
          </span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_2_4_yes ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">Yes</span>

            {sec_2_4_no ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">No</span>

            {sec_2_4_both ? isChecked : notChecked}
            <span className="bmad-text">Both</span>
          </div>
        </div>
      </div> */}

      {/* SEC 2.5 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">5. Maximum Rotor Weight</span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_2_5_max_rotor_weight !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Max Rotor Weight:</span>
                <span className="bmad-text bmad-margin-left-10">
                  {sec_2_5_max_rotor_weight}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Max Rotor Weight:</span>
                <span className="bmad-text bmad-margin-left-10">unknown</span>
              </div>
            )}

            {sec_2_5_unit_kg ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">kg</span>

            {sec_2_5_unit_lb ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">lb</span>
          </div>
        </div>
      </div> */}

      {/* SEC 2.6 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">6. Minimum Rotor Weight</span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_2_6_min_rotor_weight !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Min Rotor Weight:</span>
                <span className="bmad-text bmad-margin-left-10">
                  {sec_2_6_min_rotor_weight}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Min Rotor Weight:</span>
                <span className="bmad-text bmad-margin-left-10">unknown</span>
              </div>
            )}

            {sec_2_6_unit_kg ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">kg</span>

            {sec_2_6_unit_lb ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">lb</span>
          </div>
        </div>
      </div> */}

      {/* SEC 2.7 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">
            7. (D) Maximum Swing Diameter of Rotors
          </span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_2_7_max_swing_diameter !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Max Swing Diameter:</span>
                <span className="bmad-text bmad-margin-left-10">
                  {sec_2_7_max_swing_diameter}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Max Swing Diameter:</span>
                <span className="bmad-text bmad-margin-left-10">unknown</span>
              </div>
            )}

            {sec_2_7_unit_mm ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">mm</span>

            {sec_2_7_unit_in ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">in</span>
          </div>
        </div>
      </div> */}

      {/* SEC 2.8 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">8. Maximum Rotor Length</span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_2_8_max_rotor_length !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Max Rotor Length:</span>
                <span className="bmad-text bmad-margin-left-10">
                  {sec_2_8_max_rotor_length}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Max Rotor Length:</span>
                <span className="bmad-text bmad-margin-left-10">unknown</span>
              </div>
            )}

            {sec_2_8_unit_mm ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">mm</span>

            {sec_2_8_unit_in ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">in</span>
          </div>
        </div>
      </div> */}

      {/* SEC 2.9 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">9. Minimum Rotor Length</span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_2_9_min_rotor_length !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Min Rotor Length:</span>
                <span className="bmad-text bmad-margin-left-10">
                  {sec_2_9_min_rotor_length}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Min Rotor Length:</span>
                <span className="bmad-text bmad-margin-left-10">unknown</span>
              </div>
            )}

            {sec_2_9_unit_mm ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">mm</span>

            {sec_2_9_unit_in ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">in</span>
          </div>
        </div>
      </div> */}

      {/* SEC 2.10 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">
            10. (B) Maximum Distance between Journals
          </span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_2_10_max_dist_between_journals !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Max Distance:</span>
                <span className="bmad-text bmad-margin-left-10">
                  {sec_2_10_max_dist_between_journals}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Max Distance:</span>
                <span className="bmad-text bmad-margin-left-10">unknown</span>
              </div>
            )}

            {sec_2_10_unit_mm ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">mm</span>

            {sec_2_10_unit_in ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">in</span>
          </div>
        </div>
      </div> */}

      {/* SEC 2.11 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">
            11. (B) Minimum Distance between Journals
          </span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_2_11_min_dist_between_journals !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Min Distance:</span>
                <span className="bmad-text bmad-margin-left-10">
                  {sec_2_11_min_dist_between_journals}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Min Distance:</span>
                <span className="bmad-text bmad-margin-left-10">unknown</span>
              </div>
            )}

            {sec_2_11_unit_mm ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">mm</span>

            {sec_2_11_unit_in ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">in</span>
          </div>
        </div>
      </div> */}

      {/* SEC 2.12 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">12. Rotor Support Method</span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapperrow">
            {sec_2_12_own_bearing ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">Own Bearings</span>

            {sec_2_12_journal_surface ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">
              Journal Surface
            </span>
          </div>
        </div>
      </div> */}

      {/* SEC 2.13 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">13. (A) Maximum Journal Diameter</span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_2_13_max_journal_diam !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Max Journal Diameter:</span>
                <span className="bmad-text bmad-margin-left-10">
                  {sec_2_13_max_journal_diam}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Max Journal Diameter:</span>
                <span className="bmad-text bmad-margin-left-10">unknown</span>
              </div>
            )}

            {sec_2_13_unit_mm ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">mm</span>

            {sec_2_13_unit_in ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">in</span>
          </div>
        </div>
      </div> */}

      {/* SEC 2.14 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">14. (A) Minimum Journal Diameter</span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_2_14_min_journal_diam !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Min Journal Diameter:</span>
                <span className="bmad-text bmad-margin-left-10">
                  {sec_2_14_min_journal_diam}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Min Journal Diameter:</span>
                <span className="bmad-text bmad-margin-left-10">unknown</span>
              </div>
            )}

            {sec_2_14_unit_mm ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">mm</span>

            {sec_2_14_unit_in ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">in</span>
          </div>
        </div>
      </div> */}

      {/* SEC 2.15 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">15. (E) Minimum Journal Width</span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_2_15_min_journal_width !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Min Journal Width:</span>
                <span className="bmad-text bmad-margin-left-10">
                  {sec_2_15_min_journal_width}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Min Journal Width:</span>
                <span className="bmad-text bmad-margin-left-10">unknown</span>
              </div>
            )}

            {sec_2_15_unit_mm ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">mm</span>

            {sec_2_15_unit_in ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">in</span>
          </div>
        </div>
      </div> */}

      {/* SEC 2.16 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">16. Maximum Moment of Inertia</span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_2_16_unit_not_sure ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">Not Sure</span>

            {sec_2_16_max_moment_of_inertia !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Moment of Max Inertia:</span>
                <span className="bmad-text bmad-margin-left-10">
                  {sec_2_16_max_moment_of_inertia}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Moment of Max Inertia:</span>
                <span className="bmad-text bmad-margin-left-10">unknown</span>
              </div>
            )}

            {sec_2_16_unit_kgm2 ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">kgm^2</span>

            {sec_2_16_unit_lbft2 ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">lb ft^2</span>
          </div>
        </div>
      </div> */}

      {/* SEC 2.17 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">17. Operating Speed Range of Rotors</span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_2_17_speed_range_rotors !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Operating Speed Range:</span>
                <span className="bmad-text bmad-margin-left-10">
                  {sec_2_17_speed_range_rotors}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Operating Speed Range:</span>
                <span className="bmad-text bmad-margin-left-10">unknown</span>
              </div>
            )}
          </div>
        </div>
      </div> */}

      {/* SEC 2.18 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">
            18. Max. Power Absorbed at Operating Speed
          </span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_2_18_unit_not_sure ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">Not Sure</span>

            {sec_2_18_max_power_absorbed !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Max Power Absorbed:</span>
                <span className="bmad-text bmad-margin-left-10">
                  {sec_2_18_max_power_absorbed}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Max Power Absorbed:</span>
                <span className="bmad-text bmad-margin-left-10">unknown</span>
              </div>
            )}

            {sec_2_18_unit_kw ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">kW</span>

            {sec_2_18_unit_hp ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">hp</span>
          </div>
        </div>
      </div> */}

      {/* SEC 2.19 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">
            19. Roll Data: Maximum Balancing Speed for Rolls
          </span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_2_19_max_bal_speed_rolls !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Max Speed:</span>
                <span className="bmad-text bmad-margin-left-10">
                  {sec_2_19_max_bal_speed_rolls}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Max Speed:</span>
                <span className="bmad-text bmad-margin-left-10">unknown</span>
              </div>
            )}

            {sec_2_19_unit_mmin ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">m/min</span>

            {sec_2_19_unit_ftmin ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">ft/min</span>
          </div>
        </div>
      </div> */}

      {/* SEC 2.20 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">
            20. Roll Data: High Internal Resistance? (Suction)
          </span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_2_20_high_int_yes ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">Yes</span>

            {sec_2_20_high_int_no ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">No</span>
          </div>
        </div>
      </div> */}

      {/* SECTION 3 */}

      {/* <div className="row bmad-row">
        <h2 className="bmad-header-text">3. Balance Details</h2>
      </div> */}

      {/* SEC 3.1 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">1. Number of Balance Planes</span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_3_1_one_plane ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">One</span>

            {sec_3_1_two_plane ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">Two</span>

            {sec_3_1_both_planes ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">Both</span>
          </div>
        </div>
      </div> */}

      {/* SEC 3.2 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">
            2. Balance Tolerance Level – Amount/Grade Value and Units Required
          </span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_3_2_tolerance_amount !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Tolerance Level:</span>
                <span className="bmad-text bmad-margin-left-10">
                  {sec_3_2_tolerance_amount}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Tolerance Level:</span>
                <span className="bmad-text bmad-margin-left-10">unknown</span>
              </div>
            )}

            {sec_3_2_unit_iso ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">ISO Grade</span>

            {sec_3_2_unit_api ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">API</span>

            {sec_3_2_unit_gmm ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">g-mm</span>

            {sec_3_2_unit_ozin ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-right-25">oz-in</span>
          </div>
        </div>
      </div> */}

      {/* SEC 3.3 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">3. Maximum Initial Unbalance</span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_3_3_unit_not_sure ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">Not Sure</span>

            {sec_3_3_max_init_unbal !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Max Initial Unbalance</span>
                <span className="bmad-text bmad-margin-left-10">
                  {sec_3_3_max_init_unbal}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Max Initial Unbalance</span>
                <span className="bmad-text bmad-margin-left-10">unknown</span>
              </div>
            )}

            {sec_3_3_unit_gin ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">g-in</span>

            {sec_3_3_unit_gmm ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">g-mm</span>

            {sec_3_3_unit_ozin ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">oz-in</span>
          </div>
        </div>
      </div> */}

      {/* SEC 3.4 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">
            4. Number of Rotors to Balance in 8 hours
          </span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_3_4_num_rotors_bal !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Rotors to Balance</span>
                <span className="bmad-text bmad-margin-left-10">
                  {sec_3_4_num_rotors_bal}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Rotors to Balance</span>
                <span className="bmad-text bmad-margin-left-10">unknown</span>
              </div>
            )}
          </div>
        </div>
      </div> */}

      {/* SECTION 4 */}

      {/* <div className="row bmad-row">
        <h2 className="bmad-header-text">4. System Details</h2>
      </div> */}

      {/* SEC 4.5 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">
            5. Available Electrical Mains Supply
          </span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_4_5_ac_volt !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">AC Volts</span>
                <span className="bmad-text bmad-margin-left-10">
                  {sec_4_5_ac_volt}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">AC Volts</span>
                <span className="bmad-text bmad-margin-left-10">unknown</span>
              </div>
            )}

            {sec_4_5_phase !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Phase</span>
                <span className="bmad-text bmad-margin-left-10">
                  {sec_4_5_phase}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Phase</span>
                <span className="bmad-text bmad-margin-left-10">____</span>
              </div>
            )}

            {sec_4_5_hz !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Hz</span>
                <span className="bmad-text bmad-margin-left-10">
                  {sec_4_5_hz}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Hz</span>
                <span className="bmad-text bmad-margin-left-10">____</span>
              </div>
            )}
          </div>
        </div>
      </div> */}

      {/* SEC 4.6 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">6. Rotor Drive Type</span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_4_6_belt ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">Belt</span>

            {sec_4_6_end_drive ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">End Drive</span>
          </div>
        </div>
      </div> */}

      {/* SEC 4.7 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">7. Desired Drive Power Requirements</span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_4_7_unit_not_sure ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">Not Sure</span>

            {sec_4_7_drive_power_req !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Drive Power Requirements:</span>
                <span className="bmad-text bmad-margin-left-10">
                  {sec_4_7_drive_power_req}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text">Drive Power Requirements:</span>
                <span className="bmad-text bmad-margin-left-10">unknown</span>
              </div>
            )}

            {sec_4_7_unit_kw ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">kW</span>

            {sec_4_7_unit_hp ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">hp</span>
          </div>
        </div>
      </div> */}

      {/* SEC 4.8 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">8. Balance Speed Control</span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_4_8_var_speed ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">
              Variable Speed
            </span>
          </div>
        </div>
      </div> */}

      {/* SEC 4.9*/}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">9. Instrument Type</span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_4_9_portable ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">Portable</span>

            {sec_4_9_digital ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">Digital</span>

            {sec_4_9_computer ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">Computer</span>
          </div>
        </div>
      </div> */}

      {/* SEC 4.10 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">
            10. Printed Balance Certificates Required
          </span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_4_10_cert_yes ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">Yes</span>

            {sec_4_10_cert_no ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">No</span>

            {sec_4_10_cert_not_sure ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">Not Sure</span>
          </div>
        </div>
      </div> */}

      {/* SEC 4.11 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-bold">11. Safety Device (see note below)</span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_4_11_safety_none ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">None</span>

            {sec_4_11_safety_perimeter_fence ? isChecked : notChecked}
            <span className="bmad-text bmad-margin-left-25">
              Perimeter Fence
            </span>
          </div>
        </div>
      </div> */}

      {/* SAFETY NOTICE ROW */}

      {/* <div className="row bmad-row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 bmad-col">
          <h2 className="bmad-header-text">* Safety Device Note</h2>
          <span className="bmad-text">{content.safety_notice}</span>
        </div>
      </div> */}

      {/* SECTION 5 */}

      {/* <div className="row bmad-row">
        <h2 className="bmad-header-text">5. Additional Information</h2>
      </div> */}

      {/* SEC 5.1 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-text bmad-bold">
            Attach drawings, sketches, or pictures of rotors to be balanced, if
            available.
          </span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper">
            {sec_2_1_other ? isChecked : notChecked}
            <span className="bmad-text bmad-bold">*</span>
          </div>
        </div>
      </div> */}

      {/* SEC 5.2 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-section-text bmad-bold">
            If rotor is to be balanced in its own bearings, please attach
            details of bearings (Type, OD, Width, self aligning, etc.)
          </span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_5_2_bearing_details !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text bmad-margin-left-10">
                  {sec_5_2_bearing_details}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text bmad-margin-left-10">n/a</span>
              </div>
            )}
          </div>
        </div>
      </div> */}

      {/* SEC 5.3 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-section-text bmad-bold">
            Other information that may be helpful in determining the appropriate
            balancing system.
          </span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_5_3_other_info !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text bmad-margin-left-10">
                  {sec_5_3_other_info}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text bmad-margin-left-10">n/a</span>
              </div>
            )}
          </div>
        </div>
      </div> */}

      {/* SEC 5.4 */}
      {/* <div className="row bmad-row bmad-indent-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 bmad-col">
          <span className="bmad-section-text bmad-bold">
            Other additional System Requirements not listed above.
          </span>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 bmad-col">
          <div className="bmad-group-wrapper-row">
            {sec_5_4_additional_req !== null ? (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text bmad-margin-left-10">
                  {sec_5_4_additional_req}
                </span>
              </div>
            ) : (
              <div className="bmad-selected-container bmad-margin-right-25">
                <span className="bmad-text bmad-margin-left-10">n/a</span>
              </div>
            )}
          </div>
        </div>
      </div> */}
      <ModalWindow
        show={show}
        modalMode="bal-mach-app-view"
        setShow={setShow}
        setContinueDelete={setContinueDelete}
        message={message}
      />
      <LargeModalWindow
        showLargeModal={showLargeModal}
        setShowLargeModal={setShowLargeModal}
        form_id={form_id}
        date={date}
        contact_person={contact_person}
        title={title}
        email={email}
        phone={phone}
        fax={fax}
        company_name={company_name}
        company_address={company_address}
        city={city}
        state={state}
        postal_code={postal_code}
        country={country}
        sec_2_1_fan={sec_2_1_fan}
        sec_2_1_armature={sec_2_1_armature}
        sec_2_1_turbine={sec_2_1_turbine}
        sec_2_1_roll={sec_2_1_roll}
        sec_2_1_impeller={sec_2_1_impeller}
        sec_2_1_spindle={sec_2_1_spindle}
        sec_2_1_disk={sec_2_1_disk}
        sec_2_1_crankshaft={sec_2_1_crankshaft}
        sec_2_1_flywheel={sec_2_1_flywheel}
        sec_2_1_other={sec_2_1_other}
        sec_2_1_other_details={sec_2_1_other_details}
        sec_2_2_between_bearings={sec_2_2_between_bearings}
        sec_2_2_overhung={sec_2_2_overhung}
        sec_2_2_between_bearings_and_overhung={
          sec_2_2_between_bearings_and_overhung
        }
        sec_2_3_max_upward_force={sec_2_3_max_upward_force}
        sec_2_3_unit_kg={sec_2_3_unit_kg}
        sec_2_3_unit_lb={sec_2_3_unit_lb}
        sec_2_3_not_sure={sec_2_3_not_sure}
        sec_2_4_yes={sec_2_4_yes}
        sec_2_4_no={sec_2_4_no}
        sec_2_4_both={sec_2_4_both}
        sec_2_5_max_rotor_weight={sec_2_5_max_rotor_weight}
        sec_2_5_unit_kg={sec_2_5_unit_kg}
        sec_2_5_unit_lb={sec_2_5_unit_lb}
        sec_2_6_min_rotor_weight={sec_2_6_min_rotor_weight}
        sec_2_6_unit_kg={sec_2_6_unit_kg}
        sec_2_6_unit_lb={sec_2_6_unit_lb}
        sec_2_7_max_swing_diameter={sec_2_7_max_swing_diameter}
        sec_2_7_unit_mm={sec_2_7_unit_mm}
        sec_2_7_unit_in={sec_2_7_unit_in}
        sec_2_8_max_rotor_length={sec_2_8_max_rotor_length}
        sec_2_8_unit_mm={sec_2_8_unit_mm}
        sec_2_8_unit_in={sec_2_8_unit_in}
        sec_2_9_min_rotor_length={sec_2_9_min_rotor_length}
        sec_2_9_unit_mm={sec_2_9_unit_mm}
        sec_2_9_unit_in={sec_2_9_unit_in}
        sec_2_10_max_dist_between_journals={sec_2_10_max_dist_between_journals}
        sec_2_10_unit_mm={sec_2_10_unit_mm}
        sec_2_10_unit_in={sec_2_10_unit_in}
        sec_2_11_min_dist_between_journals={sec_2_11_min_dist_between_journals}
        sec_2_11_unit_mm={sec_2_11_unit_mm}
        sec_2_11_unit_in={sec_2_11_unit_in}
        sec_2_12_own_bearing={sec_2_12_own_bearing}
        sec_2_12_journal_surface={sec_2_12_journal_surface}
        sec_2_13_max_journal_diam={sec_2_13_max_journal_diam}
        sec_2_13_unit_mm={sec_2_13_unit_mm}
        sec_2_13_unit_in={sec_2_13_unit_in}
        sec_2_14_min_journal_diam={sec_2_14_min_journal_diam}
        sec_2_14_unit_mm={sec_2_14_unit_mm}
        sec_2_14_unit_in={sec_2_14_unit_in}
        sec_2_15_min_journal_width={sec_2_15_min_journal_width}
        sec_2_15_unit_mm={sec_2_15_unit_mm}
        sec_2_15_unit_in={sec_2_15_unit_in}
        sec_2_16_max_moment_of_inertia={sec_2_16_max_moment_of_inertia}
        sec_2_16_unit_kgm2={sec_2_16_unit_kgm2}
        sec_2_16_unit_lbft2={sec_2_16_unit_lbft2}
        sec_2_16_unit_not_sure={sec_2_16_unit_not_sure}
        sec_2_17_speed_range_rotors={sec_2_17_speed_range_rotors}
        sec_2_18_max_power_absorbed={sec_2_18_max_power_absorbed}
        sec_2_18_unit_kw={sec_2_18_unit_kw}
        sec_2_18_unit_hp={sec_2_18_unit_hp}
        sec_2_18_unit_not_sure={sec_2_18_unit_not_sure}
        sec_2_19_max_bal_speed_rolls={sec_2_19_max_bal_speed_rolls}
        sec_2_19_unit_mmin={sec_2_19_unit_mmin}
        sec_2_19_unit_ftmin={sec_2_19_unit_ftmin}
        sec_2_20_high_int_yes={sec_2_20_high_int_yes}
        sec_2_20_high_int_no={sec_2_20_high_int_no}
        sec_3_1_one_plane={sec_3_1_one_plane}
        sec_3_1_two_plane={sec_3_1_two_plane}
        sec_3_1_both_planes={sec_3_1_both_planes}
        sec_3_2_tolerance_amount={sec_3_2_tolerance_amount}
        sec_3_2_unit_iso={sec_3_2_unit_iso}
        sec_3_2_unit_api={sec_3_2_unit_api}
        sec_3_2_unit_gmm={sec_3_2_unit_gmm}
        sec_3_2_unit_ozin={sec_3_2_unit_ozin}
        sec_3_3_max_init_unbal={sec_3_3_max_init_unbal}
        sec_3_3_unit_gin={sec_3_3_unit_gin}
        sec_3_3_unit_gmm={sec_3_3_unit_gmm}
        sec_3_3_unit_ozin={sec_3_3_unit_ozin}
        sec_3_3_unit_not_sure={sec_3_3_unit_not_sure}
        sec_3_4_num_rotors_bal={sec_3_4_num_rotors_bal}
        sec_4_5_ac_volt={sec_4_5_ac_volt}
        sec_4_5_phase={sec_4_5_phase}
        sec_4_5_hz={sec_4_5_hz}
        sec_4_6_belt={sec_4_6_belt}
        sec_4_6_end_drive={sec_4_6_end_drive}
        sec_4_7_drive_power_req={sec_4_7_drive_power_req}
        sec_4_7_unit_kw={sec_4_7_unit_kw}
        sec_4_7_unit_hp={sec_4_7_unit_hp}
        sec_4_7_unit_not_sure={sec_4_7_unit_not_sure}
        sec_4_8_var_speed={sec_4_8_var_speed}
        sec_4_9_portable={sec_4_9_portable}
        sec_4_9_digital={sec_4_9_digital}
        sec_4_9_computer={sec_4_9_computer}
        sec_4_10_cert_yes={sec_4_10_cert_yes}
        sec_4_10_cert_no={sec_4_10_cert_no}
        sec_4_10_cert_not_sure={sec_4_10_cert_not_sure}
        sec_4_11_safety_none={sec_4_11_safety_none}
        sec_4_11_safety_perimeter_fence={sec_4_11_safety_perimeter_fence}
        sec_5_2_bearing_details={sec_5_2_bearing_details}
        sec_5_3_other_info={sec_5_3_other_info}
        sec_5_4_additional_req={sec_5_4_additional_req}
        checked={checked}
        square={square}
        trashIcon={trashIcon}
        newBalMachIntro={content.intro}
        newBalMachSafetyDeviceNote={content.safety_notice}
        processDeleteBalMachApp={processDeleteBalMachApp}
      />
      {/* END */}
    </div>
  );
}
