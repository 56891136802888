import React from 'react';
import './warrentyService.css';

export default function WarrantyService() {
  return (
    <div className="ws-container">
      <div className="ws-top-content">
        <h2 className="ws-header-text">Standard IRD® Warranty</h2>
        <span className="ws-text">
          A. Equipment. Under normal and proper use, IRD® warrants to the
          Original Purchaser all Products against defects in materials and
          workmanship for a period of one year from the date of shipment In the
          event of a defect in materials or workmanship in a component covered
          by this warranty, IRD® will, at its option, repair or replace the
          defective component. The Original Purchaser must notify IRD® in
          writing of a defect believed by the Original Purchaser to be covered
          by this warranty, obtain a Return Authorization Number from IRD® and
          ship the component, with the Return Authorization Number, to the
          location designated by IRD® for warranty service. All component
          removal, inbound and outbound shipping and reinstallation charges
          shall be paid by the Original Purchaser. IRD® will inspect components
          properly returned for warranty service and will determine whether, and
          in what manner, this warranty applies. IRD®'s determinations regarding
          the application of this warranty are final. Damage resulting from
          improper setup, use or maintenance or operation not in conformity with
          IRD® specifications is excluded from the above warranties.
        </span>
      </div>
      <div className="ws-middle-content">
        <span className="ws-text">
          B. Software. For the Original Purchaser, IRD® will replace, at no
          charge, diskettes defective in materials or workmanship that are
          returned to IRD®, shipping prepaid, within ninety (90) days of the
          date of delivery. IRD® warrants for a period of one (1) year from the
          date of delivery that the Software will perform in substantial
          compliance with the written materials accompanying the Software. If,
          within the warranty period, the original purchaser reports to IRD®, in
          writing, a significant defect in the Software, and IRD® is unable to
          correct the defect within ninety (90) days of the date the defect is
          reported, the Software and accompanying materials may be returned,
          shipping prepaid, for a full refund of the purchase price.
        </span>
      </div>
      <div className="ws-bottom-content">
        <span className="ws-text">
          C. Limitation of Remedies and Liability. The above warranties are
          limited to the Original Purchaser and do not apply to subsequent
          purchasers, through resale or otherwise, except to the Original
          Purchaser through an Authorized IRD® Distributor. THE ABOVE WARRANTIES
          ARE IN LIEU OF ANY OTHER WARRANTIES, EXPRESS, IMPLIED OR STATUTORY,
          INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY AND FITNESS
          FOR A PARTICULAR PURPOSE. THE SOLE REMEDY AVAILABLE UNDER THIS
          WARRANTY, OR FOR BREACH OF WARRANTY, IS THE REPAIR OR REPLACEMENT, AT
          IRD®'S OPTION, OF DEFECTIVE COMPONENTS. IN NO EVENT WILL IRD® OR
          ANYONE ELSE INVOLVED IN THE DESIGN, PRODUCTION, OR DELIVERY OF ANY
          IRD® PRODUCT AND ANY ASSOCIATED WRITTEN MATERIALS OR PRODUCTS SUPPLIED
          BY IRD®, BE LIABLE FOR ANY DIRECT, INCIDENTAL OR CONSEQUENTIAL
          DAMAGES, INCLUDING BUT NOT LIMITED TO PROPERTY DAMAGE, PERSONAL
          INJURY, LOST PROFITS, LOST SAVINGS, LOST USE, LOST BENEFITS, ATTORNEYS
          FEES AND ANY OTHER COSTS OF PURSUING A LEGAL CLAIM, ARISING OUT OF THE
          USE OR INABILITY TO USE ANY IRD® PRODUCT, AND ASSOCIATED PRODUCTS, OR
          ARISING OUT OF ANY BREACH OF WARRANTY.
        </span>
      </div>
    </div>
  );
}
