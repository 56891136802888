import React from 'react';
import { useSelector } from 'react-redux';
import Banner from '../components/Banner/Banner';
import SideMenu from '../components/SideMenu/SideMenu';
import '../styles/homeStyle.css';

export default function Home() {
  const { has_paid } = useSelector((state) => state.controls);
  console.log('has_paid', has_paid);
  return (
    <div className="container-fluid home-container">
      {has_paid && <Banner />}
      {has_paid && <SideMenu />}
    </div>
  );
}
