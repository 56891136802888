import model1081 from '../assets/images/model-1081-clt.png';
import model9100vo from '../assets/images/model-9100vo-sensor.png';
import imageNotAvailable from '../assets/images/image-not-available.png';
import model246 from '../assets/images/model-246.png';
import docModel246 from '../assets/pdf-docs/balancing-instruments-vibration-meters/model-246.pdf';
import model236 from '../assets/images/model-236.png';
import docModel236 from '../assets/pdf-docs/balancing-instruments-vibration-meters/model-236.pdf';
import b01_b02s from '../assets/images/complete-machine-images/b01-b02s.png';
import b01doc from '../assets/pdf-docs/complete-machines/b01-b02s.pdf';
import model258 from '../assets/images/model-258v.png';
import docModel258 from '../assets/pdf-docs/balancing-instruments-vibration-meters/model-258b.pdf';

export const SENSORS_VELOCITY_TRANSDUCERS_MODEL = [
  {
    model: '544',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/134/398/E04526-544-Velocity-Transducer__61180.1538142601.jpg?c=2',
    ird_part_number: 'E04526',
    entek_rockwell_ref: 'EK-04526',
    description:
      'Sensor Only - Portable, waterproof, dust proof, Division 2 hazardous area proof, for use in balancing machine applications',
    purchase_link: 'https://shop.irdproducts.com/544-velocity-transducer/',
  },
  {
    model: '544B',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/204/630/E12084-544B_Vel._Transducer-LR__78718.1578684310.jpg?c=2',
    ird_part_number: 'E12084',
    entek_rockwell_ref: 'EK-12084',
    description:
      'Sensor Only - Portable, waterproof, dust proof Division 2 Hazardous Environment Rating, isolated from area ground loops and power surges, for use in field balancing applications',
    purchase_link: 'https://shop.irdproducts.com/544b-velocity-transducer/',
  },
  {
    model: '544M',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/135/492/E09712-544M-Mountable-Velocity-Transducer-1__86443.1542730053.jpg?c=2',
    ird_part_number: 'E09712',
    entek_rockwell_ref: 'EK-09712',
    description: '544B Sensor with 4-Hole Mount, for permanent installation',
    purchase_link:
      'https://shop.irdproducts.com/544m-mountable-velocity-transducer/',
  },
  {
    model: '546DP',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/132/402/E02785-546DP-Velocity-Transducer__15329.1538142987.jpg?c=2',
    ird_part_number: 'E02785',
    entek_rockwell_ref: 'EK-02785',
    description: 'Standard pickup in all Balancing Machines',
    purchase_link: 'https://shop.irdproducts.com/546dp-velocity-transducer/',
  },
  {
    model: '546DP Assembly',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/133/400/E11194-546DP-Velocity-Transducer-with-MTG-Bracket__38908.1538142829.jpg?c=2',
    ird_part_number: 'E11194',
    entek_rockwell_ref: 'N/A',
    description: 'Pickup Assembly, 546DP w/ Right Angle Mounting Bracket',
    purchase_link:
      'https://shop.irdproducts.com/546dp-velocity-transducer-with-mounting-bracket/',
  },
  {
    model: '580',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/220/723/E28852-1LR__71356.1628014788.png?c=2',
    ird_part_number: 'E28852',
    entek_rockwell_ref: 'EK-28852',
    description: 'Sensor, Velocity Piezo Model 580',
    purchase_link: 'https://shop.irdproducts.com/model-580-vibration-sensor/',
  },
  {
    model: '9100VO',
    href_to_model_doc: '',
    image_url: model9100vo,
    ird_part_number: 'E44445',
    entek_rockwell_ref: 'N/A',
    description: 'Sensor, Compact Velocity, 25 mm/1" dia. 1080 mv/in/sec',
    purchase_link: '',
  },
  {
    model: '544MSP',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/201/626/E23063-544MSP_Mountable_Vel._Transducer_Side_Cable_Connection-1-LR__90543.1578684150.jpg?c=2',
    ird_part_number: 'E23063',
    entek_rockwell_ref: 'EK-23063',
    description:
      'Same as 544M except with Right Angle Connection for installation',
    purchase_link:
      'https://shop.irdproducts.com/544msp-mountable-velocity-transducer-side-cable-connection/',
  },
];

export const SENSORS_ACCELEROMETERS_MODEL = [
  {
    model: '911R',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/213/672/E37691-911S-1-LR__75848.1605627914.jpg?c=2',
    ird_part_number: 'E37692',
    entek_rockwell_ref: 'EK-37692',
    description: 'Sensor, Accelerometer, Model 911R, 100mv/g, Right Angle',
    purchase_link:
      'https://shop.irdproducts.com/model-911-100mv-g-accelerometer/',
  },
  {
    model: '911S',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/213/672/E37691-911S-1-LR__75848.1605627914.jpg?c=2',
    ird_part_number: 'E37691',
    entek_rockwell_ref: 'EK-37691',
    description: 'Sensor, Accelerometer, Model, 911S, 100mv/g',
    purchase_link:
      'https://shop.irdproducts.com/model-911-100mv-g-accelerometer/',
  },
  {
    model: `941B 10' Cable`,
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/222/716/E33340-1LR__49304.1628014610.png?c=2',
    ird_part_number: 'E33339',
    entek_rockwell_ref: 'EK-33339',
    description: `Sensor, Accelerometer, Model 941B, 10' long 50mv/g`,
    purchase_link: 'https://shop.irdproducts.com/model-941b-vibration-sensor/',
  },
  {
    model: `941B 30' Cable`,
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/222/716/E33340-1LR__49304.1628014610.png?c=2',
    ird_part_number: 'E33340',
    entek_rockwell_ref: 'EK-33340',
    description: `Sensor, Accelerometer, Model 941B, 30' long 50mv/g`,
    purchase_link: 'https://shop.irdproducts.com/model-941b-vibration-sensor/',
  },
  {
    model: `941B 50' Cable`,
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/222/716/E33340-1LR__49304.1628014610.png?c=2',
    ird_part_number: 'E33341',
    entek_rockwell_ref: 'EK-33341',
    description: `Sensor, Accelerometer, Model 941B, 50' long 50mv/g`,
    purchase_link: 'https://shop.irdproducts.com/model-941b-vibration-sensor/',
  },
  {
    model: `942 10' Cable`,
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/212/664/942-Cable-01-LR__63422.1595015830.jpg?c=2',
    ird_part_number: 'E36501',
    entek_rockwell_ref: 'EK-36501',
    description: `Sensor, Accelerometer, Model, 942, 10' long, 100mv/g`,
    purchase_link: 'https://shop.irdproducts.com/model-942-accelerometer/',
  },
  {
    model: `942 30' Cable`,
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/212/664/942-Cable-01-LR__63422.1595015830.jpg?c=2',
    ird_part_number: 'E36502',
    entek_rockwell_ref: 'EK-36502',
    description: `Sensor, Accelerometer, Model, 942, 30' long, 100mv/g`,
    purchase_link: 'https://shop.irdproducts.com/model-942-accelerometer/',
  },
  {
    model: `942 50' Cable`,
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/212/664/942-Cable-01-LR__63422.1595015830.jpg?c=2',
    ird_part_number: 'E36503',
    entek_rockwell_ref: 'EK-36503',
    description: `Sensor, Accelerometer, Model, 942, 50' long, 100mv/g`,
    purchase_link: 'https://shop.irdproducts.com/model-942-accelerometer/',
  },
  {
    model: `943`,
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    ird_part_number: 'E35530',
    entek_rockwell_ref: 'EK-35530',
    description: 'Sensor, Accelerometer, Model 943, 100mv/g',
    purchase_link: '',
  },
  {
    model: `970`,
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    ird_part_number: 'E19697',
    entek_rockwell_ref: 'EK-19697',
    description: 'Sensor, Accelerometer, Model 970, 50mv/g, 4-pin',
    purchase_link: '',
  },
  {
    model: `970i`,
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/137/396/E38946-970i-50mvg-Accelerometer__08722.1538141779.jpg?c=2',
    ird_part_number: 'E38946',
    entek_rockwell_ref: 'EK-38946',
    description: 'Sensor, Accelerometer, Model 970i, 50mv/g, 2-pin',
    purchase_link: 'https://shop.irdproducts.com/970i-50mv-g-accelerometer/',
  },
  {
    model: `9000A`,
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    ird_part_number: 'E43781',
    entek_rockwell_ref: 'N/A',
    description: 'Sensors, Accelerometer, General Purpose',
    purchase_link: '',
  },
];

export const SENSORS_SPEED_SENSORS_MODEL = [
  {
    type: 'Photoelectric',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/129/405/E21000-Speed-Sensor-Photocell__54384.1538145671.jpg?c=2',
    ird_part_number: 'E21000',
    description:
      '5 VDC, Reflective Tape Trigger, 25-600 mm Range. Used on balancing instruments 236/246/295/245/260/265/290. Compatible with all balancing machines.',
    purchase_link: 'https://shop.irdproducts.com/speed-sensor-photocell/',
  },
  {
    type: 'Photoelectric',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/130/495/E72665-Fiber-Optic-Speed-Sensor-B01-B02__01550.1542730353.jpg?c=2',
    ird_part_number: 'E72665',
    description:
      '40 mm Range, 2 m Cable, Triggers from Mark on Rotor. Only used with B01/S & B02/S instruments',
    purchase_link:
      'https://shop.irdproducts.com/fiber-optic-speed-sensor-for-b01-b02/',
  },
  {
    type: 'Laser',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/131/493/E47240-Laser-Speed-Sensor-IRD-256-258__84099.1542730174.jpg?c=2',
    ird_part_number: 'E47240',
    description:
      '5 VDC, Reflective Tape Trigger, 100-200 mm Range. Only used with B01/S & B02/S instruments',
    purchase_link:
      'https://shop.irdproducts.com/stainless-steel-laser-speed-sensor-for-256-258/',
  },
  {
    type: 'Infrared',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    ird_part_number: 'E47255',
    description:
      '12 VDC, Light / Dark Trigger, 100-150 mm Range. Only used with B01/S & B02/S instruments',
    purchase_link: '',
  },
];

export const SENSORS_PARTS_AND_ACCESSORIES_MODEL = [
  {
    ird_part_number: 'E04366',
    image_url: imageNotAvailable,
    href_to_model_doc: '',
    entek_rockwell_ref: 'EK-04366',
    description: 'Connector, 2-pin, female',
    purchase_link: '',
  },
  {
    ird_part_number: 'E10140',
    image_url: imageNotAvailable,
    href_to_model_doc: '',
    entek_rockwell_ref: 'EK-10140',
    description: 'Shield, Magnetic (100:1 Reduction) for Model 544',
    purchase_link: '',
  },
  {
    ird_part_number: 'E10308',
    image_url: imageNotAvailable,
    href_to_model_doc: '',
    entek_rockwell_ref: 'EK-10308',
    description: 'Mounting Stud, Insulated for Model 910 (1/4-28 Threads)',
    purchase_link: '',
  },
  {
    ird_part_number: 'E10449',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/206/639/E10449-Magnetic_Shield_for_544_Vel._Transducer_1-LR__10748.1578684467.jpg?c=2',
    href_to_model_doc: '',
    entek_rockwell_ref: 'EK-10449',
    description: 'Shield, Magnetic (100:1 Reduction) for Model 544M',
    purchase_link:
      'https://shop.irdproducts.com/magnetic-shield-for-544-velocity-transducer/',
  },
  {
    ird_part_number: 'E14797',
    image_url: imageNotAvailable,
    href_to_model_doc: '',
    entek_rockwell_ref: 'EK-14797',
    description: 'Connector, 2-pin female, 90 Degree MS',
    purchase_link: '',
  },
  {
    ird_part_number: 'E15039',
    image_url: imageNotAvailable,
    href_to_model_doc: '',
    entek_rockwell_ref: 'EK-15039',
    description: 'ASM, Conn E10183 w/ E01173 Clamp',
    purchase_link: '',
  },
  {
    ird_part_number: 'E24746',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/228/727/E24746_-_Magnetic_Holder_for_970-970i__51908.1662990987.jpg?c=2',
    href_to_model_doc: '',
    entek_rockwell_ref: 'EK-24746',
    description: 'Magnetic Holder for Model 970',
    purchase_link:
      'https://shop.irdproducts.com/magnetic-holder-for-970-accelerometer/',
  },
  {
    ird_part_number: 'E25136',
    image_url: imageNotAvailable,
    href_to_model_doc: '',
    entek_rockwell_ref: 'EK-25136',
    description: 'Cable for Model 560 to Model 880, 12ft long',
    purchase_link: '',
  },
  {
    ird_part_number: 'E32585',
    image_url: imageNotAvailable,
    href_to_model_doc: '',
    entek_rockwell_ref: 'EK-32585',
    description: 'Mounting Kit Clamp for Model 940/942',
    purchase_link: '',
  },
  {
    ird_part_number: 'E34948',
    image_url: imageNotAvailable,
    href_to_model_doc: '',
    entek_rockwell_ref: 'EK-34948',
    description: 'Mounting Plate for Model 942',
    purchase_link: '',
  },
  {
    ird_part_number: 'E35536',
    image_url: imageNotAvailable,
    href_to_model_doc: '',
    entek_rockwell_ref: 'EK-35536',
    description: 'Magnetic Holder for Model 943',
    purchase_link: '',
  },
  {
    ird_part_number: 'E35540',
    image_url: imageNotAvailable,
    href_to_model_doc: '',
    entek_rockwell_ref: 'EK-35540',
    description: 'Probe for Model 943, 5" long, Stainless Steel',
    purchase_link: '',
  },
  {
    ird_part_number: 'E35973',
    image_url: imageNotAvailable,
    href_to_model_doc: '',
    entek_rockwell_ref: 'EK-35973',
    description: 'Probe for Model 943, 1/2" long, Stainless Steel',
    purchase_link: '',
  },
  {
    ird_part_number: 'E35976',
    image_url: imageNotAvailable,
    href_to_model_doc: '',
    entek_rockwell_ref: 'EK-35976',
    description: 'Cable, Model 943 to Fastrack, 6ft long',
    purchase_link: '',
  },
  {
    ird_part_number: 'E36394',
    image_url: imageNotAvailable,
    href_to_model_doc: '',
    entek_rockwell_ref: 'EK-36394',
    description: 'Kit for Fastrack:  Model 943, Accelerometer, Cable, & Magnet',
    purchase_link: '',
  },
  {
    ird_part_number: 'E36733',
    image_url: imageNotAvailable,
    href_to_model_doc: '',
    entek_rockwell_ref: 'EK-36733',
    description: 'Cable, Model 943 to 885/890, 6ft long',
    purchase_link: '',
  },
  {
    ird_part_number: 'E49210',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/195/607/E49210-050-model-450-shaft-rider-side-angle-1__42564.1563195766.jpg?c=2',
    href_to_model_doc: '',
    entek_rockwell_ref: 'N/A',
    description: 'Shaft Rider for IRD® Balancing Sensors (Various Sizes)',
    purchase_link:
      'https://shop.irdproducts.com/model-450-shaft-rider-for-ird-balancing-sensors/',
  },
];

export const CORE_LOSS_TESTER_MODELS = [
  {
    model: '1081',
    href_to_model_doc: '',
    image_url: model1081,
    max_hp: '500',
    max_kva: '10',
  },
  {
    model: '2025',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    max_hp: '1,250',
    max_kva: '25',
  },
  {
    model: '2040',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    max_hp: '1,750',
    max_kva: '40',
  },
  {
    model: '2060',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    max_hp: '2,500',
    max_kva: '60',
  },
  {
    model: '2125',
    image_url: imageNotAvailable,
    href_to_model_doc: '',
    max_hp: '5,000',
    max_kva: '125',
  },
  {
    model: '2200',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    max_hp: '7,500',
    max_kva: '200',
  },
  {
    model: '3000',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    max_hp: '10,000',
    max_kva: '300',
  },
];

export const PARTS_AND_ACCESSORIES_PAGE_CABLES_OPTIONS = [
  {
    part_num: 'E04915',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/140/662/E04690-544-546-Sensor-Cable-25-ft__44138.1595003547.jpg?c=2',
    for_use_with:
      '544/546DP Sensor Cables for use with 236, 246, 290, 265, 260, 220, 230, 350',
    description: '2 pin female MS to 3 pin female quick lock',
    length: '4ft or 1.2m ',
    ship_weight: '1lb or 0.5kg',
    purchase_link:
      'https://shop.irdproducts.com/544-546dp-velocity-transducer-cables/',
  },
  {
    part_num: 'E04521',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/140/662/E04690-544-546-Sensor-Cable-25-ft__44138.1595003547.jpg?c=2',
    for_use_with:
      '544/546DP Sensor Cables for use with 236, 246, 290, 265, 260, 220, 230, 350',
    description: '2 pin female MS to 3 pin female quick lock',
    length: '12ft or 3.7m',
    ship_weight: '2lb or 0.9kg',
    purchase_link:
      'https://shop.irdproducts.com/544-546dp-velocity-transducer-cables/',
  },
  {
    part_num: 'E04690',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/140/662/E04690-544-546-Sensor-Cable-25-ft__44138.1595003547.jpg?c=2',
    for_use_with:
      '544/546DP Sensor Cables for use with 236, 246, 290, 265, 260, 220, 230, 350',
    description: '2 pin female MS to 3 pin female quick lock',
    length: '25ft or 7.6m',
    ship_weight: '3lb or 1.4kg',
    purchase_link:
      'https://shop.irdproducts.com/544-546dp-velocity-transducer-cables/',
  },
  {
    part_num: 'E04691',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/140/662/E04690-544-546-Sensor-Cable-25-ft__44138.1595003547.jpg?c=2',
    for_use_with:
      '544/546DP Sensor Cables for use with 236, 246, 290, 265, 260, 220, 230, 350',
    description: '2 pin female MS to 3 pin female quick lock',
    length: '50ft or 15m',
    ship_weight: '5lb or 2.3kg',
    purchase_link:
      'https://shop.irdproducts.com/544-546dp-velocity-transducer-cables/',
  },
  {
    part_num: 'E05202',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/140/662/E04690-544-546-Sensor-Cable-25-ft__44138.1595003547.jpg?c=2',
    for_use_with:
      '544/546DP Sensor Cables for use with 236, 246, 290, 265, 260, 220, 230, 350',
    description: '2 pin female MS to 3 pin female quick lock',
    length: '75ft or 23m',
    ship_weight: '9lb or 4.1kg',
    purchase_link:
      'https://shop.irdproducts.com/544-546dp-velocity-transducer-cables/',
  },
  {
    part_num: 'E04692',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/140/662/E04690-544-546-Sensor-Cable-25-ft__44138.1595003547.jpg?c=2',
    for_use_with:
      '544/546DP Sensor Cables for use with 236, 246, 290, 265, 260, 220, 230, 350',
    description: '2 pin female MS to 3 pin female quick lock',
    length: '100ft or 30m',
    ship_weight: '13lb or 5.9kg',
    purchase_link:
      'https://shop.irdproducts.com/544-546dp-velocity-transducer-cables/',
  },
  {
    part_num: 'E02482',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    for_use_with:
      '544/546DP Sensor Cables that connect to side of old control consoles (female to female)',
    description: '2 pin female MS to 2 pin female MS',
    length: '4ft or 1.2m',
    ship_weight: '1lb or 0.5kg',
    purchase_link: '',
  },
  {
    part_num: 'E02484',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    for_use_with:
      '544/546DP Sensor Cables that connect to side of old control consoles (female to female)',
    description: '2 pin female MS to 2 pin female MS',
    length: '12ft or 3.7m',
    ship_weight: '2lb or 0.9kg',
    purchase_link: '',
  },
  {
    part_num: 'E02485',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    for_use_with:
      '544/546DP Sensor Cables that connect to side of old control consoles (female to female)',
    description: '2 pin female MS to 2 pin female MS',
    length: '25ft or 7.6m',
    ship_weight: '3lb or 1.4kg',
    purchase_link: '',
  },
  {
    part_num: 'E02486',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    for_use_with:
      '544/546DP Sensor Cables that connect to side of old control consoles (female to female)',
    description: '2 pin female MS to 2 pin female MS',
    length: '50ft or 15m',
    ship_weight: '5lb or 2.3kg',
    purchase_link: '',
  },
  {
    part_num: 'E02488',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    for_use_with:
      '544/546DP Sensor Cables that connect to side of old control consoles (female to female)',
    description: '2 pin female MS to 2 pin female MS',
    length: '100ft or 23m',
    ship_weight: '13lb or 4.1kg',
    purchase_link: '',
  },
  {
    part_num: 'E04657',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    for_use_with:
      '544/546DP Sensor Cables that connect to side of old control consoles (female to female)',
    description: '2 pin female MS to 2 pin female MS  *retractile',
    length: '20ft* or 30m',
    ship_weight: '2lb or 5.9kg',
    purchase_link: '',
  },
  {
    part_num: 'E21045',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    for_use_with:
      '544/546DP Sensor Extension Cables for use with 236, 246, 290, 265, 260, 220, 230, 350',
    description: '2 pin female MS to 2 pin male MS',
    length: '25ft or 7.6m',
    ship_weight: '3lb or 1.4kg',
    purchase_link: '',
  },
  {
    part_num: 'E21049',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    for_use_with:
      '544/546DP Sensor Extension Cables for use with 236, 246, 290, 265, 260, 220, 230, 350',
    description: '2 pin female MS to 2 pin male MS',
    length: '50ft or 15m',
    ship_weight: '5lb or 2.3kg',
    purchase_link: '',
  },
  {
    part_num: 'E37668',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    for_use_with: '544/546DP Sensor Cables for 290H/T',
    description: 'Flying leads on one end',
    length: '25ft or 7.6m',
    ship_weight: '3lb or 1.4kg',
    purchase_link: '',
  },
  {
    part_num: 'E31506',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    for_use_with: '544/546DP Sensor Cables for 290H/T',
    description: 'For use with Schenck pickup',
    length: '25ft or 7.6m',
    ship_weight: '3lb or 1.4kg',
    purchase_link: '',
  },
  {
    part_num: 'E30108',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    for_use_with: '544/546DP Sensor Cables inside work support pedestals',
    description:
      'New style, exit from bottom rear of pedestal, B5-200 2 pin female MS to 2 pin male MS',
    length: '3ft or 1m',
    ship_weight: '1lb or 0.5kg',
    purchase_link: '',
  },
  {
    part_num: 'E12498',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    for_use_with: '544/546DP Sensor Cables inside work support pedestals',
    description:
      'Old style, exit through rear wall of pedestal, B50-B800R 2 pin female MS to 2 pin Male MS with mtg plate',
    length: '3ft or 1m',
    ship_weight: '1lb or 0.5kg',
    purchase_link: '',
  },
  {
    part_num: 'E12524',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    for_use_with: '544/546DP Sensor Cables inside work support pedestals',
    description:
      'Old style, exit through rear wall of pedestal, B140 2 pin female MS to 2 pin male MS with mtg plate',
    length: '6ft or 1.8m',
    ship_weight: '2lb or 0.9kg',
    purchase_link: '',
  },
  {
    part_num: 'E23700',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    for_use_with: '546DP Sensor Cable  - Roll balancer whip indicator',
    description: 'Vibration sensor cable with 90 degree connector',
    length: '75ft or 23m',
    ship_weight: '9lb or 4.1kg',
    purchase_link: '',
  },
  {
    part_num: 'E11689',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    for_use_with:
      'Speed Sensor Cables for use with 236, 246, 290, 265, 260, 220, 230, 350',
    description: '4 pin female MS to 4 pin male quick lock',
    length: '5ft or 1.5m',
    ship_weight: '2lb or 0.9kg',
    purchase_link: '',
  },
  {
    part_num: 'E10179',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/141/531/E10179__39932.1548190791.jpg?c=2',
    for_use_with:
      'Speed Sensor Cables for use with 236, 246, 290, 265, 260, 220, 230, 350',
    description: '4 pin female MS to 4 pin male quick lock',
    length: '25ft or 7.6m',
    ship_weight: '3lb or 1.4kg',
    purchase_link:
      'https://shop.irdproducts.com/photoelectric-speed-sensor-cable/',
  },
  {
    part_num: 'E25863',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/141/531/E10179__39932.1548190791.jpg?c=2',
    for_use_with:
      'Speed Sensor Cables for use with 236, 246, 290, 265, 260, 220, 230, 350',
    description: '4 pin female MS to 4 pin male quick lock',
    length: '50ft or 15m',
    ship_weight: '5lb or 2.3kg',
    purchase_link:
      'https://shop.irdproducts.com/photoelectric-speed-sensor-cable/',
  },
  {
    part_num: 'E42006',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/183/549/E42006__12023.1552923240.jpg?c=2',
    for_use_with: 'Calibrator Cables for use with 290, 260, 220',
    description: '3 pin female quick lock to 3 pin male quick lock',
    length: '4ft or 1.2m',
    ship_weight: '1lb or 0.5kg',
    purchase_link:
      'https://shop.irdproducts.com/calibrator-cable-for-ird-balancing-calibrators/',
  },
  {
    part_num: 'E10159',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/183/549/E42006__12023.1552923240.jpg?c=2',
    for_use_with: 'Calibrator Cables for use with 290, 260, 220',
    description: '3 pin female quick lock to 3 pin male quick lock',
    length: '25ft or 7.6m',
    ship_weight: '3lb or 1.4kg',
    purchase_link:
      'https://shop.irdproducts.com/calibrator-cable-for-ird-balancing-calibrators/',
  },
  {
    part_num: 'E10178',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/183/549/E42006__12023.1552923240.jpg?c=2',
    for_use_with: 'Calibrator Cables for use with 290, 260, 220',
    description: '3 pin female quick lock to 3 pin male quick lock',
    length: '50ft or 15m',
    ship_weight: '6lb or 2.7kg',
    purchase_link:
      'https://shop.irdproducts.com/calibrator-cable-for-ird-balancing-calibrators/',
  },
  {
    part_num: 'E23196',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    for_use_with: 'Calibrator Cables for use with 290, 260, 220',
    description: '3 pin female quick lock to 3 pin male quick lock',
    length: '75ft or 23m',
    ship_weight: '9lb or 4.1kg',
    purchase_link: '',
  },
  {
    part_num: 'E20433',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    for_use_with: 'Balancing Instrument Cables for 245',
    description:
      '544 / 546DP vibration sensor cable 2 pin female MS to 9 pin male twist lock',
    length: '12ft or 3.7m',
    ship_weight: '2lb or 0.9kg',
    purchase_link: '',
  },
  {
    part_num: 'E30219',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    for_use_with: 'Balancing Instrument Cables for 245',
    description:
      '544 / 546DP vibration sensor cable 2 pin female MS to 9 pin male twist lock',
    length: '25ft or 7.6m',
    ship_weight: '3lb or 1.4kg',
    purchase_link: '',
  },
  {
    part_num: 'E24761',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    for_use_with: 'Balancing Instrument Cables for 245',
    description: 'Speed sensor cable 4 pin female MS to 5 pin male screw lock',
    length: '25ft or 7.6m',
    ship_weight: '3lb or 1.4kg',
    purchase_link: '',
  },
];

export const PARTS_AND_ACCESSORIES_PAGE_SUPPLIES_OPTIONS = [
  {
    part_num: 'E19061',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/123/496/E19061-Angle-Indicator-Magnetic-Base__35736.1542730465.jpg?c=2',
    description: 'Magnetic base angle indicator',
    ship_weight: '3lb or 1.4kg',
    purchase_link:
      'https://shop.irdproducts.com/angle-indicator-magnetic-base/',
  },
  {
    part_num: 'E11820',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/121/411/E11820-Balancing-Wax__63442.1538146001.jpg?c=2',
    description: 'Balancing wax (price per pound)',
    ship_weight: '2lb or 0.9kg',
    purchase_link: 'https://shop.irdproducts.com/balancing-wax/',
  },
  {
    part_num: 'E32147',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/124/409/E32147-EPO-Dynaweight-Balancing-Compound__29130.1538145924.jpg?c=2',
    description: 'EPO Dynaweight kit',
    ship_weight: '2lb or 0.9kg',
    purchase_link:
      'https://shop.irdproducts.com/epo-dynaweight-balancing-compound/',
  },
  {
    part_num: 'E04753',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/125/502/E04753-Balancing-Weights-Small-C-Clamps__25046.1542730603.jpg?c=2',
    description: 'Balancing weights: set of 16 (c-shaped with set screw)',
    ship_weight: '6lb or 2.7kg',
    purchase_link:
      'https://shop.irdproducts.com/balancing-weights-small-c-clamps/',
  },
  {
    part_num: 'E11946',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/126/499/E11946-Balancing-Weights-Small-Clips__37904.1542730550.jpg?c=2',
    description: 'Balancing clips: squirrel cage fan (7 sizes of 100 each)',
    ship_weight: '11lb or 5kg',
    purchase_link: 'https://shop.irdproducts.com/balancing-weights-fan-clips/',
  },
  {
    part_num: 'E04526',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    description: '544 vibration sensor',
    ship_weight: '2lb or 0.9kg',
    purchase_link: '',
  },
  {
    part_num: 'E04332',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    description: 'Magnetic holder for 544 sensor',
    ship_weight: '1lb or 0.5kg',
    purchase_link: '',
  },
  {
    part_num: 'E02785',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    description: '546DP vibration sensor',
    ship_weight: '2lb or 0.9kg',
    purchase_link: '',
  },
  {
    part_num: 'E11194',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/133/400/E11194-546DP-Velocity-Transducer-with-MTG-Bracket__38908.1538142829.jpg?c=2',
    description:
      '546DP vibration sensor w/ magnetic tip, mounting bracket & hardware',
    ship_weight: '7lb or 3.2kg',
    purchase_link:
      'https://shop.irdproducts.com/546dp-velocity-transducer-with-mounting-bracket/',
  },
  {
    part_num: 'E10170',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/189/565/E10170__57620.1552941701.jpg?c=2',
    description: 'Magnetic tip for 546DP sensor',
    ship_weight: '1lb or 0.5kg',
    purchase_link:
      'https://shop.irdproducts.com/replacement-magnetic-tip-for-model-546dp/',
  },
  {
    part_num: 'E26383',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/176/446/E26383-Universal-Speed-Sensor-Mounting-Arm__53978.1542731725.jpg?c=2',
    description: 'Magnetic flex arm holder for speed sensor',
    ship_weight: '5lb or 2.3kg',
    purchase_link:
      'https://shop.irdproducts.com/speed-sensor-flexible-arm-with-magnetic-base-mounting/',
  },
  {
    part_num: 'E21000',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/129/405/E21000-Speed-Sensor-Photocell__54384.1538145671.jpg?c=2',
    description: 'Photoelectric speed sensor',
    ship_weight: '1lb or 0.5kg',
    purchase_link: 'https://shop.irdproducts.com/speed-sensor-photocell/',
  },
  {
    part_num: 'E21200',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/122/503/Reflective-Tape-50-Yards-2__52255.1542730685.jpg?c=2',
    description:
      'Reflective tape for use w/ photoelectric speed sensor (1 roll)',
    ship_weight: '1lb or 0.5kg',
    purchase_link:
      'https://shop.irdproducts.com/reflective-tape-for-use-with-photoelectric-speed-sensor/',
  },
  {
    part_num: 'E33678',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/114/383/290-Printer-Paper-5-Rolls__51687.1533755893.png?c=2',
    description: `Printer (thermal) paper for older style 290 w/ built in printer 4.4"W x 82' (box of 5 rolls)`,
    ship_weight: '4lb or 1.8kg',
    purchase_link:
      'https://shop.irdproducts.com/replacement-paper-for-290-printer-5-rolls/',
  },
  {
    part_num: 'E35770',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/113/381/246-Printer-Paper-5-Roll__95286.1533755750.png?c=2',
    description: `Printer (thermal) paper for 246, 3.1"W x 230' (box of 5 rolls)`,
    ship_weight: '4lb or 1.8kg',
    purchase_link:
      'https://shop.irdproducts.com/refill-paper-for-246-built-in-printer-5-rolls/',
  },
];

export const PARTS_AND_ACCESSORIES_PAGE_BELTS_OPTIONS = [
  {
    part_num: 'E23917',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/170/656/E23915-0.5in-Belt__34680.1595002060.jpg?c=2',
    for_use_with: 'B5 & B10 Balancing Machines - 0.5in/13mm wide',
    length: '120in or 3,048mm',
    ship_weight: '1lb or 0.5kg',
    purchase_link:
      'https://shop.irdproducts.com/1-2-wide-balancing-machine-belts/',
  },
  {
    part_num: 'E44847',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/170/656/E23915-0.5in-Belt__34680.1595002060.jpg?c=2',
    for_use_with: 'B5 & B10 Balancing Machines - 0.5in/13mm wide',
    length: '135in or 3,429mm',
    ship_weight: '1lb or 0.5kg',
    purchase_link:
      'https://shop.irdproducts.com/1-2-wide-balancing-machine-belts/',
  },
  {
    part_num: 'E23916',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/170/656/E23915-0.5in-Belt__34680.1595002060.jpg?c=2',
    for_use_with: 'B5 & B10 Balancing Machines - 0.5in/13mm wide',
    length: '140in or 3,556mm',
    ship_weight: '1lb or 0.5kg',
    purchase_link:
      'https://shop.irdproducts.com/1-2-wide-balancing-machine-belts/',
  },
  {
    part_num: 'E44848',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/170/656/E23915-0.5in-Belt__34680.1595002060.jpg?c=2',
    for_use_with: 'B5 & B10 Balancing Machines - 0.5in/13mm wide',
    length: '150in or 3,810mm',
    ship_weight: '1lb or 0.5kg',
    purchase_link:
      'https://shop.irdproducts.com/1-2-wide-balancing-machine-belts/',
  },
  {
    part_num: 'E23915',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/170/656/E23915-0.5in-Belt__34680.1595002060.jpg?c=2',
    for_use_with: 'B5 & B10 Balancing Machines - 0.5in/13mm wide',
    length: '160in or 4,064mm',
    ship_weight: '1lb or 0.5kg',
    purchase_link:
      'https://shop.irdproducts.com/1-2-wide-balancing-machine-belts/',
  },
  {
    part_num: 'E30115',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/155/460/E12218-1in-Belt__31020.1542728103.jpg?c=2',
    for_use_with: 'B50 & B100 Balancing Machines - 1in/25mm wide',
    length: '105in or 2,667mm',
    ship_weight: '2lb or 0.9kg',
    purchase_link:
      'https://shop.irdproducts.com/1-wide-x-0-07-thick-25-mm-x-1-7-mm-balancing-machine-belts/',
  },
  {
    part_num: 'E12314',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/155/460/E12218-1in-Belt__31020.1542728103.jpg?c=2',
    for_use_with: 'B50 & B100 Balancing Machines - 1in/25mm wide',
    length: '110in or 2,794mm',
    ship_weight: '2lb or 0.9kg',
    purchase_link:
      'https://shop.irdproducts.com/1-wide-x-0-07-thick-25-mm-x-1-7-mm-balancing-machine-belts/',
  },
  {
    part_num: 'E12437',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/155/460/E12218-1in-Belt__31020.1542728103.jpg?c=2',
    for_use_with: 'B50 & B100 Balancing Machines - 1in/25mm wide',
    length: '115in or 2,921mm',
    ship_weight: '2lb or 0.9kg',
    purchase_link:
      'https://shop.irdproducts.com/1-wide-x-0-07-thick-25-mm-x-1-7-mm-balancing-machine-belts/',
  },
  {
    part_num: 'E12216',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/155/460/E12218-1in-Belt__31020.1542728103.jpg?c=2',
    for_use_with: 'B50 & B100 Balancing Machines - 1in/25mm wide',
    length: '120in or 3,048mm',
    ship_weight: '2lb or 0.9kg',
    purchase_link:
      'https://shop.irdproducts.com/1-wide-x-0-07-thick-25-mm-x-1-7-mm-balancing-machine-belts/',
  },
  {
    part_num: 'E27718',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/155/460/E12218-1in-Belt__31020.1542728103.jpg?c=2',
    for_use_with: 'B50 & B100 Balancing Machines - 1in/25mm wide',
    length: '125in or 3,175mm',
    ship_weight: '3lb or 1.4kg',
    purchase_link:
      'https://shop.irdproducts.com/1-wide-x-0-07-thick-25-mm-x-1-7-mm-balancing-machine-belts/',
  },
  {
    part_num: 'E12217',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/155/460/E12218-1in-Belt__31020.1542728103.jpg?c=2',
    for_use_with: 'B50 & B100 Balancing Machines - 1in/25mm wide',
    length: '135in or 3,429mm',
    ship_weight: '3lb or 1.4kg',
    purchase_link:
      'https://shop.irdproducts.com/1-wide-x-0-07-thick-25-mm-x-1-7-mm-balancing-machine-belts/',
  },
  {
    part_num: 'E12438',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/155/460/E12218-1in-Belt__31020.1542728103.jpg?c=2',
    for_use_with: 'B50 & B100 Balancing Machines - 1in/25mm wide',
    length: '145in or 3,683mm',
    ship_weight: '3lb or 1.4kg',
    purchase_link:
      'https://shop.irdproducts.com/1-wide-x-0-07-thick-25-mm-x-1-7-mm-balancing-machine-belts/',
  },
  {
    part_num: 'E12218',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/155/460/E12218-1in-Belt__31020.1542728103.jpg?c=2',
    for_use_with: 'B50 & B100 Balancing Machines - 1in/25mm wide',
    length: '150in or 3,810mm',
    ship_weight: '3lb or 1.4kg',
    purchase_link:
      'https://shop.irdproducts.com/1-wide-x-0-07-thick-25-mm-x-1-7-mm-balancing-machine-belts/',
  },
  {
    part_num: 'E29170',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/155/460/E12218-1in-Belt__31020.1542728103.jpg?c=2',
    for_use_with: 'B50 & B100 Balancing Machines - 1in/25mm wide',
    length: '155in or 3,937mm',
    ship_weight: '3lb or 1.4kg',
    purchase_link:
      'https://shop.irdproducts.com/1-wide-x-0-07-thick-25-mm-x-1-7-mm-balancing-machine-belts/',
  },
  {
    part_num: 'E12219',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/155/460/E12218-1in-Belt__31020.1542728103.jpg?c=2',
    for_use_with: 'B50 & B100 Balancing Machines - 1in/25mm wide',
    length: '165in or 4,191mm',
    ship_weight: '3lb or 1.4kg',
    purchase_link:
      'https://shop.irdproducts.com/1-wide-x-0-07-thick-25-mm-x-1-7-mm-balancing-machine-belts/',
  },
  {
    part_num: 'E12220',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/155/460/E12218-1in-Belt__31020.1542728103.jpg?c=2',
    for_use_with: 'B50 & B100 Balancing Machines - 1in/25mm wide',
    length: '180in or 4,572mm',
    ship_weight: '4lb or 1.8kg',
    purchase_link:
      'https://shop.irdproducts.com/1-wide-x-0-07-thick-25-mm-x-1-7-mm-balancing-machine-belts/',
  },
  {
    part_num: 'E12221',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/155/460/E12218-1in-Belt__31020.1542728103.jpg?c=2',
    for_use_with: 'B50 & B100 Balancing Machines - 1in/25mm wide',
    length: '195in or 4,953mm',
    ship_weight: '4lb or 1.8kg',
    purchase_link:
      'https://shop.irdproducts.com/1-wide-x-0-07-thick-25-mm-x-1-7-mm-balancing-machine-belts/',
  },
  {
    part_num: 'E12228',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/155/460/E12218-1in-Belt__31020.1542728103.jpg?c=2',
    for_use_with: 'B50 & B100 Balancing Machines - 1in/25mm wide',
    length: '210in or 5,334mm',
    ship_weight: '4lb or 1.8kg',
    purchase_link:
      'https://shop.irdproducts.com/1-wide-x-0-07-thick-25-mm-x-1-7-mm-balancing-machine-belts/',
  },
  {
    part_num: 'E33201',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/155/460/E12218-1in-Belt__31020.1542728103.jpg?c=2',
    for_use_with: 'B50 & B100 Balancing Machines - 1in/25mm wide',
    length: '235in or 5,969mm',
    ship_weight: '4lb or 1.8kg',
    purchase_link:
      'https://shop.irdproducts.com/1-wide-x-0-07-thick-25-mm-x-1-7-mm-balancing-machine-belts/',
  },
  {
    part_num: 'E11705',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/155/460/E12218-1in-Belt__31020.1542728103.jpg?c=2',
    for_use_with: 'B50 & B100 Balancing Machines - 1in/25mm wide',
    length: '256in or 6,502mm',
    ship_weight: '4lb or 1.8kg',
    purchase_link:
      'https://shop.irdproducts.com/1-wide-x-0-07-thick-25-mm-x-1-7-mm-balancing-machine-belts/',
  },
  {
    part_num: 'E33202',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/155/460/E12218-1in-Belt__31020.1542728103.jpg?c=2',
    for_use_with: 'B50 & B100 Balancing Machines - 1in/25mm wide',
    length: '280in or 7,112mm',
    ship_weight: '4lb or 1.8kg',
    purchase_link:
      'https://shop.irdproducts.com/1-wide-x-0-07-thick-25-mm-x-1-7-mm-balancing-machine-belts/',
  },
  {
    part_num: 'E30596',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/171/461/1.75in-belt__01560.1579183635.jpg?c=2',
    for_use_with: 'B150 Balancing Machines - 1.75in/44mm wide',
    length: '192in or 4,877mm',
    ship_weight: '5lb or 2.3kg',
    purchase_link:
      'https://shop.irdproducts.com/1-75-wide-balancing-machine-belts/',
  },
  {
    part_num: 'E30597',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/171/461/1.75in-belt__01560.1579183635.jpg?c=2',
    for_use_with: 'B150 Balancing Machines - 1.75in/44mm wide',
    length: '224in or 5,690mm',
    ship_weight: '5lb or 2.3kg',
    purchase_link:
      'https://shop.irdproducts.com/1-75-wide-balancing-machine-belts/',
  },
  {
    part_num: 'E30598',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/171/461/1.75in-belt__01560.1579183635.jpg?c=2',
    for_use_with: 'B150 Balancing Machines - 1.75in/44mm wide',
    length: '256in or 6,502mm',
    ship_weight: '6lb or 2.7kg',
    purchase_link:
      'https://shop.irdproducts.com/1-75-wide-balancing-machine-belts/',
  },
  {
    part_num: 'E12442',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/184/658/E48934-242__48737.1595002618.jpg?c=2',
    for_use_with: 'B150 & B200 Balancing Machines - 2in/50mm wide',
    length: '218in or 5,537mm',
    ship_weight: '6lb or 2.7kg',
    purchase_link:
      'https://shop.irdproducts.com/2-wide-balancing-machine-belts/',
  },
  {
    part_num: 'E12443',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/184/658/E48934-242__48737.1595002618.jpg?c=2',
    for_use_with: 'B150 & B200 Balancing Machines - 2in/50mm wide',
    length: '242in or 6,147mm',
    ship_weight: '7lb or 3.2kg',
    purchase_link:
      'https://shop.irdproducts.com/2-wide-balancing-machine-belts/',
  },
  {
    part_num: 'E12444',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/184/658/E48934-242__48737.1595002618.jpg?c=2',
    for_use_with: 'B150 & B200 Balancing Machines - 2in/50mm wide',
    length: '266in or 6,756mm',
    ship_weight: '8lb or 4.5kg',
    purchase_link:
      'https://shop.irdproducts.com/2-wide-balancing-machine-belts/',
  },
  {
    part_num: 'E12445',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/184/658/E48934-242__48737.1595002618.jpg?c=2',
    for_use_with: 'B150 & B200 Balancing Machines - 2in/50mm wide',
    length: '290in or 7,366mm',
    ship_weight: '10lb or 4.5kg',
    purchase_link:
      'https://shop.irdproducts.com/2-wide-balancing-machine-belts/',
  },
  {
    part_num: 'E09108',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    for_use_with: 'B200 Balancing Machines - 3in/76mm wide',
    length: '187in or 4,750mm',
    ship_weight: '12lb or 5.5kg',
    purchase_link: '',
  },
  {
    part_num: 'E09109',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    for_use_with: 'B200 Balancing Machines - 3in/76mm wide',
    length: '223in or 5,664mm',
    ship_weight: '13lb or 5.9kg',
    purchase_link: '',
  },
  {
    part_num: 'E09110',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    for_use_with: 'B200 Balancing Machines - 3in/76mm wide',
    length: '259in or 6,579mm',
    ship_weight: '14lb or 6.4kg',
    purchase_link: '',
  },
];

export const PARTS_AND_ACCESSORIES_PAGE_ROLLERS_OPTIONS = [
  {
    part_num: 'E30718',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    description:
      'Twin roller assembly for p/n 30600, 100 lb support qty. 2 required, each',
    diameter: '2in or -mm',
    width: '3/8in or -mm',
    ship_weight: '4lb or 1.8kg',
    purchase_link: '',
  },
  {
    part_num: 'E71275',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    description:
      'Rotor safety hold down with counter roller for p/n 71263 or p/n 71264, 200 lb work supports, qty. 2 required, each',
    diameter: '-in or -mm',
    width: '-in or -mm',
    ship_weight: '10lb or 4.5kg',
    purchase_link: '',
  },
  {
    part_num: 'E71290',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    description:
      'Roller Bearing for p/n 71263 or p/n 71264 200 lb work supports, qty. 4 required, each',
    diameter: '2.17in or 55mm',
    width: '-in or -mm',
    ship_weight: '3lb or 1.4kg',
    purchase_link: '',
  },
  {
    part_num: 'E71291',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    description:
      'Roller Bearing for p/n 71263 or p/n 71264 200 lb work supports, qty. 4 required, each',
    diameter: '3.94lb or 100mm',
    width: '-in or -mm',
    ship_weight: '5lb or 2.3kg',
    purchase_link: '',
  },
  {
    part_num: 'E13434',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/149/522/E13434-3in-diameter-0.5in-Wide-Rollers-1.5K__87230.1542746003.jpg?c=2',
    description:
      'Tire and bearing assembly for p/n 13400 work support qty. 4 required, each',
    diameter: '3in or 76mm',
    width: '0.5in or 13mm',
    ship_weight: '2lb or 0.9kg',
    purchase_link:
      'https://shop.irdproducts.com/3-75-mm-roller-assembly-no-hub/',
  },
  {
    part_num: 'E13435',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/177/585/E13435-5-inch-roller-assembly-no-hub__70804.1553017475.jpg?c=2',
    description:
      '5 inch tire and bearing assembly for p/n 13400 work support qty 4 required, each',
    diameter: '-in or -mm',
    width: '-in or -mm',
    ship_weight: '4lb or 1.8kg',
    purchase_link:
      'https://shop.irdproducts.com/5-125-mm-roller-assembly-no-hub-for-1-500-lb-roller-work-support/',
  },
  {
    part_num: 'E13165',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/203/629/E13165-3_Roller_Assembly_with_Mounting_Hub-LR__62628.1578684266.jpg?c=2',
    description:
      'Bearing and hub assembly for p/n 07379 work support qty. 4 required, each',
    diameter: '3in or 76mm',
    width: '1.375in or 35mm',
    ship_weight: '4lb or 1.8kg',
    purchase_link:
      'https://shop.irdproducts.com/3-80-mm-roller-assembly-with-mounting-hub-5-000-lb-roller-work-support/',
  },
  {
    part_num: 'E11360',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/151/474/E11360-Roller_Bearing__10068.1542728890.jpg?c=2',
    description:
      'Bearing and hub assembly for p/n 07379 work support qty. 4 required, each',
    diameter: '5in or 127mm',
    width: '1.5625in or 40mm',
    ship_weight: '9lb or 4.1kg',
    purchase_link:
      'https://shop.irdproducts.com/5-125-mm-diameter-x-1-56-40-mm-wide-roller-assembly-w-mounting-hub-for-5-000-lb-roller-work-support/',
  },
  {
    part_num: 'E21901',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    description:
      'Bearing and hub assembly for p/n 17913 5000 lb offset support, qty. 4 required, each',
    diameter: '3in or 76mm',
    width: '1.375in or 35mm',
    ship_weight: '4lb or 1.8kg',
    purchase_link: '',
  },
  {
    part_num: 'E21902',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    description:
      'Bearing and hub assembly for p/n 17913 5000 lb offset support, qty. 4 required, each',
    diameter: '5in or 127mm',
    width: '1.5625in or 40mm',
    ship_weight: '6lb or 2.7kg',
    purchase_link: '',
  },
  {
    part_num: 'E11785',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/150/475/E11785-Roller_Bearing__86715.1542728964.jpg?c=2',
    description:
      'Bearing and hub assembly for p/n 11698 work support qty. 4 required, each',
    diameter: '4in or 102mm',
    width: '1.5625in or 40mm',
    ship_weight: '6lb or 2.7kg',
    purchase_link:
      'https://shop.irdproducts.com/4-100-mm-diameter-roller-assembly-with-mounting-hub/',
  },
  {
    part_num: 'E11784',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/159/473/E11784-Roller_Bearing__00988.1542728859.jpg?c=2',
    description:
      'Bearing and hub assembly for p/n 11698 work support qty. 4 required, each',
    diameter: '6in or 152mm',
    width: '1.9375in or 49mm',
    ship_weight: '15lb or 6.8kg',
    purchase_link:
      'https://shop.irdproducts.com/6-diameter-x-1-94-wide-roller-for-use-with-15-000-lb-roller-work-support/',
  },
  {
    part_num: 'E11713',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/202/628/E11713-5_Diameter_Roller-LR__81959.1578684233.jpg?c=2',
    description:
      'Bearing and hub assembly for p/n 07725 work support qty. 4 required, each',
    diameter: '5in or 127mm ',
    width: '2.6875in or 68mm',
    ship_weight: '16lb or 7.3kg',
    purchase_link:
      'https://shop.irdproducts.com/5-diameter-x-2-69-wide-roller-for-use-with-25-000-lb-roller-work-support/',
  },
];

export const CORE_LOSS_TESTER_PAGE_CABLE_OPTIONS = [
  {
    part_num: 'L00301',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/154/505/Flux-Measurement-Cables__29205.1542730850.jpg?c=2',
    description: '15ft (4.6m) Flux Measurement Cable',
    category: 'Flux Measurement Cables',
    kva_col_one: 'Standard',
    kva_col_two: 'Optional',
    purchase_link: 'https://shop.irdproducts.com/flux-measurement-cables/',
  },
  {
    part_num: 'L00302',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/154/505/Flux-Measurement-Cables__29205.1542730850.jpg?c=2',
    description: '20ft (6.1m) Flux Measurement Cable',
    category: 'Flux Measurement Cables',
    kva_col_one: 'Optional',
    kva_col_two: 'Standard',
    purchase_link: 'https://shop.irdproducts.com/flux-measurement-cables/',
  },
  {
    part_num: 'L00213',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/166/523/L00203-Main-Cable-for-CLT__18044.1547498614.jpg?c=2',
    description: '15ft (4.6m) Male to Female Extension Cable',
    category: 'Main and Extension Cables',
    kva_col_one: 'Standard',
    kva_col_two: 'Optional',
    purchase_link:
      'https://shop.irdproducts.com/armature-extension-cable-male-female/',
  },
  {
    part_num: 'L00216',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/166/523/L00203-Main-Cable-for-CLT__18044.1547498614.jpg?c=2',
    description: '20ft (6.1m) Male to Female Extension Cable',
    category: 'Main and Extension Cables',
    kva_col_one: 'Optional',
    kva_col_two: 'Standard',
    purchase_link:
      'https://shop.irdproducts.com/armature-extension-cable-male-female/',
  },
  {
    part_num: 'L00203',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/156/659/L00203-Main-Cable__38665.1595002699.jpg?c=2',
    description: '15ft (4.6m) Main Cable - bolts to VRT',
    category: 'Main and Extension Cables',
    kva_col_one: 'Standard',
    kva_col_two: 'Optional',
    purchase_link:
      'https://shop.irdproducts.com/main-cable-for-lexseco-core-loss-tester/',
  },
  {
    part_num: 'L00205',
    href_to_model_doc: '',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/156/659/L00203-Main-Cable__38665.1595002699.jpg?c=2',
    description: '20ft (6.1m) Main Cable - bolts to VRT',
    category: 'Main and Extension Cables',
    kva_col_one: 'Optional',
    kva_col_two: 'Standard',
    purchase_link:
      'https://shop.irdproducts.com/main-cable-for-lexseco-core-loss-tester/',
  },
  {
    part_num: 'L00215',
    href_to_model_doc: '',
    image_url: imageNotAvailable,
    description: '20ft (6.2m) Male to Male Cable',
    category: 'Main and Extension Cables',
    kva_col_one: 'Optional',
    kva_col_two: 'Standard',
    purchase_link: '',
  },
];

export const LEGACY_PRODUCTS_OPTIONS = [
  {
    part_num: 'Model 246',
    href_to_model_doc: docModel246,
    image_url: model246,
    description: 'portable design for use in on-site balancing',
    category: 'soft-bearing balancing machines',
  },
  {
    part_num: 'Model 236',
    href_to_model_doc: docModel236,
    image_url: model236,
    description: 'cost-effective, high performance solution',
    category: 'soft-bearing balancing machines',
  },
  {
    part_num: 'Model B01/S',
    href_to_model_doc: b01doc,
    image_url: b01_b02s,
    description: 'tabletop w/instrument',
    category: 'B-Series horizontal machines (complete machines)',
  },
  {
    part_num: 'Model B02/S',
    href_to_model_doc: b01doc,
    image_url: b01_b02s,
    description: 'tabletop w/instrument',
    category: 'B-Series horizontal machines (complete machines)',
  },
  {
    part_num: 'Model 258B Series II',
    href_to_model_doc: docModel258,
    image_url: model258,
    description:
      'a single instrument for a range of service, maintenance & diagnostic applications',
    category: 'Vibration Analyzer & Balancer Kit',
  },
  {
    part_num: 'Model 258V Series II',
    href_to_model_doc: docModel258,
    image_url: model258,
    description:
      'a single instrument for a range of service, maintenance & diagnostic applications',
    category:
      'Vibration Analyzer & Balancer Kit. 1, 2, 3, or tri-axial channel sensors',
  },
  // {
  //   part_num: '',
  //   href_to_model_doc: '',
  //   image_url: '',
  //   description: '',
  //   category: '',
  // },
];
