import lexescoArmature from '../assets/images/lexseco_armature.png';
import cartIcon from '../assets/icons/shopping-cart-black.svg';
import armatureTestingDoc from '../assets/pdf-docs/armature-testing/armature-testing.pdf';

export const ARMATURE_TESTING_KIT = [
  {
    part_num: 'L00500',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/119/436/L00500-Armature-Kit-15ft__34566.1542725149.jpg?c=2',
    description: 'Details',
    description_details: `AC Rotor & DC Armature Testing Package
        Includes:  Pair 8" shaft clamps (L08000) & extension cable (L00213)
        `,
    href_to_model_doc: armatureTestingDoc,
    kva_details_one: '10KVA, 25KVA, 40KVA, 60KVA',
    kva_col_one: 'Optional',
    kva_details_two: '125KVA, 200KVA, 300KVA',
    kva_col_two: 'Included',
    purchase: 'Purchase',
    purchase_link:
      'https://shop.irdproducts.com/armature-kit-male-female-clamps-15-ft-cable/',
    purchase_icon: cartIcon,
  },
  {
    part_num: 'L08000',
    image_url:
      'https://cdn11.bigcommerce.com/s-oh65btrrs3/images/stencil/1280x1280/products/118/437/L08000-Kit-Armature-Clamps-Male-and-Female__71517.1542725217.jpg?c=2',
    description: 'Details',
    description_details: `Replacement 8" Shaft Clamp, 1 pair`,
    href_to_model_doc: armatureTestingDoc,
    kva_details_one: '10KVA, 25KVA, 40KVA, 60KVA',
    kva_col_one: 'Optional',
    kva_details_two: '125KVA, 200KVA, 300KVA',
    kva_col_two: 'Optional',
    purchase: 'Purchase',
    purchase_link:
      'https://shop.irdproducts.com/armature-clamp-kit-male-female-clamps/',
    purchase_icon: cartIcon,
  },
];
