import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFirestore, collection, query, orderBy } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyBghv-i0mJ13UhlmfVB037G5QLNMrL8zA8',
  authDomain: 'ird-balancing.firebaseapp.com',
  projectId: 'ird-balancing',
  storageBucket: 'ird-balancing.appspot.com',
  messagingSenderId: '181087817305',
  appId: '1:181087817305:web:b9608560616b9db6f52b48',
  measurementId: 'G-B6B3PZHT1Y',
};

// init firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage(app);
// init services
export const db = getFirestore();
export const featureProdRef = collection(db, 'featured_product');
export const jobPostingsRef = collection(db, 'job_postings');
export const appForBalMachRef = collection(db, 'app_for_bal_mach');
export const customerUtilVidRef = collection(db, 'customer_util_videos');
export const irdVidRef = collection(db, 'ird_videos');
export const contactFormRef = collection(db, 'contact_form');
export const rmaReqRef = collection(db, 'rma_requests');
export const extendedSurveysRef = collection(db, 'extended_surveys');
export const feedbackSurverysRef = collection(db, 'feedback_surveys');
export const shopIrdSurveyRef = collection(db, 'shop_ird_survey');
export const productServRemRef = collection(db, 'product_service_reminders');
export const hasPaidRef = collection(db, 'has_paid');

export const featuredProdRefQuery = query(
  collection(db, 'feautred_product'),
  orderBy('createdAt', 'desc')
);

export const jobPostingsRefQuery = query(
  collection(db, 'job_postings'),
  orderBy('createdAt', 'desc')
);
export const appForBalMachRefQuery = query(
  collection(db, 'app_for_bal_mach'),
  orderBy('createdAt', 'desc')
);

export const productServRemRefQuery = query(
  collection(db, 'product_service_reminders'),
  orderBy('createdAt', 'desc')
);
