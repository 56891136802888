import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowMenu } from '../redux/controls';
import SideMenu from '../components/SideMenu/SideMenu';
import TopMenu from '../components/TopMenu/TopMenu';
import ContentBlock from '../components/ContentBlock/ContentBlock';
import AccordionComponent from '../components/AccordionComponent/AccordionComponent';
import Footer from '../components/Footer/Footer';
import '../styles/standardPageStyle.css';
import '../styles/miniComponents.css';

import { BALANCING_MACHINES_INFO as content } from '../data/PAGE_TEXT_CONTENT';
import classificationBalMach from '../assets/images/balancing-machines-info-page/classification-balancing-machines.jpeg';
import softBearingWorkSupp from '../assets/images/balancing-machines-info-page/soft-bearing-work-supports.png';
import softVsHardBearing from '../assets/images/balancing-machines-info-page/soft-vs-hard-bearing-chart.jpeg';
import staticBalDevice from '../assets/images/balancing-machines-info-page/static-balancing-devices.png';

export default function BalancingMachinesInfo() {
  const { has_paid } = useSelector((state) => state.controls);
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  const WhyTheNeed = () => (
    <div className="mini-component-container">
      <div className="mini-content-wrapper-flex-col">
        <h2 className="mini-h2">{content.why_the_need}</h2>
        <p className="mini-p-text">{content.why_the_need_para_one}</p>
        <p className="mini-p-text">{content.why_the_need_para_two}</p>
        <p className="mini-p-text">{content.why_the_need_para_three}</p>
      </div>
    </div>
  );

  const TypesOfBalMach = () => (
    <div className="mini-component-container">
      <div className="mini-content-wrapper-flex-col">
        <h2 className="mini-h2">{content.types_of_bal_machines}</h2>
        <p className="mini-p-text">{content.types_paragraph}</p>
      </div>

      <div className="mini-image-wrapper">
        <img
          src={classificationBalMach}
          alt="chart"
          className="mini-image-fourty-percent"
        />
      </div>

      <div>
        <h3 className="mini-h3">{content.classification_of_centrifugal}</h3>
        <p className="mini-p-text">{content.centrifugal_para}</p>

        <h4 className="mini-h4">{content.class_one}</h4>
        <p className="mini-p-text">{content.class_one_para}</p>

        <h4 className="mini-h4">{content.class_two}</h4>
        <p className="mini-p-text">{content.class_two_para}</p>

        <h4 className="mini-h4">{content.class_three}</h4>
        <p className="mini-p-text">{content.class_three_para}</p>

        <h4 className="mini-h4">{content.class_four}</h4>
        <p className="mini-p-text">{content.class_four_para}</p>

        <h3 className="mini-h3">{content.static_non_rotating_bal}</h3>
        <p className="mini-p-text">{content.static_para_one}</p>

        <div className="mini-image-wrapper">
          <img
            src={staticBalDevice}
            alt="static balance device"
            className="mini-image-thirty-percent"
          />
        </div>

        <p className="mini-p-text">{content.static_para_two}</p>

        <ul className="mini-ul">
          <li className="mini-li-text">{content.static_satisfactory_for}</li>
          <li className="mini-li-text li-indent">
            {content.satisfactory_for_one}
          </li>
          <li className="mini-li-text li-indent">
            {content.satisfactory_for_two}
          </li>
          <li className="mini-li-text li-indent">
            {content.satisfactory_for_three}
          </li>
        </ul>
      </div>
    </div>
  );

  const SoftVsHardBal = () => (
    <div className="mini-component-container">
      <div>
        <h2>{content.dynamic_bal_soft_vs_hard}</h2>
        <p className="mini-p-text">{content.soft_vs_hard_para}</p>
      </div>

      <div className="mini-content-wrapper-flex-col">
        <div className="mini-content-wrapper-flex-col">
          <h3 className="mini-h3">{content.soft_bearing_machines}</h3>
          <div className="mini-content-wrapper-flex-row">
            <p className="mini-p-text width-fifty-percent">
              {content.soft_bearing_para_one}
            </p>
            <div className="mini-image-wrapper width-fifty-percent">
              <img
                src={softBearingWorkSupp}
                alt="soft bearing work supports"
                className="mini-image-seventy-percent"
              />
            </div>
          </div>
        </div>

        <p className="mini-p-text">{content.soft_bearing_para_two}</p>
        <p className="mini-p-text">{content.soft_bearing_para_three}</p>
        <p className="mini-p-text">{content.soft_bearing_para_four}</p>
        <p className="mini-p-text">{content.soft_bearing_para_five}</p>
        <p className="mini-p-text">{content.soft_bearing_para_six}</p>
      </div>
      <div>
        <h3 className="mini-h3">{content.hard_bearing_machines}</h3>
        <p className="mini-p-text">{content.hard_bearing_para_one}</p>
        <p className="mini-p-text">{content.hard_bearing_para_two}</p>
        <p className="mini-p-text">{content.hard_bearing_para_three}</p>
      </div>

      <div className="mini-image-wrapper">
        <img
          src={softVsHardBearing}
          alt="soft bearing work supports"
          className="mini-image"
        />
      </div>

      <div>
        <h3 className="mini-h3">{content.whip}</h3>
        <p className="mini-p-text">{content.whip_para}</p>
      </div>
    </div>
  );

  const BalMethods = () => (
    <div className="mini-component-container">
      <div>
        <h2 className="mini-h2">{content.balancing_methods}</h2>
        <p className="mini-p-text">{content.bal_method_para}</p>
      </div>
      <ul className="mini-ul">
        <li className="mini-li-title">{content.step_one}</li>
        <li className="mini-li-text li-indent">{content.step_one_para}</li>
        <li className="mini-li-title">{content.step_two}</li>
        <li className="mini-li-text li-indent">{content.step_two_para}</li>
        <li className="mini-li-title">{content.step_three}</li>
        <li className="mini-li-text li-indent">{content.step_three_para}</li>
      </ul>
      <p className="mini-p-text">{content.bal_method_conclusion}</p>
    </div>
  );

  const Alternative = () => (
    <div className="mini-component-container">
      <div>
        <h2 className="mini-h2">{content.alternative_to_mach_bal_field_bal}</h2>
        <p className="mini-p-text">{content.alternative_para_one}</p>
        <p className="mini-p-text">{content.alternative_para_two}</p>
        <ul className="mini-ul">
          <li className="mini-li-text">
            {content.alternative_para_two_pt_one}
          </li>
          <li className="mini-li-text">
            {content.alternative_para_two_pt_two}
          </li>
          <li className="mini-li-text">
            {content.alternative_para_two_pt_three}
          </li>
          <li className="mini-li-text">
            {content.alternative_para_two_pt_four}
          </li>
          <li className="mini-li-text">
            {content.alternative_para_two_pt_five}
          </li>
        </ul>
        <p className="mini-p-text">{content.alternative_para_three}</p>
        <p className="mini-p-text">{content.alternative_para_four}</p>
        <p className="mini-p-text">{content.alternative_para_five}</p>
        <p className="mini-p-text">{content.alternative_para_six}</p>
      </div>
    </div>
  );

  return (
    <main className="container-fluid stnd-container">
      {has_paid && <SideMenu />}
      {has_paid && <TopMenu />}

      <div className="row stnd-content" onClick={toggleMenu}>
        <div className="row stnd-top-content">
          <ContentBlock
            title={content.page_header_text}
            text={content.intro_paragraph}
          />
        </div>

        <div className="row stnd-middle-content-flex-col stnd-center-div accordion-width-wrapper-eighty-percent">
          <AccordionComponent
            defaultActiveKey={'01'}
            eventKeyOne={'01'}
            titleOne={content.why_the_need}
            contentOneFlexCol={false}
            contentOne={<WhyTheNeed />}
            eventKeyTwo={'02'}
            titleTwo={content.types_of_bal_machines}
            contentTwo={<TypesOfBalMach />}
            eventKeyThree={'03'}
            titleThree={content.dynamic_bal_soft_vs_hard}
            contentThree={<SoftVsHardBal />}
            eventKeyFour={'04'}
            titleFour={content.balancing_methods}
            contentFour={<BalMethods />}
            eventKeyFive={'05'}
            titleFive={content.alternative_to_mach_bal_field_bal}
            contentFive={<Alternative />}
          />
        </div>

        <div className="row stnd-bottom-content"></div>
      </div>

      <Footer />
    </main>
  );
}
