/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useDispatch } from 'react-redux';
import { setShowMenu } from '../../redux/controls';
import './banner.css';

import { LINKS as links } from '../../data/LINKS';

export default function Banner() {
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  // const BannerLink = ({ hrefLink, label }) => (
  //   <a
  //     className="btn banner-link banner-animate banner-delay-1 btn-sm"
  //     href={hrefLink}
  //     role="button"
  //   >
  //     {label}
  //   </a>
  // );

  return (
    <div className="container-fluid banner-container" onClick={toggleMenu}>
      <div className="p-5 rounded-lg m-3 banner-background">
        <div className="p-2 banner-content-wrapper">
          <h1 className="display-4 banner-h1">Welcome to IRD</h1>
          <p className="banner-text">
            IRD is a manufacturer of quality assurance testing equipment with
            over 70 years of experience.
            <br />
            We are a full service manufacturer of balancing machines,
            instruments, sensors, and Lexseco core loss testers.
          </p>
          <hr className="my-4 banner-text" />

          {/* <BannerLink hrefLink={links.ird_balancing} label="IRD Balancing" />
          <BannerLink
            hrefLink={links.ird_balancing_products}
            label="IRD Products"
          />
          <BannerLink
            hrefLink={links.lexseco_products}
            label="Lexseco Products"
          /> */}

          <a
            className="btn banner-link banner-animate banner-delay-1 btn-sm"
            href={links.ird_balancing}
            role="button"
          >
            IRD Balancing
          </a>

          <a
            className="btn banner-link banner-animate banner-delay-2 btn-sm"
            href={links.ird_balancing_products}
            role="button"
          >
            IRD Products
          </a>

          <a
            className="btn banner-link banner-animate banner-delay-3 btn-sm"
            href={links.lexseco_products}
            role="button"
          >
            Lexseco Products
          </a>

          {/* <a
            className="btn banner-link banner-animate banner-delay-2 btn-sm"
            href={links.service}
            role="button"
          >
            Service
          </a> */}

          {/* <a
            className="btn banner-link banner-animate banner-delay-3 btn-sm"
            href={links.contact}
            role="button"
          >
            Contact Us
          </a> */}

          {/* <a
            className="btn banner-link banner-animate banner-delay-4 btn-sm"
            href={links.shop}
            target="_blank"
            rel="noreferrer"
            role="button"
          >
            Shop
          </a> */}
        </div>
      </div>
    </div>
  );
}
