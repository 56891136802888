import React from 'react';
import { Link } from 'react-router-dom';
import './meterExchangeService.css';

import { LINKS as links } from '../../data/LINKS';

export default function MeterExchangeService() {
  return (
    <div className="mes-container">
      <div className="mes-top-content">
        <h2 className="mes-header-text">
          Flux Metering System Exchange Program
        </h2>
        <span className="mes-text">
          Do you have an "E" meter? If not, you're going to need one to
          participate in this program. Consider upgrading your LEXSECO® Core
          Loss Tester to not only receive the newest flux meter, but to have
          your Core Loss Tester completely refurbished.{' '}
          <Link to={links.lexseco_clt_upgrades} className="mes-link">
            Find more information about upgrading your Core Loss Tester here!{' '}
          </Link>
          Note: If you're not sure what type of flux meter you have, the upgrade
          page has information you'll need! If the answer is "YES," then
          congratulations, you qualify for the Flux Metering System Exchange
          Program! Here at IRD®, we understand that one day without a
          functioning Core Loss Tester can mean a loss of customers, profits,
          and time. We value our customers and work to serve their needs. That's
          why we created the exchange program. You can now have your meter
          recalibrated with absolutely no downtime!
        </span>
      </div>
      <div className="mes-middle-content">
        <h2 className="mes-sub-header-text">
          Advantages of the Exchange Program
        </h2>
        <ul className="mes-list">
          <li className="mes-text">
            Designed to address calibration requirements for maintaining the
            high testing accuracy for your LEXSECO® Core Loss Tester.
          </li>
          <li className="mes-text">
            Self-enclosed modular metering system which provides for a quick
            removal and installation process.
          </li>
          <li className="mes-text">
            The recalibrated metering system comes complete with Certificate of
            Compliance certified in conformity with the National Institute of
            Standards and Technology (NIST).
          </li>
          <li className="mes-text">
            Will help you conform to ISO 9001, EASA Accreditation, and other
            program standards that require regular and traceable calibration
            while maintaining your Core Loss Testing with little or no
            interruption.
          </li>
        </ul>
      </div>
      <div className="mes-bottom-content">
        <h2 className="mes-sub-header-text">How it works:</h2>
        <ul className="mes-list">
          <li className="mes-text">
            Let us know when you're ready to have your meter calibrated.
          </li>
          <li className="mes-text">
            Send us a request by{' '}
            <a href="mailto:sales@irdproducts.com">email</a>
          </li>
          <li className="mes-text">
            We'll send you a calibrated meter in the mail.
          </li>
          <li className="mes-text">
            Once you receive your meter, promptly return the old meter within
            the same shipping box and padding you received the new one in.
          </li>
          <li className="mes-text">
            Note: If the old meter is not returned within 30 days, you will be
            charged the full price for the cost of a new meter.
          </li>
        </ul>
      </div>
    </div>
  );
}
