import React from 'react';
import './calibrationService.css';

export default function CalibrationService() {
  return (
    <div className="cs-container">
      <div className="cs-top-content">
        <h2 className="cs-header-text">
          Annual Calibration for NIST Compliance
        </h2>
        <span className="cs-text">
          For many workshops around the world, certification is required. For
          others, it's a way of telling the customer that you are qualified to
          do the job. Whatever the reason, IRD® has you covered. For all of our
          devices we are able to perform yearly calibrations which comply with
          the National Institute of Standards and Technology (NIST). For our
          balancing instruments, we offer two options:
        </span>
      </div>
      <div className="cs-middle-content">
        <ul className="cs-list">
          <li className="cs-sub-header-text">In house calibration:</li>
          <li className="cs-text cs-indent">
            Send us your balancing instrument and we will calibrate it in house.
            If any parts are found to be non-operational, you will be notified,
            informed of any costs, and you choose the course of action taken.
            Advantages of this include the fact that in-house, our technicians
            have access to all possible parts necessary to fix the instrument.
            If on-site, repair items may have to be shipped and costs may
            accumulate.
          </li>
          <li className="cs-sub-header-text">On-site calibration:</li>
          <li className="cs-text cs-indent">
            We understand that machine downtime is a major hindrance in some
            shops and can result in a loss of profits. We offer on-site
            calibration of our instruments to ensure that our customer doesn't
            miss a day of work.
          </li>
        </ul>
      </div>
      <div className="cs-bottom-content">
        <span className="cs-text">
          For our Core Loss Testers, we offer both in-house and on-site
          calibration for testers with old meters. However, for newer testers
          which contain an E-meter, we offer the meter exchange program, which
          removes all down time of the Core Loss Tester while reaching full NIST
          compliance. For full information about the meter exchange program,
          keep reading!
        </span>
      </div>
    </div>
  );
}
