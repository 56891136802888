import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentUserDispatch, setUserAuth } from '../../redux/user';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { signOut, getAuth } from 'firebase/auth';
// import { auth } from '../../firebase';
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import './loginForm.css';
// import { Firestore } from 'firebase/firestore';

export default function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { current_user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passConfirm, setPassConfirm] = useState('');
  // const [role, setRole] = useState('');
  // const [admin, setAdmin] = useState(false);
  const [hasError, setHasError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [mode, setMode] = useState('login');
  const auth = getAuth();

  function signIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function resetForm() {
    setEmail('');
    setPassword('');
    setHasError(false);
  }

  // Not utilizing the signup function as firebase will only be used by employees and do not want to expose option to signup to public
  // eslint-disable-next-line no-unused-vars
  async function handleSignup(e) {
    e.preventDefault();
    if (password === passConfirm) {
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          console.log('user', user);
          alert('Account created successfully');
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log('errorCode', errorCode);
          console.log('errorMessage', errorMessage);
        });
    }
  }

  async function handleLogin(e) {
    e.preventDefault();
    try {
      setLoading(true);
      await signIn(email, password)
        .then(setLoading(false))
        .then(navigate('/admin'));
    } catch (error) {
      console.log(error.message);
      alert('Error', error);
    }
  }

  const handleSignout = async () => {
    await signOut(auth)
      .then(() => {
        alert('Signout successful');
      })
      .then(dispatch(setCurrentUserDispatch(null)))
      .then(dispatch(setUserAuth(false)))
      .catch((error) => {
        console.log('signout error:', error);
      });
  };

  const handleResetPassword = () => {
    sendPasswordResetEmail(auth, email)
      .then(
        alert(
          'An email with a password reset has been sent. You may need to check the Spam folder.'
        )
      )
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    resetForm();
  }, [hasError, current_user]);

  return (
    <div className="login-form-container">
      {!current_user ? (
        <>
          <Form className="login-form">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            {mode === 'signup' && (
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Re-Enter Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Re-Enter Password"
                  onChange={(e) => setPassConfirm(e.target.value)}
                />
              </Form.Group>
            )}

            {mode === 'login' && (
              <Button
                className="login-submit-button"
                type="submit"
                disabled={loading}
                onClick={handleLogin}
              >
                Login
              </Button>
            )}

            {mode === 'signup' && (
              <Button
                className="signup-submit-button"
                type="submit"
                disabled={loading}
                // onClick={handleSignup}
              >
                Signup
              </Button>
            )}
          </Form>

          <div className="login-options-wrapper">
            <span
              className="forgot-password-link"
              onClick={handleResetPassword}
            >
              Forgot Password?
            </span>

            {/* <span className="login-text">-Or-</span>

            {mode === 'login' ? (
              <span
                className="change-mode-link"
                onClick={() => setMode('signup')}
              >
                Need to sign up?
              </span>
            ) : (
              <span
                className="change-mode-link"
                onClick={() => setMode('login')}
              >
                Already have an account?
              </span>
            )} */}
          </div>
        </>
      ) : (
        <Button
          className="signout-button"
          type="submit"
          disabled={loading}
          onClick={handleSignout}
        >
          Sign Out
        </Button>
      )}
    </div>
  );
}
