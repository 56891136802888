import React from 'react';
import './applicationList.css';

export default function ApplicationList({ title }) {
  return (
    <div className="application-list-container">
      <div className="application-title-row">
        <h2 className="application-list-title">{title}</h2>
      </div>
      <div className="row application-list-row application-list-guide-row">
        <span className="application-list-text bold-list-text">
          Not sure if our balancers will suit your needs? Here's what our
          customers have used our devices to balance in the past!
        </span>
        <span className="application-list-text">
          <strong>F</strong> = Field balancing, or balancing a rotor in its own
          bearings & support structure. Check out our Field Balancing
          Instruments (Model 246 & Model 258)
        </span>
        <span className="application-list-text">
          <strong> M </strong>= Machine Balancing. Check out our Machine
          Balancing Instrument, Model 295 and browse our Balancing Machines.
        </span>
      </div>
      <div className="row application-list-row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 application-list-col">
          <ul className="application-list">
            <li className="application-list-item">
              Alternator Rotor Balancing (F/M)
            </li>
            <li className="application-list-item">
              Agricultural Machinery Balancing (M)
            </li>
            <li className="application-list-item">Armature Balancing (F/M)</li>
            <li className="application-list-item">Axle Balancing (M)</li>
            <li className="application-list-item">
              Blower Assembly Balancing (F/M)
            </li>
            <li className="application-list-item">
              Blower Wheel Balancing (F/M)
            </li>
            <li className="application-list-item">Camshaft Balancing (F)</li>
            <li className="application-list-item">
              Centrifugal Blowers Balancing (F/M)
            </li>
            <li className="application-list-item">
              Centrifuge Drum Balancing (F)
            </li>
            <li className="application-list-item">Chopper Balancing (F/M)</li>
            <li className="application-list-item">
              Combine Rotor Balancing (F/M)
            </li>
            <li className="application-list-item">
              Compression Rotor Balancing (F/M)
            </li>
            <li className="application-list-item">
              Crankshaft Balancing (F/M)
            </li>
            <li className="application-list-item">
              Cutter Wheel Balancing (F)
            </li>
            <li className="application-list-item">CV Assembly Balancing (M)</li>
            <li className="application-list-item">Disc Balancing (F/M)</li>
            <li className="application-list-item">
              Driveshaft Balancing (F/M)
            </li>
            <li className="application-list-item">
              Electrical Generator Balancing (F/M)
            </li>
            <li className="application-list-item">
              Electrical Motor Balancing (F/M)
            </li>
            <li className="application-list-item">Engine Balancing (F)</li>
            <li className="application-list-item">Fanblade Balancing (F/M)</li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 application-list-col">
          <ul className="application-list">
            <li className="application-list-item">Fan Balancing (F/M)</li>
            <li className="application-list-item">
              Flywheel Balancing (F/M size dependent)
            </li>
            <li className="application-list-item">
              Gas Separator Balancing (F)
            </li>
            <li className="application-list-item">
              Gas Turbine Balancing (F/M)
            </li>
            <li className="application-list-item">
              Gearbox & Flexible Coupling Balancing (F)
            </li>
            <li className="application-list-item">Generator Balancing (F)</li>
            <li className="application-list-item">
              Grinding Machine Drive Balancing (F)
            </li>
            <li className="application-list-item">Gyroscope Balancing (F/M)</li>
            <li className="application-list-item">Impeller Balancing (F/M)</li>
            <li className="application-list-item">
              Jet Engine Assembly Balancing (F)
            </li>
            <li className="application-list-item">
              Jet Turbine Balancing (F/M)
            </li>
            <li className="application-list-item">
              Juice Extractor Balancing (F/M)
            </li>
            <li className="application-list-item">
              Motor Balancing (F - rotor machine assembly)
            </li>
            <li className="application-list-item">
              Motor Blower Assembly Balancing (F)
            </li>
            <li className="application-list-item">
              Motor Fan Assembly Balancing (F/M)
            </li>
            <li className="application-list-item">
              Paper Roller Balancing (M)
            </li>
            <li className="application-list-item">
              Pinion Flange Balancing (F/M)
            </li>
            <li className="application-list-item">Propeller Balancing (F/M)</li>
            <li className="application-list-item">
              Pump Impeller Balancing (F/M)
            </li>
            <li className="application-list-item">
              Radiator Fan Assembly Balancing (F/M)
            </li>
            <li className="application-list-item">Roll Balancing (M)</li>
          </ul>
        </div>
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 application-list-col">
          <ul className="application-list">
            <li className="application-list-item">
              Rotary Vacuum Pump Balancing (F/M)
            </li>
            <li className="application-list-item">Rotor Balancing (F/M)</li>
            <li className="application-list-item">
              Supercharger Balancing (F/M)
            </li>
            <li className="application-list-item">Shaft Balancing (F/M)</li>
            <li className="application-list-item">Sheave Balancing (M)</li>
            <li className="application-list-item">Spindle Balancing (F/M)</li>
            <li className="application-list-item">
              Steam Turbine Balancing (F/M)
            </li>
            <li className="application-list-item">
              Stump Grinder Balancing (F/M)
            </li>
            <li className="application-list-item">Sun Gear Balancing (F)</li>
            <li className="application-list-item">
              Timing Disc Balancing (F/M)
            </li>
            <li className="application-list-item">
              Transmission Component Balancing (F)
            </li>
            <li className="application-list-item">Turbine Balancing (F/M)</li>
            <li className="application-list-item">
              Turbo Compressor Balancing (M/F)
            </li>
            <li className="application-list-item">
              Turbo Core Balancing (F/M)
            </li>
            <li className="application-list-item">
              Turbo Charger Balancing (F/M)
            </li>
            <li className="application-list-item">
              Washer Drum Balancing (F/M)
            </li>
            <li className="application-list-item">
              Weed Trimmer Balancing (F/M)
            </li>
            <li className="application-list-item">Wheel Balancing (F)</li>
            <li className="application-list-item">
              Wind Turbine Balancing (F)
            </li>
            <li className="application-list-item">
              Wood Chipper Balancing (F)
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
