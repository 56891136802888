import React from 'react';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from '@react-pdf/renderer';

export default function PDF({
  form_id,
  date,
  contact_person,
  title,
  email,
  phone,
  fax,
  company_name,
  company_address,
  city,
  state,
  postal_code,
  country,
  sec_2_1_fan,
  sec_2_1_armature,
  sec_2_1_turbine,
  sec_2_1_roll,
  sec_2_1_impeller,
  sec_2_1_spindle,
  sec_2_1_disk,
  sec_2_1_crankshaft,
  sec_2_1_flywheel,
  sec_2_1_other,
  sec_2_1_other_details,
  sec_2_2_between_bearings,
  sec_2_2_overhung,
  sec_2_2_between_bearings_and_overhung,
  sec_2_3_max_upward_force,
  sec_2_3_unit_kg,
  sec_2_3_unit_lb,
  sec_2_3_not_sure,
  sec_2_4_yes,
  sec_2_4_no,
  sec_2_4_both,
  sec_2_5_max_rotor_weight,
  sec_2_5_unit_kg,
  sec_2_5_unit_lb,
  sec_2_6_min_rotor_weight,
  sec_2_6_unit_kg,
  sec_2_6_unit_lb,
  sec_2_7_max_swing_diameter,
  sec_2_7_unit_mm,
  sec_2_7_unit_in,
  sec_2_8_max_rotor_length,
  sec_2_8_unit_mm,
  sec_2_8_unit_in,
  sec_2_9_min_rotor_length,
  sec_2_9_unit_mm,
  sec_2_9_unit_in,
  sec_2_10_max_dist_between_journals,
  sec_2_10_unit_mm,
  sec_2_10_unit_in,
  sec_2_11_min_dist_between_journals,
  sec_2_11_unit_mm,
  sec_2_11_unit_in,
  sec_2_12_own_bearing,
  sec_2_12_journal_surface,
  sec_2_13_max_journal_diam,
  sec_2_13_unit_mm,
  sec_2_13_unit_in,
  sec_2_14_min_journal_diam,
  sec_2_14_unit_mm,
  sec_2_14_unit_in,
  sec_2_15_min_journal_width,
  sec_2_15_unit_mm,
  sec_2_15_unit_in,
  sec_2_16_max_moment_of_inertia,
  sec_2_16_unit_kgm2,
  sec_2_16_unit_lbft2,
  sec_2_16_unit_not_sure,
  sec_2_17_speed_range_rotors,
  sec_2_18_max_power_absorbed,
  sec_2_18_unit_kw,
  sec_2_18_unit_hp,
  sec_2_18_unit_not_sure,
  sec_2_19_max_bal_speed_rolls,
  sec_2_19_unit_mmin,
  sec_2_19_unit_ftmin,
  sec_2_20_high_int_yes,
  sec_2_20_high_int_no,
  sec_3_1_one_plane,
  sec_3_1_two_plane,
  sec_3_1_both_planes,
  sec_3_2_tolerance_amount,
  sec_3_2_unit_iso,
  sec_3_2_unit_api,
  sec_3_2_unit_gmm,
  sec_3_2_unit_ozin,
  sec_3_3_max_init_unbal,
  sec_3_3_unit_gin,
  sec_3_3_unit_gmm,
  sec_3_3_unit_ozin,
  sec_3_3_unit_not_sure,
  sec_3_4_num_rotors_bal,
  sec_4_5_ac_volt,
  sec_4_5_phase,
  sec_4_5_hz,
  sec_4_6_belt,
  sec_4_6_end_drive,
  sec_4_7_drive_power_req,
  sec_4_7_unit_kw,
  sec_4_7_unit_hp,
  sec_4_7_unit_not_sure,
  sec_4_8_var_speed,
  sec_4_9_portable,
  sec_4_9_digital,
  sec_4_9_computer,
  sec_4_10_cert_yes,
  sec_4_10_cert_no,
  sec_4_10_cert_not_sure,
  sec_4_11_safety_none,
  sec_4_11_safety_perimeter_fence,
  sec_5_2_bearing_details,
  sec_5_3_other_info,
  sec_5_4_additional_req,
  newBalMachIntro,
  newBalMachSafetyDeviceNote,
}) {
  const styles = StyleSheet.create({
    viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 15,
      paddingBottom: 20,
      paddingLeft: 10,
      paddingRight: 10,
    },
    form_id_wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      marginBottom: 40,
    },
    flex_col: {
      display: 'flex',
      flexDirection: 'column',
    },
    flex_row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
    },
    flex_row_space_between: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
    },
    fr: {
      display: 'flex',
      flexDirection: 'row',
    },
    fr_end: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: '100%',
    },
    flex_col_50: {
      display: 'flex',
      flexDirection: 'column',
      width: '40%',
      marginLeft: '5%',
      marginRight: '5%',
    },
    fc_70: {
      display: 'flex',
      flexDirection: 'column',
      width: '70%',
    },
    fc_60: {
      display: 'flex',
      flexDirection: 'column',
      width: '60%',
    },
    fc_50: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
    },
    fc_40: {
      display: 'flex',
      flexDirection: 'column',
      width: '40%',
    },
    fc_30: {
      display: 'flex',
      flexDirection: 'column',
      width: '30%',
    },
    flex_row_m10: {
      display: 'flex',
      marginLeft: 10,
      marginRight: 10,
    },
    bold_text: {
      fontWeight: 50,
      fontSize: 13,
    },
    bold_small: {
      fontWeight: 50,
      fontSize: 11,
    },
    small_text: {
      fontSize: 11,
    },
    small_text_mr5: {
      fontSize: 10,
      marginRight: 5,
    },
    small_text_mr10: {
      fontSize: 10,
      marginRight: 10,
    },
    small_text_ml5: {
      fontSize: 10,
      marginLeft: 5,
    },
    small_text_ml10: {
      fontSize: 10,
      marginLeft: 10,
    },
    ml_10: {
      marginLeft: 10,
    },
    mr_10: {
      marginRight: 10,
    },
    ml_20: {
      marginLeft: 20,
    },
    mr_20: {
      marginRight: 20,
    },
    mt_20: {
      marginTop: 20,
    },
    mb_20: {
      marginBottom: 20,
    },
    lm_icon: {
      width: 20,
      marginRight: 5,
      marginLeft: 5,
    },
  });

  const isChecked = <Text style={styles.small_text}>_x_</Text>;
  const notChecked = <Text style={styles.small_text}>___</Text>;

  return (
    <PDFViewer style={styles.viewer}>
      <Document>
        <Page style={styles.body}>
          <View style={styles.form_id_wrapper}>
            <View style={styles.flex_row}>
              <Text style={styles.bold_small}>Customer:</Text>
              <Text style={styles.small_text_ml5}>{company_name}</Text>
            </View>
            <View style={styles.flex_row}>
              <Text style={styles.bold_small}>Form ID:</Text>
              <Text style={styles.small_text_ml5}>{form_id}</Text>
            </View>
          </View>

          <View style={styles.flex_row}>
            <View style={styles.flex_col_50}>
              <View style={styles.flex_row_space_between}>
                <Text style={styles.bold_small}>Date</Text>
                <Text style={styles.small_text}>{date}</Text>
              </View>

              <View style={styles.flex_row_space_between}>
                <Text style={styles.bold_small}>Contact Person</Text>
                <Text style={styles.small_text}>{contact_person}</Text>
              </View>

              <View style={styles.flex_row_space_between}>
                <Text style={styles.bold_small}>Title</Text>
                <Text style={styles.small_text}>{title}</Text>
              </View>

              <View style={styles.flex_row_space_between}>
                <Text style={styles.bold_small}>Email</Text>
                <Text style={styles.small_text}>{email}</Text>
              </View>

              <View style={styles.flex_row_space_between}>
                <Text style={styles.bold_small}>Phone</Text>
                <Text style={styles.small_text}>{phone}</Text>
              </View>

              <View style={styles.flex_row_space_between}>
                <Text style={styles.bold_small}>Fax</Text>
                <Text style={styles.small_text}>{fax}</Text>
              </View>
            </View>

            <View style={styles.flex_col_50}>
              <View style={styles.flex_row_space_between}>
                <Text style={styles.bold_small}>Company Name</Text>
                <Text style={styles.small_text}>{company_name}</Text>
              </View>

              <View style={styles.flex_row_space_between}>
                <Text style={styles.bold_small}>Company Address</Text>
                <Text style={styles.small_text}>{company_address}</Text>
              </View>

              <View style={styles.flex_row_space_between}>
                <Text style={styles.bold_small}>City</Text>
                <Text style={styles.small_text}>{city}</Text>
              </View>

              <View style={styles.flex_row_space_between}>
                <Text style={styles.bold_small}>State</Text>
                <Text style={styles.small_text}>{state}</Text>
              </View>

              <View style={styles.flex_row_space_between}>
                <Text style={styles.bold_small}>Postal Code</Text>
                <Text style={styles.small_text}>{postal_code}</Text>
              </View>

              <View style={styles.flex_row_space_between}>
                <Text style={styles.bold_small}>Country</Text>
                <Text style={styles.small_text}>{country}</Text>
              </View>
            </View>
          </View>

          <View
            style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}
          >
            <Text style={styles.bold_text}>1. Introduction</Text>
            <Text style={styles.small_text}>{newBalMachIntro}</Text>
          </View>
        </Page>

        <Page style={styles.body}>
          <View
            style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}
          >
            <Text style={styles.bold_text}>
              2. Rotor Details (If necessary, reproduce this page and enter data
              for more rotors)
            </Text>
          </View>

          {/* SEC 2.1 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_40}>
                <Text style={styles.bold_small}>
                  1. List All Types of Rotors to Balance
                </Text>
              </View>

              <View style={styles.flex_col}>
                <View style={styles.flex_row}>
                  {sec_2_1_fan ? isChecked : notChecked}
                  <Text style={styles.small_text}>Fan</Text>
                </View>

                <View style={styles.flex_row}>
                  {sec_2_1_roll ? isChecked : notChecked}
                  <Text style={styles.small_text}>Roll</Text>
                </View>

                <View style={styles.flex_row}>
                  {sec_2_1_crankshaft ? isChecked : notChecked}
                  <Text style={styles.small_text}>Crankshaft</Text>
                </View>
              </View>

              <View style={styles.flex_col}>
                <View style={styles.flex_row}>
                  {sec_2_1_armature ? isChecked : notChecked}
                  <Text style={styles.small_text}>Armature</Text>
                </View>

                <View style={styles.flex_row}>
                  {sec_2_1_impeller ? isChecked : notChecked}
                  <Text style={styles.small_text}>Impeller</Text>
                </View>

                <View style={styles.flex_row}>
                  {sec_2_1_flywheel ? isChecked : notChecked}
                  <Text style={styles.small_text}>Flywheel</Text>
                </View>
              </View>

              <View style={styles.flex_col}>
                <View style={styles.flex_row}>
                  {sec_2_1_turbine ? isChecked : notChecked}
                  <Text style={styles.small_text}>Gas/Steam Turbine</Text>
                </View>

                <View style={styles.flex_row}>
                  {sec_2_1_spindle ? isChecked : notChecked}
                  <Text style={styles.small_text}>Spindle</Text>
                </View>

                <View style={styles.flex_row}>
                  {sec_2_1_disk ? isChecked : notChecked}
                  <Text style={styles.small_text}>Disk</Text>
                </View>
              </View>

              <View style={styles.flex_col}>
                <View style={styles.flex_row}>
                  {sec_2_1_other ? isChecked : notChecked}
                  <Text style={styles.small_text}>Other</Text>
                </View>

                <Text style={styles.small_text}>Other Details</Text>

                <View style={styles.flex_row}>
                  {sec_2_1_other_details !== null ? (
                    <Text style={styles.small_text}>
                      {sec_2_1_other_details}
                    </Text>
                  ) : (
                    <Text style={styles.small_text}>n/a</Text>
                  )}
                </View>
              </View>
            </View>
          </View>

          {/* SEC 2.2 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>2. Rotor Mass Symmetry</Text>
              </View>
              <View style={styles.fc_50}>
                <View style={styles.flex_row}>
                  {sec_2_2_between_bearings ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>Between Bearings</Text>

                  {sec_2_2_overhung ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>Overhung</Text>

                  {sec_2_2_between_bearings_and_overhung
                    ? isChecked
                    : notChecked}
                  <Text style={styles.small_text_mr5}>Both</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 2.3 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>
                  3. If Overhung Rotor, estimated max upward force
                </Text>
              </View>
              <View style={styles.fc_50}>
                <View style={styles.fr_end}>
                  {sec_2_3_not_sure ? isChecked : notChecked}
                  <Text style={styles.small_text_mr10}>Not Sure</Text>

                  {sec_2_3_max_upward_force !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Max Upward Force:
                      </Text>
                      <Text style={styles.small_text_mr5}>
                        {sec_2_3_max_upward_force}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Max Upward Force:
                      </Text>
                      <Text style={styles.small_text}>unknown</Text>
                    </View>
                  )}

                  {sec_2_3_unit_kg ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>kg</Text>

                  {sec_2_3_unit_lb ? isChecked : notChecked}
                  <Text style={styles.small_text}>lb</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 2.4 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>
                  4. Do Rotors have their own shaft with journals?
                </Text>
              </View>
              <View style={styles.fc_50}>
                <View style={styles.fr_end}>
                  {sec_2_4_yes ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>Yes</Text>

                  {sec_2_4_no ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>No</Text>

                  {sec_2_4_both ? isChecked : notChecked}
                  <Text style={styles.small_text}>Both</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 2.5 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>5. Maximum Rotor Weight</Text>
              </View>
              <View style={styles.fc_50}>
                <View style={styles.fr_end}>
                  {sec_2_5_max_rotor_weight !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Max Rotor Weight:
                      </Text>
                      <Text style={styles.small_text_mr10}>
                        {sec_2_5_max_rotor_weight}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Max Rotor Weight:
                      </Text>
                      <Text style={styles.small_text_mr10}>unknown</Text>
                    </View>
                  )}
                  {sec_2_5_unit_kg ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>kg</Text>

                  {sec_2_5_unit_lb ? isChecked : notChecked}
                  <Text style={styles.small_text}>lb</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 2.6 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>6. Minimum Rotor Weight</Text>
              </View>
              <View style={styles.fc_50}>
                <View style={styles.fr_end}>
                  {sec_2_6_min_rotor_weight !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Min Rotor Weight:
                      </Text>
                      <Text style={styles.small_text_mr10}>
                        {sec_2_6_min_rotor_weight}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Min Rotor Weight:
                      </Text>
                      <Text style={styles.small_text_mr10}>unknown</Text>
                    </View>
                  )}

                  {sec_2_6_unit_kg ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>kg</Text>

                  {sec_2_6_unit_lb ? isChecked : notChecked}
                  <Text style={styles.small_text}>lb</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 2.7 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>
                  7. (D) Maximum Swing Diameter of Rotors
                </Text>
              </View>
              <View style={styles.fc_50}>
                <View style={styles.fr_end}>
                  {sec_2_7_max_swing_diameter !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>Max Swing Diam:</Text>
                      <Text style={styles.small_text_mr10}>
                        {sec_2_7_max_swing_diameter}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>Max Swing Diam:</Text>
                      <Text style={styles.small_text_mr10}>unknown</Text>
                    </View>
                  )}

                  {sec_2_7_unit_mm ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>mm</Text>

                  {sec_2_7_unit_in ? isChecked : notChecked}
                  <Text style={styles.small_text}>in</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 2.8 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>8. Maximum Rotor Length</Text>
              </View>
              <View style={styles.fc_50}>
                <View style={styles.fr_end}>
                  {sec_2_8_max_rotor_length !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Max Rotor Length:
                      </Text>
                      <Text style={styles.small_text_mr10}>
                        {sec_2_8_max_rotor_length}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Max Rotor Length:
                      </Text>
                      <Text style={styles.small_text_mr10}>unknown</Text>
                    </View>
                  )}
                  {sec_2_8_unit_mm ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>mm</Text>

                  {sec_2_8_unit_in ? isChecked : notChecked}
                  <Text style={styles.small_text}>in</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 2.9 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>9. Minimum Rotor Length</Text>
              </View>
              <View style={styles.fc_50}>
                <View style={styles.fr_end}>
                  {sec_2_9_min_rotor_length !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Min Rotor Length:
                      </Text>
                      <Text style={styles.small_text_mr10}>
                        {sec_2_9_min_rotor_length}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Min Rotor Length:
                      </Text>
                      <Text style={styles.small_text_mr10}>unknown</Text>
                    </View>
                  )}
                  {sec_2_9_unit_mm ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>mm</Text>

                  {sec_2_9_unit_in ? isChecked : notChecked}
                  <Text style={styles.small_text}>in</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 2.10 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>
                  10. (B) Maximum Distance between Journals
                </Text>
              </View>
              <View style={styles.fc_50}>
                <View style={styles.fr_end}>
                  {sec_2_10_max_dist_between_journals !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>Max Distance:</Text>
                      <Text style={styles.small_text_mr10}>
                        {sec_2_10_max_dist_between_journals}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>Max Distance:</Text>
                      <Text style={styles.small_text_mr10}>unknown</Text>
                    </View>
                  )}

                  {sec_2_10_unit_mm ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>mm</Text>

                  {sec_2_10_unit_in ? isChecked : notChecked}
                  <Text style={styles.small_text}>in</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 2.11 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>
                  11. (B) Minimum Distance between Journals
                </Text>
              </View>
              <View style={styles.fc_50}>
                <View style={styles.fr_end}>
                  {sec_2_11_min_dist_between_journals !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>Min Distance:</Text>
                      <Text style={styles.small_text_mr10}>
                        {sec_2_11_min_dist_between_journals}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>Min Distance:</Text>
                      <Text style={styles.small_text_mr10}>unknown</Text>
                    </View>
                  )}

                  {sec_2_11_unit_mm ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>mm</Text>

                  {sec_2_11_unit_in ? isChecked : notChecked}
                  <Text style={styles.small_text}>in</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 2.12 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>12. Rotor Support Method</Text>
              </View>
              <View style={styles.fc_50}>
                <View style={styles.fr_end}>
                  {sec_2_12_own_bearing ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>Own Bearings</Text>

                  {sec_2_12_journal_surface ? isChecked : notChecked}
                  <Text style={styles.small_text}>Journal Surface</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 2.13 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>
                  13. (A) Maximum Journal Diameter
                </Text>
              </View>
              <View style={styles.fc_50}>
                <View style={styles.fr_end}>
                  {sec_2_13_max_journal_diam !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Max Journal Diam:
                      </Text>
                      <Text style={styles.small_text_mr10}>
                        {sec_2_13_max_journal_diam}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Max Journal Diam:
                      </Text>
                      <Text style={styles.small_text_mr10}>unknown</Text>
                    </View>
                  )}

                  {sec_2_13_unit_mm ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>mm</Text>

                  {sec_2_13_unit_in ? isChecked : notChecked}
                  <Text style={styles.small_text}>in</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 2.14 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>
                  14. (A) Minimum Journal Diameter
                </Text>
              </View>
              <View style={styles.fc_50}>
                <View style={styles.fr_end}>
                  {sec_2_14_min_journal_diam !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Min Journal Diam:
                      </Text>
                      <Text style={styles.small_text_mr10}>
                        {sec_2_14_min_journal_diam}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Min Journal Diam:
                      </Text>
                      <Text style={styles.small_text_mr10}>unknown</Text>
                    </View>
                  )}

                  {sec_2_14_unit_mm ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>mm</Text>

                  {sec_2_14_unit_in ? isChecked : notChecked}
                  <Text style={styles.small_text}>in</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 2.15 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>
                  15. (E) Minimum Journal Width
                </Text>
              </View>
              <View style={styles.fc_50}>
                <View style={styles.fr_end}>
                  {sec_2_15_min_journal_width !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Min Journal Width:
                      </Text>
                      <Text style={styles.small_text_mr10}>
                        {sec_2_15_min_journal_width}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Min Journal Width:
                      </Text>
                      <Text style={styles.small_text_mr10}>unknown</Text>
                    </View>
                  )}

                  {sec_2_15_unit_mm ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>mm</Text>

                  {sec_2_15_unit_in ? isChecked : notChecked}
                  <Text style={styles.small_text}>in</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 2.16 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_40}>
                <Text style={styles.bold_small}>
                  16. Maximum Moment of Inertia
                </Text>
              </View>
              <View style={styles.fc_60}>
                <View style={styles.fr_end}>
                  {sec_2_16_unit_not_sure ? isChecked : notChecked}
                  <Text style={styles.small_text_mr10}>Not Sure</Text>

                  {sec_2_16_max_moment_of_inertia !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Moment of Max Intertia:
                      </Text>
                      <Text style={styles.small_text_mr5}>
                        {sec_2_16_max_moment_of_inertia}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Moment of Max Intertia:
                      </Text>
                      <Text style={styles.small_text_mr10}>unknown</Text>
                    </View>
                  )}

                  {sec_2_16_unit_kgm2 ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>kgm^2</Text>

                  {sec_2_16_unit_lbft2 ? isChecked : notChecked}
                  <Text style={styles.small_text}>lb ft^2</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 2.17 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>
                  17. Operating Speed Range of Rotors
                </Text>
              </View>
              <View style={styles.fc_50}>
                <View style={styles.fr_end}>
                  {sec_2_17_speed_range_rotors !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Operating Speed Range:
                      </Text>
                      <Text style={styles.small_text}>
                        {sec_2_17_speed_range_rotors}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Operating Speed Range:
                      </Text>
                      <Text style={styles.small_text}>unknown</Text>
                    </View>
                  )}
                </View>
              </View>
            </View>
          </View>

          {/* SEC 2.18 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>
                  18. Max. Power Absorbed at Operating Speed
                </Text>
              </View>
              <View style={styles.fc_50}>
                <View style={styles.fr_end}>
                  {sec_2_18_unit_not_sure ? isChecked : notChecked}
                  <Text style={styles.small_text_mr10}>Not Sure</Text>

                  {sec_2_18_max_power_absorbed !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Max Power Absorbed:
                      </Text>
                      <Text style={styles.small_text_mr10}>
                        {sec_2_18_max_power_absorbed}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Max Power Absorbed:
                      </Text>
                      <Text style={styles.small_text_mr10}>unknown</Text>
                    </View>
                  )}

                  {sec_2_18_unit_kw ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>kW</Text>

                  {sec_2_18_unit_hp ? isChecked : notChecked}
                  <Text style={styles.small_text}>hp</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 2.19 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>
                  19. Roll Data: Maximum Balancing Speed for Rolls
                </Text>
              </View>
              <View style={styles.fc_50}>
                <View style={styles.fr_end}>
                  {sec_2_19_max_bal_speed_rolls !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>Max Speed:</Text>
                      <Text style={styles.small_text_mr10}>
                        {sec_2_19_max_bal_speed_rolls}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>Max Speed:</Text>
                      <Text style={styles.small_text_mr10}>unknown</Text>
                    </View>
                  )}

                  {sec_2_19_unit_mmin ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>m/min</Text>

                  {sec_2_19_unit_ftmin ? isChecked : notChecked}
                  <Text style={styles.small_text}>ft/min</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 2.20 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>
                  20. Roll Data: High Internal Resistance? (Suction)
                </Text>
              </View>
              <View style={styles.fc_50}>
                <View style={styles.fr_end}>
                  {sec_2_20_high_int_yes ? isChecked : notChecked}
                  <Text style={styles.small_text_mr10}>Yes</Text>

                  {sec_2_20_high_int_no ? isChecked : notChecked}
                  <Text style={styles.small_text}>No</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>

        <Page style={styles.body}>
          {/* SECTION 3 */}
          <View
            style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}
          >
            <Text style={styles.bold_text}>3. Balance Details</Text>
          </View>

          {/* SEC 3.1 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>
                  1. Number of Balance Planes
                </Text>
              </View>
              <View style={styles.fc_50}>
                <View style={styles.fr_end}>
                  {sec_3_1_one_plane ? isChecked : notChecked}
                  <Text style={styles.small_text_mr10}>One</Text>

                  {sec_3_1_two_plane ? isChecked : notChecked}
                  <Text style={styles.small_text_mr10}>Two</Text>

                  {sec_3_1_both_planes ? isChecked : notChecked}
                  <Text style={styles.small_text}>Both</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 3.2 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_30}>
                <Text style={styles.bold_small}>
                  2. Balance Tolerance Level – Amount/Grade Value and Units
                  Required
                </Text>
              </View>
              <View style={styles.fc_70}>
                <View style={styles.fr_end}>
                  {sec_3_2_tolerance_amount !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Tolerance Level:
                      </Text>
                      <Text style={styles.small_text_mr5}>
                        {sec_3_2_tolerance_amount}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Tolerance Level:
                      </Text>
                      <Text style={styles.small_text_mr5}>unknown</Text>
                    </View>
                  )}

                  {sec_3_2_unit_iso ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>ISO Grade</Text>

                  {sec_3_2_unit_api ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>API</Text>

                  {sec_3_2_unit_gmm ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>g-mm</Text>

                  {sec_3_2_unit_ozin ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>oz-in</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 3.3 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_40}>
                <Text style={styles.bold_small}>
                  3. Maximum Initial Unbalance
                </Text>
              </View>
              <View style={styles.fc_60}>
                <View style={styles.fr_end}>
                  {sec_3_3_unit_not_sure ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>Not Sure</Text>

                  {sec_3_3_max_init_unbal !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Max Initial Unbalance:
                      </Text>
                      <Text style={styles.small_text_mr5}>
                        {sec_3_3_max_init_unbal}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Max Initial Unbalance:
                      </Text>
                      <Text style={styles.small_text_mr5}>unknown</Text>
                    </View>
                  )}

                  {sec_3_3_unit_gin ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>g-in</Text>

                  {sec_3_3_unit_gmm ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>g-mm</Text>

                  {sec_3_3_unit_ozin ? isChecked : notChecked}
                  <Text style={styles.small_text}>oz-in</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 3.4 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_40}>
                <Text style={styles.bold_small}>
                  4. Number of Rotors to Balance in 8 hours
                </Text>
              </View>
              <View style={styles.fc_60}>
                <View style={styles.fr_end}>
                  {sec_3_4_num_rotors_bal !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Rotors to Balance:
                      </Text>
                      <Text style={styles.small_text_mr5}>
                        {sec_3_4_num_rotors_bal}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Rotors to Balance:
                      </Text>
                      <Text style={styles.small_text_mr5}>unknown</Text>
                    </View>
                  )}
                </View>
              </View>
            </View>
          </View>

          {/* SECTION 4 */}
          <View
            style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}
          >
            <Text style={styles.bold_text}>4. System Details</Text>
          </View>

          {/* SEC 4.5 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>
                  5. Available Electrical Mains Supply
                </Text>
              </View>
              <View style={styles.fc_50}>
                <View style={styles.fr_end}>
                  {sec_4_5_ac_volt !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>AC Volts:</Text>
                      <Text style={styles.small_text_mr5}>
                        {sec_4_5_ac_volt}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>AC Volts:</Text>
                      <Text style={styles.small_text_mr5}>___</Text>
                    </View>
                  )}

                  {sec_4_5_phase !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>Phase:</Text>
                      <Text style={styles.small_text_mr5}>{sec_4_5_phase}</Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>Phase:</Text>
                      <Text style={styles.small_text_mr5}>___</Text>
                    </View>
                  )}

                  {sec_4_5_hz !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>Hz:</Text>
                      <Text style={styles.small_text_mr5}>{sec_4_5_hz}</Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>Hz:</Text>
                      <Text style={styles.small_text}>___</Text>
                    </View>
                  )}
                </View>
              </View>
            </View>
          </View>

          {/* SEC 4.6 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_40}>
                <Text style={styles.bold_small}>6. Rotor Drive Type</Text>
              </View>
              <View style={styles.fc_60}>
                <View style={styles.fr_end}>
                  {sec_4_6_belt ? isChecked : notChecked}
                  <Text style={styles.small_text_mr10}>Belt</Text>

                  {sec_4_6_end_drive ? isChecked : notChecked}
                  <Text style={styles.small_text}>End Drive</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 4.7 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_30}>
                <Text style={styles.bold_small}>
                  7. Desired Drive Power Requirements
                </Text>
              </View>
              <View style={styles.fc_70}>
                <View style={styles.fr_end}>
                  {sec_4_7_unit_not_sure ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>Not Sure</Text>

                  {sec_4_7_drive_power_req !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Drive Power Requirements:
                      </Text>
                      <Text style={styles.small_text_mr5}>
                        {sec_4_7_drive_power_req}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        Drive Power Requirements:
                      </Text>
                      <Text style={styles.small_text_mr5}>unknown</Text>
                    </View>
                  )}

                  {sec_4_7_unit_kw ? isChecked : notChecked}
                  <Text style={styles.small_text_mr5}>kW</Text>

                  {sec_4_7_unit_hp ? isChecked : notChecked}
                  <Text style={styles.small_text}>hp</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 4.8 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_40}>
                <Text style={styles.bold_small}>8. Balance Speed Control</Text>
              </View>
              <View style={styles.fc_60}>
                <View style={styles.fr_end}>
                  {sec_4_8_var_speed ? isChecked : notChecked}
                  <Text style={styles.small_text}>Variable Speed</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 4.9 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_40}>
                <Text style={styles.bold_small}>9. Instrument Type</Text>
              </View>

              <View style={styles.fc_60}>
                <View style={styles.fr_end}>
                  {sec_4_9_portable ? isChecked : notChecked}
                  <Text style={styles.small_text_mr10}>Portable</Text>

                  {sec_4_9_digital ? isChecked : notChecked}
                  <Text style={styles.small_text_mr10}>Digital</Text>

                  {sec_4_9_computer ? isChecked : notChecked}
                  <Text style={styles.small_text}>Computer</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 4.10 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_40}>
                <Text style={styles.bold_small}>
                  10. Printed Balance Certificates Required
                </Text>
              </View>

              <View style={styles.fc_60}>
                <View style={styles.fr_end}>
                  {sec_4_10_cert_yes ? isChecked : notChecked}
                  <Text style={styles.small_text_mr10}>Yes</Text>

                  {sec_4_10_cert_no ? isChecked : notChecked}
                  <Text style={styles.small_text_mr10}>No</Text>

                  {sec_4_10_cert_not_sure ? isChecked : notChecked}
                  <Text style={styles.small_text}>Not Sure</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SEC 4.11 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_40}>
                <Text style={styles.bold_small}>
                  11. Safety Device (see note below)
                </Text>
              </View>

              <View style={styles.fc_60}>
                <View style={styles.fr_end}>
                  {sec_4_11_safety_none ? isChecked : notChecked}
                  <Text style={styles.small_text_mr10}>None</Text>

                  {sec_4_11_safety_perimeter_fence ? isChecked : notChecked}
                  <Text style={styles.small_text}>Perimeter Fence</Text>
                </View>
              </View>
            </View>
          </View>

          {/* SAFETY NOTICE */}
          <View
            style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}
          >
            <Text style={styles.bold_text}>* Safety Device Note</Text>
            <Text style={styles.small_text}>{newBalMachSafetyDeviceNote}</Text>
          </View>
        </Page>

        <Page style={styles.body}>
          {/* SECTION 5 */}
          <View
            style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}
          >
            <Text style={styles.bold_text}>5. Additional Information</Text>
          </View>

          {/* SEC 5.1 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <Text style={styles.bold_small}>
                Attach drawings, sketches, or pictures of rotors to be balanced,
                if available.
              </Text>
            </View>
          </View>

          {/* SEC 5.2 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>
                  If rotor is to be balanced in its own bearings, please attach
                  details of bearings (Type, OD, Width, self aligning, etc.)
                </Text>
              </View>

              <View style={styles.fc_50}>
                <View style={styles.fr_end}>
                  {sec_5_2_bearing_details !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>
                        {sec_5_2_bearing_details}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr5}>n/a</Text>
                    </View>
                  )}
                </View>
              </View>
            </View>
          </View>

          {/* SEC 5.3 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>
                  Other information that may be helpful in determining the
                  appropriate balancing system.
                </Text>
              </View>

              <View style={styles.fc_50}>
                <View style={styles.fr_end}>
                  {sec_5_3_other_info !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr10}>
                        {sec_5_3_other_info}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr10}>n/a</Text>
                    </View>
                  )}
                </View>
              </View>
            </View>
          </View>

          {/* SEC 5.4 */}
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
              marginLeft: 10,
              border: 1,
              borderRadius: 10,
              padding: 10,
            }}
          >
            <View style={styles.flex_row}>
              <View style={styles.fc_50}>
                <Text style={styles.bold_small}>
                  Other additional System Requirements not listed above.
                </Text>
              </View>

              <View style={styles.fc_50}>
                <View style={styles.fr_end}>
                  {sec_5_4_additional_req !== null ? (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr10}>
                        {sec_5_4_additional_req}
                      </Text>
                    </View>
                  ) : (
                    <View style={styles.fr}>
                      <Text style={styles.small_text_mr10}>n/a</Text>
                    </View>
                  )}
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}
