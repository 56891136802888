import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowMenu } from '../redux/controls';
import SideMenu from '../components/SideMenu/SideMenu';
import TopMenu from '../components/TopMenu/TopMenu';
import ContentBlock from '../components/ContentBlock/ContentBlock';
import YouTubeContainer from '../components/YouTubeContainer/YouTubeContainer';
import Footer from '../components/Footer/Footer';
import '../styles/lexsecoCltUpgrades.css';
import '../styles/standardPageStyle.css';

import { LEXSECO_CLT_UPGRADES_PAGE as content } from '../data/PAGE_TEXT_CONTENT';
import lexsecoBeforeAndAfter from '../assets/images/lexseco-before-and-after.jpg';
import crosshairImg from '../assets/images/crosshair.png';
import checkwindowImg from '../assets/images/checkwindow.png';
import meterExchangeDoc from '../assets/pdf-docs/IRD-E-Meter-Brochure.pdf';

const SectionElement = ({ title, image, text, onClick, link, clickable }) => {
  return (
    <div
      className={
        clickable ? 'element-container clickable' : 'element-container'
      }
      onClick={() => onClick(link)}
    >
      <div className="element-left-side">
        <img src={image} alt="element icon" className="element-image" />
      </div>
      <div className="element-right-side">
        <h4 className="stnd-h4">{title}</h4>
        <span className="stnd-text">{text}</span>
      </div>
    </div>
  );
};

const UpgradeFeatures = () => (
  <div className="upgrade-features-container">
    <h2 className="stnd-h2">Upgraded Features</h2>
    <div className="upgrade-features-container-inner">
      <div className="upgrade-features-left">
        <ul className="upgrade-list">
          <li className="upgrade-list-item">{content.feature_one}</li>
          <li className="upgrade-list-item">{content.feature_two}</li>
          <li className="upgrade-list-item">{content.feature_three}</li>
          <li className="upgrade-list-item">{content.feature_four}</li>
          <li className="upgrade-list-item">{content.feature_five}</li>
        </ul>
      </div>
      <div className="upgrade-features-right">
        <ul className="upgrade-list">
          <li className="upgrade-list-item">{content.feature_six}</li>
          <li className="upgrade-list-item">{content.feature_seven}</li>
          <li className="upgrade-list-item">{content.feature_eight}</li>
          <li className="upgrade-list-item">{content.feature_nine}</li>
          <li className="upgrade-list-item">{content.feature_ten}</li>
        </ul>
      </div>
    </div>
    <div className="disclaimer-wrapper">
      <span className="small-text">{content.disclaimer_one}</span>
      <span className="small-text">{content.disclaimer_two}</span>
    </div>
  </div>
);

export default function LexsecoCltUpgrades() {
  const [irdVideo, setIrdVideo] = useState(null);
  const { ird_videos } = useSelector((state) => state.controls);
  const dispatch = useDispatch();

  let length;
  if (ird_videos) {
    length = ird_videos.length;
  }

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  const goToPage = (page) => {
    window.open(page);
  };

  useEffect(() => {
    if (ird_videos) {
      const result = ird_videos.filter(
        (video) =>
          video.video_title ===
          `Lexseco Core Loss Tester Upgrade Transformation`
      );
      setIrdVideo(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length]);

  return (
    <div className="container-fluid stnd-container">
      <SideMenu />
      <TopMenu />

      <div className="row stnd-content" onClick={toggleMenu}>
        <div className="row stnd-top-content">
          <ContentBlock
            title={'Lexseco CLT Upgrade'}
            text={content.intro_para}
            image={lexsecoBeforeAndAfter}
            imageSize="small"
            showButton={false}
          />
        </div>

        <div className="row stnd-middle-content-flex-col">
          <div className="stnd-content-wrapper-flex-row">
            <SectionElement
              title={content.latest_software_title}
              image={checkwindowImg}
              text={content.latest_software_para}
            />

            <SectionElement
              title={content.enhanced_accuracy_title}
              image={crosshairImg}
              text={content.enhanced_accuracy_para}
              link={meterExchangeDoc}
              onClick={goToPage}
              clickable={true}
            />
          </div>
        </div>

        <div className="row stnd-bottom-content">
          <UpgradeFeatures />

          <div className="stnd-content-wrapper-flex-row flex-row-center margin-top-thirty">
            {irdVideo &&
              irdVideo.map((video) => {
                return (
                  <YouTubeContainer
                    key={video.id}
                    smallContainer={false}
                    embedId={video.video_link_id}
                    title={video.video_title}
                  />
                );
              })}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
