import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { serverTimestamp, doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './jobPostingForm.css';

export default function JobPostingForm() {
  const [loading, setLoading] = useState(false);
  const [jobTitle, setJobTitle] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const notifyJobPostSuccess = () =>
    toast.success('New job created successfully', {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

  const handleResetForm = () => {
    setJobTitle('');
    setJobDescription('');
  };

  const addJobToFirebase = async (title, description) => {
    setLoading(true);
    const today = new Date();
    const randomId = uuidv4();
    const jobRef = doc(db, 'job_postings', randomId);
    await setDoc(jobRef, {
      id: randomId,
      job_title: title,
      job_description: description,
      date: today,
      createdAt: serverTimestamp(),
    });
    notifyJobPostSuccess();
    setLoading(false);
  };

  function handleSubmit(e) {
    e.preventDefault();
    addJobToFirebase(jobTitle, jobDescription);
    handleResetForm();
  }

  return (
    <div className="job-posting-form-container">
      <Form className="job-posting-form">
        <Form.Group className="mb-3" controlId="job-title">
          <Form.Label>Job Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Job Title"
            value={jobTitle}
            onChange={(e) => setJobTitle(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="job-description">
          <Form.Label>Job Description</Form.Label>
          <Form.Control
            as="textarea"
            rows="6"
            placeholder="Description"
            value={jobDescription}
            className="job-description-input"
            onChange={(e) => setJobDescription(e.target.value)}
          />
        </Form.Group>

        <div className="job-posting-button-wrapper">
          <Button
            className="job-posting-submit-button"
            type="submit"
            disabled={loading}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </Form>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}
