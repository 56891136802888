import React, { useState } from 'react';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import irdLogo from '../../assets/images/ird_logo2.png';
import './topMenu.css';

import { LINKS as links } from '../../data/LINKS';

export default function TopMenu() {
  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useState('');

  const handleDropdownSelect = (e) => {
    setPage(e);
  };

  // const goToPage = () => {
  //   if (page === 'clt-training-request') {
  //     window.open(
  //       'https://irdproducts.typeform.com/to/eBzna2?mc_cid=768f5c7827&mc_eid=da56f8dde3'
  //     );
  //   } else if (page === 'balancing-training-request') {
  //     window.open(
  //       'https://irdproducts.typeform.com/to/eBzna2?mc_cid=768f5c7827&mc_eid=da56f8dde3'
  //     );
  //   }
  // };

  return (
    <div className="container-fluid top-menu-container">
      <div className="row top-menu-content">
        <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 top-menu-col top-menu-img-col">
          <img src={irdLogo} alt="logo" className="top-menu-img" />
        </div>

        <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 top-menu-col top-menu-link-col">
          {/* PRODUCTS */}
          <DropdownButton
            id="products-dropdown"
            title="Products"
            variant="outline-dark"
            className="top-menu-dropdown-btn"
          >
            <Dropdown.Item eventKey="ird-balancing-products">
              <Link
                to={links.ird_balancing_products}
                className="top-menu-link-text"
              >
                IRD Balancing Products
              </Link>
            </Dropdown.Item>
            <Dropdown.Item eventKey="balancing-instruments">
              <Link
                to={links.balancing_instruments}
                className="top-menu-link-text"
              >
                IRD Balancing/Vibration Instruments
              </Link>
            </Dropdown.Item>
            <Dropdown.Item eventKey="ird-balancing-machines">
              <Link
                to={links.soft_bearing_balance_machines}
                className="top-menu-link-text"
              >
                IRD Balancing Machines
              </Link>
            </Dropdown.Item>
            <Dropdown.Item eventKey="sensors">
              <Link to={links.sensors} className="top-menu-link-text">
                Sensors
              </Link>
            </Dropdown.Item>
            <Dropdown.Item eventKey="balancing-parts-and-accessories">
              <Link
                to={links.parts_and_accessories}
                className="top-menu-link-text"
              >
                Balancing Parts & Accessories
              </Link>
            </Dropdown.Item>
            {/* HIDE THE New Products PAGE UNTIL CONTENT IS AVAILABLE */}
            {/* <Dropdown.Item eventKey="new-products">
              <Link to={links.new_products} className="top-menu-link-text">
                New Products
              </Link>
            </Dropdown.Item> */}
            {/* HIDE THE Refurbished PAGE UNTIL CONTENT IS AVAILABLE */}
            {/* <Dropdown.Item eventKey="refurbished">
              <Link to={links.refurbished} className="top-menu-link-text">
                Refurbished
              </Link>
            </Dropdown.Item> */}
            {/* HIDE THE Refurbished Machines PAGE UNTIL CONTENT IS AVAILABLE */}
            {/* <Dropdown.Item eventKey="refurbished-machines">
              <Link
                to={links.refurbished_machines}
                className="top-menu-link-text"
              >
                Refurbished Machines
              </Link>
            </Dropdown.Item> */}
            <Dropdown.Item eventKey="lexseco-products">
              <Link to={links.lexseco_products} className="top-menu-link-text">
                Lexseco Products
              </Link>
            </Dropdown.Item>
            <Dropdown.Item eventKey="lexseco-clt-upgrade">
              <Link
                to={links.lexseco_clt_upgrades}
                className="top-menu-link-text"
              >
                Lexseco CLT Upgrade
              </Link>
            </Dropdown.Item>
            <Dropdown.Item eventKey="core-loss-testing">
              <Link to={links.lexseco_products} className="top-menu-link-text">
                Core Loss Testing
              </Link>
            </Dropdown.Item>
            {/* HIDE THE Balancing Equipment PAGE UNTIL CONTENT IS AVAILABLE */}
            {/* <Dropdown.Item eventKey="balancing-equipment">
              <Link
                to={links.balancing_equipment}
                className="top-menu-link-text"
              >
                Balancing Equipment
              </Link>
            </Dropdown.Item> */}
            <Dropdown.Item eventKey="legacy-products">
              <Link to={links.legacy_products} className="top-menu-link-text">
                Legacy Products
              </Link>
            </Dropdown.Item>
          </DropdownButton>

          {/* INFORMATION */}
          <DropdownButton
            id="information-dropdown"
            title="Information"
            variant="outline-dark"
            className="top-menu-dropdown-btn"
          >
            {/* HIDE THE FAQ PAGE UNTIL CONTENT IS AVAILABLE */}
            {/* <Dropdown.Item eventKey="faq">
              <Link to={links.faq} className="top-menu-link-text">
                FAQ's
              </Link>
            </Dropdown.Item> */}
            <Dropdown.Item eventKey="ird-balancing">
              <Link to={links.ird_balancing} className="top-menu-link-text">
                IRD Balancing: Education
              </Link>
            </Dropdown.Item>
            <Dropdown.Item eventKey="balancing-machines-info">
              <Link
                to={links.balancing_machines_info}
                className="top-menu-link-text"
              >
                Balancing Machines Info
              </Link>
            </Dropdown.Item>
            <Dropdown.Item eventKey="unbalance">
              <Link to={links.unbalance} className="top-menu-link-text">
                Unbalance
              </Link>
            </Dropdown.Item>
            <Dropdown.Item eventKey="lexseco-advantage">
              <Link to={links.lexseco_advantage} className="top-menu-link-text">
                The Lexseco Advantage
              </Link>
            </Dropdown.Item>
            <Dropdown.Item eventKey="training-videos">
              <Link to={links.training_videos} className="top-menu-link-text">
                Training Videos
              </Link>
            </Dropdown.Item>
            <Dropdown.Item eventKey="core-evaluation">
              <Link to={links.core_evaluation} className="top-menu-link-text">
                Core Evaluation
              </Link>
            </Dropdown.Item>
          </DropdownButton>

          {/* SERVICES */}
          <DropdownButton
            id="services-dropdown"
            title="Services"
            variant="outline-dark"
            className="top-menu-dropdown-btn"
            onSelect={handleDropdownSelect}
          >
            <Dropdown.Item eventKey="services">
              <Link to={links.service} className="top-menu-link-text">
                Services
              </Link>
            </Dropdown.Item>
            {/* HIDE THE New Products PAGE UNTIL CONTENT IS AVAILABLE */}
            {/* <Dropdown.Item eventKey="rentals">
              <Link to={links.rentals} className="top-menu-link-text">
                Rentals
              </Link>
            </Dropdown.Item> */}
            <Dropdown.Item eventKey="meter-exchange">
              <Link to={links.meter_exchange} className="top-menu-link-text">
                Meter Exchange
              </Link>
            </Dropdown.Item>
            <Dropdown.Item eventKey="balancing-training-request">
              {/* <span onClick={goToPage}>Balancing Training</span> */}
              <Link
                to={links.balancing_training}
                className="top-menu-link-text"
              >
                Balancing Training
              </Link>
            </Dropdown.Item>
            <Dropdown.Item eventKey="clt-training-request">
              {/* <span onClick={goToPage}>CLT Training</span> */}
              <Link to={links.clt_training} className="top-menu-link-text">
                CLT Training
              </Link>
            </Dropdown.Item>
          </DropdownButton>

          <Button
            href={links.shop}
            variant="outline-dark"
            className="top-menu-link-version-two"
          >
            Shop
          </Button>
        </div>
      </div>
    </div>
  );
}
