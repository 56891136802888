import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowMenu } from '../../redux/controls';
import './sideMenu.css';

import irdLogo from '../../assets/images/ird_logo2.png';
import closeIcon from '../../assets/icons/icon-close-circle.svg';
import menuIcon from '../../assets/icons/icon-menu.svg';
import contactIcon from '../../assets/icons/contacts.svg';
import blogIcon from '../../assets/icons/discuss.svg';
import aboutIcon from '../../assets/icons/information.svg';
import serviceIcon from '../../assets/icons/service.svg';
import shoppingIcon from '../../assets/icons/shopping-cart.svg';
import productsIcon from '../../assets/icons/arrow-right-circle.svg';
import chevronDown from '../../assets/icons/chevron-down-white.svg';

import { LINKS as links } from '../../data/LINKS';

export default function SideMenu() {
  const dispatch = useDispatch();
  const {
    show_menu,
    new_balance_machine_form_data,
    rma_requests,
    extended_surveys,
    feedback_surveys,
    shop_ird_surveys,
  } = useSelector((state) => state.controls);
  const { current_user } = useSelector((state) => state.user);
  const [newApps, setNewApps] = useState(0);
  const [rmaReq, setRmaReq] = useState(0);
  const [extSur, setExtSur] = useState(0);
  const [feedbackSur, setFeedbackSur] = useState(0);
  const [shopIrdSur, setShopIrdSur] = useState(0);
  // eslint-disable-next-line no-unused-vars

  const [linkGroup, setLinkGroup] = useState(null);
  let balMachLength;
  let rmaReqLength;
  let extendedSurveysLength;
  let feedbackSurveysLength;
  let shopIrdSurveysLength;
  if (new_balance_machine_form_data) {
    balMachLength = new_balance_machine_form_data.length;
  }
  if (rma_requests) {
    rmaReqLength = rma_requests.length;
  }
  if (extended_surveys) {
    extendedSurveysLength = extended_surveys.length;
  }
  if (feedback_surveys) {
    feedbackSurveysLength = feedback_surveys.length;
  }
  if (shop_ird_surveys) {
    shopIrdSurveysLength = shop_ird_surveys.length;
  }
  let totalSurveys;
  if (
    extendedSurveysLength + feedbackSurveysLength + shopIrdSurveysLength >
    0
  ) {
    totalSurveys =
      extendedSurveysLength + feedbackSurveysLength + shopIrdSurveysLength;
  }

  useEffect(() => {
    if (balMachLength !== null) {
      setNewApps(balMachLength);
    }
  }, [balMachLength]);

  useEffect(() => {
    if (rmaReqLength !== null) {
      setRmaReq(rmaReqLength);
    }
  }, [rmaReqLength]);

  useEffect(() => {
    if (extendedSurveysLength !== null) {
      setExtSur(extendedSurveysLength);
    }
  }, [extendedSurveysLength]);

  useEffect(() => {
    if (feedbackSurveysLength !== null) {
      setFeedbackSur(feedbackSurveysLength);
    }
  }, [feedbackSurveysLength]);

  useEffect(() => {
    if (shopIrdSurveysLength !== null) {
      setShopIrdSur(shopIrdSurveysLength);
    }
  }, [shopIrdSurveysLength]);

  const toggleMenu = () => {
    dispatch(setShowMenu(!show_menu));
  };

  const handleSetLinkGroup = (e) => {
    if (linkGroup === null || linkGroup !== e.currentTarget.id) {
      setLinkGroup(e.currentTarget.id);
    } else {
      setLinkGroup(null);
    }
  };

  // console.log('totalSurveys', totalSurveys);

  return (
    <div
      className={
        show_menu
          ? 'side-menu-container-open animate-open'
          : 'side-menu-container-closed'
      }>
      <div
        className={
          show_menu
            ? 'side-menu-toggle-controls-open'
            : 'side-menu-toggle-controls-closed'
        }>
        {show_menu ? (
          <img
            src={closeIcon}
            alt="close button"
            className="side-menu-toggle-icon close-icon"
            onClick={toggleMenu}
          />
        ) : (
          <div className="side-menu-closed-wrapper">
            <div className="side-menu-closed-left">
              <img
                src={menuIcon}
                alt="menu button"
                className="side-menu-toggle-icon open-icon"
                onClick={toggleMenu}
              />
            </div>
            <div className="side-menu-closed-right">
              {newApps > 0 && current_user != null && (
                <div className="side-menu-new-apps-info-wrapper">
                  <span className="side-menu-text">Bal Mach Apps:</span>
                  <a href="/admin" className="side-menu-admin-link">
                    <div className="side-menu-new-apps-count-wrapper">
                      <span className="side-menu-text-bold">{newApps}</span>
                    </div>
                  </a>
                </div>
              )}

              {rmaReq > 0 && current_user != null && (
                <div className="side-menu-new-apps-info-wrapper">
                  <span className="side-menu-text">RMA Req:</span>
                  <a href="/admin" className="side-menu-admin-link">
                    <div className="side-menu-new-rma-count-wrapper">
                      <span className="dark-text">{rmaReq}</span>
                    </div>
                  </a>
                </div>
              )}

              {totalSurveys > 0 && current_user != null && (
                <div className="side-menu-new-apps-info-wrapper">
                  <span className="side-menu-text">Surveys:</span>
                  <a href="/admin" className="side-menu-admin-link">
                    <div className="side-menu-new-surveys-count-wrapper">
                      <span className="dark-text">{totalSurveys}</span>
                    </div>
                  </a>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {show_menu && (
        <div className="side-menu-links-container">
          <a href={links.about} className="side-menu-solo-link">
            About Us
            <img
              src={aboutIcon}
              alt="about"
              className="side-menu-icon open-icon"
            />
          </a>

          <div className="side-menu-link-and-icon-wrapper">
            <div className="link-side">
              <a href={links.ird_balancing} className="side-menu-link">
                IRD Balancing: Education
              </a>
            </div>
            <div className="icon-side" id="01" onClick={handleSetLinkGroup}>
              <img
                src={chevronDown}
                alt="products"
                className="side-menu-icon open-icon"
              />
            </div>
          </div>

          {linkGroup === '01' && (
            <div className="sub-link-group-wrapper">
              <a
                href={links.balancing_machines_info}
                className="side-menu-link side-menu-sub-link">
                Balancing Machines: Info
                <img
                  src={productsIcon}
                  alt="products"
                  className="side-menu-icon open-icon"
                />
              </a>

              <a
                href={links.unbalance}
                className="side-menu-link side-menu-sub-link">
                Unbalance
                <img
                  src={productsIcon}
                  alt="products"
                  className="side-menu-icon open-icon"
                />
              </a>
            </div>
          )}

          <div className="side-menu-link-and-icon-wrapper">
            <div className="link-side">
              <a href={links.ird_balancing_products} className="side-menu-link">
                IRD Balancing Products
              </a>
            </div>
            <div className="icon-side" id="02" onClick={handleSetLinkGroup}>
              <img
                src={chevronDown}
                alt="products"
                className="side-menu-icon open-icon"
              />
            </div>
          </div>

          {linkGroup === '02' && (
            <div className="sub-link-group-wrapper">
              <a
                href={links.balancing_instruments}
                className="side-menu-link side-menu-sub-link">
                Balancing Instruments
                <img
                  src={productsIcon}
                  alt="products"
                  className="side-menu-icon open-icon"
                />
              </a>

              <a
                href={links.soft_bearing_balance_machines}
                className="side-menu-link side-menu-sub-link">
                Balancing Machines
                <img
                  src={productsIcon}
                  alt="products"
                  className="side-menu-icon open-icon"
                />
              </a>

              <a
                href={links.sensors}
                className="side-menu-link side-menu-sub-link">
                Sensors
                <img
                  src={productsIcon}
                  alt="products"
                  className="side-menu-icon open-icon"
                />
              </a>

              <a
                href={links.parts_and_accessories}
                className="side-menu-link side-menu-sub-link">
                Balancing Parts & Accessories
                <img
                  src={productsIcon}
                  alt="products"
                  className="side-menu-icon open-icon"
                />
              </a>
              {/* HIDE THE New Products PAGE UNTIL CONTENT IS AVAILABLE */}
              {/* <a
                href={links.new_products}
                className="side-menu-link side-menu-sub-link"
              >
                New Products
                <img
                  src={productsIcon}
                  alt="products"
                  className="side-menu-icon open-icon"
                />
              </a> */}
              {/* HIDE THE Refurbished PAGE UNTIL CONTENT IS AVAILABLE */}
              <a
                href={links.refurbished}
                className="side-menu-link side-menu-sub-link">
                Refurbished Products
                <img
                  src={productsIcon}
                  alt="products"
                  className="side-menu-icon open-icon"
                />
              </a>
            </div>
          )}

          <div className="side-menu-link-and-icon-wrapper">
            <div className="link-side">
              <a href={links.lexseco_advantage} className="side-menu-link">
                The Lexseco Advantage: Education
              </a>
            </div>
            <div className="icon-side" id="03" onClick={handleSetLinkGroup}>
              <img
                src={chevronDown}
                alt="products"
                className="side-menu-icon open-icon"
              />
            </div>
          </div>

          {linkGroup === '03' && (
            <div className="sub-link-group-wrapper">
              <a
                href={links.core_evaluation}
                className="side-menu-link side-menu-sub-link">
                Core Evaluation
                <img
                  src={productsIcon}
                  alt="products"
                  className="side-menu-icon open-icon"
                />
              </a>
            </div>
          )}

          <div className="side-menu-link-and-icon-wrapper">
            <div className="link-side">
              <a href={links.lexseco_products} className="side-menu-link">
                Lexseco Products
              </a>
            </div>
            <div className="icon-side" id="04" onClick={handleSetLinkGroup}>
              <img
                src={chevronDown}
                alt="products"
                className="side-menu-icon open-icon"
              />
            </div>
          </div>

          {linkGroup === '04' && (
            <div className="sub-link-group-wrapper">
              <a
                href={links.clt_upgrades}
                className="side-menu-link side-menu-sub-link">
                Lexseco CLT Upgrade
                <img
                  src={productsIcon}
                  alt="core-loss-testing"
                  className="side-menu-icon open-icon"
                />
              </a>

              <a
                href={links.service}
                className="side-menu-link side-menu-sub-link">
                Meter Exchange Program
                <img
                  src={productsIcon}
                  alt="core-loss-testing"
                  className="side-menu-icon open-icon"
                />
              </a>

              <a
                href={links.lexseco_products}
                className="side-menu-link side-menu-sub-link">
                Core Loss Testing
                <img
                  src={productsIcon}
                  alt="core-loss-testing"
                  className="side-menu-icon open-icon"
                />
              </a>
            </div>
          )}

          <div className="side-menu-link-and-icon-wrapper">
            <div className="link-side">
              <a href={links.service} className="side-menu-link">
                Service
                <img
                  src={serviceIcon}
                  alt="service"
                  className="side-menu-icon open-icon"
                />
              </a>
            </div>
            <div className="icon-side" id="05" onClick={handleSetLinkGroup}>
              <img
                src={chevronDown}
                alt="products"
                className="side-menu-icon open-icon"
              />
            </div>
          </div>

          {linkGroup === '05' && (
            <div className="sub-link-group-wrapper">
              {/* HIDE THE Rentals PAGE UNTIL CONTENT IS AVAILABLE */}
              {/* <a
                href={links.rentals}
                className="side-menu-link side-menu-sub-link"
              >
                Rentals
                <img
                  src={productsIcon}
                  alt="rentals"
                  className="side-menu-icon open-icon"
                />
              </a> */}
            </div>
          )}

          <a href={links.blog} className="side-menu-solo-link">
            Blog
            <img
              src={blogIcon}
              alt="blog"
              className="side-menu-icon open-icon"
            />
          </a>

          <a href={links.customer_utilization} className="side-menu-solo-link">
            Customer Utilization
            <img
              src={productsIcon}
              alt="utilization"
              className="side-menu-icon open-icon"
            />
          </a>

          <div className="side-menu-link-and-icon-wrapper">
            <div className="link-side">
              <a href={links.contact} className="side-menu-link">
                Contact Us
                <img
                  src={contactIcon}
                  alt="contact"
                  className="side-menu-icon open-icon"
                />
              </a>
            </div>
            <div className="icon-side" id="06" onClick={handleSetLinkGroup}>
              <img
                src={chevronDown}
                alt="products"
                className="side-menu-icon open-icon"
              />
            </div>
          </div>

          {linkGroup === '06' && (
            <div className="sub-link-group-wrapper">
              <a
                href={links.career}
                className="side-menu-link side-menu-sub-link">
                Careers
                <img
                  src={productsIcon}
                  alt="rentals"
                  className="side-menu-icon open-icon"
                />
              </a>
            </div>
          )}
          {/* HIDE THE FAQ PAGE UNTIL CONTENT IS AVAILABLE */}
          {/* <a href={links.faq} className="side-menu-solo-link">
            FAQ's
            <img
              src={productsIcon}
              alt="faq"
              className="side-menu-icon open-icon"
            />
          </a> */}

          <a
            href="https://shop.irdproducts.com"
            className="side-menu-solo-link">
            Shop Online
            <img
              src={shoppingIcon}
              alt="shop"
              className="side-menu-icon open-icon"
            />
          </a>

          <div className="side-menu-logo-wrapper">
            <a href="/" className="side-menu-logo-link">
              <img src={irdLogo} alt="logo" className="side-menu-logo" />
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
