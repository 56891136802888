import React from 'react';
import './coverPlanService.css';

export default function CoverPlanService() {
  return (
    <div className="cps-container">
      <div className="cps-top-content">
        <h2 className="cps-header-text">Coverplan</h2>
        <span className="cps-text">
          Protect your equipment with our COVERPLAN! COVERPLAN is designed to
          provide customers with a comprehensive and cost effective service
          package to ensure correct operation, and a long reliable service life
          of all equipment. IRD® will schedule and carry out routine maintenance
          and calibration visits and will provide priority response to any
          emergency requests in the event of machine breakdown or equipment
          requiring repair. As part of our service we would provide operator
          training and assist with application specific support. Cover will be
          automatically renewed on each anniversary of the start date in order
          to ensure continuity of the service. (IRD® will advise any changes in
          charge rates before undertaking service visits. The customer retains
          the right to decline renewal or to cancel at any time.) All service
          work is undertaken subject to IRD® standard terms and conditions of
          service. Any spare parts or products provided are subject to IRD
          standard terms and conditions of sale.
        </span>
      </div>
      <div className="cps-middle-content">
        <h2 className="cps-sub-header-text">What's Included?</h2>
        <ul className="cps-list">
          <li className="cps-sub-header-text">
            Annual Service & Calibration when Due
          </li>
          <li className="cps-text cps-indent">
            Service & Calibration of IRD® Balancing Machine systems and LEXSECO®
            Core Loss Testers
          </li>
          <li className="cps-text cps-indent">
            Quality service by factory trained technicians with ready access to
            manufacturer's original spare parts
          </li>
          <li className="cps-text cps-indent">
            Automatic reminder of 'calibration due' date
          </li>
          <li className="cps-text cps-indent">
            Calibration traceability to manufacturer's Standard reference and
            approved procedures
          </li>
          <li className="cps-text cps-indent">
            Extended operator training on machine operation and balancing
            applications
          </li>
          <li className="cps-text cps-indent">
            Assessment of existing capability and condition of equipment with
            recommendations for on-going maintenance and development
          </li>
          <li className="cps-sub-header-text">
            Preferential rates for service & calibration - charges will only be
            payable after the service is completed
          </li>
          <li className="cps-text cps-indent">
            Pre-agreed rate will be charged only when service visit is completed
          </li>
          <li className="cps-text cps-indent">
            Discount on spare parts and consumable items used
          </li>
          <li className="cps-sub-header-text">Extended Warranty</li>
          <li className="cps-text cps-indent">
            A system commissioned by IRD and serviced under COVERPLAN qualifies
            for a THREE YEAR WARRANTY at no additional cost
          </li>
          <li className="cps-sub-header-text">
            Priority response to Emergency Call-outs and Repair facility
          </li>
          <li className="cps-sub-header-text">
            Provision of 'on-loan' instrument if we are unable to turn-round an
            instrument repair within 5 working days
          </li>
          <li className="cps-text cps-indent">(Subject to availability)</li>
          <li className="cps-sub-header-text">
            Helpline for technical support and assistance with applications
          </li>
          <li className="cps-text cps-indent">
            Extended support may be chargeable
          </li>
          <li className="cps-sub-header-text">
            Preferential pricing for updates and upgrades
          </li>
        </ul>
      </div>
      <div className="cps-bottom-content"></div>
    </div>
  );
}
