import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import './contentBlock.css';

export default function ContentBlock({
  title,
  text,
  textTwo,
  image,
  imageSize,
  caption,
  hasList,
  liOne,
  liTwo,
  liThree,
  liFour,
  liFive,
  liSix,
  liSeven,
  liEight,
  liNine,
  liTen,
  showButton,
}) {
  const [sizeOfImage, setSizeOfImage] = useState('content-block-image');

  useEffect(() => {
    if (imageSize === 'standard') {
      setSizeOfImage('content-block-image');
    } else if (imageSize === 'medium') {
      setSizeOfImage('content-block-image-medium');
    } else {
      setSizeOfImage('content-block-image-small');
    }
  }, [imageSize]);

  return (
    <div className="container-fluid content-block">
      <h2 className="content-block-title">{title}</h2>

      <div className="row content-block-row">
        <div
          className={
            image
              ? 'col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 content-block-col content-block-text-col'
              : 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 content-block-col content-block-text-col'
          }
        >
          <p className="content-block-text">{text}</p>
          {textTwo && <p className="content-block-text">{textTwo}</p>}

          {hasList && (
            <ul className="content-block-list">
              {liOne && <li className="content-block-text">{liOne}</li>}
              {liTwo && <li className="content-block-text">{liTwo}</li>}
              {liThree && <li className="content-block-text">{liThree}</li>}
              {liFour && <li className="content-block-text">{liFour}</li>}
              {liFive && <li className="content-block-text">{liFive}</li>}
              {liSix && <li className="content-block-text">{liSix}</li>}
              {liSeven && <li className="content-block-text">{liSeven}</li>}
              {liEight && <li className="content-block-text">{liEight}</li>}
              {liNine && <li className="content-block-text">{liNine}</li>}
              {liTen && <li className="content-block-text">{liTen}</li>}
            </ul>
          )}
        </div>

        {image && (
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 content-block-col content-block-img-col content-block-animate content-block-delay">
            <img src={image} alt="equipment" className={sizeOfImage} />

            {caption && (
              <div className="content-box-caption-container">{caption}</div>
            )}
          </div>
        )}
      </div>

      <div className="row content-block-row content-block-footer">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 content-block-col content-block-footer-col">
          {showButton && (
            <Button className="content-block-button" onClick={null}>
              Find Out More
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
