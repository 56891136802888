import React, { useState, useEffect } from 'react';
import useWindowSize from '../hooks/useWindowSize';
import { useDispatch } from 'react-redux';
import { setShowMenu, setTableView } from '../redux/controls';
import { Button } from 'react-bootstrap';
import TopMenu from '../components/TopMenu/TopMenu';
import ContentBlock from '../components/ContentBlock/ContentBlock';
import ReversibleContentBlock from '../components/ContentBlock/ReversibleContentBlock';
import AccordionComponent from '../components/AccordionComponent/AccordionComponent';
import SideMenu from '../components/SideMenu/SideMenu';
import CardComponent from '../components/CardComponent/CardComponent';
import TableComponent from '../components/Table/TableComponent';
import CarouselComponent from '../components/CarouselComponent/CarouselComponent';
import Pagination from '../components/Pagination/Pagination';
import Footer from '../components/Footer/Footer';
import '../styles/lexsecoProductsPageStyle.css';
import '../styles/standardPageStyle.css';

import lexsecoProduct from '../assets/images/lexseco-core-loss-tester.png';
import coreLossTester from '../assets/images/core-loss-testers.png';
import { LEXSECO_PRODUCTS_PAGE as content } from '../data/PAGE_TEXT_CONTENT';
import { CORE_LOSS_TESTERS_PAGE } from '../data/PAGE_TEXT_CONTENT';
import { CORE_LOSS_TESTER_MODEL_OPTIONS } from '../data/CORE_LOSS_TESTER_OPTIONS';
import { ARMATURE_TESTING_KIT } from '../data/ARMATURE_TESTING_KITS';
import { MAGNETIC_PAPER } from '../data/MAGNETIC_PAPER';

import laptopPrinterImg from '../assets/images/laptop-printer.png';
import greenGearImg from '../assets/images/lexseco_corelengthgreentransparent.png';
import lexsecoCoreLengthImg from '../assets/images/lexseco_corelength4.png';
import slideOne from '../assets/images/core-loss-software-slide-1.jpg';
import slideTwo from '../assets/images/core-loss-software-slide-2.jpg';
import slideThree from '../assets/images/core-loss-software-slide-3.jpg';
import slideFour from '../assets/images/core-loss-software-slide-4.jpg';
import slideFive from '../assets/images/core-loss-software-slide-5.jpg';

export default function LexsecoProducts() {
  // const { show_menu } = useSelector((state) => state.controls);
  const size = useWindowSize();
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [partType, setPartType] = useState('core-loss-tester');
  const dispatch = useDispatch();

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItem = items.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  const changeTableView = (part, view) => {
    setPartType(part);
    dispatch(setTableView(view));
  };

  useEffect(() => {
    dispatch(setTableView('core-loss-tester-page-cables'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (partType === 'core-loss-tester') {
      setItems(CORE_LOSS_TESTER_MODEL_OPTIONS);
      setItemsPerPage(4);
    }
  }, [partType]);

  useEffect(() => {
    if (size.width <= 500) {
      setItemsPerPage(1);
    } else {
      setItemsPerPage(4);
    }
  }, [size.width]);

  // console.log('size', size);

  const SelectingCoreLossTester = () => (
    <div className="selecting-core-loss-tester-container">
      <div className="selecting-core-loss-tester-left-side">
        <span className="selecting-core-loss-tester-text">
          While horse power is important, it is only one factor that contributes
          to the size selection of a Core Loss Tester.
        </span>
        <span className="selecting-core-loss-tester-text">
          Please provide our sales representatives with the following details
          about the LARGEST core they will be testing:
        </span>
        <ul className="selecting-core-loss-tester-list">
          <li className="selecting-core-loss-tester-text">Core Length</li>
          <li className="selecting-core-loss-tester-text">Inner Diameter</li>
          <li className="selecting-core-loss-tester-text">Slot Depth</li>
          <li className="selecting-core-loss-tester-text">Back Iron</li>
          <li className="selecting-core-loss-tester-text">
            Number of Vent Ducts
          </li>
          <li className="selecting-core-loss-tester-text">Vent Duct Length</li>
          <li className="selecting-core-loss-tester-text">
            Number of Vent Holes
          </li>
          <li className="selecting-core-loss-tester-text">
            Vent Hole Diameter
          </li>
        </ul>
      </div>
      <div className="selecting-core-loss-tester-right-side">
        <img
          src={lexsecoCoreLengthImg}
          alt="core loss gear"
          className="selecting-core-loss-tester-img"
        />
        <img
          src={greenGearImg}
          alt="core loss gear"
          className="selecting-core-loss-tester-img"
        />
      </div>
    </div>
  );

  const LaptopAndPrinter = () => (
    <div className="laptop-printer-container">
      <div className="laptop-printer-left-side">
        <span className="laptop-printer-text">
          LEXSECO® Core Loss Testers can come equipped with a Windows 10 laptop
          and / or an inkjet printer. The MP 7TM software will be installed and
          ready to go!
        </span>
      </div>
      <div className="laptop-printer-left-side">
        <img
          src={laptopPrinterImg}
          alt="core loss gear"
          className="selecting-core-loss-tester-img"
        />
      </div>
    </div>
  );

  const InfoSnippet = ({ contentOne, contentTwo }) => (
    <div className="info-snippet-container">
      <div className="info-snippet-top-content">
        <span className="info-snippet-text">{contentOne}</span>
      </div>
      <div className="info-snippet-bottom-content">
        <span className="info-snippet-text">{contentTwo}</span>
      </div>
    </div>
  );

  const CoreLossSoftware = () => (
    <div className="core-loss-software-container">
      <div className="core-loss-software-top-content">
        <h2 className="core-loss-software-header-text">
          MP 7.0TM Core Loss Testing Software
        </h2>
        <span className="core-loss-software-text">
          LEXSECO®'s MP 7TM is the all new electric motor testing utility for
          the next generation. MP 7TM utilizes modern metering technology which
          provides the user hands-free, automated testing, as well as a newly
          designed user interface that allows the software to be more intuitive
          than ever before. Once installed, all previous data from MP 6.0 can be
          imported with the click of a button, providing a seamless transition.
          MP 7TM is versatile, providing testing and reporting for core losses,
          hot spots, integrity tests, winding verification, and other types of
          electrical and mechanical testing. Click here for a printable brochure
          about MP 7.0 software.
        </span>
      </div>
      <div className="core-loss-software-middle-content">
        <CarouselComponent
          imgOne={slideOne}
          imgTwo={slideTwo}
          imgThree={slideThree}
          imgFour={slideFour}
          imgFive={slideFive}
        />
      </div>
      <div className="core-loss-software-bottom-content">
        <h2 className="core-loss-software-sub-header-text">
          LEXSECO® Multiparameter MP 7TM Software
        </h2>
        <span className="core-loss-software-text">
          MP 7TM allows differentiation between NEMA, pre-NEMA, U, T, and IEC
          frames at standard and high efficiencies. Users may also build their
          own database based on the parameters of unique equipment that may be
          serviced or manufactured. MP 7TM provides an array of mechanical and
          electrical testing fields all with comprehensive reporting features
          making the LEXSECO® Core Loss Tester a powerful repair documentation
          center.
        </span>
      </div>
    </div>
  );

  const StandardFeatures = () => (
    <div className="clt-features-window">
      <h3 className="clt-features-window-title">Standard Features</h3>
      <ul className="clt-features-window-list">
        <li className="clt-list-title">All Models</li>
        <li className="clt-features-window-list-item indent-list">
          MP Software & Metering System
        </li>
        <li className="clt-features-window-list-item indent-list">
          Flux Measurement Cables
        </li>
        <li className="clt-features-window-list-item indent-list">
          Cable Storage Brackets
        </li>
        <li className="clt-features-window-list-item indent-list">
          Locking Steel Lid
        </li>
        <li className="clt-list-title">Model 2125 & larger</li>
        <li className="clt-features-window-list-item indent-list">
          AC Rotor & DC Armature Testing Package
        </li>
      </ul>
    </div>
  );

  const OptionalFeatures = () => (
    <div className="clt-features-window">
      <h3 className="clt-features-window-title">Optional Features</h3>
      <ul className="clt-features-window-list">
        <li className="clt-list-title">Models 1081, 2025, 2040, 2060</li>
        <li className="clt-features-window-list-item indent-list">
          Extended Input Voltage Range, 220-600V
        </li>

        <li className="clt-list-title">Models 2125, 2200, 3000</li>
        <li className="clt-features-window-list-item indent-list">
          Extended Input Voltage Range 480-600V
        </li>

        <li className="clt-list-title">Model 2060 & below</li>
        <li className="clt-features-window-list-item indent-list">
          AC Rotor & DC Armature Testing Package
        </li>
      </ul>
    </div>
  );

  return (
    <main className="container-fluid stnd-container">
      <SideMenu />
      <TopMenu />
      <div className="row stnd-content" onClick={toggleMenu}>
        <div className="row stnd-top-content">
          <ContentBlock
            title={'Lexseco Products'}
            text={content.lexseco_product_para}
            textTwo={content.meter_exchange_program}
            image={lexsecoProduct}
            imageSize="small"
            showButton={false}
          />
        </div>

        <div className="row stnd-middle-content-flex-col">
          <ReversibleContentBlock
            reverse={true}
            title="Core Loss Testers"
            text={content.core_loss_tester_para}
            image={coreLossTester}
            imageSize="small"
            showButton={false}
          />

          <div className="stnd-col">
            <a
              href={CORE_LOSS_TESTERS_PAGE.upgrade_option_link}
              className="stnd-link-text stnd-center-text"
            >
              <p className="stnd-text stnd-center-text">
                {CORE_LOSS_TESTERS_PAGE.upgrade_option_link_text}
              </p>
            </a>

            <h3 className="stnd-h3">
              Click on the model number of the parts listed to view their data
              sheets.
            </h3>
          </div>
        </div>

        <div className="row stnd-bottom-content">
          <div className="row stnd-flex-row-wrapper">
            <Button
              variant="outline-dark"
              active={partType === 'core-loss-tester' ? true : false}
              className="stnd-button-type-one"
              onClick={() =>
                changeTableView(
                  'core-loss-tester',
                  'core-loss-tester-page-cables'
                )
              }
            >
              Core Loss Tester & Accessories
            </Button>
            <Button
              variant="outline-dark"
              active={partType === 'armature-testing' ? true : false}
              className="stnd-button-type-one"
              onClick={() => setPartType('armature-testing')}
            >
              Armature Testing Kit
            </Button>
            <Button
              variant="outline-dark"
              active={partType === 'magnetic-paper' ? true : false}
              className="stnd-button-type-one"
              onClick={() => setPartType('magnetic-paper')}
            >
              Magnetic Paper
            </Button>
            <Button
              variant="outline-dark"
              active={partType === 'core-loss-software' ? true : false}
              className="stnd-button-type-one"
              onClick={() => setPartType('core-loss-software')}
            >
              Core Loss Testing Software: MP 7.0
            </Button>
          </div>

          <div className="row clt-flex-row-wrapper margin-top-thirty stnd-center-div">
            {partType === 'core-loss-tester' && (
              <AccordionComponent
                defaultActiveKey="01"
                eventKeyOne="01"
                titleOne="Core Loss Tester Model Options"
                contentOne={currentItem.map((item) => {
                  return (
                    <CardComponent
                      largeCard={true}
                      cardImg={item.image}
                      cardTitle={`Model: ${item.model}`}
                      titleTwo={item.description}
                      itemTwo={item.description_details}
                      linkOne={`Model ${item.model} Data Sheet`}
                      hrefOne={item.href_to_model_doc}
                      titleThree={item.max_hp}
                      itemThree={item.max_hp_val}
                      titleFour={item.max_kva}
                      itemFour={item.max_kva_val}
                    />
                  );
                })}
                subContentOne={
                  <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={items.length}
                    currentPage={currentPage}
                    paginate={paginate}
                  />
                }
                eventKeyTwo="02"
                titleTwo="Selecting the Proper Sized Core Loss Tester"
                contentTwo={<SelectingCoreLossTester />}
                eventKeyThree="03"
                titleThree="Cables"
                contentThree={<TableComponent />}
                eventKeyFour="04"
                titleFour="Laptop & Printer"
                contentFour={<LaptopAndPrinter />}
              />
            )}

            {partType === 'armature-testing' &&
              ARMATURE_TESTING_KIT.map((item) => {
                return (
                  <CardComponent
                    largeCard={true}
                    cardImg={item.image_url}
                    cardTitle={`Part Number: ${item.part_num}`}
                    linkOne={`Model ${item.part_num} Data Sheet`}
                    hrefOne={item.href_to_model_doc}
                    titleTwo={item.description}
                    itemTwo={item.description_details}
                    titleThree={item.kva_details_one}
                    itemThree={item.kva_col_one}
                    titleFour={item.kva_details_two}
                    itemFour={item.kva_col_two}
                    linkFiveIsText={false}
                    hrefFive={item.purchase_link}
                    linkFive={item.purchase_icon}
                  />
                );
              })}

            {partType === 'magnetic-paper' &&
              MAGNETIC_PAPER.map((item) => {
                return (
                  <CardComponent
                    largeCard={true}
                    cardImg={item.image_url}
                    cardTitle={`Part Number: ${item.part_num}`}
                    linkOne={`Model ${item.part_num} Data Sheet`}
                    hrefOne={item.href_to_model_doc}
                    titleTwo={item.description}
                    itemTwo={item.description_details}
                    linkThreeIsText={false}
                    hrefThree={item.purchase_link}
                    linkThree={item.purchase_icon}
                  />
                );
              })}

            {partType === 'core-loss-software' && <CoreLossSoftware />}
          </div>

          {partType === 'core-loss-tester' && (
            <div className="row stnd-flex-row-wrapper margin-top-thirty">
              <StandardFeatures />

              <OptionalFeatures />
            </div>
          )}

          {partType === 'armature-testing' && (
            <InfoSnippet
              contentOne={
                CORE_LOSS_TESTERS_PAGE.armature_testing_kit_paragraph_one
              }
              contentTwo={
                CORE_LOSS_TESTERS_PAGE.armature_testing_kit_paragraph_two
              }
            />
          )}

          {partType === 'magnetic-paper' && (
            <InfoSnippet
              contentOne={CORE_LOSS_TESTERS_PAGE.magnetic_paper_paragraph_one}
              contentTwo={CORE_LOSS_TESTERS_PAGE.magnetic_paper_list}
            />
          )}
        </div>
      </div>

      <Footer />
    </main>
  );
}
