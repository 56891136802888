import React from 'react';
import './onSiteTrainingService.css';

import onSiteTrainingImg from '../../assets/images/on-site-training-img.jpeg';

export default function OnSiteTrainingService() {
  return (
    <div className="osts-container">
      <div className="osts-left-content">
        <h2 className="osts-header-text">On-Site Start-Up Training</h2>
        <span className="osts-text">
          The balancing of large rotors presents a danger to the users, the
          rotor, and the machine if the machine is not set up properly. It is
          recommended that the company opt to invest in training for their
          balancing engineers. Besides ensuring that your machine is properly
          set up, our Application Engineer will work with you step-by-step
          through the balancing process to ensure that your operators are
          properly trained and able to balance quickly and efficiently. The
          LEXSECO® Core Loss Tester uses a high amperage that could pose a risk
          to the user. While its design reduces that risk, proper training can
          eliminate it. Additionally, training will ensure quick and easy core
          loss evaluations!
        </span>
      </div>
      <div className="osts-right-content">
        <img
          src={onSiteTrainingImg}
          alt="on-site training"
          className="osts-img"
        />
      </div>
    </div>
  );
}
