/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowMenu } from '../redux/controls';
// import { Wrapper, Status } from '@googlemaps/react-wrapper';
import SideMenu from '../components/SideMenu/SideMenu';
import Footer from '../components/Footer/Footer';
import iconMail from '../assets/icons/icon-mail.svg';
import iconMap from '../assets/icons/icon-map.svg';
import iconPhone from '../assets/icons/icon-phone.svg';
import iconFax from '../assets/icons/icon-fax.svg';
import '../styles/contactPageStyle.css';

export default function Contact() {
  // const { show_menu } = useSelector((state) => state.controls);
  const { has_paid } = useSelector((state) => state.controls);
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  return (
    <div className="contact-page-container">
      {has_paid && <SideMenu />}
      <div className="contact-page-header">
        <h1 className="contact-page-header-text">Contact Us</h1>
      </div>
      <div className="contact-page-content" onClick={toggleMenu}>
        <div className="contact-page-left">
          <span className="contact-page-text">
            For sales information or technical assistance, please contact our
            global headquarters in Louisville, Kentucky, USA or our
            international distributor that corresponds with your location. Use
            the map below to find the distributor in your area. If no
            distributor is listed, please contact our headquarters.
          </span>

          <hr />

          <div className="contact-page-contact-info-wrapper">
            <h2 className="contact-page-subtitle">
              Phone{' '}
              <img src={iconPhone} alt="phone" className="contact-page-icon" />
            </h2>
            <span className="contact-page-text indent-text">
              1-888-473-2251
            </span>
            <span className="contact-page-text indent-text">
              1-502-366-0916
            </span>
            <h2 className="contact-page-subtitle">
              Fax{' '}
              <img src={iconFax} alt="phone" className="contact-page-icon" />
            </h2>
            <span className="contact-page-text indent-text">
              1-502-238-1001
            </span>
            <h2 className="contact-page-subtitle">
              Email{' '}
              <img src={iconMail} alt="phone" className="contact-page-icon" />
            </h2>
            <span className="contact-page-text indent-text">
              <a href="mailto:sales@irdproducts.com">sales@IRDProducts.com</a>
            </span>
            <h2 className="contact-page-subtitle">
              Address
              <img src={iconMap} alt="phone" className="contact-page-icon" />
            </h2>

            <span className="contact-page-text indent-text">
              4740 Allmond Ave.
              <br />
              Louisville, KY 40209
            </span>
          </div>
        </div>
        <div className="contact-page-right">
          <div className="contact-page-map-container">
            <iframe
              src="https://www.google.com/maps/d/u/3/embed?mid=12A_VGd9on3R0Do908NB0tUMGeU7vAhI&ehbc=2E312F"
              width="640"
              height="480"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
