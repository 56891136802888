import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowMenu, setTableView } from '../redux/controls';
import { Button } from 'react-bootstrap';
import TopMenu from '../components/TopMenu/TopMenu';
import SideMenu from '../components/SideMenu/SideMenu';
import ContentBlock from '../components/ContentBlock/ContentBlock';
import TableComponent from '../components/Table/TableComponent';
import Footer from '../components/Footer/Footer';
import '../styles/standardPageStyle.css';

import sensorsImg from '../assets/images/sensors.jpg';

import { SENSORS_PAGE as content } from '../data/PAGE_TEXT_CONTENT';

export default function Sensors() {
  const [partType, setPartType] = useState('velocity-trans');
  // eslint-disable-next-line no-unused-vars
  const { table_view } = useSelector((state) => state.controls);
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  const changeTableView = (part, view) => {
    setPartType(part);
    dispatch(setTableView(view));
  };

  useEffect(() => {
    dispatch(setTableView('sensors-page-velocity-transducers'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="container-fluid stnd-container">
      <SideMenu />
      <TopMenu />

      <div className="row stnd-content" onClick={toggleMenu}>
        <div className="row stnd-top-content">
          <ContentBlock
            title={'Sensors'}
            text={content.intro_para}
            image={sensorsImg}
            imageSize="small"
            showButton={false}
          />
        </div>

        <div className="row stnd-middle-content-flex-row">
          <Button
            variant="outline-dark"
            active={partType === 'velocity-trans' ? true : false}
            className="stnd-button-type-one"
            onClick={() =>
              changeTableView(
                'velocity-trans',
                'sensors-page-velocity-transducers'
              )
            }
          >
            Velocity Transducers
          </Button>
          <Button
            variant="outline-dark"
            active={partType === 'accelerometers' ? true : false}
            className="stnd-button-type-one"
            onClick={() =>
              changeTableView('accelerometers', 'sensors-page-accelerometers')
            }
          >
            Accelerometers
          </Button>
          <Button
            variant="outline-dark"
            active={partType === 'speed-sensors' ? true : false}
            className="stnd-button-type-one"
            onClick={() =>
              changeTableView('speed-sensors', 'sensors-page-speed-sensors')
            }
          >
            Speed Sensors
          </Button>
          <Button
            variant="outline-dark"
            active={partType === 'parts-and-access' ? true : false}
            className="stnd-button-type-one"
            onClick={() =>
              changeTableView(
                'parts-and-access',
                'sensors-page-parts-and-accessories'
              )
            }
          >
            Parts & Accessories
          </Button>
        </div>

        <div className="row stnd-bottom-content">
          {partType === 'velocity-trans' && <TableComponent />}
          {partType === 'accelerometers' && <TableComponent />}
          {partType === 'speed-sensors' && <TableComponent />}
          {partType === 'parts-and-access' && <TableComponent />}
        </div>
      </div>

      <Footer />
    </main>
  );
}
