import React from 'react';
import { Accordion } from 'react-bootstrap';
import './accordionComponent.css';

export default function AccordionComponent({
  defaultActiveKey,
  eventKeyOne,
  titleOne,
  contentOne,
  contentOneFlexCol,
  subContentOne,
  eventKeyTwo,
  titleTwo,
  contentTwo,
  eventKeyThree,
  titleThree,
  contentThree,
  eventKeyFour,
  titleFour,
  contentFour,
  eventKeyFive,
  titleFive,
  contentFive,
  eventKeySix,
  titleSix,
  contentSix,
  eventKeySeven,
  titleSeven,
  contentSeven,
  eventKeyEight,
  titleEight,
  contentEight,
  eventKeyNine,
  titleNine,
  contentNine,
  eventKeyTen,
  titleTen,
  contentTen,
}) {
  return (
    <Accordion
      defaultActiveKey={defaultActiveKey}
      bsPrefix="accordion"
      className="container-fluid accordion-container"
    >
      <Accordion.Item eventKey={eventKeyOne} bsPrefix="accordion-item">
        <Accordion.Header bsPrefix="accordion-header">
          {titleOne}
        </Accordion.Header>
        <Accordion.Body bsPrefix="accordion-body">
          <div
            className={
              contentOneFlexCol
                ? 'accordion-content-col'
                : 'accordion-content-row'
            }
          >
            {contentOne}
          </div>
          {subContentOne && (
            <div className="accordion-content">{subContentOne}</div>
          )}
        </Accordion.Body>
      </Accordion.Item>

      {eventKeyTwo && (
        <Accordion.Item eventKey={eventKeyTwo} bsPrefix="accordion-item">
          <Accordion.Header bsPrefix="accordion-header">
            {titleTwo}
          </Accordion.Header>
          <Accordion.Body bsPrefix="accordion-body">
            <div className="accordion-content">{contentTwo}</div>
          </Accordion.Body>
        </Accordion.Item>
      )}

      {eventKeyThree && (
        <Accordion.Item eventKey={eventKeyThree} bsPrefix="accordion-item">
          <Accordion.Header bsPrefix="accordion-header">
            {titleThree}
          </Accordion.Header>
          <Accordion.Body bsPrefix="accordion-body">
            <div className="accordion-content">{contentThree}</div>
          </Accordion.Body>
        </Accordion.Item>
      )}

      {eventKeyFour && (
        <Accordion.Item eventKey={eventKeyFour} bsPrefix="accordion-item">
          <Accordion.Header bsPrefix="accordion-header">
            {titleFour}
          </Accordion.Header>
          <Accordion.Body bsPrefix="accordion-body">
            <div className="accordion-content">{contentFour}</div>
          </Accordion.Body>
        </Accordion.Item>
      )}

      {eventKeyFive && (
        <Accordion.Item eventKey={eventKeyFive} bsPrefix="accordion-item">
          <Accordion.Header bsPrefix="accordion-header">
            {titleFive}
          </Accordion.Header>
          <Accordion.Body bsPrefix="accordion-body">
            <div className="accordion-content">{contentFive}</div>
          </Accordion.Body>
        </Accordion.Item>
      )}

      {eventKeySix && (
        <Accordion.Item eventKey={eventKeySix} bsPrefix="accordion-item">
          <Accordion.Header bsPrefix="accordion-header">
            {titleSix}
          </Accordion.Header>
          <Accordion.Body bsPrefix="accordion-body">
            <div className="accordion-content">{contentSix}</div>
          </Accordion.Body>
        </Accordion.Item>
      )}

      {eventKeySeven && (
        <Accordion.Item eventKey={eventKeySeven} bsPrefix="accordion-item">
          <Accordion.Header bsPrefix="accordion-header">
            {titleSeven}
          </Accordion.Header>
          <Accordion.Body bsPrefix="accordion-body">
            <div className="accordion-content">{contentSeven}</div>
          </Accordion.Body>
        </Accordion.Item>
      )}

      {eventKeyEight && (
        <Accordion.Item eventKey={eventKeyEight} bsPrefix="accordion-item">
          <Accordion.Header bsPrefix="accordion-header">
            {titleEight}
          </Accordion.Header>
          <Accordion.Body bsPrefix="accordion-body">
            <div className="accordion-content">{contentEight}</div>
          </Accordion.Body>
        </Accordion.Item>
      )}

      {eventKeyNine && (
        <Accordion.Item eventKey={eventKeyNine} bsPrefix="accordion-item">
          <Accordion.Header bsPrefix="accordion-header">
            {titleNine}
          </Accordion.Header>
          <Accordion.Body bsPrefix="accordion-body">
            <div className="accordion-content">{contentNine}</div>
          </Accordion.Body>
        </Accordion.Item>
      )}

      {eventKeyTen && (
        <Accordion.Item eventKey={eventKeyTen} bsPrefix="accordion-item">
          <Accordion.Header bsPrefix="accordion-header">
            {titleTen}
          </Accordion.Header>
          <Accordion.Body bsPrefix="accordion-body">
            <div className="accordion-content">{contentTen}</div>
          </Accordion.Body>
        </Accordion.Item>
      )}
    </Accordion>
  );
}
