import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setShowMenu } from '../redux/controls';
import TopMenu from '../components/TopMenu/TopMenu';
import ContentBlock from '../components/ContentBlock/ContentBlock';
import YouTubeContainer from '../components/YouTubeContainer/YouTubeContainer';
import SideMenu from '../components/SideMenu/SideMenu';
import Footer from '../components/Footer/Footer';
import '../styles/standardPageStyle.css';

import lexsecoCoreLossTester from '../assets/images/lexseco-core-loss-tester.png';
import { LEXSECO_ADVANTAGE_PAGE as content } from '../data/PAGE_TEXT_CONTENT';

export default function LexsecoAdvantage() {
  const [irdVideo, setIrdVideo] = useState(null);
  const { ird_videos } = useSelector((state) => state.controls);
  const dispatch = useDispatch();
  console.log(ird_videos);

  let length;
  if (ird_videos) {
    length = ird_videos.length;
  }

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  useEffect(() => {
    if (ird_videos) {
      const result = ird_videos.filter(
        (video) =>
          video.video_title === `Core Loss Testing with Lexseco's 25 KVA CLT`
        // console.log(video.video_title)
      );
      // console.log('result', result);
      setIrdVideo(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length]);

  return (
    <div className="container-fluid stnd-container">
      <SideMenu />
      <TopMenu />

      <div className="row stnd-content" onClick={toggleMenu}>
        <div className="row stnd-top-content">
          <ContentBlock
            title={content.page_header_text}
            text={content.intro_para}
            image={lexsecoCoreLossTester}
            imageSize="small"
            showButton={false}
          />
        </div>

        <div className="row stnd-middle-content-flex-col">
          {irdVideo &&
            irdVideo.map((video) => {
              return (
                <YouTubeContainer
                  key={video.id}
                  smallContainer={false}
                  embedId={video.video_link_id}
                  title={video.video_title}
                />
              );
            })}
        </div>

        <div className="row stnd-bottom-content">
          <h2 className="stnd-h2">{content.advantage_title_one}</h2>
          <span className="stnd-text">{content.advantage_one_para}</span>

          <h2 className="stnd-h2 margin-top-twenty">
            {content.advantage_title_two}
          </h2>
          <span className="stnd-text">{content.advantage_two_para}</span>

          <h2 className="stnd-h2 margin-top-twenty">
            {content.advantage_title_three}
          </h2>
          <span className="stnd-text">{content.advantage_three_para}</span>

          <h2 className="stnd-h2 margin-top-twenty">
            {content.advantage_title_four}
          </h2>
          <span className="stnd-text">{content.advantage_four_para}</span>

          <h2 className="stnd-h2 margin-top-twenty">
            {content.advantage_title_five}
          </h2>
          <span className="stnd-text">{content.advantage_five_para}</span>

          <h2 className="stnd-h2 margin-top-twenty">
            {content.advantage_title_six}
          </h2>
          <span className="stnd-text">{content.advantage_six_para}</span>

          <h2 className="stnd-h2 margin-top-twenty">
            {content.advantage_title_seven}
          </h2>
          <span className="stnd-text">{content.advantage_seven_para}</span>

          <h2 className="stnd-h2 margin-top-twenty">
            {content.advantage_title_eight}
          </h2>
          <span className="stnd-text">{content.advantage_eight_para}</span>

          <h2 className="stnd-h2 margin-top-twenty">
            {content.advantage_title_nine}
          </h2>
          <span className="stnd-text">{content.advantage_nine_para}</span>
        </div>
      </div>

      <Footer />
    </div>
  );
}
