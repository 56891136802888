import React, { useState, useEffect } from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './youTubeContainer.css';
import trashIcon from '../../assets/icons/trash-white.svg';

export default function YouTubeContainer({
  id,
  smallContainer,
  embedId,
  title,
  admin,
  handleDeleteVideo,
  arg,
}) {
  const [outerContainerSize, setOuterContainerSize] =
    useState('outer-container');
  const [ytContainer, setYtContainer] = useState('container yt-container');
  const size = useWindowSize();

  useEffect(() => {
    if (size.width <= 600) {
      setOuterContainerSize('outer-container-mobile');
      setYtContainer('container yt-container-mobile');
    } else if (smallContainer) {
      setOuterContainerSize('outer-container-small');
      setYtContainer('container yt-container-small');
    } else {
      setOuterContainerSize('outer-container');
      setYtContainer('container yt-container');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size.width]);

  return (
    <div className={outerContainerSize}>
      {admin && (
        <div className="icon-wrapper">
          <img
            src={trashIcon}
            alt="delete"
            className="yt-icon"
            onClick={() => handleDeleteVideo(arg, id)}
          />
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      )}
      <div className={ytContainer}>
        <div className="ratio ratio-16x9">
          <iframe
            src={`https://www.youtube.com/embed/${embedId}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={title}
          />
        </div>
      </div>

      <div className="info-wrapper">
        <span className={smallContainer ? 'info-text-small' : 'info-text'}>
          {title}
        </span>
      </div>
    </div>
  );
}
