import React, { useState, useEffect } from 'react';
import useWindowSize from '../hooks/useWindowSize';
import { useDispatch } from 'react-redux';
import { setShowMenu } from '../redux/controls';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
// import { motion, AnimatePresence } from 'framer-motion';
import TopMenu from '../components/TopMenu/TopMenu';
import SideMenu from '../components/SideMenu/SideMenu';
import ContentBlock from '../components/ContentBlock/ContentBlock';
import Pagination from '../components/Pagination/Pagination';
import CardComponent from '../components/CardComponent/CardComponent';
import Footer from '../components/Footer/Footer';
import '../styles/standardPageStyle.css';

import b10 from '../assets/images/b10_endthrust.png';

import { SOFT_BEARING_BALANCING_MACHINES_PAGE as content } from '../data/PAGE_TEXT_CONTENT';

import {
  COMPLETE_MACHINES,
  MACHINE_UPGRADES,
  USER_FABRICATED,
  MODULAR_SUSPENSION_WORK_SUPPORTS,
  ROLLER_WORK_SUPPORTS,
} from '../data/SOFT_BEARING_BAL_MACH';

export default function SoftBearingBalanceMachines() {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [partType, setPartType] = useState('complete-machines');
  const [filterBy, setFilterBy] = useState(null);
  const [filterType, setFilterType] = useState(null);
  const [displayedFilterBy, setDisplayedFilterBy] = useState('no filter');
  const [displayedFilterType, setDisplayedFilterType] = useState('no filter');
  const size = useWindowSize();
  const dispatch = useDispatch();

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItem = items.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSetItems = () => {
    if (partType === 'complete-machines') {
      setItems(COMPLETE_MACHINES);
      setItemsPerPage(4);
    } else if (partType === 'machine-upgrades') {
      setItems(MACHINE_UPGRADES);
      setItemsPerPage(4);
    } else if (partType === 'user-fabricated') {
      setItems(USER_FABRICATED);
      setItemsPerPage(4);
    } else if (partType === 'modular-sus') {
      setItems(MODULAR_SUSPENSION_WORK_SUPPORTS);
      setItemsPerPage(4);
    } else if (partType === 'roller-work') {
      setItems(ROLLER_WORK_SUPPORTS);
      setItemsPerPage(4);
    }
  };

  const applyFilter = (isInt, key, type, min, max) => {
    if (!isInt) {
      const filteredItems = items.filter((item) => item[key] === type);

      setItems(filteredItems);
    } else {
      const filteredItems = items.filter(
        (item) => item[key] >= min && item[key] <= max
      );

      setItems(filteredItems);
    }
  };

  const handleResetFilter = () => {
    handleSetItems();
    setFilterBy(null);
    setFilterType(null);
    setDisplayedFilterBy('no filter');
    setDisplayedFilterType('no filter');
  };

  const handleSetFilterBy = (e) => {
    setFilterBy(e);
    handleSetItems();
  };

  const handleSetFilterType = (e) => {
    setFilterType(e);
    handleSetItems();
  };

  const handleSetPartType = (type) => {
    handleResetFilter();
    setPartType(type);
  };

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  useEffect(() => {
    handleSetItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partType]);

  useEffect(() => {
    if (filterBy === 'max_sensitivity') {
      setDisplayedFilterBy('Max Sensitivity');
    } else if (filterBy === 'capacity_pair') {
      setDisplayedFilterBy('Capacity Pair');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBy]);

  useEffect(() => {
    if (
      filterType ===
      'max_sens between 0.0006g/in (0.015g/mm) - 0.06g/in (1.5g/mm)'
    ) {
      setDisplayedFilterType('0.0006g/in (0.015g/mm) - 0.06g/in (1.5g/mm)');
    } else if (
      filterType === 'max_sens between 0.08g/in (2g/mm) - 0.16g/in (4g/mm)'
    ) {
      setDisplayedFilterType('0.08g/in (2g/mm) - 0.16g/in (4g/mm)');
    } else if (
      filterType === 'max_sens between 0.4g/in (10g/mm) - 4g/in (100g/mm)'
    ) {
      setDisplayedFilterType('0.4g/in (10g/mm) - 4g/in (100g/mm)');
    } else if (
      filterType === 'max_sens between 4g/in (100g/mm) - 7g/in (180g/mm)'
    ) {
      setDisplayedFilterType('4g/in (100g/mm) - 7g/in (180g/mm)');
    } else if (filterType === 'cap_pair between 7lb (3kg) - 500lb (230kg)') {
      setDisplayedFilterType('7lb (3kg) - 500lb (230kg)');
    } else if (
      filterType === 'cap_pair between 1000lb (450kg) - 5000lb (2300kg)'
    ) {
      setDisplayedFilterType('1000lb (450kg) - 5000lb (2300kg)');
    } else if (
      filterType === 'cap_pair between 10000lb (4500kg) - 75000lb (34000kg)'
    ) {
      setDisplayedFilterType('10000lb (4500kg) - 75000lb (34000kg)');
    } else if (
      filterType === 'cap_pair between 75000lb (34000kg) - 110000lb (50000kg)'
    ) {
      setDisplayedFilterType('75000lb (34000kg) - 110000lb (50000kg)');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType]);

  useEffect(() => {
    if (filterBy === 'drive_type' && filterType === 'belt') {
      applyFilter(false, filterBy, filterType);
    } else if (filterBy === 'drive_type' && filterType === 'end') {
      applyFilter(false, filterBy, filterType);
    } else if (
      filterBy === 'max_sensitivity' &&
      filterType ===
        'max_sens between 0.0006g/in (0.015g/mm) - 0.06g/in (1.5g/mm)'
    ) {
      applyFilter(true, 'max_sensitivity_inch', filterType, 0.0006, 0.06);
    } else if (
      filterBy === 'max_sensitivity' &&
      filterType === 'max_sens between 0.08g/in (2g/mm) - 0.16g/in (4g/mm)'
    ) {
      applyFilter(true, 'max_sensitivity_inch', filterType, 0.08, 0.16);
    } else if (
      filterBy === 'max_sensitivity' &&
      filterType === 'max_sens between 0.4g/in (10g/mm) - 4g/in (100g/mm)'
    ) {
      applyFilter(true, 'max_sensitivity_inch', filterType, 0.4, 4);
    } else if (
      filterBy === 'max_sensitivity' &&
      filterType === 'max_sens between 4g/in (100g/mm) - 7g/in (180g/mm)'
    ) {
      applyFilter(true, 'max_sensitivity_inch', filterType, 4, 7);
    } else if (
      filterBy === 'capacity_pair' &&
      filterType === 'cap_pair between 7lb (3kg) - 500lb (230kg)'
    ) {
      applyFilter(true, 'capacity_pair_lb', filterType, 7, 500);
    } else if (
      filterBy === 'capacity_pair' &&
      filterType === 'cap_pair between 1000lb (450kg) - 5000lb (2300kg)'
    ) {
      applyFilter(true, 'capacity_pair_lb', filterType, 1000, 5000);
    } else if (
      filterBy === 'capacity_pair' &&
      filterType === 'cap_pair between 10000lb (4500kg) - 75000lb (34000kg)'
    ) {
      applyFilter(true, 'capacity_pair_lb', filterType, 10000, 75000);
    } else if (
      filterBy === 'capacity_pair' &&
      filterType === 'cap_pair between 75000lb (34000kg) - 110000lb (50000kg)'
    ) {
      applyFilter(true, 'capacity_pair_lb', filterType, 75000, 110000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBy, filterType]);

  useEffect(() => {
    if (size.width <= 700) {
      setItemsPerPage(1);
    } else {
      setItemsPerPage(4);
    }
  }, [size.width]);

  // console.log('size', size.width);
  // console.log('filterType', filterType);
  // console.log('currentItem', currentItem);

  return (
    <div className="container-fluid stnd-container">
      <SideMenu />
      <TopMenu />

      <div className="container-fluid stnd-content" onClick={toggleMenu}>
        <div className="row stnd-top-content">
          <ContentBlock
            title={'Soft Bearing Balance Machines'}
            text={content.soft_bearing_bal_mach_para}
            image={b10}
            imageSize="small"
            showButton={false}
          />
        </div>

        <div className="row stnd-middle-content-flex-col">
          <div className="stnd-width-seventy-percent margin-top-ten margin-bottom-ten">
            <p className="stnd-text">
              If you're new to balancing and do not have the knowledge or
              resources to build parts of the machine, this is the perfect place
              to start. You are encouraged to fill out and submit the form below
              to allow us to help you choose the right balancing machine for
              your applications. Once you have chosen a machine, all you need to
              do is choose from 295, 236, or 246 Balancing Instruments for
              Complete Balancing System
            </p>
          </div>

          <Button
            href="/new-balance-machine-app"
            variant="outline-primary"
            className="stnd-link-button"
          >
            IRD Balancing New Machine Application Form
          </Button>

          <h2 className="stnd-h2 stnd-center-text margin-top-ten margin-bottom-ten">
            Click on the model number of the parts listed to view their data
            sheets.
          </h2>
        </div>

        <div className="row stnd-bottom-content">
          <div className="stnd-content-wrapper-flex-row center-div">
            <Button
              variant="outline-dark"
              active={partType === 'complete-machines' ? true : false}
              className="stnd-button-type-one"
              onClick={() => handleSetPartType('complete-machines')}
            >
              Complete Machines
            </Button>
            <Button
              variant="outline-dark"
              active={partType === 'machine-upgrades' ? true : false}
              className="stnd-button-type-one"
              onClick={() => handleSetPartType('machine-upgrades')}
            >
              Machine Upgrades
            </Button>
            <Button
              variant="outline-dark"
              active={partType === 'user-fabricated' ? true : false}
              className="stnd-button-type-one"
              onClick={() => handleSetPartType('user-fabricated')}
            >
              User Fabricated
            </Button>
            <Button
              variant="outline-dark"
              active={partType === 'modular-sus' ? true : false}
              className="stnd-button-type-one"
              onClick={() => handleSetPartType('modular-sus')}
            >
              Modular Suspension Work Supports
            </Button>
            <Button
              variant="outline-dark"
              active={partType === 'roller-work' ? true : false}
              className="stnd-button-type-one"
              onClick={() => handleSetPartType('roller-work')}
            >
              Roller Work Supports
            </Button>
          </div>
          <div className="row stnd-content-wrapper-flex-row center-div">
            <div className="stnd-filter-controls-wrapper">
              <DropdownButton
                id="filter-by"
                title="Filter Results By"
                variant="outline-primary"
                onSelect={handleSetFilterBy}
              >
                {/* <Dropdown.Item eventKey="drive_type">Drive</Dropdown.Item> */}

                <Dropdown.Item eventKey="max_sensitivity">
                  Max Sensitivity
                </Dropdown.Item>
                <Dropdown.Item eventKey="capacity_pair">
                  Capacity Pair
                </Dropdown.Item>
              </DropdownButton>

              <span className="stnd-text">
                <strong>Results: </strong> {displayedFilterBy}
              </span>

              <DropdownButton
                id="filter-type"
                title="Types Available"
                variant="outline-secondary"
                onSelect={handleSetFilterType}
              >
                {/* {filterBy === 'drive_type' && (
                  <>
                    <Dropdown.Item eventKey="belt">belt drive</Dropdown.Item>
                    <Dropdown.Item eventKey="end">end drive</Dropdown.Item>
                  </>
                )} */}

                {filterBy === 'max_sensitivity' && (
                  <>
                    <Dropdown.Item eventKey="max_sens between 0.0006g/in (0.015g/mm) - 0.06g/in (1.5g/mm)">
                      between 0.0006g/in (0.015g/mm) - 0.06g/in (1.5g/mm)
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="max_sens between 0.08g/in (2g/mm) - 0.16g/in (4g/mm)">
                      between 0.08g/in (2g/mm) - 0.16g/in (4g/mm)
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="max_sens between 0.4g/in (10g/mm) - 4g/in (100g/mm)">
                      between 0.4g/in (10g/mm) - 4g/in (100g/mm)
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="max_sens between 4g/in (100g/mm) - 7g/in (180g/mm)">
                      between 4g/in (100g/mm) - 7g/in (180g/mm)
                    </Dropdown.Item>
                  </>
                )}

                {filterBy === 'capacity_pair' && (
                  <>
                    <Dropdown.Item eventKey="cap_pair between 7lb (3kg) - 500lb (230kg)">
                      between 7lb (3kg) - 500lb (230kg)
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="cap_pair between 1000lb (450kg) - 5000lb (2300kg)">
                      between 1000lb (450kg) - 5000lb (2300kg)
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="cap_pair between 10000lb (4500kg) - 75000lb (34000kg)">
                      between 10000lb (4500kg) - 75000lb (34000kg)
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="cap_pair between 75000lb (34000kg) - 110000lb (50000kg)">
                      between 75000lb (34000kg) - 110000lb (50000kg)
                    </Dropdown.Item>
                  </>
                )}
              </DropdownButton>

              <span className="stnd-text">
                <strong>Type: </strong> {displayedFilterType}
              </span>

              <Button variant="outline-secondary" onClick={handleResetFilter}>
                Reset Filter
              </Button>
            </div>
          </div>
          <div className="row stnd-content-wrapper-flex-row stnd-wrap">
            <div className="col stnd-col">
              <h2 className="stnd-h2 stnd-margin-top-bottom">
                B-Series Horizontal Machines (Complete Machines)
              </h2>

              <div className="row stnd-content-wrapper-flex-row stnd-wrap flex-row-center">
                {partType === 'complete-machines' && (
                  <>
                    {partType === 'complete-machines' && (
                      <>
                        {currentItem.map((item) => {
                          return (
                            <CardComponent
                              largeCard={true}
                              cardImg={item.product_image}
                              cardTitle={item.model}
                              cardText={item.description}
                              linkOne={item.link_text}
                              hrefOne={item.doc_link}
                              titleTwo={item.drive}
                              itemTwo={item.drive_type}
                              titleThree={item.max_sensitivity}
                              itemThreeImperVal={`${item.max_sensitivity_inch} g`}
                              itemThreeImperUnit="in"
                              itemThreeMetrVal={`${item.max_sensitivity_mm} g`}
                              itemThreeMetrUnit="mm"
                              titleFour={item.capacity_pair}
                              itemFourImperVal={item.capacity_pair_lb}
                              itemFourImperUnit="lb"
                              itemFourMetrVal={item.capacity_pair_kg}
                              itemFourMetrUnit="kg"
                              titleFive={item.max_rotor_dia}
                              itemFiveImperVal={item.max_rotor_dia_inch}
                              itemFiveImperUnit="in"
                              itemFiveMetrVal={item.max_rotor_dia_mm}
                              itemFiveMetrUnit="mm"
                              titleSix={item.max_journal_dia}
                              itemSixImperVal={item.max_journal_dia_inch}
                              itemSixImperUnit="in"
                              itemSixMetrVal={item.max_journal_dia_mm}
                              itemSixMetrUnit="mm"
                              titleSeven={item.drive_motor_size}
                              itemSevenImperVal={`DC ${item.drive_motor_size_hp}`}
                              itemSevenImperUnit="hp"
                              itemSevenMetrVal={`DC ${item.drive_motor_size_kw}`}
                              itemSevenMetrUnit="kw"
                              titleEight={item.standard_bed_length}
                              itemEightImperVal={item.standard_bed_length_inch}
                              itemEightImperUnit="in"
                              itemEightMetrVal={item.standard_bed_length_mm}
                              itemEightMetrUnit="mm"
                            />
                          );
                        })}
                      </>
                    )}
                  </>
                )}

                {partType === 'machine-upgrades' && (
                  <>
                    {partType === 'machine-upgrades' && (
                      <>
                        {currentItem.map((item) => {
                          return (
                            <CardComponent
                              largeCard={true}
                              cardImg={item.product_image}
                              cardTitle={item.model}
                              cardText={item.description}
                              linkOne={item.link_text}
                              hrefOne={item.doc_link}
                              titleTwo={item.capacity_pair}
                              itemTwoImperVal={item.capacity_pair_lb}
                              itemTwoImperUnit="lb"
                              itemTwoMetrVal={item.capacity_pair_kg}
                              itemTwoMetrUnit="kg"
                              titleThree={item.max_journal_dia}
                              itemThreeImperVal={item.max_journal_dia_inch}
                              itemThreeImperUnit="in"
                              itemThreeMetrVal={item.max_journal_dia_mm}
                              itemThreeMetrUnit="mm"
                              titleFour={item.max_sensitivity}
                              itemFourImperVal={`${item.max_sensitivity_inch} g`}
                              itemFourImperUnit="in"
                              itemFourMetrVal={`${item.max_sensitivity_mm} g`}
                              itemFourMetrUnit="mm"
                              titleFive={item.standard_bed_length}
                              itemFive={item.standard_bed_length_desc}
                            />
                          );
                        })}
                      </>
                    )}
                  </>
                )}

                {partType === 'user-fabricated' && (
                  <>
                    {partType === 'user-fabricated' && (
                      <>
                        {currentItem.map((item) => {
                          return (
                            <CardComponent
                              largeCard={true}
                              cardImg={item.product_image}
                              cardTitle={item.model}
                              cardText={item.description}
                              linkOne={item.link_text}
                              hrefOne={item.doc_link}
                              titleTwo={item.capacity_pair}
                              itemTwoImperVal={item.capacity_pair_lb}
                              itemTwoImperUnit="lb"
                              itemTwoMetrVal={item.capacity_pair_kg}
                              itemTwoMetrUnit="kg"
                              titleThree={item.max_journal_dia}
                              itemThreeImperVal={item.max_journal_dia_inch}
                              itemThreeImperUnit="in"
                              itemThreeMetrVal={item.max_journal_dia_mm}
                              itemThreeMetrUnit="mm"
                              titleFour={item.max_sensitivity}
                              itemFourImperVal={`${item.max_sensitivity_inch} g`}
                              itemFourImperUnit="in"
                              itemFourMetrVal={`${item.max_sensitivity_mm} g`}
                              itemFourMetrUnit="mm"
                              titleFive={item.standard_bed_length}
                              itemFiveImperVal={item.standard_bed_length_inch}
                              itemFiveImperUnit="in"
                              itemFiveMetrVal={item.standard_bed_length_mm}
                              itemFiveMetrUnit="mm"
                              titleSix={item.drive_motor_size}
                              itemSixImperVal={`DC ${item.drive_motor_size_hp}`}
                              itemSixImperUnit="hp"
                              itemSixMetrVal={`DC ${item.drive_motor_size_kw}`}
                              itemSixMetrUnit="kw"
                            />
                          );
                        })}
                      </>
                    )}
                  </>
                )}

                {partType === 'modular-sus' && (
                  <>
                    {partType === 'modular-sus' && (
                      <>
                        {currentItem.map((item) => {
                          return (
                            <CardComponent
                              largeCard={true}
                              cardImg={item.product_image}
                              cardTitle={item.model}
                              cardText={item.description}
                              linkOne={item.link_text}
                              hrefOne={item.doc_link}
                              titleTwo={item.capacity_pair}
                              itemTwoImperVal={item.capacity_pair_lb}
                              itemTwoImperUnit="lb"
                              itemTwoMetrVal={item.capacity_pair_kg}
                              itemTwoMetrUnit="kg"
                              titleThree={item.max_sensitivity}
                              itemThreeImperVal={`${item.max_sensitivity_inch} g`}
                              itemThreeImperUnit="in"
                              itemThreeMetrVal={`${item.max_sensitivity_mm} g`}
                              itemThreeMetrUnit="mm"
                              titleFour={item.standard_bed_length}
                              itemFour={item.standard_bed_length_desc}
                            />
                          );
                        })}
                      </>
                    )}
                  </>
                )}

                {partType === 'roller-work' && (
                  <>
                    {partType === 'roller-work' && (
                      <>
                        {currentItem.map((item) => {
                          return (
                            <CardComponent
                              largeCard={true}
                              cardImg={item.product_image}
                              cardTitle={item.model}
                              cardText={item.description}
                              linkOne={item.link_text}
                              hrefOne={item.doc_link}
                              titleTwo={item.capacity_pair}
                              itemTwoImperVal={item.capacity_pair_lb}
                              itemTwoImperUnit="lb"
                              itemTwoMetrVal={item.capacity_pair_kg}
                              itemTwoMetrUnit="kg"
                              titleThree={item.max_journal_dia}
                              itemThreeImperVal={item.max_journal_dia_inch}
                              itemThreeImperUnit="in"
                              itemThreeMetrVal={item.max_journal_dia_mm}
                              itemThreeMetrUnit="mm"
                              titleFour={item.work_support_width}
                              itemFourImperVal={item.work_support_width_inch}
                              itemFourImperUnit="in"
                              itemFourMetrVal={item.work_support_width_mm}
                              itemFourMetrUnit="mm"
                              titleFive={item.max_rotor_dia}
                              itemFiveImperVal={item.max_rotor_dia_inch}
                              itemFiveImperUnit="in"
                              itemFiveMetrVal={item.max_rotor_dia_mm}
                              itemFiveMetrUnit="mm"
                              titleSix={item.can_be_mounted_on}
                              itemSix={item.can_be_mounted_on_val}
                            />
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={items.length}
            currentPage={currentPage}
            paginate={paginate}
          />
        </div>
      </div>

      <Footer />
    </div>
  );
}
