import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import './reminderForm.css';

export default function ReminderForm({ irdVideo }) {
  const today = new Date();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    last_calib_date: ``,
    customer_name: ``,
    email: ``,
    product: ``,
  });
  const [errors, setErrors] = useState({});
  const [formReset, setFormReset] = useState(false);

  const resetForm = (field) => {
    if (field === true) {
      form[field] = false;
    } else if (field === 'date') {
      form[field] = today;
    } else {
      form[field] = '';
    }

    setForm({
      ...form,
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const addToFirebase = async (lastCalibDate, customerName, email, product) => {
    setLoading(true);
    const randomId = uuidv4();
    const productRemindersRef = doc(db, 'product_service_reminders', randomId);
    lastCalibDate = new Date(lastCalibDate);
    let reminderDate = '';
    let lastCalibMonth = lastCalibDate.getMonth();
    let lastCalibYear = lastCalibDate.getFullYear();
    let lastCalibDay = lastCalibDate.getDate();
    let reminderMonth = lastCalibMonth + 11;
    reminderDate = new Date(lastCalibYear, reminderMonth, lastCalibDay);

    await setDoc(productRemindersRef, {
      id: randomId,
      last_calib_date: lastCalibDate,
      reminder_date: reminderDate,
      reminder_email_sent: false,
      customer_name: customerName,
      email: email,
      product: product,
    });
    alert('New entry added successfully');
    setLoading(false);
  };

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const findErrors = () => {
    const newErrors = {};
    if (!form.last_calib_date || form.last_calib_date === '')
      newErrors.last_calib_date = 'cannot be blank!';
    if (!form.customer_name || form.customer_name === '')
      newErrors.customer_name = 'cannot be blank!';
    if (!form.email || form.email === '') newErrors.email = 'cannot be blank!';
    if (!form.product || form.product === '')
      newErrors.product = 'cannot be blank!';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = findErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      addToFirebase(
        form.last_calib_date,
        form.customer_name,
        form.email,
        form.product
      );
    }
    setFormReset(true);
  };

  useEffect(() => {
    if (formReset) {
      for (const key in form) {
        resetForm(key);
      }
      setFormReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formReset]);

  return (
    <div className="reminder-form-container">
      <Form className="reminder-form">
        <Form.Group className="mb-3" controlId="customer-name">
          <Form.Label>Customer Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Customer Name"
            value={form.customer_name}
            onChange={(e) => setField('customer_name', e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="email"
            value={form.email}
            onChange={(e) => setField('email', e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="product">
          <Form.Label>Product</Form.Label>
          <Form.Control
            placeholder="product"
            value={form.product}
            onChange={(e) => setField('product', e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="last-calib-date">
          <Form.Label>Last Calibration Date</Form.Label>
          <Form.Control
            type="date"
            value={form.last_calib_date}
            onChange={(e) => setField('last_calib_date', e.target.value)}
          />
        </Form.Group>

        <div className="job-posting-button-wrapper">
          <Button
            className="job-posting-submit-button"
            type="submit"
            disabled={loading}
            onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
}
