/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import './footer.css';

export default function Footer() {
  const { current_user } = useSelector((state) => state.user);

  return (
    <div className="container-fluid footer-container">
      <div className="footer-content-wrapper">
        <div className="row footer-row">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 footer-col footer-form-col">
            <form action="" className="footer-form">
              <label htmlFor="footer-form" className="footer-form-label">
                Subscribe To Our Mailing List
              </label>
              <div className="footer-input-and-button-container">
                <input
                  type="text"
                  id="footer-form"
                  placeholder="email address"
                />
                <Button className="footer-button" onClick={null}>
                  Subscribe
                </Button>
              </div>
            </form>
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 footer-col footer-text-col">
            <span className="footer-text">
              Copyright © 2021 IRD® LLC. All rights reserved.
            </span>
            <span className="footer-text footer-bold-text">
              Setting the Standard in Industrial Research and Development
            </span>
            <div className="footer-link-wrapper">
              <a
                href="/disclaimer-and-privacy-agreement"
                className="footer-link"
              >
                Disclaimer
              </a>
              <a
                href="/disclaimer-and-privacy-agreement"
                className="footer-link"
              >
                Privacy Agreement
              </a>

              <a
                href="/login"
                className={
                  current_user ? 'footer-link-attention' : 'footer-link'
                }
              >
                {current_user ? 'Sign Out' : 'Login'}
              </a>
              {current_user && (
                <a href="/admin" className="footer-link">
                  Admin
                </a>
              )}
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 footer-col footer-text-col">
            <div className="footer-contact-section">
              <span className="footer-text footer-bold-text">
                World Headquarters
              </span>
              <span className="footer-text">IRD® LLC</span>
              <span className="footer-text">Louisville, KY 40209</span>
              <span className="footer-text">Phone: 502-366-0916</span>
              <span className="footer-text">Fax: 502-238-1001</span>
              <span className="footer-text">Email: sales@IRDProducts.com</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
