import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { serverTimestamp, doc, setDoc, updateDoc } from 'firebase/firestore';
import { storage, db } from '../../firebase';
import './featuredProductForm.css';

export default function FeaturedProductForm() {
  const { featured_product } = useSelector((state) => state.controls);
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState(null);
  const [productDescription, setProductDescription] = useState(null);
  const [imageUpload, setImageUpload] = useState(null);
  const [linkToProduct, setLinkToProduct] = useState(null);
  const [ableToPurchaseOnline, setAbleToPurchaseOnline] = useState(false);

  let length = 0;

  if (featured_product) {
    length = featured_product.length;
  }

  const uploadPostToFirebase = async (
    imgUrl,
    prodName,
    prodDesc,
    prodLink,
    showLink
  ) => {
    const randomId = uuidv4();
    const prodRef = doc(db, 'featured_product', randomId);
    setDoc(prodRef, {
      id: randomId,
      featured_prod_img: imgUrl,
      featured_prod_name: prodName,
      featured_prod_desc: prodDesc,
      featured_prod_link: prodLink,
      show_feat_prod_link: showLink,
      createdAt: serverTimestamp(),
    });
  };

  async function addNewFeaturedProduct(file) {
    setLoading(true);
    if (imageUpload === null) return;
    const random = uuidv4();
    const extension = '.png';
    const imageRef = ref(
      storage,
      `featuredProductImages/${imageUpload.name + random + extension}`
    );
    // eslint-disable-next-line no-unused-vars
    const snapshot = await uploadBytes(imageRef, file);
    const image_url = await getDownloadURL(imageRef);
    uploadPostToFirebase(
      image_url,
      productName,
      productDescription,
      linkToProduct,
      ableToPurchaseOnline
    );

    setLoading(false);
    alert('Added new featured product successfully');
  }

  const updateFeaturedProduct = async (file) => {
    setLoading(true);
    if (imageUpload === null) return;
    const random = uuidv4();
    const extension = '.png';
    const imageRef = ref(
      storage,
      `featuredProductImages/${imageUpload.name + random + extension}`
    );
    // eslint-disable-next-line no-unused-vars
    const snapshot = await uploadBytes(imageRef, file);
    const image_url = await getDownloadURL(imageRef);
    const docRef = doc(db, 'featured_product', featured_product[0].id);
    updateDoc(docRef, {
      featured_prod_img: image_url,
      featured_prod_name: productName,
      featured_prod_desc: productDescription,
      featured_prod_link: linkToProduct,
      show_feat_prod_link: ableToPurchaseOnline,
    });
    setLoading(false);
    alert('Featured Product updated successfully');
  };

  function handleChange(e) {
    if (e.target.files[0]) {
      setImageUpload(e.target.files[0]);
    }
  }

  function handleSubmit() {
    addNewFeaturedProduct(imageUpload);
  }

  function handleUpdate() {
    updateFeaturedProduct(imageUpload);
  }

  function onSwitchAction() {
    setAbleToPurchaseOnline(!ableToPurchaseOnline);
  }

  return (
    <div className="featured-product-form-container">
      <Form className="featured-product-form">
        <Form.Group className="mb-3" controlId="product-name">
          <Form.Label>Product Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Product Name"
            onChange={(e) => setProductName(e.target.value)}
          />
        </Form.Group>

        <input
          type="file"
          placeholder="Choose product image file"
          className="form-control file-selection-input"
          onChange={handleChange}
        />

        <Form.Group className="mb-3" controlId="product-description">
          <Form.Label>Product Description</Form.Label>
          <Form.Control
            as="textarea"
            rows="4"
            placeholder="Description"
            onChange={(e) => setProductDescription(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="product-link">
          <Form.Label>Link to Purchase Product Online</Form.Label>
          <Form.Control
            type="text"
            placeholder="Product Link"
            onChange={(e) => setLinkToProduct(e.target.value)}
          />
        </Form.Group>

        <Form.Check
          type="switch"
          checked={ableToPurchaseOnline}
          id="purchase-online-toggles"
          label="Product Available to Purchase Online?"
          onChange={onSwitchAction}
        />

        <div className="featured-product-button-wrapper">
          {length === 0 ? (
            <Button
              className="featured-product-submit-button"
              type="submit"
              disabled={loading}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          ) : (
            <Button
              className="featured-product-submit-button"
              type="submit"
              disabled={loading}
              onClick={handleUpdate}
            >
              Update
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
}
