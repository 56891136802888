import React from 'react';
import { useDispatch } from 'react-redux';
import { setShowMenu } from '../redux/controls';
import SideMenu from '../components/SideMenu/SideMenu';
import TopMenu from '../components/TopMenu/TopMenu';
import Footer from '../components/Footer/Footer';
import AccordionComponent from '../components/AccordionComponent/AccordionComponent';
import CalibrationService from '../components/ServiceOptions/CalibrationService';
import CoverPlanService from '../components/ServiceOptions/CoverPlanService';
import MeterExchangeService from '../components/ServiceOptions/MeterExchangeService';
import OnSiteTrainingService from '../components/ServiceOptions/OnSiteTrainingService';
import UpdateService from '../components/ServiceOptions/UpdatingService';
import WarrentyService from '../components/ServiceOptions/WarrantyService';
import ContactForm from '../components/ContactForm/ContactForm';
import '../styles/servicePageStyle.css';

export default function Service() {
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  return (
    <div className="container-fluid service-page-container">
      <SideMenu />
      <TopMenu />
      <div className="row service-page-header">
        <h1 className="service-page-header-text">Services</h1>
      </div>
      <div className="row service-page-content" onClick={toggleMenu}>
        <div className="service-page-center">
          <AccordionComponent
            defaultActiveKey="03"
            eventKeyOne="01"
            titleOne="Coverplan"
            contentOne={<CoverPlanService />}
            eventKeyTwo="02"
            titleTwo="On-SiteTraining"
            contentTwo={<OnSiteTrainingService />}
            eventKeyThree="03"
            titleThree="Callibration"
            contentThree={<CalibrationService />}
            eventKeyFour="04"
            titleFour="Warranty"
            contentFour={<WarrentyService />}
            eventKeyFive="05"
            titleFive="Updating/Refurbishment"
            contentFive={<UpdateService />}
            eventKeySix="06"
            titleSix="Meter Exchange"
            contentSix={<MeterExchangeService />}
          />
        </div>

        <div className="row service-page-form-container">
          <ContactForm />
        </div>
      </div>

      <Footer />
    </div>
  );
}
