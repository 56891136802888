import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowMenu } from '../redux/controls';
import { Button } from 'react-bootstrap';
import TopMenu from '../components/TopMenu/TopMenu';
import SideMenu from '../components/SideMenu/SideMenu';
import CardComponent from '../components/CardComponent/CardComponent';
import Footer from '../components/Footer/Footer';
import '../styles/standardPageStyle.css';

import {
  BALANCING_INSTRUMENTS_STAND_USE,
  BALANCING_INSTRUMENTS_FIELD_BALANCING,
  BALANCING_INSTRUMENTS_STAND_USE_AND_FIELD_BALANCING,
  VIBRATION_METERS,
  BALANCING_AND_VIBRATION,
} from '../data/BALANCING_VIBTRATION_INSTRUMENTS';

export default function BalancingInstruments() {
  const { has_paid } = useSelector((state) => state.controls);
  const [instrumentMode, setInstrumentMode] = useState('balance');
  const [caseUse, setCaseUse] = useState('stand');
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  const handleChange = (mode, useCase) => {
    setInstrumentMode(mode);
    setCaseUse(useCase);
  };

  // Commented out buttons that are not currently in use do to not having any products that fit the description. Leaving them in code in case new products are added.

  return (
    <div className="container-fluid stnd-container">
      {has_paid && <SideMenu />}
      {has_paid && <TopMenu />}

      <div className="row stnd-header">
        <h1 className="stnd-h1 margin-left-two-percent">
          IRD Balancing / Vibration Instruments
        </h1>
      </div>
      <div className="row stnd-content" onClick={toggleMenu}>
        <div className="row stnd-top-content stnd-center-div">
          <Button
            variant="outline-dark"
            active={instrumentMode === 'balance' ? true : false}
            className="stnd-button-type-one"
            onClick={() => setInstrumentMode('balance')}>
            Balancing Instruments
          </Button>
          <Button
            variant="outline-dark"
            active={instrumentMode === 'vibration' ? true : false}
            className="stnd-button-type-one"
            onClick={() => handleChange('vibration', 'none')}>
            Vibration Meters
          </Button>
          {/* <Button
            variant="outline-dark"
            active={instrumentMode === 'balance-vibration' ? true : false}
            className="stnd-button-type-one"
            onClick={() => setInstrumentMode('balance-vibration')}
          >
            Balancing/Vibration
          </Button> */}
        </div>

        <div className="row stnd-middle-content-flex-row">
          <Button
            variant="outline-primary"
            active={caseUse === 'stand' ? true : false}
            className="stnd-button-type-one"
            onClick={() => setCaseUse('stand')}>
            For Balancing Stand Use
          </Button>
          {/* <Button
            variant="outline-primary"
            active={caseUse === 'field' ? true : false}
            className="stnd-button-type-one"
            onClick={() => setCaseUse('field')}
          >
            For Field Balancing
          </Button> */}
          {/* <Button
            variant="outline-primary"
            active={caseUse === 'stand-field' ? true : false}
            className="stnd-button-type-one"
            onClick={() => setCaseUse('stand-field')}
          >
            For Both
          </Button> */}
        </div>

        <div className="row stnd-bottom-content">
          {instrumentMode === 'balance' &&
            caseUse === 'stand' &&
            BALANCING_INSTRUMENTS_STAND_USE.map((item) => {
              return (
                <CardComponent
                  largeCard={true}
                  cardImg={item.product_image}
                  cardTitle={item.model}
                  linkOne={item.doc_link_text}
                  hrefOne={item.doc_link}
                  titleTwo={item.for_use_with}
                  itemTwo={item.for_use_with_details}
                  titleThree={item.description}
                  itemThree={item.description_details}
                  titleFour={item.readout}
                  itemFour={item.readout_details}
                />
              );
            })}

          {instrumentMode === 'balance' &&
            caseUse === 'field' &&
            BALANCING_INSTRUMENTS_FIELD_BALANCING.map((item) => {
              return (
                <CardComponent
                  largeCard={true}
                  cardImg={item.product_image}
                  cardTitle={item.model}
                  linkOne={item.doc_link_text}
                  hrefOne={item.doc_link}
                  titleTwo={item.for_use_with}
                  itemTwo={item.for_use_with_details}
                  titleThree={item.description}
                  itemThree={item.description_details}
                  titleFour={item.readout}
                  itemFour={item.readout_details}
                />
              );
            })}

          {instrumentMode === 'balance' &&
            caseUse === 'stand-field' &&
            BALANCING_INSTRUMENTS_STAND_USE_AND_FIELD_BALANCING.map((item) => {
              return (
                <CardComponent
                  largeCard={true}
                  cardImg={item.product_image}
                  cardTitle={item.model}
                  linkOne={item.doc_link_text}
                  hrefOne={item.doc_link}
                  titleTwo={item.for_use_with}
                  itemTwo={item.for_use_with_details}
                  titleThree={item.description}
                  itemThree={item.description_details}
                  titleFour={item.readout}
                  itemFour={item.readout_details}
                />
              );
            })}

          {instrumentMode === 'vibration' && caseUse === 'none' && (
            <div className="card-flex-column-wrapper">
              <div className="card-flex-row-wrapper">
                {VIBRATION_METERS.map((item) => {
                  return (
                    <CardComponent
                      largeCard={true}
                      cardImg={item.product_image}
                      cardTitle={item.model}
                      linkOne={item.doc_link_text}
                      hrefOne={item.doc_link}
                      titleTwo={item.for_use_with}
                      itemTwo={item.for_use_with_details}
                      titleThree={item.description}
                      itemThree={item.description_details}
                      titleFour={item.readout}
                      itemFour={item.readout_details}
                    />
                  );
                })}
              </div>

              {/* <div className="stnd-col-start stnd-width-fifty-percent margin-top-thirty">
                <h2 className="stnd-h2">
                  Polymate v1.5 Data Analysis Software for 258 Data Analysis
                  IRD®
                </h2>
                <span className="stnd-text">
                  Balancing's Polymate software permits you to easily download
                  the data from your instrument and perform Fast Fourier
                  Transform analysis of the test data. Check-to-Conformance and
                  Run up-Coast down data can also be viewed and analyzed. The
                  IRD® Balancing Polymate Data Analysis and Reporting Module is
                  a software application tool designed to transfer, display and
                  store the data from the Model 256 and Model 258B & 258V
                  Instruments. Click here for more information.
                </span>
              </div> */}
            </div>
          )}

          {instrumentMode === 'balance-vibration' && caseUse === 'none' && (
            <div className="card-flex-column-wrapper">
              {BALANCING_AND_VIBRATION.map((item) => {
                return (
                  <CardComponent
                    largeCard={true}
                    cardImg={item.product_image}
                    cardTitle={item.model}
                    linkOne={item.doc_link_text}
                    hrefOne={item.doc_link}
                    titleTwo={item.for_use_with}
                    itemTwo={item.for_use_with_details}
                    titleThree={item.description}
                    itemThree={item.description_details}
                    titleFour={item.readout}
                    itemFour={item.readout_details}
                  />
                );
              })}
              {/* <div className="stnd-col-start stnd-width-fifty-percent margin-top-thirty">
                <h2 className="stnd-h2">
                  Polymate v1.5 Data Analysis Software for 258 Data Analysis
                  IRD®
                </h2>
                <span className="stnd-text">
                  Balancing's Polymate software permits you to easily download
                  the data from your instrument and perform Fast Fourier
                  Transform analysis of the test data. Check-to-Conformance and
                  Run up-Coast down data can also be viewed and analyzed. The
                  IRD® Balancing Polymate Data Analysis and Reporting Module is
                  a software application tool designed to transfer, display and
                  store the data from the Model 256 and Model 258B & 258V
                  Instruments. Click here for more information.
                </span>
              </div> */}
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}
