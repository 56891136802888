export const CORE_LOSS_TESTERS_PAGE = {
  page_header_text: `Core Loss Testers`,
  page_main_paragraph: `The LEXSECO® Core Loss Tester provides highly accurate, waveform-independent readings by utilizing high resolution flux, current, and power measuring devices for the core being examined.

    The Core Loss Tester includes a Variable Reactance Transformer (VRT) with solid state controls, a digital metering package, computer system, and test cables with quick connecting fittings.  Safety fuses, over-temperature protection, interlocks, surge suppressors, and circuit breakers are present to provide maximum protection to the hardware and operator.
    
    The Core Loss Tester provides an adjustable output current from 0 to 2,000 amperes for some models and up to 4,000 for others.  The VRT used by LEXSECO® provides ease of control and self-limiting current, both of which offer tremendous advantages over other sources of power used in the industry.
    
    Your LEXSECO® Core Loss Tester is designed to be used with LEXSECO® MP 7TM software.  This highly refined combination of software and hardware will ensure accurate results.  Please refer to the software and computer manuals for more information.`,
  upgrade_option_link: `/service`,
  upgrade_option_link_text: `Already have a Core Loss Tester? Check out our Upgrade option now!`,
  page_instructions: `Click on the model number of the parts listed to view their data sheets.`,
  armature_testing_kit_paragraph_one: `When testing wound rotors and armatures, the following test method should be employed:`,
  armature_testing_kit_paragraph_two: `If the shaft is removed or adequate space exists for the main cable to pass through the rotor, the rotor test should be implemented in the same manner as the stator test would be performed.  Otherwise, use the LEXSECO® rotor shaft clamps to clamp onto the rotor shaft and use it as the current path through the rotor.  Perform core loss test as specified in the test instructions and compare the results of the test to any tests performed on nearly identical rotors when possible.`,
  magnetic_paper_paragraph_one: `The LEXSECO® magnetic paper is a micro-encapsulated film that reacts to the presence of a magnetic field.  The film aligns with the magnetic lines and permits the user to view an electric motor's static magnetic field which can identify problems with its construction and integrity.`,
  magnetic_paper_list: `Other magnetic paper specifications:
  Color:  Green,
  Thickness:  0.006" (0.1524mm),
  Functionality:  Minimum of 2000 cycles (dark to light switches) - is normally reset with a common magnet.  We sell magnetic wands for this purpose.`,
};

export const UNBALANCE_PAGE = {
  page_header_text: `Unbalance - The Common Cause of Vibration & Premature Bearing Destruction within Rotating Machinery Equipment`,
  intro_paragraph:
    'Unbalance is defined as an unequal distribution of mass causing the mass axis to differ from the bearing axis.  During rotation, the unequal mass along with the radial acceleration due to rotation create a centrifugal force.  This results in force on the bearings and/or vibration of the bearings. Balancing is a procedure in which the mass distribution of a rotor is assessed and if necessary, adjusted via addition or subtraction of weight to ensure that the vibration of the journals and/or forces on the bearings are within specified limits. Vibration is a mechanical movement where an object oscillates about an equilibrium point.  It commonly produces unwanted sound and wastes energy.Vibration in rotating equipment can greatly reduce the life of the equipment and the bearings.  Before studying the basic principles of balancing, one must keep in mind that there are many causes of vibration other than unbalance.  In some cases, balancing may result in only a partial or temporary reduction in vibration, while in other cases balancing is the only effective course of action.  Mechanical unbalance, which produces a force at 1 X RPM, has been found to be one of the most common causes of machinery vibration, present to some degree on nearly all rotating machines.',
  causes_of_unbalanced_motor: 'Causes of an Unbalanced Rotor',
  causes_paragraph:
    'Unbalance is often defined as simply the unequal distribution of the weight of a rotor about its rotating centerline.  Causes of unbalance include the following',
  cause_one: 'Blow Holes in Castings',
  cause_one_paragraph:
    'Occasionally, cast rotors such as pump impellers or large sheaves have blow holes or sand traps which result from the casting process.  While undetectable through normal visual inspection, blow holes may be present within the material and create a significant source of unbalance.',
  cause_two: 'Eccentricity',
  cause_two_paragraph:
    'Eccentricity exists when the geometric centerline of a part does not coincide with its rotating centerline.  The rotor itself may be perfectly round; however, for one reason or another, the center of rotation is off centered',
  cause_three: 'Addition of Keys and Keyways',
  cause_three_paragraph:
    'A manufacturer may balance their product with a full key, a half key, or no key at all.  Thus, if both a pulley and a motor manufacturer were to balance their components without keys, they would be unbalanced when assembled together with the added weight of a key.',
  cause_four: 'Distortion',
  cause_four_paragraph:
    'Following manufacture, distortion, or change in shape, can alter the weight distribution and balance of a rotor.  Distortion is commonly caused by stress relief or thermal distortion.  Stress relieving, if not performed during manufacture, is sometimes a problem with rotors which have been fabricated by welding.  Any part that has been shaped by pressing, drawing, bending, extruding, etc., will naturally have high internal stresses.  Over time, they may distort to relieve this stress.  Thermal distortion occurs with a change in temperature.  Most metals expand when heated.  Commonly, rotors will contain minor imperfections and experience uneven heating, causing uneven distortion.  Thermal distortion is common on machines that operate at elevated temperatures including electric motors, fans, blowers, compressors, expanders, turbines, etc.  Thermal distortion can sometimes require the rotor to be balanced at its normal operating temperature.',
  cause_five: 'Clearance Tolerances',
  cause_five_paragraph:
    'The most common source of unbalance is the accumulation of tolerances in the assembly process of a machine.  An example of when this occurs is when the bore in a pulley is larger than the shaft diameter.  A key or setscrew would be  required to fill the gap, thus pushing the shaft to one side of the shaft rotating centerline.',
  cause_six: 'Corrosion or Wear',
  cause_six_paragraph:
    'Many rotors, particularly fan, blower, compressor, pump rotors, or any other rotors involved in the material handling processes, are subject to corrosion, abrasion, or wear.  If the corrosion or wear does not occur uniformly, unbalance will result.',
  cause_seven: 'Deposit Build-Up',
  cause_seven_paragraph:
    'Rotors used in material handling may become unbalanced due to the unequal build-up of deposits (dirt, lime, etc.) on the rotor.  The resultant gradual increase in unbalance can quickly become a serious problem when portions of the deposits begin to break away.  As small deposits break off, the vibration increases, which in turn, breaks off even more deposits, thus, creating a serious unbalance.  Routine inspection and cleaning can minimize the effect, but usually the rotor will need to be removed and balanced eventually.',
  cause_eight: 'Manufactured Unsymmetrical Configurations',
  cause_eight_paragraph:
    'Many rotors are manufactured in ways that produce dissymmetry.  Examples of these include:  rough surfaces on forgings, core shifts in castings, uneven number or position of bolt holes, and unsymmetrical parts, such as crankshafts, etc.',
  cause_nine: 'Hydraulic or Aerodynamic Unbalance',
  cause_nine_paragraph:
    'Oil trapped in oil galleries, oil trapped in grinding wheels and caviation or turbulence can sometimes produce unbalance forces.',
  causes_summary:
    'In summary, all of the above causes of unbalance can exist to some degree in a rotor.  However, the vector summation of all unbalance can be considered as a concentration at a point termed the "heavy spot."  Balancing is the technique for determining the magnitude and location of this heavy spot so that an equal amount of weight can be removed at this location, or an equal amount of weight added directly opposite.',
  units_of_unbalance: 'Units of Unbalance',
  units_of_unbal_paragraph_one:
    'The amount of unbalance in a rotating workpiece is expressed as the product of the unbalance mass (ounces, grams, etc.) and its distance from the rotating centerline (inches, centimeters, etc.).',
  unbalance_formula: 'U=m*e',
  units_of_unbal_paragraph_two:
    'Where U is the unbalance, m is the magnitude of the mass, and e is the eccentricity in the disc (or the distance from the center point of the mass to the axis of rotation).  Thus, the units for expressing unbalance are generally:',
  units_of_unbal_imperial: 'Imperial Units:',
  imperial_unit_gram_inches: 'gram inches (g in)',
  imperial_unit_ounce_inches: 'ounce inches (oz in)',
  units_of_unbal_metric: 'Metric Units:',
  metric_unit_gram_mm: 'gram millimeters (g mm)',
  metric_unit_gram_cm: 'gram centimeters (g cm)',
  units_of_unbal_final_paragraph:
    'For example, one ounce inch of unbalance would be a heavy spot of one ounce located at a radius of one inch from the rotating centerline.',
  types_of_rotors: 'Types of Rotors',
  types_of_rotors_paragraph_one:
    'Rotors are classified into two groups.  Whether or not a rotor is classified as rigid or flexible depends on the relationship between the rotating speed (RPM) of the rotor and its natural frequency.  When the natural frequency of some part of a machine is also equal to the rotating speed or some other exciting frequency of vibration, there is a condition of resonance.  The rotating speed at which the rotor itself goes into bending resonance is called a "critical speed."',
  types_of_rotors_paragraph_two:
    'As a general rule, rotors that operate below 70% of their critical speed are considered rigid and, when balanced at one speed will be balanced at any other normal operating speed below 70% of its critical speed.  Rotors that operate above 70% of their critical speed will actually bend or flex due to the forces of unbalance and, thus are called flexible rotors.',
  types_of_rotors_paragraph_three:
    'A flexible rotor balanced at one operating speed may not be balanced when operating at another speed.  This is because as the rotor bends or deflects, the weight of the rotor is moved out away from the rotating centerline creating a new unbalanced condition, known as whip.  This new unbalance can be corrected by re-balancing in the two end planes; however, the rotor would then be out of balance at slower speeds where there is no deflection.  The only solution to insure smooth operation at all speeds is to make the balance corrections in the actual planes of unbalance.  Typical machines which contain flexible rotors are steam and gas turbines, multistage centrifugal pumps, compressors, and paper rolls.',
  balancing_limits: 'Balancing Limits',
  balancing_limits_paragraph_one:
    'The International Standard ISO 1940/1 "Balance Quality Requirements of Rigid Rotors" is a widely-accepted reference for selecting rigid rotor balance quality.  This standard sets a level of unbalance to be acceptable based on the type of rotor and its operating speed.  To determine the balance quality requirement of your rotor, please reference the following link.',
  balancing_limits_paragraph_one_link_text:
    'Technical Literature, Balance Quality Requirements of Rigid Rotors',
  balancing_limits_paragraph_two:
    'While the ISO determines a standardized qualification for balancing, the unbalance tolerance, or amount of unbalance allowed in a product can easily be determined by the manufacturer.  The best way to determine an acceptable balance tolerance is to experiment with a batch of rotors.  Balance the rotors to reduce unbalance to a minimum.  Then slowly add unbalance until the performance becomes unacceptable.  Keep in mind that more unbalance can lead to premature bearing failure or excessive vibration.  Pick a balance tolerance within this range that is cost effective and achievable with little lost time.',
  static_unbalance: 'Static Unbalance',
  static_unbalance_paragraph_one:
    'Static unbalance is a condition of unbalance where the central principal axis is displaced parallel to the rotating centerline.  It can be detected by placing the rotor at its point of rotation on each end.  The heavy side of the rotor will swing to the bottom.  A part is considered statically balanced when it does not rotate regardless of the position in which it is placed.',
  static_unbalance_paragraph_two:
    'When testing a rotor that is symmetrically supported between identical bearings, identical vibration amplitude and phase readings will be measured at the bearings / end of shaft if the unbalance is truly static.  This does not apply for rotors which are mounted in an overhung configuration.',
  static_unbalance_paragraph_three:
    'Static unbalance can be corrected by adding or removing weight in only one correction plane.  In the figure to the right, the balance correction weight in scenario A is added as one singular weight addition in the same plane as the unbalance.  This will result in a well balanced rotor.  In scenario B, the static unbalance is corrected by placing the correction weights in-line at opposite ends of the rotor.  This method is typically used when it is not possible to add a single correction weight at the center portion of the rotor.  This results in a statically balanced rotor; however, during faster rotations, there is an increased chance of bending moments.',
  static_unbalance_paragraph_four:
    'Scenario C shows an unacceptable attempt of balancing a rotor.  The correction weight was added in a different plane than the one containing the rotor center of gravity.  The rotor may be considered statically balanced, due to the fact that no heavy spot would swing to the bottom if the rotor were suspended and allowed to spin freely; however, when the rotor is rotated, the original heavy spot and correction weight, being located in different planes, produce moments of inertia which cause the central principal axis to intersect the rotating centerline, thus creating another type of unbalance.',
  couple_unbalance: 'Couple Unbalance',
  couple_unbalance_paragraph_one:
    'Couple unbalance exists when two unbalances exist 180 degrees apart, but in different planes.  This condition of unbalance has a central principal mass axis intersecting the rotating centerline. Unlike static unbalance, couple unbalance cannot be detected by allowing the rotor to spin freely.  In fact, during the process of static balancing, one can add the weight in the wrong plane, as seen in the Methods of Fixing Static Unbalance, Figure C.  When the weight is added disproportionately, a coupled unbalance is created.  Couple unbalance can only be detected when the part is rotating and can be identified by comparing the bearing or shaft vibration amplitude and phase readings at each end of the rotor.',
  couple_unbalance_paragraph_two:
    'Readings from a rotor experiencing couple unbalance will reveal equal amplitudes of vibration with phase readings which differ by 180 degrees.  Again, this method of detecting the type of unbalance does not apply to overhung rotors.',
  couple_unbalance_paragraph_three:
    'Correction of couple unbalance requires that weight be added or subtracted within two correction planes.  In only very few cases will a rotor have true static or true couple unbalance.  Normally, an unbalanced rotor will have some of each type.  Combinations of static and couple unbalance are further classified as quasi-static or dynamic unbalance.',
  dynamic_unbalance: 'Dynamic Unbalance',
  dynamic_unbalance_paragraph_one:
    'Dynamic unbalance is the most common type of unbalance and is defined simply as unbalance where the central principal axis and the rotating centerline do no coincide or touch.  This type of unbalance exists whenever static and couple unbalance are present, but where the static unbalance is not in direct line with either couple component.  As a result, the central principal axis is both tilted and displaced from the rotating centerline.',
  dynamic_unbalance_paragraph_two:
    'Generally, a condition of dynamic unbalance will reveal comparative phase readings which are neither the same nor directly opposite one another.  This type of unbalance can only be solved by making weight corrections in a minimum of two planes.',
  methods_of_correcting_unbalance: 'Methods of Correcting Unbalance',
  methods_of_correcting_unbal_paragraph_one:
    'Once the heavy spot is located, it can be corrected by either adding mass to the rotor on the opposite side as the heavy weight or removing weight from the heavy location.',
  methods_of_correcting_unbal_paragraph_two:
    'Methods of adding weight include bolting or welding weights onto the rotor, etc.  Removing weight from the rotor can be done by drilling, grinding, milling, etc.',
  methods_of_correcting_unbal_paragraph_three:
    'If the location of weight addition / removal is unaccessible, the unbalance vector can be split such that the correction can be made via two accessible additions / removals instead.',
  importance_of_dynamic_balancing: 'The Importance of Dynamic Balancing',
  importance_of_dynamic_bal_paragraph_one:
    'One important reason for balancing is that the forces created by unbalance are detrimental to the life of the machine - the rotor, the bearings, and the supporting structure.  The amount of force created by unbalance depends on the speed of rotation and the amount of unbalance.',
  importance_of_dynamic_bal_paragraph_two:
    'In the figure to the right, an unbalance is represented by a heavy spot, W, located at some radius, R, from the rotating centerline.  If the unbalance weight, radius and machine RPM are known, the centrifugal force, F, can be generated:',
  importance_of_dynamic_bal_paragraph_three:
    'Example:  If an unbalance of 1 ounce exists 3 inches from the rotating centerline on a rotor that operates at 2,000 RPM, the centrifugal force can be calculated as follows.',
  importance_of_dynamic_bal_paragraph_four:
    'While 1 ounce is a small amount of weight, simply revolving the rotor at 2,000 RPM can produce 69.4 pounds-force.  As speeds increase, the force increases.  Thus, for a very high speed machine, a relatively small unbalance weight can produce a tremendous amount of force.',
};

export const BALANCING_MACHINES_INFO = {
  page_header_text: 'Balancing Machines - Types, Classification, and Methods',
  intro_paragraph:
    'The goal of rotor balancing is to reduce unbalance enough such that it can operate properly once installed on site.  Reducing unbalance reduces vibration and increases efficiency and life of the rotor and bearings.  Additionally, during production and repair, it is necessary to balance rotors before full assembly because there may be limited access to the rotor. A balancing machine is used to determine the location and amount of unbalanced masses on a rotor.  The rotor is mounted on the machine bearings and the machine spins the rotor.  Soft bearing machines measure the displacement of the ends of the rotor and bearings.  The machine measures this displacement and the phase angle, then computes the unbalance present.  IRD® Balancing machines then provide the operator with corrections to be made to the rotor via addition or subtraction of weight.',
  why_the_need: 'Why the Need for a Balancing Machine?',
  why_the_need_para_one:
    'When assessing a rotor, unbalance cannot be visually identified.  A hole or added weight on a rotor may be there from an initial balancing of the rotor, not the cause of unbalance.  The only way to assess unbalance is via the vibration or the force it generates.',
  why_the_need_para_two:
    'In maintenance and overhaul of rotating equipment, there are many cases when it is impractical to attempt in-place balancing because weight corrections cannot be made.  This is true for many pumps and totally enclosed motors as well as turbines and some centrifuges.  Also, the process of repairing a rotor causes gross unbalance which requires balancing prior to reassembling, thereby eliminating possible damage when starting up the machine.  A balancing machine is used to balance parts before re-installation, ensuring smooth operation of the machine.  A balancing machine can be an extremely valuable asset to any maintenance department that repairs pumps, motors and other rotating equipment.  It can save costs due to delays by eliminating the need to send parts to outside specialists to be balanced.  Often the savings on one job alone can justify the expense for a balancing machine.',
  why_the_need_para_three:
    'Manufacturers of rotating mechanical equipment must have assurance that their product will operate smoothly when installed at the final site.  One of the prime concerns is the balancing quality of the rotating components.  Through experience, the manufacturer can established a tolerable limit of unbalance which can be accepted in a particular machine.  The manufacturer knows that by exceeding this limit, customer complaints and machinery downtime will reflect in the quality of his product.  To meet this situation, parts will be balanced at the time of manufacturer, in most instances, prior to assembly and sometimes trim balanced as an assembly.',
  types_of_bal_machines: 'Types of Balancing Machines',
  types_paragraph:
    'Balancing Machines are usually classified according to the principle employed, how the unbalance is indicated, type of machine, method of operation, etc.',
  classification_of_centrifugal:
    'Classification of Centrifugal Balancing Machines',
  centrifugal_para:
    'Centrifugal balancing machines may be categorized by the type of unbalance a machine is capable of indicating (static or dynamic), the attitude of the journal axis of the workpiece (vertical or horizontal), and the type of rotor-bearing-support system employed (soft-bearing or hard-bearing).  In each category, one or more classes of machines are commercially built.',
  class_one: 'Class I - Displacement Indicating Balancing Machines',
  class_one_para:
    'Machines in this class are usually of the soft-bearing type.  They do not usually indicate unbalance directly in weight units (such as ounces or grams in the actual correction planes) but indicated displacement and / or velocity of vibration at the bearings.  The instrumentation does not directly indicate the amount of weight which must be added or removed in each of the correction planes.  Balancing with this type of machines involves the use of portable instrumentation.  Amount and angular location of correction weights are determined by performing simple vector calculations or by using a calculator to perform two-plane balancing operations.',
  class_two:
    'Class II - Calibratable Balancing Machines Requiring a Balanced Prototype',
  class_two_para:
    'Machines in this class are of the soft-bearing type using instrumentation which permits plane separation and calibration for a given rotor type, if a balanced master or prototype rotor is available.',
  class_three:
    'Class III - Calibratable Balancing Machines Not Requiring a Balanced Prototype',
  class_three_para:
    'Machines in this class are of the soft-bearing type.  Any (unbalanced) rotor may be used in plane of a balanced master rotor.  In turn, plane separation and calibration can be achieved for rotors without trial and error.  This class includes soft-bearing machines with electrically driven shakers fitted to the vibratory part of their rotor supports.  Microprocessor techniques allow this type to be calibrated without spinning the rotor and represents the latest in balancing technology.',
  class_four: 'Class IV - Permanently Calibrated Balancing Machines',
  class_four_para:
    'Machines in this class are of the hard-bearing type.  They are calibrated by the manufacture for all rotors falling within the weight and speed range of a given machine size.  These machines indicate unbalance in the first run without individual rotor calibration.  This accomplished by the incorporation of an analog computer into the instrumentation associated with the machine.  This type of balancing requires a very substantial machine foundation to eliminate vibration interference from other equipment.',
  static_non_rotating_bal: 'Static, Non-Rotating Balancing',
  static_para_one:
    'The first principle group is based on the fact that a body free to rotate will seek a position where its center of gravity is lowest.  Thus, the heavier side of the the rotor will seek the lowest position, automatically indicating the angular position of the unbalance weight.  The magnitude of unbalance is determined experimentally by adding weight in the form of wax or putty to the light side of the disc until it is in balance, or when the disc no longer rotates.  The roller stand, pendulum and horizontal ways can be used to determine static unbalance via gravity.',
  static_para_two:
    'The roller stand in Figure A is optimal because it allows for run-out measurements to be taken and does not require precise alignment like the horizontal ways in Figure C.  In Figure B, a disc is supported on a flexible cable or alternately, a ball-and-socket device which coincides with the center of the disc and slightly above the normal plane through the center of gravity.  The heavy side will tend to seek a lower level than the light, thereby indicating the angular position of the unbalance.  The disc is balanced by adding weight to a point diametrically opposed to the spot until the disc is level, as indicated by a circular "bubble" or level at the center of the balancing machine.  Variations of these methods are used.',
  static_satisfactory_for:
    'Static (non-rotating) balancing is satisfactory for:',
  satisfactory_for_one:
    'Rotors having low length/diameter ratios operating at low speeds',
  satisfactory_for_two: 'Narrow rotors operating at moderate speeds',
  satisfactory_for_three:
    'High-speed rotors which are to be assembled to a shaft and later balanced as an assembly',
  dynamic_bal_soft_vs_hard:
    'Dynamic Balancing Machines, Soft-Bearing vs. Hard-Bearing',
  soft_vs_hard_para:
    'Two-plane balancing machines, or dynamic balancing machines, are used for correcting static and dynamic unbalance.  The two general types of dynamic balancing machines that have received the widest acceptance are the "soft" or flexible bearing machine and the "hard" or rigid bearing machine.  While there is really no difference between the bearings used, the machines have different types of suspensions.',
  soft_bearing_machines: 'Soft-Bearing Balancing Machines',
  soft_bearing_para_one:
    'The soft-bearing machine derives its name from the fact that it supports the rotor to be balanced on bearings that are free to move in at least one direction, usually horizontally or perpendicularly to the rotor axis.  The theory behind this style of balancing is that the rotor behaves as if suspended in mid-air while the movements of the rotor are measured.  The mechanical design of a soft-bearing machine is slightly more complex, but the electronics involved are relatively simple compared to the hard-bearing machines.  The design of the soft-bearing balancing machine allows for it to be placed almost anywhere, as the flexible work supports provide a natural isolation from nearby activity.  This also allows for the machine to be moved without affecting the calibration of the device, unlike the hard-bearing machines.',
  soft_bearing_para_two:
    'The resonance of the rotor and bearing system occurs at one-half or less of the lowest balancing speed.  Balancing is done at a frequency higher than the resonance frequency of the suspension.',
  soft_bearing_para_three:
    'Besides the fact that a soft-bearing balancing machine is a portable one, it provides the added advantages of having a higher sensitivity than the hard-bearing machines at lower balancing speeds; the hard-bearing machines measure force which typically requires a higher balancing speed.  An additional benefit is that our soft-bearing machines measure and display the actual movement or displacement of the rotor while it is spinning which provides a built-in means of validating the fact that the machine is responding properly and the rotor is balanced correctly.',
  soft_bearing_para_four:
    'The major advantage of soft-bearing machines is that they tend to be more versatile.  They can handle a wide range of rotor weights on one size of a machine.  No special foundation is required for insulation and the machine can be moved without having to obtain a re-calibration from a specialist.',
  soft_bearing_para_five:
    'Soft-bearing balancing machines, like hard bearing machines, can balance most horizontally oriented rotors.  However, balancing of an overhung rotor requires the use of a negative load hold-down attachment piece.',
  soft_bearing_para_six: `The image above shows IRD®'s soft bearing balancing machine.  Notice that the orientation of the bearing system allows for the pendulum to swing back and forth with the rotor.  The displacement is recorded by the vibration sensor and later used to calculate the unbalance present.`,
  hard_bearing_machines: 'Hard-Bearing Balancing Machines',
  hard_bearing_para_one:
    'Hard-bearing balancing machines have stiff work supports and rely on sophisticated electronics to interpret the vibrations.  This requires a massive, stiff foundation where they must be permanently set and calibrated in place by the manufacturer.  The theory behind this balancing system is that the rotor is fully constrained and the forces that the rotor puts on the supports are measured.  Background vibration from adjacent machines or activity on the work floor can affect balancing results.  Commonly, hard-bearing machines are used in manufacturing production operations where a fast cycle time is required.',
  hard_bearing_para_two:
    'The major advantage to hard-bearing machines is that they tend to provide a quick unbalance readout, which is useful in high speed production balancing.',
  hard_bearing_para_three:
    'A limiting factor of hard-bearing machines is the required balancing speed of the rotor during testing.  Because the machine measures unbalance force of the rotating rotor, the rotor must be spun at a high speed to generate enough force to be detected by the stiff suspensions.',
  whip: 'Whip',
  whip_para:
    'Regardless of which horizontal balancing machine used, analysis of whip may be necessary when balancing long, thin rolls, or other flexible rotors.  Whip is a measurement of the deformation or bending of a flexible rotor.  If you suspect that you may need to measure whip, check with our technical support and we will determine whether or not a whip indicator is necessary for your application.',
  balancing_methods: `Balancing Methods`,
  bal_method_para: `A long time ago, it was essential for a balancing technician to be able to perform all of the balancing calculations, and human error was a potential contributing factor in all balancing situations.  Now, everything is automated.  When using IRD® Balancing's soft bearing balancing machines, one simply follows the instructions on the screen, step by step.  This steps are as follows:`,
  step_one: `Chose the Rotor Setup.`,
  step_one_para: `Our Balancing Instruments are set up to handle the 9 different rotor configurations.  Once you choose the rotor configuration, you are instructed to spin the rotor and take a measurement reading, then stop the rotor.`,
  step_two: `Calibration of the Machine with the Rotor`,
  step_two_para: `The Balancing Instrument will then instruct the user to add a known weight to the first correcting location on the left hand side, enter the illustrated required dimensions, spin up the rotor and take a measurement, stop the rotor, then remove the left hand weight and place it in the right hand correction plane and repeat.  Stop the rotor and remove the known weight.  The instrument then uses these measurements to calibrate itself.`,
  step_three: `Balancing the Rotor`,
  step_three_para: `The Balancing Instrument now displays both the left and right hand correction amounts and the angular location of the correction weights for the addition or subtraction (user specified) of material.`,
  bal_method_conclusion: `That's it!  Advancements in technology have turned balancing into a fairly easy task, making it applicable and necessary in every machine shop!`,
  alternative_to_mach_bal_field_bal:
    'The Alternative to Machine Balancing - Field Balancing',
  alternative_para_one:
    'Many rotors can often be balanced in place, running at their own operating speed, with minimum disassembly.  To balance in place, of course, a basic requirement is that the rotor has to be accessible to make corrections.  Machines such as fans and blowers are good candidates.  Totally enclosed motor armatures and pump impellers are not.',
  alternative_para_two:
    'The technique of balancing in place is referred to as Field Balancing and it offers some distinct advantages including:',
  alternative_para_two_pt_one:
    'Balancing is performed on the complete assembled machine and compensates for the assembly tolerances.',
  alternative_para_two_pt_two:
    'Costly and time-consuming disassembly to transport the rotor to a balancing machine is eliminated.',
  alternative_para_two_pt_three:
    'The effects of temperature, pressure, distortion and other environmental influences can be incorporated.',
  alternative_para_two_pt_four:
    'The resultant vibration can be the tolerance applied to the rotor, rather than the published balance tolerances normally used in a balancing machine.  This is particularly advantageous if the supporting structure is close to a resonance.  The unbalance in the rotor may have to be adjusted to abnormally fine levels to minimize the resultant resonant structural vibration.',
  alternative_para_two_pt_five:
    'Since the rotors are balanced in place, a balancing machine does not have to be purchased.',
  alternative_para_three:
    'Modern instruments such as vibration analyzers, data collectors and portable balancers provide accurate information to assist in the balancing process.  The vibration level measured at the rotating speed frequency is used as an indicator of the amount of unbalance.  The location is determined by measuring the phase.  Phase, (the relative motion of one part of a machine to another) is measured by means of a stroboscopic light or by an indicator in the instrument, triggered by a photocell.',
  alternative_para_four:
    'It is imperative that the vibration measured is a result of the unbalance and not some other exiting force.  Only a detailed, thorough, analysis can identify where the vibration measured is coming from.  Many sources of vibration can occur at the rotating speed frequency.',
  alternative_para_five: `When field balancing, trial weights for balance computation and permanent weights for final correction are normally added to the rotor.  Care should be taken when attaching weights.  They should be attached securely so that they cannot 'fly off' when the machine is operating.  They not only constitute a personnel safety hazard but also can cause damage.  Loose balance weights rattling around inside a turbine for example can wreck the machine.`,
  alternative_para_six:
    'Our field balancing instruments are the Model 246 & the Model 258.',
};

export const IRD_BALANCING_PRODUCTS_PAGE = {
  page_header_text: `IRD® Balancing Products`,
  intro_para_one: `IRD® Balancing offers a complete range of Balancing Products. A wide range
  of features are available to cover all of the requirements of precision
  balancing, including comparison to established tolerances and printing a
  certificate to document the balancing results. Whether your needs call for
  balancing rotating equipment on site or on a balancing machine, IRD®
  Balancing can provide the solution.`,
  intro_para_two: ` Our balancing capabilities extend up to rotors which weigh as much as
  500,000 lbs (226796 kg). They have a high balance sensitivity, as small as
  0.00002 oz-in (0.015 g-mm). No matter what you're balancing, our products
  should meet your requirements!`,
  page_sub_header_text: `First time here?`,
  first_time_para: ` You'll want to start by determining which balancing instrument meets your
  needs. Our balancing instruments come in kits with the necessary sensors.
  All that's left is to choose the balancing machine that meets your
  requirements! Don't forget to check out our user-fabricated options, as we
  allow you to build part of your machine too!`,
  ird_advantage: `The IRD Advantage`,
  benefits_of_ird: `Benefits of the IRD® Balancing Soft Bearing Machine`,
  benefits_para: `  Besides the fact that IRD® has been one of the top providers in the
  balancing industry for decades, our quality, technology, and service make
  us the best choice. Below you will find just a few of the important
  reasons you should look towards IRD for your balancing needs!`,
  benefits_list_title_one: `Truly portable machines - no large, permanent foundation required`,
  title_one_pt_one: `IRD® Balancing's machines do not require any special beds or foundations
  to achieve and maintain system sensitivity and balancing accuracy. Our
  machines can be set up on any standard floor that will support the
  weight of the machine and rotor. This makes IRD® Balancing systems ideal
  for transporting to any site for quick set up and balancing of all types
  of rotors.`,
  title_one_pt_two: `Easy set up for new rotors`,
  title_one_pt_three: `The self-aligning properties of the standard IRD® roller bearing work
  supports removes the need for prolonged and precise alignment and
  leveling procedures.`,
  title_one_pt_four: `No special purpose, pressure lubricated hydrostatic or shell bearings
  are required for heavier rotors.`,
  benefits_list_title_two: `Less damage to rotor journals`,
  benefits_list_title_three: `Only balancing system that provides vibration quality check`,
  benefits_list_title_four: `Easy to upgrade and update`,
  title_four_pt_one: `  The rotor weight handling capacity of IRD® Balancing machines can be
  easily upgraded by doubling up pedestals and/or suspensions modules with
  no changes to the base or foundation. This is not possible with hard
  bearing machines because uprating the capacity also requires uprating
  the entire balancing machine system, including the bed and foundations.`,
  title_four_pt_two: `  IRD components can also be used for updating and uprating existing
  competitor soft and hard bearing machines, or as kits to facilitate
  customers manufacturing their own machines.`,
  benefits_list_title_five: `Soft-Bearing Work Support Design`,
  title_five_pt_one: `  IRD's balancing machine utilizes a pendulum like design for the roller
  work supports and suspension system (modular work supports). We believe
  our design is more durable and can handle more aggressive loading of
  rotors. The self-aligning properties of the standard IRD® roller bearing
  work supports remove the need for prolonged and precise alignment and
  leveling procedures.`,
  benefits_list_title_six: ` IRD ® Balancing's unique system can achieve the most exact balance
  tolerances at lower and safer balancing speeds`,
  title_six_pt_one: `The best balancing speed is the lowest speed possible based on the
  balancing machine sensitivity and the balancing instrument.`,
  title_six_pt_two: `Low speed balancing is safer`,
  title_six_pt_three: ` Low speed balancing is faster due to reduced start and stop time of the
  rotor.`,
  title_six_pt_four: ` Tests have shown that IRD® machines can achieve specified balance
  tolerances at speeds as low as 250 rpm, whereas rotors in hard-bearing
  machines may need to be spun up to at least 800 rpm to meet the same
  quality standards.`,
  title_six_pt_five: `The inertia of the unbalanced rotor is absorbed by the unique pendulum
  action of the IRD suspensions. This significantly reduces the likelihood
  of the rotors coming off the rollers during testing.`,
  benefits_list_title_seven: `Regenerative motor and braking system`,
  title_seven_pt_one: ` Regenerative control gives a motor the capability to act as a generator
  while in operation. Energy is regenerated during the braking process
  (regenerative braking) and the output of energy is supplied to an
  electrical load. Regenerative DC drives are often called "regen drives"
  for short. In its basic form, a regenerative braking system will stop
  the rotor in the same amount of time that it takes to bring the rotor up
  to balancing speed. The energy, material, and time savings make it well
  worth the investment in many industrial facilities.`,
  benefits_list_title_eight: `End Thrust Assembly`,
  title_eight_pt_one: ` IRD's soft bearing balancing machines come with an end thrust assembly
  kit. This assembly will prevent rotors from walking off either end of
  the balancing machine, whereas some competitors will require a step next
  to the journal as a means to control end thrust. This greatly limits the
  types of rotors that can be balanced on competitor's machines.`,
};

export const LEXSECO_ADVANTAGE_PAGE = {
  page_header_text: `Lexseco Advantage`,
  intro_para: `In the 1970's, LEXSECO®'s founding engineers recognized that no
  quick and efficient method existed for standardized motor core
  testing. They spent several years developing the first commercial
  core loss tester and test database. Now the Industry Standard
  since 1982, LEXSECO® has the knowledge and experience as the
  originator of Commercial Core Loss Testing technology. LEXSECO®
  Core Loss Testers are used around the world by motor repair
  companies and maintenance shops of motor users such as railroads,
  armed forces and utilities. Motor manufactures also use our
  testers for quality control during their production processes.
  Check out a video of the Core Loss Tester in action below!`,
  advantage_title_one: `LEXSECO® Makes Core Loss Testing Easy`,
  advantage_one_para: `  Testing occurs with the winding in place or removed. Winding
  condition, lamination grade and thickness do not significantly
  affect test results. Core dimensions and meter readings are fed into
  the MP 7TM software which calculates watts/lb (kg) of core loss.
  With parameters for "bad", "marginal" and "good" cores, the MP 7TM
  generates a detailed core condition report. Localized damage is
  found by increasing the excitation level to reveal hot spots within
  the core. The entire test takes only about 10 minutes!`,
  advantage_title_two: `LEXSECO® Means Quality Equipment`,
  advantage_two_para: `      The LEXSECO® Core Loss Tester provides high current at low voltage
  with continuously variable output to simulate operating conditions
  of the core. Housed in rugged steel cabinets, our testers are
  incorporated with surge and overload protection. Every LEXSECO®
  component meets rigid quality standards. Our patented Variable
  Reactance Transformer is inherently current limiting and will not
  allow the tester to source more than the rated current. With
  machines in service over two decades, we have never experienced a
  transformer failure, a remarkable quality testament. When purchased
  with our Cover Plan, the LEXSECO® Core Loss Tester comes with a
  3-Year Warranty.`,
  advantage_title_three: `Simple & Efficient to Operate`,
  advantage_three_para: `The LEXSECO® Core Loss Testers have a fully automated, auto-ranging
  metering system. One button is all that is needed to ramp the system
  up or down. This simple and intuitive operation makes training a
  breeze.`,
  advantage_title_four: `LEXSECO® Flux Meters Provide the Highest Metering Accuracy on the
  Market`,
  advantage_four_para: `  The metering system is a key component in most industrial test
  equipment and should be specifically designed to address the
  application of the particular equipment. The LEXSECO® Flux Metering
  systems are a result of decades of research in the field of core
  loss testing and advancements in metering technology. The system
  measures flux, true RMS current, and watts at the highest accuracy
  level available on the market today. The system was designed
  specifically for core loss testing. It provides the most accurate
  metering available for testing a wide array of HP and frame
  characteristics. The accuracy specifications are presented as a
  percentage of the actual meter reading. The system is certified
  traceable to the requirements of the National Institute of Standards
  and Technology (NIST). One of the terms listed in the NIST
  specification requires additional explanation. The "% of reading" or
  "% of rdg" is a very significant term when used in conjunction with
  instrumentation specifications. It indicates how far the meter's
  reported reading can vary as a percentage of the reported reading
  from the actual. The "% of rdg" must be compared to a more standard
  term "% of full scale" or "% of FS" in order to fully understand its
  significance. The term "% of FS" indicates how far the meter's
  reported reading can vary as a percentage of the largest reading the
  meter can display from the actual. Consider the following example of
  two watt meters (both meters have a five digit display and full
  scale = 10,000 watts) displaying 180 watts when testing a core:
  Other Meters - (with an accuracy specification of ±0.5% FS) The
  reading of 180W might actually be between 130 and 230W, a range of
  100 watts LEXSECO®'s Flux Meter - (with an accuracy specification of
  ±0.25% RDG ±0.2) The reading of 180W might actually be between
  179.35 and 180.65W, a range of only 1.3 watts. Wattage is the most
  difficult parameter to represent accurately of all metering issues
  associated with the Core Loss Tester. It is derived from voltage,
  current and their observed phase relationship. The example above
  illustrates how one specification can greatly affect your confidence
  in the reported results.`,
  advantage_title_five: `Our Flux Meter Exchange Program Ensures that You'll Always be Ready
  to Test`,
  advantage_five_para: `   Calibration is easily addressed with our flux meter exchange
  program. The exchange metering system comes complete with a
  traceable Certificate of Compliance certified in conformity with the
  National Institute of Standards and Technology (NIST). The Flux
  Metering System is a self-enclosed modular component, which provides
  for a quick removal and installation process. This program will help
  you conform to ISO 9001, EASA-Q and other program standards that
  require regular and traceable calibration while maintaining your
  core loss testing with little or no interruption. Learn more about
  our Flux Meter Exchange Program.`,
  advantage_title_six: `Fully Automated Core Loss Testers`,
  advantage_six_para: `   Our automated testers are computer controlled systems that will
  reduce testing time and increase accuracy while allowing for the
  highest level of testing flexibility with an any-point manual
  over-ride. These testers integrate with our MP 7TM software to
  automatically excite the core to target levels, produce test results
  and then automatically ramp the power back down with a touch of a
  button. Hot Spot tests are automatically timed to prevent
  overheating of the core.`,
  advantage_title_seven: `Safety & Reliability Matter`,
  advantage_seven_para: `     Here at LEXSECO®, the safety and satisfaction of the customer are of
  utmost importance. That is why we implement the following into our
  Core Loss Testers: Current Limiting Power Source Safety fuses
  Over-temperature protection Surge suppressors Circuit breakers
  provide maximum protection to the hardware and the operator
  Inherently current limiting - no over current failures Test Cables
  with Quick Connecting Fittings Phenolic covers for safety Cables
  rated at 2,000 amps maximum per 5 minute duty cycle Variable
  Reactance Transformer (VRT) Sophisticated, patented solid state
  controls Provides ease of control over output`,
  advantage_title_eight: `LEXSECO® Software is the Standard`,
  advantage_eight_para: `    The first standardized core loss parameters LEXSECO® developed
  represented an appropriate average of the existing base of motors
  found in the market. LEXSECO® realized, however, that acceptable
  losses vary with core configuration. To achieve greater accuracy,
  separate parameters should be determined for each frame type and
  efficiency type. This recognition lead to the development of the
  LEXSECO®'s unique "Multiparameter," or MP 7TM software.
  Exceptionally versatile and user friendly, MP 7TM allows
  differentiation between NEMA, pre-NEMA, U, T and IEC frames and
  standard to high efficiencies. Users may also build their own
  databases with special parameters for the particular equipment they
  service or manufacture. The MP 7TM's Winding Verification program
  and an array of mechanical and electrical testing fields make our
  tester a powerful repair documentation center.`,
  advantage_title_nine: `Equipment Upgrade Programs`,
  advantage_nine_para: `Our upgrade program proves that buying a LEXSECO® Core Loss Tester
  is a wise investment. Recognizing that the LEXSECO® testers are
  built to last, we have created packages to bring earlier generations
  of the equipment to the latest standards. A LEXSECO® Core Loss
  Tester is an investment that pays dividends for many years.
  Continuing R&D, largely conducted in our affiliated EASA member
  motor service company, produces regular advances that keep LEXSECO®
  users current with cutting edge technology. Some of LEXSECO®'s
  earliest clients have been able to upgrade their 1980 vintage
  testers to have the latest metering, hardware and software! Learn
  more about our Upgrade Program! When you buy LEXSECO®, you gain
  access to our staff of trained test engineers who ensure you get the
  most from your LEXSECO® tester. Our engineers consult daily with
  shop personnel around the world, giving them the benefit of our many
  years of testing knowledge and experience. We support our products
  after the purchase, which is one reason LEXSECO® clients come back
  again and again. The knowledge that comes from originating a test
  equipment concept - an unparalleled quality record - Multiparameter
  software with regular upgrades - after purchase product support.
  These are the reasons LEXSECO® sets the standard.`,
};

export const NEW_BALANCE_MACHINE_FORM = {
  intro: `The purpose of this survey is to allow our engineers to better understand your present and future applications and provide an equipment recommendation to meet your balancing needs.
  There is no cost or obligation for this service and all information will be kept strictly confidential.
  Please fill out all pages of this requirements form and either save or scan it, then email it back to us at sales@IRDproducts.com. You may also fax it to us at 1-502-238-1001.`,
  safety_notice: `Safety guards, enclosures or perimeter fences are required to meet relevant safety regulations. It is the buyer’s responsibility to insure that the Balancing Machine has an adequate safety protection system before operating the machine.`,
};

export const TOP_MENU_LINKS = [
  {
    link_one: '/ird-products',
    link_one_text: 'IRD Products',
    link_two: '/balancing-instruments',
    link_two_text: 'Balancing Instruments',
    link_three: '/soft-bearing-balance-machines',
    link_three_text: 'Soft Bearing Balance Machine',
    link_four: '/parts-and-accessories',
    link_four_text: 'Parts & Accessories',
    link_five: '/sensors',
    link_five_text: 'Sensors',
    link_six: '/core-loss-testing',
    link_six_text: 'Core Loss Testing',
  },
];

export const IRD_BALANCING_PAGE = {
  intro_para: `Welcome to the IRD® Balancing home page.  With over 70 years of experience, IRD® Balancing offers products and services designed to meet all of your soft-bearing balancing needs.  We can accommodate a range of rotor weights from a fraction of a kilogram to over 225,000 kilograms (0.022 to 500,000 pounds).  Our knowledge of precision balancing allows us to recommend the best Balancing Machine and Instrumentation solution for your specific application.
  Currently, IRD® does not focus on hard-bearing balancing technology.  We spend all of our time focusing on producing the best soft-bearing balancing technology on the market.  However a few of our instruments are compatible with hard-bearing machines. What's the Difference?  Click the button below to find out!
  A global network of service, support, application expertise, and training programs make us well equipped to deliver a top of the line portable balancer when you need it.`,
  benefits_of_bal_list_item_one: `Minimize Vibration`,
  benefits_of_bal_list_item_two: `Minimize Noise`,
  benefits_of_bal_list_item_three: `Minimize Structural Stress`,
  benefits_of_bal_list_item_four: `Minimize Operator Fatigue & Annoyance`,
  benefits_of_bal_list_item_five: `Increase Machine Life`,
  benefits_of_bal_list_item_six: `Increase Bearing Life`,
  benefits_of_bal_list_item_seven: `Increase Product Quality`,
  benefits_of_bal_list_item_eight: `Increase Personnel Safety`,
  benefits_of_bal_list_item_nine: `Increase Productivity`,
  benefits_of_bal_list_item_ten: `Reduce Power Losses`,
  benefits_of_bal_list_item_eleven: `Lower Operating Costs`,
  model_two_nine_five_caption_line_one: `IRD®'s Newest Model: 295+`,
  model_two_nine_five_caption_line_two: `Delivers Accurate Results for Faster Balancing!`,
  model_two_nine_five_caption_line_three: `Check out a Video Demonstration of a 295 below!`,
};

export const ABOUT_US_PAGE = {
  about_us_para_one: `IRD®, or Industrial Research & Development, LLC specializes in machinery
  that assists in the repair of industrial equipment. Currently, IRD® LLC
  builds and sells two separate brands of equipment. IRD® Balancing
  equipment allows customers to balance rotors of a wide range of sizes via
  soft-bearing technology which is more versatile and safer than
  hard-bearing technology. Our knowledge and technology span back to 1952
  when IRD Mechanalysis produced the first portable electronic instrument
  for measuring and analyzing rotating machinery vibration and allowed for
  dynamic balancing of rotors in-place. Since then, IRD® Balancing
  technology has advanced with the purpose of providing top-of-the-line
  balancing machinery across the globe.`,
  about_us_para_two: `The LEXSECO® founding engineers created the first Core Loss Tester which
  revolutionized the motor repair industry in 1978. From then, the Core Loss
  Testers have advanced to becoming a fully automated system which can test
  flux, amperage, and wattage. As with the balancing equipment, the Core
  Loss Testers have been advanced every step of the way with the end user in
  mind. This also includes our Meter Calibration Exchange program.`,
  about_us_para_three: ` Currently, IRD® LLC headquarters are located in Louisville, Kentucky next
  door to Krauth Electric, a sister company. By working closely with a motor
  repair company, IRD® LLC products get tested by the end user before they
  are available to the customers. This ensures top of the line products.`,
  our_promise_para: `    Here at IRD® LLC, we believe that excellence in workmanship and a high level
  of productivity are the key components to building long term relationships
  with our customers. IRD® has been producing top of the line balancing
  equipment on a world wide scale since the 1960s. LEXSECO® constructed the
  very first Core Loss Tester in 1978. Since then, these products have been
  revolutionizing the testing and repair industry for both balancing equipment
  and engine core testing. As a result of the superior performance and
  reliability of our products, as well as our unmatched services, we are also
  the world's fastest growing and highest margin manufacturer in our industry.`,
  affiliates_text: ` Krauth Electric Company (Industrial Electrical Products)`,
  affiliates_link: `http://www.krauthelectric.com`,
  organizations_text_one: ` EASA (Electrical Apparatus Service Association, USA)`,
  organizations_link_one: `http://www.easa.com`,
  organizations_text_two: `ISO (International Standards Organization)`,
  organizations_link_two: `http://www.iso.org`,
  organizations_text_three: `NIST (National Institute of Standards and Technology)`,
  organizations_link_three: `http://www.nist.gov`,
  organizations_text_four: ` QAS International (International Standards Certification Company)`,
  organizations_link_four: `http://www.gas-international.com`,
  organizations_text_five: `Vibration Institute`,
  organizations_link_five: `http://www.vi-institute.org`,
};

export const SOFT_BEARING_BALANCING_MACHINES_PAGE = {
  soft_bearing_bal_mach_para: `Our soft bearing balancing machines offer a wide range of capabilities. The
  smallest machine has a balance sensitivity of 0.00002 oz-in (0.015 g-mm).
  Our largest machine has a capacity of 280,000 lbs (127,000 kg). Here at
  IRD®, we strive to provide what the customer needs, but we know there are
  always special cases. That's why we even sell parts like modular suspension
  work supports, roller work supports, and kits for user fabrication.

  The best part about a soft-bearing machine is that our instrumentation
  measures vibration displacement to determine the unbalance. In theory, this
  method allows for the rotor to behave as if its rotating in mid-air.
  Additionally, it is easy to adjust the machine to reach a wide range of
  rotor sizes, and the machine is portable due to the fact that no special
  foundations are required!`,
};

export const SENSORS_PAGE = {
  intro_para: `To measure machinery or structural vibration, a transducer is
  needed to convert the mechanical motion into an equivalent
  electrical signal. Each sensor has distinct advantages in certain
  situations and limitations in other, so it is important to select
  the most suited transducer or sensor for your job. Equally,
  important is selecting the appropriate parameter of vibration
  amplitude for measurement. IRD® has a wide range of transducers
  and accelerometers available to help you obtain accurate, reliable
  data, whether your concern is vibration monitoring, analysis, or
  dynamic balancing.`,
};

export const LEXSECO_PRODUCTS_PAGE = {
  lexseco_product_para: ` LEXSECO® Core Loss Testers have been the industry standard since 1982.
  That's why you can trust us to provide you with the top of the line
  technology in Core Loss Testers. If you know what you're looking for, go
  straight to our LEXSECO® Core Loss Testers page which also includes products
  like our armatures and magnetic paper. A returning customer? Core Loss
  Tester armature clamps, spare cables, and magnetic paper can be found at the
  link above.`,
  meter_exchange_program: `Here at IRD® LLC, we understand that not having your equipment for even a
  day can cut profits and productivity. That's why we created the Flux Meter
  Exchange program (click here). Unfortunately, many of the old meters are now
  obsolete, meaning we can only perform limited repairs and those repairs are
  going to take some time. You can set yourself up for the Flux Meter Exchange
  program by Upgrading your Core Loss Tester. The new Core Loss Tester system
  not only comes with a bunch of new components and the eligibility for the
  Flux Meter Exchange program, but additionally comes with the MP 7.0TM
  software upgrade.`,
  core_loss_tester_para: `The LEXSECO® Core Loss Tester provides highly accurate,
  waveform-independent readings by utilizing high resolution flux,
  current, and power measuring devices for the core being examined.
  The Core Loss Tester includes a Variable Reactance Transformer
  (VRT) with solid state controls, a digital metering package,
  computer system, and test cables with quick connecting fittings.
  Safety fuses, over-temperature protection, interlocks, surge
  suppressors, and circuit breakers are present to provide maximum
  protection to the hardware and operator. The Core Loss Tester
  provides an adjustable output current from 0 to 2,000 amperes for
  some models and up to 4,000 for others. The VRT used by LEXSECO®
  provides ease of control and self-limiting current, both of which
  offer tremendous advantages over other sources of power used in
  the industry. Your LEXSECO® Core Loss Tester is designed to be
  used with LEXSECO® MP 7TM software. This highly refined
  combination of software and hardware will ensure accurate results.
  Please refer to the software and computer manuals for more
  information.`,
};

export const SERVICE_PAGE = {
  cover_plan_para: `  Protect your equipment with our COVERPLAN! COVERPLAN is designed to provide
  customers with a comprehensive and cost effective service package to ensure
  correct operation, and a long reliable service life of all equipment. IRD®
  will schedule and carry out routine maintenance and calibration visits and
  will provide priority response to any emergency requests in the event of
  machine breakdown or equipment requiring repair. As part of our service we
  would provide operator training and assist with application specific
  support. Cover will be automatically renewed on each anniversary of the
  start date in order to ensure continuity of the service. (IRD® will advise
  any changes in charge rates before undertaking service visits. The customer
  retains the right to decline renewal or to cancel at any time.) All service
  work is undertaken subject to IRD® standard terms and conditions of service.
  Any spare parts or products provided are subject to IRD standard terms and
  conditions of sale.`,
  service_includes_title_one: `Annual Service & Calibration when Due`,
  title_one_pt_one: `Service & Calibration of IRD® Balancing Machine systems and LEXSECO® Core
  Loss Testers`,
  title_one_pt_two: `Quality service by factory trained technicians with ready access to
  manufacturer's original spare parts`,
  title_one_pt_three: `Automatic reminder of 'calibration due' date`,
  title_one_pt_four: `Calibration traceability to manufacturer's Standard reference and approved
  procedures`,
  title_one_pt_five: `Extended operator training on machine operation and balancing applications`,
  title_one_pt_six: `Assessment of existing capability and condition of equipment with
  recommendations for on-going maintenance and development`,
  service_includes_title_two: `Preferential rates for service & calibration - charges will only be
  payable after the service is completed`,
  title_two_pt_one:
    'Pre-agreed rate will be charged only when service visit is completed',
  title_two_pt_two: 'Discount on spare parts and consumable items used',
  service_includes_title_three: `Extended Warranty`,
  title_three_pt_one:
    'A system commissioned by IRD and serviced under COVERPLAN qualifies for a THREE YEAR WARRANTY at no additional cost',
  service_includes_title_four: ` Priority response to Emergency Call-outs and Repair facility`,
  service_includes_title_five: `Provision of 'on-loan' instrument if we are unable to turn-round an
  instrument repair within 5 working days`,
  title_five_pt_one: '(Subject to availability)',
  service_includes_title_six: `Helpline for technical support and assistance with applications`,
  title_six_pt_one: 'Extended support may be chargeable',
  service_includes_title_seven: `Preferential pricing for updates and upgrades`,
};

export const CORE_EVALUATION_PAGE = {
  intro_para: `LEXSECO® determined that the most important indication of core steel condition is the watts of electrical energy lost per pound (kilogram) of core steel when the core is excited to operating conditions.  Common electrical grades of core steel have inherent Epstein test rated watts per pound (kilogram) losses ranging from 1 to 2 watts per pound (kilogram) depending upon application.  This value is increased by as much as a factor of 1.5 to 2 once the steel is punched and assembled into motor cores.  This increase may be attributed to a combination of the following:`,
  attribute_list_pt_one: `Lamination punching or stamping burrs`,
  attribute_list_pt_two: `Lamination thickness`,
  attribute_list_pt_three: `Lamination clamping pressure`,
  attribute_list_pt_four: `Type of insulation coating used on the steel`,
  attribute_list_pt_five: `Heat treatment process used on punched laminations`,
  attribute_list_pt_six: `Lamination assembly method`,
  attribute_list_pt_seven: `Silicon content and hardness of the steel`,
  attribute_list_pt_eight: `Heavy welds across the back of the core stacking`,
  para_two: `Watts per pound (kilogram) core losses may be divided into hysteresis and eddy current losses.  Hysteresis loss results from the alternating frequency reversals and shifting molecules to adjust polarity and is dissipated in the form of heat.  Hysteresis loss does not change with varying steel thicknesses.  Eddy current loss is the cross current flow found in the lamination assembly.  Unlike hysteresis loss, eddy current loss increases proportionally with steel thickness.  Like hysteresis loss, eddy current loss is dissipated in the form of heat.`,
  para_three: `The LEXSECO® Core Loss Tester relies principally upon the watts per pound (kilogram) criterion to determine core loss.  In addition, the software records the ampere turns per inch (centimeter) (AT/in or AT/cm), which is a measurement of the magnetizing power required to produce a certain flux density in the iron laminations.  Similar to the watts per pound (kilogram) calculation, the lower the ampere turns per inch, the more efficient the core.`,
  para_four: `The LEXSECO® software interprets the meter readings, makes a judgment regarding overall core condition (OK, MARGINAL, or BAD), and produces a report reflecting the core condition judgment along with recommendations for further action including "hot spot testing."`,
  hot_spot_testing_para: `Hot spot testing verifies the watts per pound (kilogram) reading.  Upon completion of the metering portion of the core loss test, the computer software instructs the operator to apply a higher excitation current.  After approximately 1 minute, imperfections and problems with the core should become apparent through a simple temperature check of core surface.  Note:  It is recommended to use a device to measure the temperature of the core to avoid chance of burn injury.`,
};

export const LEGACY_PRODUCTS_PAGE = {
  intro_para: `Please use the table below to find documentation on discontinued, legacy products.`,
};

export const LEXSECO_CLT_UPGRADES_PAGE = {
  intro_title: `Why Should You Upgrade?`,
  intro_para: `With the Lexseco Core Loss Tester Upgrade Package, you'll get the latest electronics and software available at half the cost of a new machine. We've made many improvements to our CLTs since their inception in the late 1970s. Every change has been made with the goal of improved operational efficiency and better service. The rheostat has been eliminated, and a new meter system has been incorporated, which allows for fully automated core loss tests and hot spot checks. See the full breakdown of upgraded features you can be taking advantage of below!`,
  latest_software_title: `The Latest Software`,
  latest_software_para: `Simplify core loss and hot spot testing with MP 7's clear, graphic-driven instructions to intuitively walk you through proper data entry. Together with a database of motors in the marketplace that define the pass/fail test of the motor's efficiency, reliable test results have never been more attainable.`,
  enhanced_accuracy_title: `Enhanced Accuracy`,
  enhanced_accuracy_para: `Our new “E” meters automatically hit the target flux level. With a measurement accuracy of ± 0.1%, Lexseco's CLTs provide the most accurate back-iron flux-density in the industry. They're also eligible for our Zero-Downtime Meter Exchange Program: we'll send you a fully calibrated meter in exchange for your used one. Click to find out more.`,
  zero_downtime_link: `/meter-exchange`,
  upgraded_features: `Upgraded Features`,
  feature_one: `MP 7 software pre-installed`,
  feature_two: `New desktop with laptop and printer`,
  feature_three: `New E-meter & enrollment into our Meter Exchange Program`,
  feature_four: `Complete rewire of system`,
  feature_five: `New main cables & flux meter cables`,
  feature_six: `New control & current transformers`,
  feature_seven: `NIST compliant & certified calibration`,
  feature_eight: `Complete evaluation & immediate quotation of all components*`,
  feature_nine: `Premium cabinet paint job*`,
  feature_ten: `1 Year Standard Warranty**`,
  disclaimer_one: `*Only available on factory upgrades`,
  disclaimer_two: `*Warranty only covers components listed`,
};
