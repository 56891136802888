/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table';
import './table.css';

import searchIcon from '../../assets/icons/search.svg';

import {
  SENSORS_VELOCITY_TRANSDUCERS_COLUMN,
  SENSORS_ACCELEROMETERS_COLUMN,
  SENSORS_SPEED_SENSORS_COLUMN,
  SENSORS_PARTS_AND_ACCESSORIES_COLUMN,
  PARTS_AND_ACCESSORIES_PAGE_CABLES_COLUMN,
  PARTS_AND_ACCESSORIES_PAGE_SUPPLIES_COLUMN,
  PARTS_AND_ACCESSORIES_PAGE_BELTS_COLUMN,
  PARTS_AND_ACCESSORIES_PAGE_ROLLERS_COLUMN,
  CORE_LOSS_TESTER_PAGE_CABLE_COLUMN,
  LEGACY_PRODUCTS_COLUMN,
} from './Columns';

import {
  SENSORS_VELOCITY_TRANSDUCERS_MODEL,
  SENSORS_ACCELEROMETERS_MODEL,
  SENSORS_SPEED_SENSORS_MODEL,
  SENSORS_PARTS_AND_ACCESSORIES_MODEL,
  PARTS_AND_ACCESSORIES_PAGE_CABLES_OPTIONS,
  PARTS_AND_ACCESSORIES_PAGE_SUPPLIES_OPTIONS,
  PARTS_AND_ACCESSORIES_PAGE_BELTS_OPTIONS,
  PARTS_AND_ACCESSORIES_PAGE_ROLLERS_OPTIONS,
  CORE_LOSS_TESTER_PAGE_CABLE_OPTIONS,
  LEGACY_PRODUCTS_OPTIONS,
} from '../../data/TABLE_DATA';

export default function TableComponent() {
  const { table_view } = useSelector((state) => state.controls);
  const sensorsVelocityTransducersColumn = useMemo(
    () => SENSORS_VELOCITY_TRANSDUCERS_COLUMN,
    []
  );
  const sensorsVelocityTransducersData = useMemo(
    () => SENSORS_VELOCITY_TRANSDUCERS_MODEL,
    []
  );
  const sensorsAccelerometersColumn = useMemo(
    () => SENSORS_ACCELEROMETERS_COLUMN,
    []
  );
  const sensorsAccelerometersData = useMemo(
    () => SENSORS_ACCELEROMETERS_MODEL,
    []
  );
  const sensorsSpeedSensorsColumn = useMemo(
    () => SENSORS_SPEED_SENSORS_COLUMN,
    []
  );
  const sensorsSpeedSensorsData = useMemo(
    () => SENSORS_SPEED_SENSORS_MODEL,
    []
  );
  const sensorsPartsAndAccessoriesColumn = useMemo(
    () => SENSORS_PARTS_AND_ACCESSORIES_COLUMN,
    []
  );
  const sensorsPartsAndAccessoriesData = useMemo(
    () => SENSORS_PARTS_AND_ACCESSORIES_MODEL,
    []
  );

  const partsAndAccessoriesPageCableColumn = useMemo(
    () => PARTS_AND_ACCESSORIES_PAGE_CABLES_COLUMN,
    []
  );

  const partsAndAccessoriesPageCableData = useMemo(
    () => PARTS_AND_ACCESSORIES_PAGE_CABLES_OPTIONS,
    []
  );
  const partsAndAccessoriesPageSuppliesColumn = useMemo(
    () => PARTS_AND_ACCESSORIES_PAGE_SUPPLIES_COLUMN,
    []
  );
  const partsAndAccessoriesPageSuppliesData = useMemo(
    () => PARTS_AND_ACCESSORIES_PAGE_SUPPLIES_OPTIONS,
    []
  );
  const partsAndAccessoriesPageBeltsColumn = useMemo(
    () => PARTS_AND_ACCESSORIES_PAGE_BELTS_COLUMN,
    []
  );
  const partsAndAccessoriesPageBeltsData = useMemo(
    () => PARTS_AND_ACCESSORIES_PAGE_BELTS_OPTIONS,
    []
  );
  const partsAndAccessoriesPageRollersColumn = useMemo(
    () => PARTS_AND_ACCESSORIES_PAGE_ROLLERS_COLUMN,
    []
  );
  const partsAndAccessoriesPageRollersData = useMemo(
    () => PARTS_AND_ACCESSORIES_PAGE_ROLLERS_OPTIONS,
    []
  );
  const coreLossTesterPageCableColumn = useMemo(
    () => CORE_LOSS_TESTER_PAGE_CABLE_COLUMN,
    []
  );
  const coreLossTesterPageCableData = useMemo(
    () => CORE_LOSS_TESTER_PAGE_CABLE_OPTIONS,
    []
  );
  const legacyPageColumn = useMemo(() => LEGACY_PRODUCTS_COLUMN, []);
  const legacyPageData = useMemo(() => LEGACY_PRODUCTS_OPTIONS, []);
  const [columns, setColumns] = useState(sensorsVelocityTransducersColumn);
  const [data, setData] = useState(sensorsVelocityTransducersData);
  const [searchOpen, setSearchOpen] = useState(false);

  useEffect(() => {
    if (table_view === 'sensors-page-velocity-transducers') {
      setColumns(sensorsVelocityTransducersColumn);
      setData(sensorsVelocityTransducersData);
    } else if (table_view === 'sensors-page-accelerometers') {
      setColumns(sensorsAccelerometersColumn);
      setData(sensorsAccelerometersData);
    } else if (table_view === 'sensors-page-speed-sensors') {
      setColumns(sensorsSpeedSensorsColumn);
      setData(sensorsSpeedSensorsData);
    } else if (table_view === 'sensors-page-parts-and-accessories') {
      setColumns(sensorsPartsAndAccessoriesColumn);
      setData(sensorsPartsAndAccessoriesData);
    } else if (table_view === 'parts-and-accessories-page-cables') {
      setColumns(partsAndAccessoriesPageCableColumn);
      setData(partsAndAccessoriesPageCableData);
    } else if (table_view === 'parts-and-accessories-page-supplies') {
      setColumns(partsAndAccessoriesPageSuppliesColumn);
      setData(partsAndAccessoriesPageSuppliesData);
    } else if (table_view === 'parts-and-accessories-page-belts') {
      setColumns(partsAndAccessoriesPageBeltsColumn);
      setData(partsAndAccessoriesPageBeltsData);
    } else if (table_view === 'parts-and-accessories-page-rollers') {
      setColumns(partsAndAccessoriesPageRollersColumn);
      setData(partsAndAccessoriesPageRollersData);
    } else if (table_view === 'core-loss-tester-page-cables') {
      setColumns(coreLossTesterPageCableColumn);
      setData(coreLossTesterPageCableData);
    } else if (table_view === 'legacy-products') {
      setColumns(legacyPageColumn);
      setData(legacyPageData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table_view]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageSize, pageIndex },
    state,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter } = state;
  let startingPageIdx = pageSize * pageIndex;

  const searchFilter = (
    <input
      id="table-search"
      placeholder="Search"
      value={globalFilter || ''}
      onChange={(event) => setGlobalFilter(event.target.value)}
    />
  );

  return (
    <div className="table-container">
      <div id="table-options" className="table-options-wrapper">
        <div className="pagination-section">
          <span className="page-display">
            {startingPageIdx + 1}-{startingPageIdx + page.length} of{' '}
            {rows.length}
          </span>
          <button
            className="gen-prev-button table-button"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {'<'}
          </button>
          <button
            className="gen-next-button table-button"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {'>'}
          </button>
        </div>

        <div className="search-section">
          {searchOpen ? searchFilter : ''}
          <div
            className="table-search-icon-wrapper"
            onClick={() => {
              setSearchOpen(!searchOpen);
              setGlobalFilter('');
            }}
          >
            <img
              src={searchIcon}
              alt="search"
              id="table-search-icon"
              className="table-search-icon"
            />
          </div>
        </div>
      </div>
      <Table {...getTableProps()} responsive="sm">
        <thead>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderProps } =
              headerGroup.getHeaderGroupProps();

            return (
              <tr key={key} {...restHeaderProps} className="table-header-row">
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps({
                    style: { minWidth: column.minWidth, width: column.width },
                  });

                  return (
                    <th key={key} {...restColumnProps} className="table-header">
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="table-row">
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className="table-cell">
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
