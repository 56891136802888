import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowMenu } from '../redux/controls';
import SideMenu from '../components/SideMenu/SideMenu';
import Footer from '../components/Footer/Footer';
import '../styles/aboutPageStyle.css';
import '../styles/standardPageStyle.css';

import { ABOUT_US_PAGE as content } from '../data/PAGE_TEXT_CONTENT';

import certification from '../assets/images/certification.jpg';

const Affiliates = () => (
  <ul className="stnd-ul">
    <li>
      <a
        href={content.affiliates_link}
        rel="noreferrer"
        target="_blank"
        className="stnd-link-text">
        {content.affiliates_text}
      </a>
    </li>
  </ul>
);

const Organizations = () => (
  <ul className="stnd-ul">
    <li>
      <a
        href={content.organizations_link_one}
        rel="noreferrer"
        target="_blank"
        className="stnd-link-text">
        {content.organizations_text_one}
      </a>
    </li>

    <li>
      <a
        href={content.organizations_link_two}
        rel="noreferrer"
        target="_blank"
        className="stnd-link-text">
        {content.organizations_text_two}
      </a>
    </li>
    <li>
      <a
        href={content.organizations_link_three}
        rel="noreferrer"
        target="_blank"
        className="stnd-link-text">
        {content.organizations_text_three}
      </a>
    </li>
    <li>
      <a
        href={content.organizations_link_four}
        rel="noreferrer"
        target="_blank"
        className="stnd-link-text">
        {content.organizations_text_four}
      </a>
    </li>
    <li>
      <a
        href={content.organizations_link_five}
        rel="noreferrer"
        target="_blank"
        className="stnd-link-text">
        {content.organizations_text_five}
      </a>
    </li>
  </ul>
);

export default function About() {
  const { has_paid } = useSelector((state) => state.controls);
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  return (
    <div className="about-page-container">
      {has_paid && <SideMenu />}
      <div className="about-page-header">
        <h1 className="about-page-header-text">About Us</h1>
      </div>
      <div className="about-page-content" onClick={toggleMenu}>
        <div className="about-page-left">
          <p className="stnd-text">{content.about_us_para_one}</p>
          <p className="stnd-text">{content.about_us_para_two}</p>
          <p className="stnd-text">{content.about_us_para_three}</p>
          <h2 className="stnd-h2">Our Promise</h2>
          <p>{content.our_promise_para}</p>

          <h2 className="stnd-h2">Affiliates</h2>
          <Affiliates />
          <h2 className="stnd-h2">Organizations</h2>
          <Organizations />
        </div>
        <div className="about-page-right">
          <div className="about-page-image-container">
            <img
              src={certification}
              alt="certification"
              className="about-page-image"
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
