import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowMenu } from '../redux/controls';
import TopMenu from '../components/TopMenu/TopMenu';
import SideMenu from '../components/SideMenu/SideMenu';
import AccordionComponent from '../components/AccordionComponent/AccordionComponent';
import ImageButton from '../components/ImageButton/ImageButton';
import Footer from '../components/Footer/Footer';
import '../styles/standardPageStyle.css';
import '../styles/miniComponents.css';

import { IRD_BALANCING_PRODUCTS_PAGE as content } from '../data/PAGE_TEXT_CONTENT';

import b750 from '../assets/images/b750_transparent.png';
import instrument295 from '../assets/images/295-instrument.png';

export default function IrdBalancingProducts() {
  const { has_paid } = useSelector((state) => state.controls);
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  const BenefitsOfBalancing = () => (
    <div className="mini-component-container">
      <span className="stnd-text">{content.benefits_para}</span>
      <ul className="mini-ul">
        <li className="mini-li-title">{content.benefits_list_title_one}</li>
        <li className="mini-li-text li-indent">{content.title_one_pt_one}</li>
        <li className="mini-li-text li-indent">{content.title_one_pt_two}</li>
        <li className="mini-li-text li-indent">{content.title_one_pt_three}</li>
        <li className="mini-li-text li-indent">{content.title_one_pt_four}</li>

        <li className="mini-li-title">{content.benefits_list_title_two}</li>

        <li className="mini-li-title">{content.benefits_list_title_three}</li>

        <li className="mini-li-title">{content.benefits_list_title_four}</li>
        <li className="mini-li-text li-indent">{content.title_four_pt_one}</li>
        <li className="mini-li-text li-indent">{content.title_four_pt_two}</li>

        <li className="mini-li-title">{content.benefits_list_title_five}</li>
        <li className="mini-li-text li-indent">{content.title_five_pt_one}</li>

        <li className="mini-li-title">{content.benefits_list_title_six}</li>
        <li className="mini-li-text li-indent">{content.title_six_pt_one}</li>
        <li className="mini-li-text li-indent">{content.title_six_pt_two}</li>
        <li className="mini-li-text li-indent">{content.title_six_pt_three}</li>
        <li className="mini-li-text li-indent">{content.title_six_pt_four}</li>
        <li className="mini-li-text li-indent">{content.title_six_pt_five}</li>

        <li className="mini-li-title">{content.benefits_list_title_seven}</li>
        <li className="mini-li-text li-indent">{content.title_seven_pt_one}</li>

        <li className="mini-li-title">{content.benefits_list_title_eight}</li>
        <li className="mini-li-text li-indent">{content.title_eight_pt_one}</li>
      </ul>
    </div>
  );

  return (
    <main className="container-fluid stnd-container">
      {has_paid && <SideMenu />}
      {has_paid && <TopMenu />}
      <div className="stnd-header">
        <h1 className="stnd-h1 margin-left-two-percent">
          {content.page_header_text}
        </h1>
      </div>
      <div className="stnd-content" onClick={toggleMenu}>
        <div className="stnd-col-start stnd-width-eighty-percent">
          <p className="stnd-text">{content.intro_para_one}</p>
          <p className="stnd-text">{content.intro_para_two}</p>

          <br />
          <h2 className="stnd-h2">{content.page_sub_header_text}</h2>
          <p className="stnd-text">{content.first_time_para}</p>
        </div>
        <h2 className="stnd-h2">{content.ird_advantage}</h2>

        <div className="image-button-wrapper">
          <a
            href="/balancing-instruments"
            className="ird-products-page-img-link">
            <ImageButton
              image={instrument295}
              text="Choose Your Balancing Instrument"
            />
          </a>

          <a
            href="/soft-bearing-balance-machines"
            className="ird-products-page-img-link">
            <ImageButton image={b750} text="Choose Your Balancing Machine" />
          </a>
        </div>

        <div className="accordion-width-wrapper-eighty-percent margin-top-bottom-thirty">
          <AccordionComponent
            defaultActiveKey={null}
            titleOne={content.benefits_of_ird}
            contentOne={<BenefitsOfBalancing />}
          />
        </div>
      </div>

      <Footer />
    </main>
  );
}
