import React from 'react';
import { Carousel } from 'react-bootstrap';
import './carouselComponent.css';

export default function CarouselComponent({
  imgOne,
  imgTwo,
  imgThree,
  imgFour,
  imgFive,
}) {
  return (
    <Carousel className="carousel-container">
      <Carousel.Item>
        <img className="d-block w-100" src={imgOne} alt="First slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={imgTwo} alt="Second slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={imgThree} alt="Third slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={imgFour} alt="Fourth slide" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={imgFive} alt="Fifth slide" />
      </Carousel.Item>
    </Carousel>
  );
}
