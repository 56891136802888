import React from 'react';
import './imageButton.css';

export default function ImageButton({ image, text }) {
  return (
    <div className="image-button-container">
      <img src={image} alt="machine" className="image-button-image" />
      <span className="image-button-text">{text}</span>
    </div>
  );
}
