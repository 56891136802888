import { createSlice } from '@reduxjs/toolkit';

export const controlsSlice = createSlice({
  name: 'controls',
  initialState: {
    featured_product: null,
    product_docs: null,
    table_view: null,
    job_postings: null,
    new_balance_machine_form_data: null,
    customer_util_videos: null,
    ird_videos: null,
    contact_type: null,
    received_contact: null,
    rma_requests: null,
    extended_surveys: null,
    feedback_surveys: null,
    shop_ird_surveys: null,
    product_service_reminders: null,
    show_menu: false,
    has_paid: null,
  },
  reducers: {
    setFeaturedProduct: (state, action) => {
      state.featured_product = action.payload;
    },
    setProductDocs: (state, action) => {
      state.product_docs = action.payload;
    },
    setTableView: (state, action) => {
      state.table_view = action.payload;
    },
    setJobPostings: (state, action) => {
      state.job_postings = action.payload;
    },
    setNewBalanceMachineFormData: (state, action) => {
      state.new_balance_machine_form_data = action.payload;
    },
    setCustomerUtilVideos: (state, action) => {
      state.customer_util_videos = action.payload;
    },
    setIrdVideos: (state, action) => {
      state.ird_videos = action.payload;
    },
    setContactType: (state, action) => {
      state.contact_type = action.payload;
    },
    setRmaRequests: (state, action) => {
      state.rma_requests = action.payload;
    },
    setExtendedSurveys: (state, action) => {
      state.extended_surveys = action.payload;
    },
    setFeedbackSurveys: (state, action) => {
      state.feedback_surveys = action.payload;
    },
    setShopIrdSurverys: (state, action) => {
      state.shop_ird_surveys = action.payload;
    },
    setProductServiceReminders: (state, action) => {
      state.product_service_reminders = action.payload;
    },
    // setContactForm: (state, action) => {
    //   state.contact_form = action.payload;
    // },
    // setContactFormErrors: (state, action) => {
    //   state.contact_form_errors = action.payload;
    // },
    setShowMenu: (state, action) => {
      state.show_menu = action.payload;
    },
    setHasPaid: (state, action) => {
      state.has_paid = action.payload;
    },
  },
});

export const {
  setFeaturedProduct,
  setProductDocs,
  setTableView,
  setJobPostings,
  setNewBalanceMachineFormData,
  setCustomerUtilVideos,
  setIrdVideos,
  setContactType,
  setRmaRequests,
  setExtendedSurveys,
  setFeedbackSurveys,
  setShopIrdSurverys,
  // setContactForm,
  // setContactFormErrors,
  setProductServiceReminders,
  setShowMenu,
  setHasPaid,
} = controlsSlice.actions;

export default controlsSlice.reducer;
