import model295 from '../assets/images/295-instrument.png';
// import model236 from '../assets/images/model-236.png';
// import model246 from '../assets/images/model-246.png';
// import model258b from '../assets/images/model-258b.png';
import model206 from '../assets/images/model-206.png';
// import model258v from '../assets/images/model-258v.png';
import docModel295 from '../assets/pdf-docs/balancing-instruments-vibration-meters/model-295.pdf';
// import docModel258 from '../assets/pdf-docs/balancing-instruments-vibration-meters/model-258b.pdf';
import docModel206 from '../assets/pdf-docs/balancing-instruments-vibration-meters/model-206.pdf';

// MODELS THAT ARE COMMENTED OUT HAVE BEEN DISCONTINUED

export const BALANCING_INSTRUMENTS_STAND_USE = [
  {
    model: 'Model 295 Series',
    product_image: model295,
    for_use_with: 'For Use With',
    for_use_with_details: 'soft-bearing balancing machines',
    doc_link: docModel295,
    doc_link_text: '295 Series Data Sheet',
    description: 'Description',
    description_details:
      'enhanced operator tools & reports with an easy to use interface',
    readout: 'Readout',
    readout_details: 'color touch screen',
  },
  // {
  //   model: 'Model 246',
  //   product_image: model246,
  //   for_use_with: 'For Use With',
  //   for_use_with_details: 'soft-bearing balancing machines',
  //   doc_link: '/#',
  //   doc_link_text: '246 Data Sheet',
  //   description: 'Description',
  //   description_details: 'portable design for use in on-site balancing',
  //   readout: 'Readout',
  //   readout_details: 'digital',
  // },
  // {
  //   model: 'Model 236',
  //   product_image: model236,
  //   for_use_with: 'For Use With',
  //   for_use_with_details: 'soft or hard bearing machines',
  //   doc_link: '/#',
  //   doc_link_text: '236 Data Sheet',
  //   description: 'Description',
  //   description_details: 'cost-effective, high performance solution',
  //   readout: 'Readout',
  //   readout_details: 'digital',
  // },
];

export const BALANCING_INSTRUMENTS_FIELD_BALANCING = [
  // {
  //   model: 'Model 246',
  //   product_image: model246,
  //   for_use_with: 'For Use With',
  //   for_use_with_details: 'soft-bearing balancing machines',
  //   doc_link: '/#',
  //   doc_link_text: '246 Data Sheet',
  //   description: 'Description',
  //   description_details: 'portable design for use in on-site balancing',
  //   readout: 'Readout',
  //   readout_details: 'digital',
  // },
  // {
  //   model: 'Model 258B Series II Vibration Analyzer & Balancer Kit',
  //   product_image: model258b,
  //   for_use_with: 'For Use With',
  //   for_use_with_details: 'portable design for on-site use',
  //   doc_link: docModel258,
  //   doc_link_text: '258B Data Sheet',
  //   description: 'Description',
  //   description_details:
  //     'a single instrument for a range of service, maintenance & diagnostic applications',
  //   readout: 'Readout',
  //   readout_details: 'digital',
  // },
];

export const BALANCING_INSTRUMENTS_STAND_USE_AND_FIELD_BALANCING = [
  // {
  //   model: 'Model 246',
  //   product_image: model246,
  //   for_use_with: 'For Use With',
  //   for_use_with_details: 'soft-bearing balancing machines',
  //   doc_link: '/#',
  //   doc_link_text: '246 Data Sheet',
  //   description: 'Description',
  //   description_details: 'portable design for use in on-site balancing',
  //   readout: 'Readout',
  //   readout_details: 'digital',
  // },
];

export const VIBRATION_METERS = [
  {
    model: '206 Vibration Meter',
    product_image: model206,
    for_use_with: 'For Use With',
    for_use_with_details:
      'follows the 10 - 1,000 Hz overall RMS velocity range recommended by ISO 10816',
    doc_link: docModel206,
    doc_link_text: '206 Series Data Sheet',
    description: 'Description',
    description_details:
      'portable, simple to use meter for measuring overall vibration on rotating machinery',
    readout: 'Readout',
    readout_details: 'color touch screen',
  },
  // {
  //   model: '258V Series II Vibration Analyzer',
  //   product_image: model258v,
  //   for_use_with: 'For Use With',
  //   for_use_with_details: 'options:  1, 2, 3, or tri-axial channel sensors',
  //   doc_link: docModel258,
  //   doc_link_text: '258V Data Sheet',
  //   description: 'Description',
  //   description_details:
  //     'a single instrument for a range of service, maintenance & diagnostic applications',
  //   readout: 'Readout',
  //   readout_details: 'digital',
  // },
];

export const BALANCING_AND_VIBRATION = [
  // {
  //   model: 'Model 258B Series II Vibration Analyzer & Balancer Kit',
  //   product_image: model258b,
  //   for_use_with: 'For Use With',
  //   for_use_with_details: 'portable design for on-site use',
  //   doc_link: docModel258,
  //   doc_link_text: '258B Data Sheet',
  //   description: 'Description',
  //   description_details:
  //     'a single instrument for a range of service, maintenance & diagnostic applications',
  //   readout: 'Readout',
  //   readout_details: 'digital',
  // },
];
