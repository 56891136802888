/* eslint-disable no-unused-vars */
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUserDispatch, setUserAuth } from './redux/user';
import {
  featureProdRef,
  jobPostingsRef,
  appForBalMachRef,
  customerUtilVidRef,
  irdVidRef,
  rmaReqRef,
  extendedSurveysRef,
  feedbackSurverysRef,
  shopIrdSurveyRef,
  productServRemRef,
  hasPaidRef,
} from './firebase';
import { onSnapshot } from 'firebase/firestore';
import {
  setFeaturedProduct,
  setJobPostings,
  setNewBalanceMachineFormData,
  setCustomerUtilVideos,
  setIrdVideos,
  setRmaRequests,
  setExtendedSurveys,
  setFeedbackSurveys,
  setShopIrdSurverys,
  setProductServiceReminders,
  setHasPaid,
} from './redux/controls';
import Nav from './components/Nav';
import OutOfService from './pages/OutOfService';
import './styles/appStyle.css';

function App() {
  const dispatch = useDispatch();
  const currentUser = useAuth();

  const {
    // new_balance_machine_form_data,
    // customer_util_videos,
    // ird_videos,
    has_paid,
    rma_requests,
    extended_surveys,
    feedback_surveys,
    shop_ird_surveys,
    product_service_reminders,
  } = useSelector((state) => state.controls);
  // eslint-disable-next-line no-unused-vars
  const { isAuth } = useSelector((state) => state.user);
  function useAuth() {
    const [currentUser, setCurrentUser] = useState();

    useEffect(() => {
      const unsub = onAuthStateChanged(auth, (user) => setCurrentUser(user));

      return unsub;
    }, []);

    return currentUser;
  }

  const fetchFeaturedProduct = async () => {
    onSnapshot(featureProdRef, (snapshot) => {
      const results = snapshot.docs.map((doc) => {
        return { ...doc.data(), key: doc.id };
      });
      dispatch(setFeaturedProduct(results));
    });
  };

  const fetchJobPostings = async () => {
    onSnapshot(jobPostingsRef, (snapshot) => {
      const results = snapshot.docs.map((doc) => {
        return { ...doc.data(), key: doc.id };
      });
      dispatch(setJobPostings(results));
    });
  };

  const fetchNewBalMachApps = async () => {
    onSnapshot(appForBalMachRef, (snapshot) => {
      const results = snapshot.docs.map((doc) => {
        return { ...doc.data(), key: doc.id };
      });
      dispatch(setNewBalanceMachineFormData(results));
    });
  };

  const fetchCustomerUtilVideos = async () => {
    onSnapshot(customerUtilVidRef, (snapshot) => {
      const results = snapshot.docs.map((doc) => {
        return { ...doc.data(), key: doc.id };
      });
      dispatch(setCustomerUtilVideos(results));
    });
  };

  const fetchIrdVideos = async () => {
    onSnapshot(irdVidRef, (snapshot) => {
      const results = snapshot.docs.map((doc) => {
        return { ...doc.data(), key: doc.id };
      });
      dispatch(setIrdVideos(results));
    });
  };

  const fetchRmaReq = async () => {
    onSnapshot(rmaReqRef, (snapshot) => {
      const results = snapshot.docs.map((doc) => {
        return { ...doc.data(), key: doc.id };
      });
      dispatch(setRmaRequests(results));
    });
  };

  const fetchExtendedSurveys = async () => {
    onSnapshot(extendedSurveysRef, (snapshot) => {
      const results = snapshot.docs.map((doc) => {
        return { ...doc.data(), key: doc.id };
      });
      dispatch(setExtendedSurveys(results));
    });
  };

  const fetchFeedbackSurveys = async () => {
    onSnapshot(feedbackSurverysRef, (snapshot) => {
      const results = snapshot.docs.map((doc) => {
        return { ...doc.data(), key: doc.id };
      });
      dispatch(setFeedbackSurveys(results));
    });
  };

  const fetchShopIrdSurveys = async () => {
    onSnapshot(shopIrdSurveyRef, (snapshot) => {
      const results = snapshot.docs.map((doc) => {
        return { ...doc.data(), key: doc.id };
      });
      dispatch(setShopIrdSurverys(results));
    });
  };

  const fetchProductServiceReminders = async () => {
    onSnapshot(productServRemRef, (snapshot) => {
      const results = snapshot.docs.map((doc) => {
        return { ...doc.data(), key: doc.id };
      });
      // console.log('results', results);
      dispatch(setProductServiceReminders(results));
    });
  };

  const fetchHasPaidVal = async () => {
    onSnapshot(hasPaidRef, (snapshot) => {
      const result = snapshot.docs.map((doc) => {
        return { ...doc.data(), key: doc.id };
      });
      // console.log('result', result);
      if (result.length > 0) {
        dispatch(setHasPaid(result[0].has_paid));
      }
    });
  };

  useEffect(() => {
    fetchFeaturedProduct();
    fetchJobPostings();
    fetchNewBalMachApps();
    fetchCustomerUtilVideos();
    fetchIrdVideos();
    fetchRmaReq();
    fetchExtendedSurveys();
    fetchFeedbackSurveys();
    fetchShopIrdSurveys();
    fetchProductServiceReminders();
    fetchHasPaidVal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentUser) {
      dispatch(setCurrentUserDispatch(currentUser));
      dispatch(setUserAuth(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  // console.log('rma_requests', rma_requests);
  // console.log('extended_surveys', extended_surveys);
  // console.log('feedback_surveys', feedback_surveys);
  // console.log('shop_ird_surveys', shop_ird_surveys);

  return (
    <BrowserRouter>
      <Nav />
    </BrowserRouter>
  );
}

export default App;
