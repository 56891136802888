import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
// import { motion, AnimatePresence } from 'framer-motion';
import './cardComponent.css';

export default function CardComponent({
  largeCard,
  cardImg,
  cardTitle,
  cardText,
  linkOne,
  hrefOne,
  titleOne,
  itemOne,
  itemOneImperVal,
  itemOneImperUnit,
  itemOneMetrVal,
  itemOneMetrUnit,
  linkTwo,
  hrefTwo,
  linkTwoIsText,
  titleTwo,
  itemTwo,
  itemTwoImperVal,
  itemTwoImperUnit,
  itemTwoMetrVal,
  itemTwoMetrUnit,
  linkThree,
  linkThreeIsText,
  hrefThree,
  titleThree,
  itemThree,
  itemThreeImperVal,
  itemThreeImperUnit,
  itemThreeMetrVal,
  itemThreeMetrUnit,
  linkFour,
  linkFourIsText,
  hrefFour,
  titleFour,
  itemFour,
  itemFourImperVal,
  itemFourImperUnit,
  itemFourMetrVal,
  itemFourMetrUnit,
  linkFive,
  linkFiveIsText,
  hrefFive,
  titleFive,
  itemFive,
  itemFiveImperVal,
  itemFiveImperUnit,
  itemFiveMetrVal,
  itemFiveMetrUnit,
  linkSix,
  linkSixIsText,
  hrefSix,
  titleSix,
  itemSix,
  itemSixImperVal,
  itemSixImperUnit,
  itemSixMetrVal,
  itemSixMetrUnit,
  linkSeven,
  linkSevenIsText,
  hrefSeven,
  titleSeven,
  itemSeven,
  itemSevenImperVal,
  itemSevenImperUnit,
  itemSevenMetrVal,
  itemSevenMetrUnit,
  linkEight,
  linkEightIsText,
  hrefEight,
  titleEight,
  itemEight,
  itemEightImperVal,
  itemEightImperUnit,
  itemEightMetrVal,
  itemEightMetrUnit,
  linkNine,
  hrefNine,
  titleNine,
  itemNine,
  itemNineImperVal,
  itemNineImperUnit,
  itemNineMetrVal,
  itemNineMetrUnit,
  linkTen,
  hrefTen,
  titleTen,
  itemTen,
  itemTenImperVal,
  itemTenImperUnit,
  itemTenMetrVal,
  itemTenMetrUnit,
}) {
  return (
    <Card
      className={
        largeCard
          ? 'card-component-container-large'
          : 'card-component-container-standard'
      }
    >
      <div className="card-img-wrapper">
        <Card.Img variant="top" src={cardImg} className="card-component-img" />
      </div>

      <Card.Body className="card-component-body">
        <Card.Title className="card-title-text">{cardTitle}</Card.Title>
        <Card.Subtitle className="mb-2 card-subtitle-text">
          {cardText}
        </Card.Subtitle>
      </Card.Body>
      <Card.Body>
        <ListGroup className="list-group-flush">
          {linkOne ? (
            <ListGroup.Item>
              <Card.Link href={hrefOne} target="_blank">
                {linkOne}
              </Card.Link>
            </ListGroup.Item>
          ) : (
            <ListGroup.Item className="card-list-item-wrapper">
              <Card.Text className="card-text-cust-bold">{titleOne}</Card.Text>

              <div className="card-sub-body">
                {itemOne && (
                  <Card.Text className="card-text-cust">{itemOne}</Card.Text>
                )}
                {itemOneImperVal && (
                  <span className="card-text-cust">
                    {`${itemOneImperVal} ${itemOneImperUnit}`}
                  </span>
                )}
                {itemOneMetrVal && (
                  <span className="card-text-cust">
                    {`${itemOneMetrVal} ${itemOneMetrUnit}`}
                  </span>
                )}
              </div>
            </ListGroup.Item>
          )}

          {linkTwo ? (
            <ListGroup.Item>
              <Card.Link href={hrefTwo} target="_blank">
                {linkTwo}
              </Card.Link>
            </ListGroup.Item>
          ) : (
            <ListGroup.Item className="card-list-item-wrapper">
              <Card.Text className="card-text-cust-bold">{titleTwo}</Card.Text>

              <div className="card-sub-body">
                {itemTwo && (
                  <Card.Text className="card-text-cust">{itemTwo}</Card.Text>
                )}
                {itemTwoImperVal && (
                  <span className="card-text-cust">
                    {`${itemTwoImperVal} ${itemTwoImperUnit}`}
                  </span>
                )}
                {itemTwoMetrVal && (
                  <span className="card-text-cust">
                    {`${itemTwoMetrVal} ${itemTwoMetrUnit}`}
                  </span>
                )}
              </div>
            </ListGroup.Item>
          )}

          {linkThree ? (
            <ListGroup.Item>
              <Card.Link href={hrefThree} target="_blank">
                {linkThreeIsText ? (
                  linkThree
                ) : (
                  <img src={linkThree} alt="link icon" className="card-icon" />
                )}
              </Card.Link>
            </ListGroup.Item>
          ) : (
            <ListGroup.Item className="card-list-item-wrapper">
              <Card.Text className="card-text-cust-bold">
                {titleThree}
              </Card.Text>
              <div className="card-sub-body">
                {itemThree && (
                  <Card.Text className="card-text-cust">{itemThree}</Card.Text>
                )}
                {itemThreeImperVal && (
                  <span className="card-text-cust">
                    {`${itemThreeImperVal} ${itemThreeImperUnit}`}
                  </span>
                )}
                {itemThreeMetrVal && (
                  <span className="card-text-cust">
                    {`${itemThreeMetrVal} ${itemThreeMetrUnit}`}
                  </span>
                )}
              </div>
            </ListGroup.Item>
          )}

          {linkFour && (
            <ListGroup.Item>
              <Card.Link href={hrefFour} target="_blank">
                {linkFourIsText ? (
                  linkFour
                ) : (
                  <img src={linkFour} alt="link icon" className="card-icon" />
                )}
              </Card.Link>
            </ListGroup.Item>
          )}

          {titleFour && (
            <ListGroup.Item className="card-list-item-wrapper">
              <Card.Text className="card-text-cust-bold">{titleFour}</Card.Text>
              <div className="card-sub-body">
                {itemFour && (
                  <Card.Text className="card-text-cust">{itemFour}</Card.Text>
                )}
                {itemFourImperVal && (
                  <span className="card-text-cust">
                    {`${itemFourImperVal} ${itemFourImperUnit}`}
                  </span>
                )}
                {itemFourMetrVal && (
                  <span className="card-text-cust">
                    {`${itemFourMetrVal} ${itemFourMetrUnit}`}
                  </span>
                )}
              </div>
            </ListGroup.Item>
          )}

          {linkFive && (
            <ListGroup.Item>
              <Card.Link href={hrefFive} target="_blank">
                {linkFiveIsText ? (
                  linkFive
                ) : (
                  <img src={linkFive} alt="link icon" className="card-icon" />
                )}
              </Card.Link>
            </ListGroup.Item>
          )}

          {titleFive && (
            <ListGroup.Item className="card-list-item-wrapper">
              <Card.Text className="card-text-cust-bold">{titleFive}</Card.Text>
              <div className="card-sub-body">
                {itemFive && (
                  <Card.Text className="card-text-cust">{itemFive}</Card.Text>
                )}
                {itemFiveImperVal && (
                  <span className="card-text-cust">
                    {`${itemFiveImperVal} ${itemFiveImperUnit}`}
                  </span>
                )}
                {itemFiveMetrVal && (
                  <span className="card-text-cust">
                    {`${itemFiveMetrVal} ${itemFiveMetrUnit}`}
                  </span>
                )}
              </div>
            </ListGroup.Item>
          )}

          {linkSix && (
            <ListGroup.Item>
              <Card.Link href={hrefSix} target="_blank">
                {linkSixIsText ? (
                  linkSix
                ) : (
                  <img src={linkSix} alt="link icon" className="card-icon" />
                )}
              </Card.Link>
            </ListGroup.Item>
          )}

          {titleSix && (
            <ListGroup.Item className="card-list-item-wrapper">
              <Card.Text className="card-text-cust-bold">{titleSix}</Card.Text>
              <div className="card-sub-body">
                {itemSix && (
                  <Card.Text className="card-text-cust">{itemSix}</Card.Text>
                )}
                {itemSixImperVal && (
                  <span className="card-text-cust">
                    {`${itemSixImperVal} ${itemSixImperUnit}`}
                  </span>
                )}
                {itemSixMetrVal && (
                  <span className="card-text-cust">
                    {`${itemSixMetrVal} ${itemSixMetrUnit}`}
                  </span>
                )}
              </div>
            </ListGroup.Item>
          )}

          {linkSeven && (
            <ListGroup.Item>
              <Card.Link href={hrefSeven} target="_blank">
                {linkSeven}
              </Card.Link>
            </ListGroup.Item>
          )}

          {titleSeven && (
            <ListGroup.Item className="card-list-item-wrapper">
              <Card.Text className="card-text-cust-bold">
                {titleSeven}
              </Card.Text>
              <div className="card-sub-body">
                {itemSeven && (
                  <Card.Text className="card-text-cust">{itemSeven}</Card.Text>
                )}
                {itemSevenImperVal && (
                  <span className="card-text-cust">
                    {`${itemSevenImperVal} ${itemSevenImperUnit}`}
                  </span>
                )}
                {itemSevenMetrVal && (
                  <span className="card-text-cust">
                    {`${itemSevenMetrVal} ${itemSevenMetrUnit}`}
                  </span>
                )}
              </div>
            </ListGroup.Item>
          )}

          {linkEight && (
            <ListGroup.Item>
              <Card.Link href={hrefEight} target="_blank">
                {linkEight}
              </Card.Link>
            </ListGroup.Item>
          )}

          {titleEight && (
            <ListGroup.Item className="card-list-item-wrapper">
              <Card.Text className="card-text-cust-bold">
                {titleEight}
              </Card.Text>
              <div className="card-sub-body">
                {itemEight && (
                  <Card.Text className="card-text-cust">{itemEight}</Card.Text>
                )}
                {itemEightImperVal && (
                  <span className="card-text-cust">
                    {`${itemEightImperVal} ${itemEightImperUnit}`}
                  </span>
                )}
                {itemEightMetrVal && (
                  <span className="card-text-cust">
                    {`${itemEightMetrVal} ${itemEightMetrUnit}`}
                  </span>
                )}
              </div>
            </ListGroup.Item>
          )}

          {linkNine && (
            <ListGroup.Item>
              <Card.Link href={hrefNine} target="_blank">
                {linkNine}
              </Card.Link>
            </ListGroup.Item>
          )}

          {titleNine && (
            <ListGroup.Item className="card-list-item-wrapper">
              <Card.Text className="card-text-cust-bold">{titleNine}</Card.Text>
              <div className="card-sub-body">
                {itemNine && (
                  <Card.Text className="card-text-cust">{itemNine}</Card.Text>
                )}
                {itemNineImperVal && (
                  <span className="card-text-cust">
                    {`${itemNineImperVal} ${itemNineImperUnit}`}
                  </span>
                )}
                {itemNineMetrVal && (
                  <span className="card-text-cust">
                    {`${itemNineMetrVal} ${itemNineMetrUnit}`}
                  </span>
                )}
              </div>
            </ListGroup.Item>
          )}

          {linkTen && (
            <ListGroup.Item>
              <Card.Link href={hrefTen} target="_blank">
                {linkTen}
              </Card.Link>
            </ListGroup.Item>
          )}

          {titleTen && (
            <ListGroup.Item>
              {' '}
              className="card-list-item-wrapper"
              <Card.Text className="card-text-cust-bold">{titleTen}</Card.Text>
              <div className="card-sub-body">
                {itemTen && (
                  <Card.Text className="card-text-cust">{itemTen}</Card.Text>
                )}
                {itemTenImperVal && (
                  <span className="card-text-cust">
                    {`${itemTenImperVal} ${itemTenImperUnit}`}
                  </span>
                )}
                {itemTenMetrVal && (
                  <span className="card-text-cust">
                    {`${itemTenMetrVal} ${itemTenMetrUnit}`}
                  </span>
                )}
              </div>
            </ListGroup.Item>
          )}
        </ListGroup>
      </Card.Body>
    </Card>
  );
}
