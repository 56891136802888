/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setContactType } from '../../redux/controls';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { v4 as uuidv4 } from 'uuid';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import './contactForm.css';

import logo from '../../assets/images/ird-logo-shop.png';

export default function ShopIrdSurvey({ usingOnPage }) {
  const { contact_type } = useSelector((state) => state.controls);
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState('Shop IRD Survey');
  const [template, setTemplate] = useState('');
  const [formReset, setFormReset] = useState(false);
  const [randomId, setRandomId] = useState(uuidv4());
  const [errors, setErrors] = useState({});
  const [howHappy, setHowHappy] = useState(null);
  const [rateStoreDesign, setRateStoreDesign] = useState(null);
  const [rateStoreFindWhatYouNeed, setRateStoreFindWhatYouNeed] =
    useState(null);
  const [rateStoreProductDescription, setRateStoreProductDescription] =
    useState(null);
  const [placedOrderFrom, setPlacedOrderFrom] = useState(null);
  const [useStoreAgain, setUseStoreAgain] = useState(null);
  const [howLongACustomer, setHowLongACustomer] = useState(null);
  const [likelyToRecommend, setLikelyToRecommend] = useState(null);

  const [form, setForm] = useState({
    form_id: randomId,
    file_name: randomId + '.pdf',
    form_type: 'shop_ird_survey',
    how_happy_with_experience: '',
    site_design_rating: '',
    site_find_need_rating: '',
    site_product_description_rating: '',
    placed_order_from: '',
    likely_to_use_store_again: '',
    how_long_a_customer: '',
    likely_to_recommend: '',
    message: '',
    created_on: new Date().toLocaleDateString('en-US'),
  });
  const formRef = useRef();
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const today = `${month}-${day}-${year}`;

  const emailJSconfig = {
    service_id: 'ird_email',
    shop_ird_survey: 'other_dvdmkwf',
  };

  const notifySendSuccess = () =>
    toast.success(`Thank you for your feedback`, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

  const notifyFormError = () =>
    toast.error('Uh oh, please check the form for errors', {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const findFormErrors = () => {
    const newErrors = {};

    return newErrors;
  };

  const resetForm = (field, value) => {
    if (field === 'form_id') {
      field = randomId;
    } else if (field === 'file_name') {
      field = randomId + '.pdf';
    } else if (value === true) {
      form[field] = false; // setting the DOM object value
      field = false; // setting the form object value
    } else if (field === 'created_on') {
      field = today;
    } else if (field === 'rental_model') {
      form[field] = false;
      field = false;
    } else {
      form[field] = '';
    }

    setForm({
      ...form,
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });

    setHowHappy(null);
    setRateStoreDesign(null);
    setRateStoreFindWhatYouNeed(null);
    setRateStoreDesign(null);
    setRateStoreProductDescription(null);
    setPlacedOrderFrom(null);
    setUseStoreAgain(null);
    setHowLongACustomer(null);
    setLikelyToRecommend(null);
  };

  function handleReset() {
    setFormReset(true);
  }

  const addSurveyToFirebase = async (data) => {
    setLoading(true);
    const localSurveyRef = doc(db, 'shop_ird_survey', randomId);
    await setDoc(localSurveyRef, data);
    setLoading(false);
  };

  function handleSubmit(e) {
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      notifyFormError();
      // console.log('if newErrors', newErrors);
    } else {
      // console.log('else newErrors', newErrors);
      addSurveyToFirebase(form).then(
        (result) => {
          console.log('result', result);
          notifySendSuccess();
          handleReset();
          formRef.current.reset();
          // emailjs
          //   .sendForm(
          //     emailJSconfig.service_id,
          //     template,
          //     formRef.current,
          //     'ASfyRsTYNICgAod9p'
          //   )
          //   .then(
          //     (result) => {
          //       console.log(result.text);
          //       notifySendSuccess();
          //       handleReset();
          //       formRef.current.reset();
          //     },
          //     (error) => {
          //       console.log(error.text);
          //     }
          //   );
        },
        (error) => {
          console.log('error', error);
        }
      );
    }
  }

  useEffect(() => {
    setTemplate(emailJSconfig.shop_ird_survey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setField('created_on', today);
    setField('form_id', randomId);
    setField('form_type', 'shop_ird_survey');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setField('how_happy_with_experience', howHappy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [howHappy]);

  useEffect(() => {
    setField('site_design_rating', rateStoreDesign);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateStoreDesign]);

  useEffect(() => {
    setField('site_find_need_rating', rateStoreFindWhatYouNeed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateStoreFindWhatYouNeed]);

  useEffect(() => {
    setField('site_product_description_rating', rateStoreProductDescription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateStoreProductDescription]);

  useEffect(() => {
    setField('placed_order_from', placedOrderFrom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placedOrderFrom]);

  useEffect(() => {
    setField('likely_to_use_store_again', useStoreAgain);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useStoreAgain]);

  useEffect(() => {
    setField('how_long_a_customer', howLongACustomer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [howLongACustomer]);

  useEffect(() => {
    setField('likely_to_recommend', likelyToRecommend);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [likelyToRecommend]);

  useEffect(() => {
    if (formReset) {
      for (const key in form) {
        let value = form[key];
        resetForm(key, value);
      }
      setFormReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formReset]);

  // console.log('form', form);

  return (
    <div className="contact-form-container form-width-40-percent">
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="contact-header-section">
        <img src={logo} alt="logo" className="contact-logo" />
        <div className="contact-header-text-wrapper">
          <p className="contact-header-text">Thanks for shopping with IRD.</p>
          <p className="contact-header-text">
            We'd Love to know a bit more about your experience with us.
          </p>
          <p className="contact-header-text">
            Do you have 5 minutes to answer a few questions to help us imporove?
          </p>
        </div>
      </div>
      <Form className="contact-form" ref={formRef}>
        <Form.Group className="mb-3" controlId="subject">
          <Form.Label className="hidden">Subject</Form.Label>
          <Form.Control
            placeholder="Subject"
            name="subject"
            value={subject}
            className="hidden"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="happy_rating">
          <Form.Label className="bold-text">
            How happy were you with your experience at shop.irdproducts.com? (1
            being very bad, 5 being excellent)
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="1"
              type="checkbox"
              id={`how_happy_with_experience_1`}
              name="how_happy_with_experience"
              checked={howHappy === '1'}
              feedbackType="invalid"
              value={'1'}
              isInvalid={!!errors.how_happy_with_experience}
              feedback={errors.how_happy_with_experience}
              onChange={(e) => setHowHappy(e.target.value)}
            />
            <Form.Check
              inline
              label="2"
              type="checkbox"
              id={`how_happy_with_experience_2`}
              name="how_happy_with_experience"
              checked={howHappy === '2'}
              feedbackType="invalid"
              value={'2'}
              isInvalid={!!errors.how_happy_with_experience}
              feedback={errors.how_happy_with_experience}
              onChange={(e) => setHowHappy(e.target.value)}
            />
            <Form.Check
              inline
              label="3"
              type="checkbox"
              id={`how_happy_with_experience_3`}
              name="how_happy_with_experience"
              checked={howHappy === '3'}
              feedbackType="invalid"
              value={'3'}
              isInvalid={!!errors.how_happy_with_experience}
              feedback={errors.how_happy_with_experience}
              onChange={(e) => setHowHappy(e.target.value)}
            />
            <Form.Check
              inline
              label="4"
              type="checkbox"
              id={`how_happy_with_experience_4`}
              name="how_happy_with_experience"
              checked={howHappy === '4'}
              feedbackType="invalid"
              value={'4'}
              isInvalid={!!errors.how_happy_with_experience}
              feedback={errors.how_happy_with_experience}
              onChange={(e) => setHowHappy(e.target.value)}
            />
            <Form.Check
              inline
              label="5"
              type="checkbox"
              id={`how_happy_with_experience_5`}
              name="how_happy_with_experience"
              checked={howHappy === '5'}
              feedbackType="invalid"
              value={'5'}
              isInvalid={!!errors.how_happy_with_experience}
              feedback={errors.how_happy_with_experience}
              onChange={(e) => setHowHappy(e.target.value)}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="design_rating">
          <Form.Label className="bold-text">
            How would you rate our online store based on design of the site? (1
            being very bad, 5 being excellent)
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="1"
              type="checkbox"
              id={`site_design_rating_1`}
              name="site_design_rating"
              checked={rateStoreDesign === '1'}
              feedbackType="invalid"
              value={'1'}
              isInvalid={!!errors.site_design_rating}
              feedback={errors.site_design_rating}
              onChange={(e) => setRateStoreDesign(e.target.value)}
            />
            <Form.Check
              inline
              label="2"
              type="checkbox"
              id={`site_design_rating_2`}
              name="site_design_rating"
              checked={rateStoreDesign === '2'}
              feedbackType="invalid"
              value={'2'}
              isInvalid={!!errors.site_design_rating}
              feedback={errors.site_design_rating}
              onChange={(e) => setRateStoreDesign(e.target.value)}
            />
            <Form.Check
              inline
              label="3"
              type="checkbox"
              id={`site_design_rating_3`}
              name="site_design_rating"
              checked={rateStoreDesign === '3'}
              feedbackType="invalid"
              value={'3'}
              isInvalid={!!errors.site_design_rating}
              feedback={errors.site_design_rating}
              onChange={(e) => setRateStoreDesign(e.target.value)}
            />
            <Form.Check
              inline
              label="4"
              type="checkbox"
              id={`site_design_rating_4`}
              name="site_design_rating"
              checked={rateStoreDesign === '4'}
              feedbackType="invalid"
              value={'4'}
              isInvalid={!!errors.site_design_rating}
              feedback={errors.site_design_rating}
              onChange={(e) => setRateStoreDesign(e.target.value)}
            />
            <Form.Check
              inline
              label="5"
              type="checkbox"
              id={`site_design_rating_5`}
              name="site_design_rating"
              checked={rateStoreDesign === '5'}
              feedbackType="invalid"
              value={'5'}
              isInvalid={!!errors.site_design_rating}
              feedback={errors.site_design_rating}
              onChange={(e) => setRateStoreDesign(e.target.value)}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="finding_need_rating">
          <Form.Label className="bold-text">
            How would you rate our online store based on finding what you need?
            (1 being very bad, 5 being excellent)
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="1"
              type="checkbox"
              id={`site_find_need_rating_1`}
              name="site_find_need_rating"
              checked={rateStoreFindWhatYouNeed === '1'}
              feedbackType="invalid"
              value={'1'}
              isInvalid={!!errors.site_find_need_rating}
              feedback={errors.site_find_need_rating}
              onChange={(e) => setRateStoreFindWhatYouNeed(e.target.value)}
            />
            <Form.Check
              inline
              label="2"
              type="checkbox"
              id={`site_find_need_rating_2`}
              name="site_find_need_rating"
              checked={rateStoreFindWhatYouNeed === '2'}
              feedbackType="invalid"
              value={'2'}
              isInvalid={!!errors.site_find_need_rating}
              feedback={errors.site_find_need_rating}
              onChange={(e) => setRateStoreFindWhatYouNeed(e.target.value)}
            />
            <Form.Check
              inline
              label="3"
              type="checkbox"
              id={`site_find_need_rating_3`}
              name="site_find_need_rating"
              checked={rateStoreFindWhatYouNeed === '3'}
              feedbackType="invalid"
              value={'3'}
              isInvalid={!!errors.site_find_need_rating}
              feedback={errors.site_find_need_rating}
              onChange={(e) => setRateStoreFindWhatYouNeed(e.target.value)}
            />
            <Form.Check
              inline
              label="4"
              type="checkbox"
              id={`site_find_need_rating_4`}
              name="site_find_need_rating"
              checked={rateStoreFindWhatYouNeed === '4'}
              feedbackType="invalid"
              value={'4'}
              isInvalid={!!errors.site_find_need_rating}
              feedback={errors.site_find_need_rating}
              onChange={(e) => setRateStoreFindWhatYouNeed(e.target.value)}
            />
            <Form.Check
              inline
              label="5"
              type="checkbox"
              id={`site_find_need_rating_5`}
              name="site_find_need_rating"
              checked={rateStoreFindWhatYouNeed === '5'}
              feedbackType="invalid"
              value={'5'}
              isInvalid={!!errors.site_find_need_rating}
              feedback={errors.site_find_need_rating}
              onChange={(e) => setRateStoreFindWhatYouNeed(e.target.value)}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="product_description_rating">
          <Form.Label className="bold-text">
            How would you rate our online store based on product description? (1
            being very bad, 5 being excellent)
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="1"
              type="checkbox"
              id={`site_product_description_rating_1`}
              name="site_product_description_rating"
              checked={rateStoreProductDescription === '1'}
              feedbackType="invalid"
              value={'1'}
              isInvalid={!!errors.site_product_description_rating}
              feedback={errors.site_product_description_rating}
              onChange={(e) => setRateStoreProductDescription(e.target.value)}
            />
            <Form.Check
              inline
              label="2"
              type="checkbox"
              id={`site_product_description_rating_2`}
              name="site_product_description_rating"
              checked={rateStoreProductDescription === '2'}
              feedbackType="invalid"
              value={'2'}
              isInvalid={!!errors.site_product_description_rating}
              feedback={errors.site_product_description_rating}
              onChange={(e) => setRateStoreProductDescription(e.target.value)}
            />
            <Form.Check
              inline
              label="3"
              type="checkbox"
              id={`site_product_description_rating_3`}
              name="site_product_description_rating"
              checked={rateStoreProductDescription === '3'}
              feedbackType="invalid"
              value={'3'}
              isInvalid={!!errors.site_product_description_rating}
              feedback={errors.site_product_description_rating}
              onChange={(e) => setRateStoreProductDescription(e.target.value)}
            />
            <Form.Check
              inline
              label="4"
              type="checkbox"
              id={`site_product_description_rating_4`}
              name="site_product_description_rating"
              checked={rateStoreProductDescription === '4'}
              feedbackType="invalid"
              value={'4'}
              isInvalid={!!errors.site_product_description_rating}
              feedback={errors.site_product_description_rating}
              onChange={(e) => setRateStoreProductDescription(e.target.value)}
            />
            <Form.Check
              inline
              label="5"
              type="checkbox"
              id={`site_product_description_rating_5`}
              name="site_product_description_rating"
              checked={rateStoreProductDescription === '5'}
              feedbackType="invalid"
              value={'5'}
              isInvalid={!!errors.site_product_description_rating}
              feedback={errors.site_product_description_rating}
              onChange={(e) => setRateStoreProductDescription(e.target.value)}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="placed_order_from">
          <Form.Label className="bold-text">
            Could you tell us where you placed your order?
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="Smartphone"
              type="checkbox"
              id={`placed_order_from_smartphone`}
              name="placed_order_from"
              checked={placedOrderFrom === 'smartphone'}
              feedbackType="invalid"
              value={'smartphone'}
              isInvalid={!!errors.placed_order_from}
              feedback={errors.placed_order_from}
              onChange={(e) => setPlacedOrderFrom(e.target.value)}
            />
            <Form.Check
              inline
              label="Tablet"
              type="checkbox"
              id={`placed_order_from_tablet`}
              name="placed_order_from"
              checked={placedOrderFrom === 'tablet'}
              feedbackType="invalid"
              value={'tablet'}
              isInvalid={!!errors.placed_order_from}
              feedback={errors.placed_order_from}
              onChange={(e) => setPlacedOrderFrom(e.target.value)}
            />
            <Form.Check
              inline
              label="Laptop Computer"
              type="checkbox"
              id={`placed_order_from_laptop`}
              name="placed_order_from"
              checked={placedOrderFrom === 'laptop'}
              feedbackType="invalid"
              value={'laptop'}
              isInvalid={!!errors.placed_order_from}
              feedback={errors.placed_order_from}
              onChange={(e) => setPlacedOrderFrom(e.target.value)}
            />
            <Form.Check
              inline
              label="Desktop Computer"
              type="checkbox"
              id={`placed_order_from_desktop`}
              name="placed_order_from"
              checked={placedOrderFrom === 'desktop'}
              feedbackType="invalid"
              value={'desktop'}
              isInvalid={!!errors.placed_order_from}
              feedback={errors.placed_order_from}
              onChange={(e) => setPlacedOrderFrom(e.target.value)}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="likely_use_again">
          <Form.Label className="bold-text">
            How likely are you to use our online store again? (1 being not at
            all, 3 being maybe, 6 being 100%)
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="1"
              type="checkbox"
              id={`likely_to_use_store_again_1`}
              name="likely_to_use_store_again"
              checked={useStoreAgain === '1'}
              feedbackType="invalid"
              value={'1'}
              isInvalid={!!errors.likely_to_use_store_again}
              feedback={errors.likely_to_use_store_again}
              onChange={(e) => setUseStoreAgain(e.target.value)}
            />
            <Form.Check
              inline
              label="2"
              type="checkbox"
              id={`likely_to_use_store_again_2`}
              name="likely_to_use_store_again"
              checked={useStoreAgain === '2'}
              feedbackType="invalid"
              value={'2'}
              isInvalid={!!errors.likely_to_use_store_again}
              feedback={errors.likely_to_use_store_again}
              onChange={(e) => setUseStoreAgain(e.target.value)}
            />
            <Form.Check
              inline
              label="3"
              type="checkbox"
              id={`likely_to_use_store_again_3`}
              name="likely_to_use_store_again"
              checked={useStoreAgain === '3'}
              feedbackType="invalid"
              value={'3'}
              isInvalid={!!errors.likely_to_use_store_again}
              feedback={errors.likely_to_use_store_again}
              onChange={(e) => setUseStoreAgain(e.target.value)}
            />
            <Form.Check
              inline
              label="4"
              type="checkbox"
              id={`likely_to_use_store_again_4`}
              name="likely_to_use_store_again"
              checked={useStoreAgain === '4'}
              feedbackType="invalid"
              value={'4'}
              isInvalid={!!errors.likely_to_use_store_again}
              feedback={errors.likely_to_use_store_again}
              onChange={(e) => setUseStoreAgain(e.target.value)}
            />
            <Form.Check
              inline
              label="5"
              type="checkbox"
              id={`likely_to_use_store_again_5`}
              name="likely_to_use_store_again"
              checked={useStoreAgain === '5'}
              feedbackType="invalid"
              value={'5'}
              isInvalid={!!errors.likely_to_use_store_again}
              feedback={errors.likely_to_use_store_again}
              onChange={(e) => setUseStoreAgain(e.target.value)}
            />
            <Form.Check
              inline
              label="6"
              type="checkbox"
              id={`likely_to_use_store_again_6`}
              name="likely_to_use_store_again"
              checked={useStoreAgain === '6'}
              feedbackType="invalid"
              value={'6'}
              isInvalid={!!errors.likely_to_use_store_again}
              feedback={errors.likely_to_use_store_again}
              onChange={(e) => setUseStoreAgain(e.target.value)}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="how_long_a_customer">
          <Form.Label className="bold-text">
            How long have you been a customer of ours?
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="This is my first purchase"
              type="checkbox"
              id={`how_long_a_customer_this_is_my_first_purchase`}
              name="how_long_a_customer"
              checked={howLongACustomer === 'this is my first purchase'}
              feedbackType="invalid"
              value={'this is my first purchase'}
              isInvalid={!!errors.how_long_a_customer}
              feedback={errors.how_long_a_customer}
              onChange={(e) => setHowLongACustomer(e.target.value)}
            />
            <Form.Check
              inline
              label="Less than 6 months"
              type="checkbox"
              id={`how_long_a_customer_less_than_6_months`}
              name="how_long_a_customer"
              checked={howLongACustomer === 'less than 6 months'}
              feedbackType="invalid"
              value={'less than 6 months'}
              isInvalid={!!errors.how_long_a_customer}
              feedback={errors.how_long_a_customer}
              onChange={(e) => setHowLongACustomer(e.target.value)}
            />
            <Form.Check
              inline
              label="Six months to a year"
              type="checkbox"
              id={`how_long_a_customer_six_months_to_a_year`}
              name="how_long_a_customer"
              checked={howLongACustomer === 'six months to a year'}
              feedbackType="invalid"
              value={'six months to a year'}
              isInvalid={!!errors.how_long_a_customer}
              feedback={errors.how_long_a_customer}
              onChange={(e) => setHowLongACustomer(e.target.value)}
            />
            <Form.Check
              inline
              label="1 - 5 years"
              type="checkbox"
              id={`how_long_a_customer_one_to_five_years`}
              name="how_long_a_customer"
              checked={howLongACustomer === '1-5 years'}
              feedbackType="invalid"
              value={'1-5 years'}
              isInvalid={!!errors.how_long_a_customer}
              feedback={errors.how_long_a_customer}
              onChange={(e) => setHowLongACustomer(e.target.value)}
            />
            <Form.Check
              inline
              label="6+ years"
              type="checkbox"
              id={`how_long_a_customer_6_plus_years`}
              name="how_long_a_customer"
              checked={howLongACustomer === '6+ years'}
              feedbackType="invalid"
              value={'6+ years'}
              isInvalid={!!errors.how_long_a_customer}
              feedback={errors.how_long_a_customer}
              onChange={(e) => setHowLongACustomer(e.target.value)}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="likely_recommend">
          <Form.Label className="bold-text">
            How likely are you to recommend us? (1 being not at all,, 3 being
            maybe, 6 being 100%)
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="1"
              type="checkbox"
              id={`likely_to_recommend_1`}
              name="likely_to_recommend"
              checked={likelyToRecommend === '1'}
              feedbackType="invalid"
              value={'1'}
              isInvalid={!!errors.likely_to_recommend}
              feedback={errors.likely_to_recommend}
              onChange={(e) => setLikelyToRecommend(e.target.value)}
            />
            <Form.Check
              inline
              label="2"
              type="checkbox"
              id={`likely_to_recommend_2`}
              name="likely_to_recommend"
              checked={likelyToRecommend === '2'}
              feedbackType="invalid"
              value={'2'}
              isInvalid={!!errors.likely_to_recommend}
              feedback={errors.likely_to_recommend}
              onChange={(e) => setLikelyToRecommend(e.target.value)}
            />
            <Form.Check
              inline
              label="3"
              type="checkbox"
              id={`likely_to_recommend_3`}
              name="likely_to_recommend"
              checked={likelyToRecommend === '3'}
              feedbackType="invalid"
              value={'3'}
              isInvalid={!!errors.likely_to_recommend}
              feedback={errors.likely_to_recommend}
              onChange={(e) => setLikelyToRecommend(e.target.value)}
            />
            <Form.Check
              inline
              label="4"
              type="checkbox"
              id={`likely_to_recommend_4`}
              name="likely_to_recommend"
              checked={likelyToRecommend === '4'}
              feedbackType="invalid"
              value={'4'}
              isInvalid={!!errors.likely_to_recommend}
              feedback={errors.likely_to_recommend}
              onChange={(e) => setLikelyToRecommend(e.target.value)}
            />
            <Form.Check
              inline
              label="5"
              type="checkbox"
              id={`likely_to_recommend_5`}
              name="likely_to_recommend"
              checked={likelyToRecommend === '5'}
              feedbackType="invalid"
              value={'5'}
              isInvalid={!!errors.likely_to_recommend}
              feedback={errors.likely_to_recommend}
              onChange={(e) => setLikelyToRecommend(e.target.value)}
            />
            <Form.Check
              inline
              label="6"
              type="checkbox"
              id={`likely_to_recommend_6`}
              name="likely_to_recommend"
              checked={likelyToRecommend === '6'}
              feedbackType="invalid"
              value={'6'}
              isInvalid={!!errors.likely_to_recommend}
              feedback={errors.likely_to_recommend}
              onChange={(e) => setLikelyToRecommend(e.target.value)}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="shop_ird_survey_message">
          <Form.Label className="bold-text">
            Do you have any other comments, questions, or concerns?
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="4"
            placeholder="type answer here"
            name="message"
            value={form.message}
            isInvalid={!!errors.message}
            errors={errors.message}
            onChange={(e) => setField('message', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.message}
          </Form.Control.Feedback>
        </Form.Group>

        <div className="contact-form-button-wrapper">
          <Button
            className="contact-submit-button"
            type="submit"
            disabled={loading}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
}
