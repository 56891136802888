import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { serverTimestamp, doc, setDoc, updateDoc } from 'firebase/firestore';
import { storage, db } from '../../firebase';
import UploadButton from '../UploadButton/UploadButton';
import './productDocumentsForm.css';

export default function ProductDocumentsForm() {
  const { product_docs } = useSelector((state) => state.controls);
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState(null);
  const [productDescription, setProductDescription] = useState(null);
  const [fileUpload, setFileUpload] = useState(null);
  console.log('productName', productName);

  let length = 0;

  if (product_docs) {
    length = product_docs.length;
  }

  // STEP 2 if adding a new product
  const uploadPostToFirebase = async (fileUrl, prodName, prodDesc) => {
    const randomId = uuidv4();
    const prodRef = doc(db, 'product_doc', randomId);
    setDoc(prodRef, {
      id: randomId,
      prod_doc_file: fileUrl,
      prod_name: prodName,
      prod_desc: prodDesc,

      createdAt: serverTimestamp(),
    });
  };

  // STEP 3 if updating product
  const updateProduct = async (file) => {
    setLoading(true);
    if (fileUpload === null) return;
    const random = uuidv4();
    const extension = '.pdf';
    const fileRef = ref(
      storage,
      `productDocs/${fileUpload.name + random + extension}`
    );
    // eslint-disable-next-line no-unused-vars
    const snapshot = await uploadBytes(fileRef, file);
    const image_url = await getDownloadURL(fileRef);
    // TODO: change product_docs[0].id to not always be [0]
    const docRef = doc(db, 'product_doc', product_docs[0].id);
    updateDoc(docRef, {
      featured_prod_img: image_url,
      featured_prod_name: productName,
      featured_prod_desc: productDescription,
    });
    setLoading(false);
    alert('Product updated successfully');
  };

  // STEP 1 if adding new product
  async function addNewFeaturedProduct(file) {
    setLoading(true);
    if (fileUpload === null) return;
    const random = uuidv4();
    const extension = '.png';
    const fileRef = ref(
      storage,
      `productDocs/${fileUpload.name + random + extension}`
    );
    // eslint-disable-next-line no-unused-vars
    const snapshot = await uploadBytes(fileRef, file);
    const file_url = await getDownloadURL(fileRef);
    uploadPostToFirebase(file_url, productName, productDescription);

    setLoading(false);
    alert('Added new product successfully');
  }

  function handleChange(e) {
    if (e.target.files[0]) {
      setFileUpload(e.target.files[0]);
    }
  }

  function handleSubmit() {
    addNewFeaturedProduct(fileUpload);
  }

  function handleUpdate() {
    updateProduct(fileUpload);
  }

  // function onSwitchAction() {
  //   setAbleToPurchaseOnline(!ableToPurchaseOnline);
  // }

  // console.log('current_user', current_user);
  // console.log('productName', productName);
  // console.log('imageUpload', imageUpload);
  // console.log('productDescription', productDescription);
  // console.log('productImageURL', productImageURL);
  // if (length > 0) {
  //   console.log('featured_product.id', featured_product[0].key);
  // }

  // console.log('linkToProduct', linkToProduct);
  // console.log('ableToPurchaseOnline', ableToPurchaseOnline);

  return (
    <div className="product-documents-form-container">
      <Form className="product-documents-form">
        <Form.Group className="mb-3" controlId="product-name">
          <Form.Label>Product Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Product Name"
            onChange={(e) => setProductName(e.target.value)}
          />
        </Form.Group>

        {/* <div className="m-3">
          <input
            type="file"
            className="form-control file-selection-input"
            onChange={handleChange}
          />
        </div> */}

        <UploadButton />

        <Form.Group className="mb-3" controlId="product-description">
          <Form.Label>Product Description</Form.Label>
          <Form.Control
            as="textarea"
            rows="4"
            placeholder="Description"
            onChange={(e) => setProductDescription(e.target.value)}
          />
        </Form.Group>

        <div className="product-documents-button-wrapper">
          {length === 0 ? (
            <Button
              className="product-documents-submit-button"
              type="submit"
              disabled={loading}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          ) : (
            <Button
              className="product-documents-submit-button"
              type="submit"
              disabled={loading}
              onClick={handleUpdate}
            >
              Update
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
}
