import shoppingCart from '../../assets/icons/shopping-cart-black.svg';

export const SENSORS_VELOCITY_TRANSDUCERS_COLUMN = [
  {
    Header: '',
    accessor: 'image_url',
    Cell: ({ value }) => {
      const imgContainer = (
        <div className="table-img-wrapper">
          <img src={value} alt="" className="table-img" />
        </div>
      );
      if (value) {
        return imgContainer;
      } else {
        return <p>image not available</p>;
      }
    },
  },
  {
    Header: 'Model',
    accessor: 'model',
    Cell: ({ value }) => {
      const modelContainer = (
        <div className="table-model-container">
          <span className="table-text">{value}</span>
        </div>
      );
      return modelContainer;
    },
    maxWidth: 55,
    minWidth: 55,
  },
  {
    Header: 'IRD Part Number',
    accessor: 'ird_part_number',
    Cell: ({ value }) => {
      const modelContainer = (
        <div className="table-part-number-container">
          <span className="table-text">{value}</span>
        </div>
      );
      return modelContainer;
    },
    maxWidth: 55,
    minWidth: 55,
  },
  {
    Header: 'Entek/Rockwell Reference',
    accessor: 'entek_rockwell_ref',
    Cell: ({ value }) => {
      const modelContainer = (
        <div className="table-entek-ref-container">
          <span className="table-text">{value}</span>
        </div>
      );
      return modelContainer;
    },
    maxWidth: 55,
    minWidth: 55,
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: ({ value }) => {
      const descriptionContainer = (
        <div className="table-description-container">
          <span className="table-text">{value}</span>
        </div>
      );
      return descriptionContainer;
    },
    maxWidth: 55,
    minWidth: 55,
  },
  {
    Header: '',
    accessor: 'purchase_link',
    maxWidth: 55,
    minWidth: 55,
    width: 55,
    Cell: ({ value }) => {
      const iconContainer = (
        <a
          href={value}
          className="table-icon-wrapper"
          rel="noreferrer"
          target="_blank"
        >
          <img src={shoppingCart} alt="" className="table-icon" />
        </a>
      );
      if (value) {
        return iconContainer;
      } else {
        return (
          <p>
            <em>purchase link not available</em>
          </p>
        );
      }
    },
  },
];

export const SENSORS_ACCELEROMETERS_COLUMN = [
  {
    Header: '',
    accessor: 'image_url',
    Cell: ({ value }) => {
      const imgContainer = (
        <div className="table-img-wrapper">
          <img src={value} alt="" className="table-img" />
        </div>
      );
      if (value) {
        return imgContainer;
      } else {
        return <p>image not available</p>;
      }
    },
  },
  {
    Header: 'Model',
    accessor: 'model',
  },
  {
    Header: 'IRD Part Number',
    accessor: 'ird_part_number',
  },
  {
    Header: 'Entek/Rockwell Reference',
    accessor: 'entek_rockwell_ref',
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: ({ value }) => {
      const descriptionContainer = (
        <div className="table-description-container">
          <span className="table-text">{value}</span>
        </div>
      );
      return descriptionContainer;
    },
    maxWidth: 55,
    minWidth: 55,
  },
  {
    Header: '',
    accessor: 'purchase_link',
    maxWidth: 55,
    minWidth: 55,
    width: 55,
    Cell: ({ value }) => {
      const iconContainer = (
        <a
          href={value}
          className="table-icon-wrapper"
          rel="noreferrer"
          target="_blank"
        >
          <img src={shoppingCart} alt="" className="table-icon" />
        </a>
      );
      if (value) {
        return iconContainer;
      } else {
        return (
          <p>
            <em>purchase link not available</em>
          </p>
        );
      }
    },
  },
];

export const SENSORS_SPEED_SENSORS_COLUMN = [
  {
    Header: '',
    accessor: 'image_url',
    Cell: ({ value }) => {
      const imgContainer = (
        <div className="table-img-wrapper">
          <img src={value} alt="" className="table-img" />
        </div>
      );
      if (value) {
        return imgContainer;
      } else {
        return <p>image not available</p>;
      }
    },
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'IRD Part Number',
    accessor: 'ird_part_number',
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: ({ value }) => {
      const descriptionContainer = (
        <div className="table-description-container">
          <span className="table-text">{value}</span>
        </div>
      );
      return descriptionContainer;
    },
    maxWidth: 55,
    minWidth: 55,
  },
  {
    Header: '',
    accessor: 'purchase_link',
    maxWidth: 55,
    minWidth: 55,
    width: 55,
    Cell: ({ value }) => {
      const iconContainer = (
        <a
          href={value}
          className="table-icon-wrapper"
          rel="noreferrer"
          target="_blank"
        >
          <img src={shoppingCart} alt="" className="table-icon" />
        </a>
      );
      if (value) {
        return iconContainer;
      } else {
        return (
          <p>
            <em>purchase link not available</em>
          </p>
        );
      }
    },
  },
];

export const SENSORS_PARTS_AND_ACCESSORIES_COLUMN = [
  {
    Header: '',
    accessor: 'image_url',
    Cell: ({ value }) => {
      const imgContainer = (
        <div className="table-img-wrapper">
          <img src={value} alt="" className="table-img" />
        </div>
      );
      if (value) {
        return imgContainer;
      } else {
        return <p>image not available</p>;
      }
    },
  },
  {
    Header: 'IRD Part Number',
    accessor: 'ird_part_number',
  },
  {
    Header: 'Entek/Rockwell Reference',
    accessor: 'entek_rockwell_ref',
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: ({ value }) => {
      const descriptionContainer = (
        <div className="table-description-container">
          <span className="table-text">{value}</span>
        </div>
      );
      return descriptionContainer;
    },
    maxWidth: 55,
    minWidth: 55,
  },
  {
    Header: '',
    accessor: 'purchase_link',
    maxWidth: 55,
    minWidth: 55,
    width: 55,
    Cell: ({ value }) => {
      const iconContainer = (
        <a
          href={value}
          className="table-icon-wrapper"
          rel="noreferrer"
          target="_blank"
        >
          <img src={shoppingCart} alt="" className="table-icon" />
        </a>
      );
      if (value) {
        return iconContainer;
      } else {
        return (
          <p>
            <em>purchase link not available</em>
          </p>
        );
      }
    },
  },
];

export const CORE_LOSS_TESTER_COLUMNS = [
  {
    Header: '',
    accessor: 'image_url',
    Cell: ({ value }) => {
      const imgContainer = (
        <div className="table-img-wrapper">
          <img src={value} alt="" className="table-img" />
        </div>
      );
      //   console.log('value:', value);
      if (value) {
        return imgContainer;
      } else {
        return <p>image not available</p>;
      }
    },
  },
  {
    Header: 'Model',
    accessor: 'model',
  },
  {
    Header: 'Maximum HP*',
    accessor: 'max_hp',
  },
  {
    Header: 'Maximum KVA',
    accessor: 'max_kva',
  },
  {
    Header: '',
    accessor: 'purchase_link',
    maxWidth: 55,
    minWidth: 55,
    width: 55,
    Cell: ({ value }) => {
      const iconContainer = (
        <a
          href={value}
          className="table-icon-wrapper"
          rel="noreferrer"
          target="_blank"
        >
          <img src={shoppingCart} alt="" className="table-icon" />
        </a>
      );
      if (value) {
        return iconContainer;
      } else {
        return (
          <p>
            <em>purchase link not available</em>
          </p>
        );
      }
    },
  },
];

export const PARTS_AND_ACCESSORIES_PAGE_CABLES_COLUMN = [
  {
    Header: '',
    accessor: 'image_url',
    Cell: ({ value }) => {
      const imgContainer = (
        <div className="table-cell table-img-wrapper">
          <img src={value} alt="" className="table-img" />
        </div>
      );
      //   console.log('value:', value);
      if (value) {
        return imgContainer;
      } else {
        return <p>image not available</p>;
      }
    },
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: 'IRD Part Number',
    accessor: 'part_num',
    maxWidth: 60,
    minWidth: 60,
    width: 60,
  },
  {
    Header: 'For Use With',
    accessor: 'for_use_with',
    maxWidth: 155,
    minWidth: 155,
    width: 155,
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: ({ value }) => {
      const descriptionContainer = (
        <div className="table-description-container">
          <span className="table-text">{value}</span>
        </div>
      );
      return descriptionContainer;
    },
    maxWidth: 80,
    minWidth: 80,
    width: 80,
  },
  {
    Header: 'Length',
    accessor: 'length',
    maxWidth: 55,
    minWidth: 55,
    width: 55,
  },
  {
    Header: 'Ship Weight',
    accessor: 'ship_weight',
    maxWidth: 55,
    minWidth: 55,
    width: 55,
  },
  {
    Header: '',
    accessor: 'purchase_link',
    maxWidth: 55,
    minWidth: 55,
    width: 55,
    Cell: ({ value }) => {
      const iconContainer = (
        <a
          href={value}
          className="table-icon-wrapper"
          rel="noreferrer"
          target="_blank"
        >
          <img src={shoppingCart} alt="" className="table-icon" />
        </a>
      );
      if (value) {
        return iconContainer;
      } else {
        return (
          <p>
            <em>purchase link not available</em>
          </p>
        );
      }
    },
  },
];

export const PARTS_AND_ACCESSORIES_PAGE_SUPPLIES_COLUMN = [
  {
    Header: '',
    accessor: 'image_url',
    Cell: ({ value }) => {
      const imgContainer = (
        <div className="table-cell table-img-wrapper">
          <img src={value} alt="" className="table-img" />
        </div>
      );
      //   console.log('value:', value);
      if (value) {
        return imgContainer;
      } else {
        return <p>image not available</p>;
      }
    },
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: 'IRD Part Number',
    accessor: 'part_num',
    maxWidth: 60,
    minWidth: 60,
    width: 60,
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: ({ value }) => {
      const descriptionContainer = (
        <div className="table-description-container">
          <span className="table-text">{value}</span>
        </div>
      );
      return descriptionContainer;
    },
    maxWidth: 80,
    minWidth: 80,
    width: 80,
  },
  {
    Header: 'Ship Weight',
    accessor: 'ship_weight',
    maxWidth: 55,
    minWidth: 55,
    width: 55,
  },
  {
    Header: '',
    accessor: 'purchase_link',
    maxWidth: 55,
    minWidth: 55,
    width: 55,
    Cell: ({ value }) => {
      const iconContainer = (
        <a
          href={value}
          className="table-icon-wrapper"
          rel="noreferrer"
          target="_blank"
        >
          <img src={shoppingCart} alt="" className="table-icon" />
        </a>
      );
      if (value) {
        return iconContainer;
      } else {
        return (
          <p>
            <em>purchase link not available</em>
          </p>
        );
      }
    },
  },
];

export const PARTS_AND_ACCESSORIES_PAGE_BELTS_COLUMN = [
  {
    Header: '',
    accessor: 'image_url',
    Cell: ({ value }) => {
      const imgContainer = (
        <div className="table-cell table-img-wrapper">
          <img src={value} alt="" className="table-img" />
        </div>
      );
      //   console.log('value:', value);
      if (value) {
        return imgContainer;
      } else {
        return <p>image not available</p>;
      }
    },
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: 'IRD Part Number',
    accessor: 'part_num',
    maxWidth: 60,
    minWidth: 60,
    width: 60,
  },
  {
    Header: 'For Use With',
    accessor: 'for_use_with',
    maxWidth: 155,
    minWidth: 155,
    width: 155,
  },
  {
    Header: 'Length',
    accessor: 'length',
    maxWidth: 55,
    minWidth: 55,
    width: 55,
  },
  {
    Header: 'Ship Weight',
    accessor: 'ship_weight',
    maxWidth: 55,
    minWidth: 55,
    width: 55,
  },
  {
    Header: '',
    accessor: 'purchase_link',
    maxWidth: 55,
    minWidth: 55,
    width: 55,
    Cell: ({ value }) => {
      const iconContainer = (
        <a
          href={value}
          className="table-icon-wrapper"
          rel="noreferrer"
          target="_blank"
        >
          <img src={shoppingCart} alt="" className="table-icon" />
        </a>
      );
      if (value) {
        return iconContainer;
      } else {
        return (
          <p>
            <em>purchase link not available</em>
          </p>
        );
      }
    },
  },
];

export const PARTS_AND_ACCESSORIES_PAGE_ROLLERS_COLUMN = [
  {
    Header: '',
    accessor: 'image_url',
    Cell: ({ value }) => {
      const imgContainer = (
        <div className="table-cell table-img-wrapper">
          <img src={value} alt="" className="table-img" />
        </div>
      );
      //   console.log('value:', value);
      if (value) {
        return imgContainer;
      } else {
        return <p>image not available</p>;
      }
    },
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: 'IRD Part Number',
    accessor: 'part_num',
    maxWidth: 60,
    minWidth: 60,
    width: 60,
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: ({ value }) => {
      const descriptionContainer = (
        <div className="table-description-container">
          <span className="table-text">{value}</span>
        </div>
      );
      return descriptionContainer;
    },
    maxWidth: 80,
    minWidth: 80,
    width: 80,
  },
  {
    Header: 'Diameter',
    accessor: 'diameter',
    maxWidth: 55,
    minWidth: 55,
    width: 55,
  },
  {
    Header: 'Width',
    accessor: 'width',
    maxWidth: 55,
    minWidth: 55,
    width: 55,
  },
  {
    Header: 'Ship Weight',
    accessor: 'ship_weight',
    maxWidth: 55,
    minWidth: 55,
    width: 55,
  },
  {
    Header: '',
    accessor: 'purchase_link',
    maxWidth: 55,
    minWidth: 55,
    width: 55,
    Cell: ({ value }) => {
      const iconContainer = (
        <a
          href={value}
          className="table-icon-wrapper"
          rel="noreferrer"
          target="_blank"
        >
          <img src={shoppingCart} alt="" className="table-icon" />
        </a>
      );
      if (value) {
        return iconContainer;
      } else {
        return (
          <p>
            <em>purchase link not available</em>
          </p>
        );
      }
    },
  },
];

export const CORE_LOSS_TESTER_PAGE_CABLE_COLUMN = [
  {
    Header: '',
    accessor: 'image_url',
    Cell: ({ value }) => {
      const imgContainer = (
        <div className="table-cell table-img-wrapper">
          <img src={value} alt="" className="table-img" />
        </div>
      );
      if (value) {
        return imgContainer;
      } else {
        return <p>image not available</p>;
      }
    },
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: 'IRD Part Number',
    accessor: 'part_num',
    maxWidth: 60,
    minWidth: 60,
    width: 60,
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: ({ value }) => {
      const descriptionContainer = (
        <div className="table-description-container">
          <span className="table-text">{value}</span>
        </div>
      );
      return descriptionContainer;
    },
    maxWidth: 80,
    minWidth: 80,
    width: 80,
  },
  {
    Header: 'Category',
    accessor: 'category',
    maxWidth: 55,
    minWidth: 55,
    width: 55,
  },
  {
    Header: '10KVA, 25KVA, 40KVA, 60KVA',
    accessor: 'kva_col_one',
    maxWidth: 55,
    minWidth: 55,
    width: 55,
  },
  {
    Header: '125KVA, 200KVA,300KVA',
    accessor: 'kva_col_two',
    maxWidth: 55,
    minWidth: 55,
    width: 55,
  },
  {
    Header: '',
    accessor: 'purchase_link',
    maxWidth: 55,
    minWidth: 55,
    width: 55,
    Cell: ({ value }) => {
      const iconContainer = (
        <a
          href={value}
          className="table-icon-wrapper"
          rel="noreferrer"
          target="_blank"
        >
          <img src={shoppingCart} alt="" className="table-icon" />
        </a>
      );
      if (value) {
        return iconContainer;
      } else {
        return (
          <p>
            <em>purchase link not available</em>
          </p>
        );
      }
    },
  },
];

export const LEGACY_PRODUCTS_COLUMN = [
  {
    Header: '',
    accessor: 'image_url',
    Cell: ({ value }) => {
      const imgContainer = (
        <div className="table-cell table-img-wrapper">
          <img src={value} alt="" className="table-img" />
        </div>
      );
      if (value) {
        return imgContainer;
      } else {
        return <p>image not available</p>;
      }
    },
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: 'IRD Part Number',
    accessor: 'part_num',
    maxWidth: 60,
    minWidth: 60,
    width: 60,
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: ({ value }) => {
      const descriptionContainer = (
        <div className="table-description-container">
          <span className="table-text">{value}</span>
        </div>
      );
      return descriptionContainer;
    },
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: 'Category',
    accessor: 'category',
    maxWidth: 55,
    minWidth: 55,
    width: 55,
  },
  // {
  //   Header: '10KVA, 25KVA, 40KVA, 60KVA',
  //   accessor: 'kva_col_one',
  //   maxWidth: 55,
  //   minWidth: 55,
  //   width: 55,
  // },
  // {
  //   Header: '125KVA, 200KVA,300KVA',
  //   accessor: 'kva_col_two',
  //   maxWidth: 55,
  //   minWidth: 55,
  //   width: 55,
  // },
  {
    Header: 'Data Sheet',
    accessor: 'href_to_model_doc',
    maxWidth: 65,
    minWidth: 55,
    width: 55,
    Cell: ({ value }) => {
      const iconContainer = (
        <a
          href={value}
          className="table-link-wrapper"
          rel="noreferrer"
          target="_blank"
        >
          Data Sheet
        </a>
      );
      if (value) {
        return iconContainer;
      } else {
        return (
          <p>
            <em>data sheet not available</em>
          </p>
        );
      }
    },
  },
];
