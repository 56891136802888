import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowMenu } from '../redux/controls';
import SideMenu from '../components/SideMenu/SideMenu';
import TopMenu from '../components/TopMenu/TopMenu';
import ContentBlock from '../components/ContentBlock/ContentBlock';
import Footer from '../components/Footer/Footer';
import '../styles/standardPageStyle.css';

import motorImg from '../assets/images/motor.png';
import { CORE_EVALUATION_PAGE as content } from '../data/PAGE_TEXT_CONTENT';

export default function CoreEvaluation() {
  const { has_paid } = useSelector((state) => state.controls);
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  return (
    <div className="container-fluid stnd-container">
      {has_paid && <SideMenu />}
      {has_paid && <TopMenu />}

      <div className="row stnd-content" onClick={toggleMenu}>
        <div className="row stnd-top-content">
          <ContentBlock
            title={'Core Evaluation'}
            text={content.intro_para}
            image={motorImg}
            imageSize="small"
            hasList={true}
            liOne={content.attribute_list_pt_one}
            liTwo={content.attribute_list_pt_two}
            liThree={content.attribute_list_pt_three}
            liFour={content.attribute_list_pt_four}
            liFive={content.attribute_list_pt_five}
            liSix={content.attribute_list_pt_six}
            liSeven={content.attribute_list_pt_seven}
            liEight={content.attribute_list_pt_eight}
            showButton={false}
          />
        </div>

        <div className="row stnd-middle-content-flex-col">
          <p className="stnd-text">{content.para_two}</p>
          <p className="stnd-text">{content.para_three}</p>

          <h2 className="stnd-h2">Hot Spot Testing</h2>
          <p className="stnd-text">{content.hot_spot_testing_para}</p>
        </div>

        <div className="row stnd-bottom-content"></div>
      </div>

      <Footer />
    </div>
  );
}
