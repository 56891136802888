import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setShowMenu, setTableView } from '../redux/controls';
import { Button } from 'react-bootstrap';
import TopMenu from '../components/TopMenu/TopMenu';
import SideMenu from '../components/SideMenu/SideMenu';
import TableComponent from '../components/Table/TableComponent';
import Footer from '../components/Footer/Footer';
import '../styles/standardPageStyle.css';

export default function PartsAndAccessories() {
  const [partType, setPartType] = useState('cables');
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  const changeTableView = (part, view) => {
    setPartType(part);
    dispatch(setTableView(view));
  };

  useEffect(() => {
    dispatch(setTableView('parts-and-accessories-page-cables'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="container-fluid stnd-container">
      <SideMenu />
      <TopMenu />
      <div className="stnd-header">
        <h1 className="stnd-h1 margin-left-two-percent">
          Balancing Parts & Accessories
        </h1>
      </div>
      <div className="row stnd-content" onClick={toggleMenu}>
        <div className="row stnd-top-content">
          <span className="stnd-text stnd-width-fifty-percent">
            Our machines are so robust that we commonly work on machines over 30
            years old. With age comes the need to buy some spare parts. If you
            lose a cable or wear out a belt, we have you covered! Below are our
            commonly used spare parts, supplies, and accessories for IRD®
            Balancing machines and instruments.
          </span>
        </div>

        <div className="row stnd-middle-content-flex-row">
          <Button
            variant="outline-dark"
            active={partType === 'cables' ? true : false}
            className="stnd-button-type-one"
            onClick={() =>
              changeTableView('cables', 'parts-and-accessories-page-cables')
            }
          >
            Cables
          </Button>
          <Button
            variant="outline-dark"
            active={partType === 'supplies' ? true : false}
            className="stnd-button-type-one"
            onClick={() =>
              changeTableView('supplies', 'parts-and-accessories-page-supplies')
            }
          >
            Supplies
          </Button>
          <Button
            variant="outline-dark"
            active={partType === 'belts' ? true : false}
            className="stnd-button-type-one"
            onClick={() =>
              changeTableView('belts', 'parts-and-accessories-page-belts')
            }
          >
            Belts
          </Button>
          <Button
            variant="outline-dark"
            active={partType === 'rollers' ? true : false}
            className="stnd-button-type-one"
            onClick={() =>
              changeTableView('rollers', 'parts-and-accessories-page-rollers')
            }
          >
            Rollers
          </Button>
        </div>

        <div className="row stnd-bottom-content">
          {partType === 'cables' && (
            <>
              <h3 className="stnd-h3 color-blueberry">
                Balancing Instrument Cables
              </h3>
              <TableComponent />
            </>
          )}

          {partType === 'supplies' && (
            <>
              <h3 className="stnd-h3 color-blueberry">
                Soft-Bearing Supplies, Replacement Parts & Accessories
              </h3>
              <TableComponent />
            </>
          )}

          {partType === 'belts' && (
            <>
              <h3 className="stnd-h3 color-blueberry">
                Flat Drive Belts for Soft-Bearing Balancing Machines
              </h3>
              <TableComponent />
            </>
          )}

          {partType === 'rollers' && (
            <>
              <h3 className="stnd-h3 color-blueberry">
                Rollers & Accessories for Modular Suspension Work Supports
              </h3>
              <TableComponent />
            </>
          )}
        </div>
      </div>

      <Footer />
    </main>
  );
}
