import model1081 from '../assets/images/model-1081-clt.png';
import cartIcon from '../assets/icons/shopping-cart-black.svg';
import coreLossTesterDoc from '../assets/pdf-docs/core-loss-tester-options/core-loss-tester.pdf';

export const CORE_LOSS_TESTER_MODEL_OPTIONS = [
  {
    model: '1081',
    href_to_model_doc: coreLossTesterDoc,
    image: model1081,
    description: 'Details',
    description_details:
      'a single instrument for a range of service, maintenance & diagnostic applications',
    max_hp: 'Maximum HP *Approximate',
    max_hp_val: 500,
    max_kva: 'Maximum KVA',
    max_kva_val: 10,
    purchase: 'Purchase',
    purchase_link: '',
    purchase_icon: cartIcon,
  },
  {
    model: '2025',
    href_to_model_doc: coreLossTesterDoc,
    image: model1081,
    description: 'Details',
    description_details:
      'a single instrument for a range of service, maintenance & diagnostic applications',
    max_hp: 'Maximum HP *Approximate',
    max_hp_val: 1250,
    max_kva: 'Maximum KVA',
    max_kva_val: 25,
    purchase: 'Purchase',
    purchase_link: '',
    purchase_icon: cartIcon,
  },
  {
    model: '2040',
    href_to_model_doc: coreLossTesterDoc,
    image: model1081,
    description: 'Details',
    description_details:
      'a single instrument for a range of service, maintenance & diagnostic applications',
    max_hp: 'Maximum HP *Approximate',
    max_hp_val: 1750,
    max_kva: 'Maximum KVA',
    max_kva_val: 40,
    purchase: 'Purchase',
    purchase_link: '',
    purchase_icon: cartIcon,
  },
  {
    model: '2060',
    href_to_model_doc: coreLossTesterDoc,
    image: model1081,
    description: 'Details',
    description_details:
      'a single instrument for a range of service, maintenance & diagnostic applications',
    max_hp: 'Maximum HP *Approximate',
    max_hp_val: 2500,
    max_kva: 'Maximum KVA',
    max_kva_val: 60,
    purchase: 'Purchase',
    purchase_link: '',
    purchase_icon: cartIcon,
  },
  {
    model: '2125',
    href_to_model_doc: coreLossTesterDoc,
    image: model1081,
    description: 'Details',
    description_details:
      'a single instrument for a range of service, maintenance & diagnostic applications',
    max_hp: 'Maximum HP *Approximate',
    max_hp_val: 5000,
    max_kva: 'Maximum KVA',
    max_kva_val: 125,
    purchase: 'Purchase',
    purchase_link: '',
    purchase_icon: cartIcon,
  },
  {
    model: '2200',
    href_to_model_doc: coreLossTesterDoc,
    image: model1081,
    description: 'Details',
    description_details:
      'a single instrument for a range of service, maintenance & diagnostic applications',
    max_hp: 'Maximum HP *Approximate',
    max_hp_val: 7500,
    max_kva: 'Maximum KVA',
    max_kva_val: 200,
    purchase: 'Purchase',
    purchase_link: '',
    purchase_icon: cartIcon,
  },
  {
    model: '300056',
    href_to_model_doc: coreLossTesterDoc,
    image: model1081,
    description: 'Details',
    description_details:
      'a single instrument for a range of service, maintenance & diagnostic applications',
    max_hp: 'Maximum HP *Approximate',
    max_hp_val: 10000,
    max_kva: 'Maximum KVA',
    max_kva_val: 300,
    purchase: 'Purchase',
    purchase_link: '',
    purchase_icon: cartIcon,
  },
];
