import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setShowMenu, setTableView } from '../redux/controls';
import SideMenu from '../components/SideMenu/SideMenu';
import TopMenu from '../components/TopMenu/TopMenu';
import TableComponent from '../components/Table/TableComponent';
import Footer from '../components/Footer/Footer';
import '../styles/standardPageStyle.css';

import { LEGACY_PRODUCTS_PAGE as content } from '../data/PAGE_TEXT_CONTENT';

export default function LegacyProducts() {
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  const changeTableView = () => {
    dispatch(setTableView('legacy-products'));
  };

  useEffect(() => {
    changeTableView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid stnd-container">
      <SideMenu />
      <TopMenu />

      <div className="stnd-header">
        <h1 className="stnd-h1 margin-left-two-percent">Legacy Products</h1>
      </div>
      <div className="row stnd-content" onClick={toggleMenu}>
        <div className="row stnd-top-content">
          <span className="stnd-text stnd-align-left">
            {content.intro_para}
          </span>
        </div>

        <div className="row stnd-middle-content-flex-col">
          <TableComponent />
        </div>

        <div className="row stnd-bottom-content"></div>
      </div>

      <Footer />
    </div>
  );
}
