import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import './videoPostingForm.css';
const getYouTubeID = require('get-youtube-id');

export default function VideoPostingForm({ irdVideo }) {
  const today = new Date();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    link: ``,
    title: ``,
    category: !irdVideo ? `customer-utilization` : ``,
    dynamic_balancing: false,
    core_loss_testing: false,
    date: today,
  });
  const [errors, setErrors] = useState({});
  const [formReset, setFormReset] = useState(false);

  const resetForm = (field) => {
    if (field === true) {
      form[field] = false;
    } else if (field === 'date') {
      form[field] = today;
    } else {
      form[field] = '';
    }

    setForm({
      ...form,
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const addVideoToFirebase = async (link, title, type, theDate) => {
    setLoading(true);
    const linkId = getYouTubeID(link);
    const randomId = uuidv4();
    const customerVideoRef = doc(db, 'customer_util_videos', randomId);
    const irdVidRef = doc(db, 'ird_videos', randomId);
    await setDoc(irdVideo ? irdVidRef : customerVideoRef, {
      id: randomId,
      video_link_id: linkId,
      video_title: title,
      category: type,
      date: theDate,
    });
    alert('New video added successfully');
    setLoading(false);
  };

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const findErrors = () => {
    const newErrors = {};
    const multipleCategoriesErr = `Select only one category`;
    const noCategoryErr = `Select one category`;
    if (irdVideo) {
      if (form.dynamic_balancing && form.core_loss_testing) {
        newErrors.dynamic_balancin = multipleCategoriesErr;
        newErrors.core_loss_testing = multipleCategoriesErr;
      } else if (!form.dynamic_balancing && !form.core_loss_testing) {
        newErrors.dynamic_balancing = noCategoryErr;
        newErrors.core_loss_testing = noCategoryErr;
      }
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = findErrors();
    console.log('newErrors', newErrors);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      addVideoToFirebase(form.link, form.title, form.category, form.date);
    }
    setFormReset(true);
  };

  useEffect(() => {
    if (form.dynamic_balancing) {
      setField('category', 'dynamic-balancing');
    } else if (form.core_loss_testing) {
      setField('category', 'core-loss-testing');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.dynamic_balancing, form.core_loss_testing]);

  useEffect(() => {
    if (formReset) {
      for (const key in form) {
        resetForm(key);
      }
      setFormReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formReset]);

  return (
    <div className="video-posting-form-container">
      <Form className="video-posting-form">
        <Form.Group className="mb-3" controlId="video-title">
          <Form.Label>Paste YouTube Link of Video</Form.Label>
          <Form.Control
            type="text"
            placeholder="YoutTube Link"
            value={form.link}
            onChange={(e) => setField('link', e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="video-title">
          <Form.Label>Video Title</Form.Label>
          <Form.Control
            placeholder="Title"
            value={form.title}
            className="video-title-input"
            onChange={(e) => setField('title', e.target.value)}
          />
        </Form.Group>

        {irdVideo && (
          <Form.Check
            inline
            className="video-posting-checkbox"
            label="Dynamic Balancing"
            name="dynamic-balancing"
            id="dynamic-balancing"
            feedbackType="invalid"
            checked={form.dynamic_balancing}
            value={form.dynamic_balancing}
            isInvalid={!!errors.dynamic_balancing}
            feedback={errors.dynamic_balancing}
            onChange={() =>
              setField('dynamic_balancing', !form.dynamic_balancing)
            }
          />
        )}

        {irdVideo && (
          <Form.Check
            inline
            className="video-posting-checkbox"
            label="Core Loss Testing"
            name="core-loss-testing"
            id="core-loss-testing"
            feedbackType="invalid"
            checked={form.core_loss_testing}
            value={form.core_loss_testing}
            isInvalid={!!errors.core_loss_testing}
            feedback={errors.core_loss_testing}
            onChange={() =>
              setField('core_loss_testing', !form.core_loss_testing)
            }
          />
        )}

        <div className="job-posting-button-wrapper">
          <Button
            className="job-posting-submit-button"
            type="submit"
            disabled={loading}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
}
