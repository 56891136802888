import React from 'react';
import { Button } from 'react-bootstrap';
import './featuredProduct.css';

export default function FeaturedProduct({
  title,
  productImage,
  productDescription,
  linkToProduct,
  ableToPurchaseOnline,
}) {
  return (
    <div className="container-fluid featured-product-container">
      <div className="row featured-product-row featured-product-heading-row">
        <h2 className="featured-product-heading">Featured Product</h2>
      </div>

      <div className="row featured-product-row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 featured-product-col featured-product-text-col">
          <h3 className="featured-product-title">{title}</h3>
          <span className="featured-product-text">{productDescription}</span>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 featured-product-col featured-product-image-col">
          <img
            src={productImage}
            alt="featured product"
            className="featured-product-image"
          />
        </div>
      </div>

      <div className="row featured-product-row featured-product-footer">
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 featured-product-col featured-product-footer-col">
          {ableToPurchaseOnline && (
            <Button
              href={ableToPurchaseOnline ? linkToProduct : '#'}
              className="featured-product-button"
            >
              Purchase
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
