/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
// import { useSelector, useDispatch } from 'react-redux';
// import { setContactForm, setContactFormErrors } from '../../redux/controls';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { v4 as uuidv4 } from 'uuid';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './contactForm.css';

import { LINKS as links } from '../../data/LINKS';

import logo from '../../assets/images/ird-logo-3.png';

export default function RmaForm() {
  // const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState('RMA Request');
  const [template, setTemplate] = useState('');
  const [formReset, setFormReset] = useState(false);
  const [randomId, setRandomId] = useState(uuidv4());
  const [sendingInstruments, setSendingInstruments] = useState([]);
  const [quantityInstruments, setQuantityInstruments] = useState({});
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [sensorsToBeSent, setSensorsToBeSent] = useState([]);
  const [quantitySensors, setQuantitySensors] = useState({});
  const [interestedInService, setInterestedInService] = useState([]);
  const [rentalModel, setRentalModel] = useState([]);
  const formRef = useRef();
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const today = `${month}-${day}-${year}`;
  const [errors, setErrors] = useState({});
  const [tempQtyInstr, setTempQtyInstr] = useState(null);
  const [form, setForm] = useState({
    form_id: randomId,
    file_name: randomId + '.pdf',
    form_type: 'rma-request',
    first_name: '',
    company_name: '',
    email: '',
    phone: '',
    shipping_info: '',
    po: '',
    sending_instruments: null,
    quantity_instruments: [],
    serial_numbers: [],
    sending_sensors: false,
    sensors_to_be_sent: null,
    quantity_sensors: [],
    sending_accessories: false,
    accessories_to_be_sent: '',
    disclaimer_one_acknowledged: false,
    disclaimer_two_acknowledged: false,
    disclaimer_three_acknowledged: false,
    interested_in_services: null,
    issues_or_concerns: '',
    want_rental_unit: false,
    rental_model: null,
    want_accessory_kit: false,
    message: '',
    created_on: today,
  });

  const emailJSconfig = {
    service_id: 'ird_email',
    rma_form: 'other_dvdmkwf',
  };

  const notifySendSuccess = () =>
    toast.success(
      `Thank you ${form['first_name']}. We have received your request and will get back with you asap`,
      {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      }
    );

  const notifyFormError = () =>
    toast.error('Uh oh, please check the form for errors', {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const handleOnChange = (e, usingAt) => {
    const { value, checked } = e.target;
    if (usingAt === 'set-instruments') {
      if (checked) {
        setSendingInstruments((previousState) => [...previousState, value]);
      } else {
        setSendingInstruments((previousState) => {
          return [...previousState.filter((item) => item !== value)];
        });
      }
    } else if (usingAt === 'set-sensors') {
      if (checked) {
        setSensorsToBeSent((previousState) => [...previousState, value]);
      } else {
        setSensorsToBeSent((previousState) => {
          return [...previousState.filter((item) => item !== value)];
        });
      }
    } else if (usingAt === 'set-service') {
      if (checked) {
        setInterestedInService((previousState) => [...previousState, value]);
      } else {
        setInterestedInService((previousState) => {
          return [...previousState.filter((item) => item !== value)];
        });
      }
    } else if (usingAt === 'set-rental-model') {
      if (checked) {
        setRentalModel((previousState) => [...previousState, value]);
      } else {
        setRentalModel((previousState) => {
          return [...previousState.filter((item) => item !== value)];
        });
      }
    }
  };

  const setSingleValue = (key, value, usedAt) => {
    if (usedAt === 'set-instrument-qty') {
      return setQuantityInstruments({
        ...quantityInstruments,
        [key]: value,
      });
    } else if (usedAt === 'set-serial-numbers') {
      return setSerialNumbers({
        ...serialNumbers,
        [key]: value,
      });
    } else if (usedAt === 'set-sensor-qty') {
      return setQuantitySensors({
        ...quantitySensors,
        [key]: value,
      });
    }
  };

  const findFormErrors = () => {
    const newErrors = {};

    // CONTACT INFO
    if (!form.first_name || form.first_name === '') {
      newErrors.first_name = 'please enter a first name';
    } else if (!form.company_name || form.company_name === '') {
      newErrors.company_name = 'please enter company name';
    } else if (!form.email || form.email === '') {
      newErrors.email = 'please enter a valid email address';
    } else if (!form.email.includes('@')) {
      newErrors.email = 'email must contain an @ symbol';
    } else if (!form.phone || form.phone === '') {
      newErrors.phone = 'please enter phone number';
    } else if (!form.shipping_info || form.shipping_info === '') {
      newErrors.shipping_info = 'please enter shipping information';
    }
    // INSTRUMENTS
    else if (form.sending_instruments === null) {
      newErrors.sending_instruments =
        'please select which instruments you would like to send in';
    } else if (form.quantity_instruments.length === 0) {
      newErrors.quantity_instruments =
        'please add a quantity of each instrument you would like to send';
    }

    // SERIAL NUMBERS
    else if (form.serial_numbers.length === 0) {
      newErrors.serial_numbers =
        'please enter serial numbers for the instruments being sent in';
    }

    // SENSORS
    else if (form.sending_sensors === true) {
      if (form.sensors_to_be_sent === null) {
        newErrors.sensors_to_be_sent = 'please select at least one sensor';
      } else if (form.quantity_sensors.length === 0) {
        newErrors.quantity_sensors =
          'please add a quantity of each sensor you would like to send';
      }
    }

    // ACCESSORIES
    else if (
      form.sending_accessories === true &&
      form.accessories_to_be_sent === ''
    ) {
      newErrors.accessories_to_be_sent =
        'please provide the accessories you would like to send in';
    }

    // RENTAL MODEL
    // else if (
    //   form.rental_model_295_plus === true &&
    //   form.rental_model_similar_instrument === true
    // ) {
    //   newErrors.rental_model_295_plus =
    //     'please select either similar instrument or 295+';
    //   newErrors.rental_model_similar_instrument =
    //     'please select either similar instrument or 295+';
    // }

    // DISCLAIMERS
    else if (!form.disclaimer_one_acknowledged) {
      newErrors.disclaimer_one_acknowledged = 'please check to acknowledge';
    } else if (!form.disclaimer_two_acknowledged) {
      newErrors.disclaimer_two_acknowledged = 'please check to acknowledge';
    } else if (!form.disclaimer_three_acknowledged) {
      newErrors.disclaimer_three_acknowledged = 'please check to acknowledge';
    }

    return newErrors;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetForm = (field, value) => {
    if (field === 'form_id') {
      field = randomId;
    } else if (field === 'file_name') {
      field = randomId + '.pdf';
    } else if (value === true) {
      form[field] = false; // setting the DOM object value
      field = false; // setting the form object value
    } else if (field === 'created_on') {
      field = today;
    } else if (field === 'rental_model') {
      form[field] = false;
      field = false;
    } else {
      form[field] = '';
    }

    setForm({
      ...form,
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const goToPage = () => {
    window.open(links.file_transfer);
  };

  const handleReset = () => {
    setFormReset(true);
  };

  const addRmaFormToFirebase = async (data) => {
    setLoading(true);
    const rmaReqRef = doc(db, 'rma_requests', randomId);
    await setDoc(rmaReqRef, data);
    setLoading(false);
  };

  function handleSubmit(e) {
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      notifyFormError();
    } else {
      notifySendSuccess();
      addRmaFormToFirebase(form).then(() => {
        emailjs
          .sendForm(
            emailJSconfig.service_id,
            template,
            formRef.current,
            'ASfyRsTYNICgAod9p'
          )
          .then(
            (result) => {
              console.log(result.text);
              notifySendSuccess();
              handleReset();
              formRef.current.reset();
            },
            (error) => {
              console.log(error.text);
            }
          );
      });
    }
  }

  useEffect(() => {
    setTemplate(emailJSconfig.rma_form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setField('created_on', today);
    setField('form_id', randomId);
    setField('form_type', 'rma_request');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setField('file_name', `rma-request-from-${form.first_name}-${today}.pdf`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.first_name]);

  useEffect(() => {
    const arrToStr = sendingInstruments.join(', ');
    setField('sending_instruments', arrToStr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendingInstruments]);

  useEffect(() => {
    setField('quantity_instruments', quantityInstruments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantityInstruments]);

  useEffect(() => {
    // const arrToStr = sendingInstruments.join(', ');
    setField('serial_numbers', serialNumbers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serialNumbers]);

  useEffect(() => {
    const arrToStr = sensorsToBeSent.join(', ');
    setField('sensors_to_be_sent', arrToStr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorsToBeSent]);

  useEffect(() => {
    setField('quantity_sensors', quantitySensors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantitySensors]);

  useEffect(() => {
    const arrToStr = interestedInService.join(', ');
    setField('interested_in_services', arrToStr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interestedInService]);

  useEffect(() => {
    const arrToStr = rentalModel.join(', ');
    setField('rental_model', arrToStr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rentalModel]);

  useEffect(() => {
    if (formReset) {
      for (const key in form) {
        let value = form[key];
        resetForm(key, value);
      }
      setFormReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formReset]);

  // console.log('form', form);
  // console.log('sendingInstruments', sendingInstruments);

  return (
    <div className="contact-form-container">
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="contact-header-section">
        <img src={logo} alt="logo" className="contact-logo-small" />
        <div className="contact-header-text-wrapper">
          <p className="contact-header-text">
            Take 5 minutes to submit this RMA request form and one of our
            product specialist will be in touch within 24 hours with your quote.
          </p>
        </div>
      </div>
      <Form className="contact-form" ref={formRef}>
        <Form.Group className="mb-3 hidden" controlId="subject">
          <Form.Label>Subject</Form.Label>
          <Form.Control
            placeholder="Subject"
            name="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="first_name">
          <Form.Label>
            <strong>What is your first name?</strong>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="first name"
            name="first_name"
            value={form.first_name}
            isInvalid={!!errors.first_name}
            errors={errors.first_name}
            onChange={(e) => setField('first_name', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.first_name}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="company-name">
          <Form.Label>
            <strong>What is the name of your company?</strong>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="company name"
            name="company_name"
            value={form.company_name}
            isInvalid={!!errors.company_name}
            errors={errors.company_name}
            onChange={(e) => setField('company_name', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.company_name}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="email">
          <Form.Label>
            <strong>What is your email address?</strong>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="email"
            name="email"
            value={form.email}
            isInvalid={!!errors.email}
            errors={errors.email}
            onChange={(e) => setField('email', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="phone">
          <Form.Label>
            <strong>What is your phone number?</strong>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="phone number"
            name="phone"
            value={form.phone}
            isInvalid={!!errors.phone}
            errors={errors.phone}
            onChange={(e) => setField('phone', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.phone}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="shipping_info">
          <Form.Label>
            <strong>Please provide your shipping information </strong>
            {''}
            (ship-to address, your preferred return shipping method, and/or
            shipping account number).
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="shipping info"
            name="shipping_info"
            value={form.shipping_info}
            isInvalid={!!errors.shipping_info}
            errors={errors.shipping_info}
            onChange={(e) => setField('shipping_info', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.shipping_info}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="po">
          <Form.Label>
            <strong>Do you have a PO? </strong>(if no, simply write "N/A").
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="PO"
            name="po"
            value={form.po}
            isInvalid={!!errors.po}
            errors={errors.po}
            onChange={(e) => setField('po', e.target.value)}
          />
        </Form.Group>
        {/* TODO: SENDING INSTRUMENTS */}
        <Form.Group className="mb-3" controlId="sending_instruments">
          <Form.Label>
            <strong>What instruments will you be sending in?</strong> (choose as
            many as you like)
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="206"
              type="checkbox"
              id={`sending_instruments_206`}
              name="sending_instruments"
              feedbackType="invalid"
              value={'206'}
              isInvalid={!!errors.sending_instruments}
              feedback={errors.sending_instruments}
              onChange={(e) => handleOnChange(e, 'set-instruments')}
            />
            <Form.Check
              inline
              label="258"
              type="checkbox"
              id={`sending_instruments_258`}
              name="sending_instruments"
              feedbackType="invalid"
              value={'258'}
              isInvalid={!!errors.sending_instruments}
              feedback={errors.sending_instruments}
              onChange={(e) => handleOnChange(e, 'set-instruments')}
            />
            <Form.Check
              inline
              label="236"
              type="checkbox"
              id={`sending_instruments_236`}
              name="sending_instruments"
              feedbackType="invalid"
              value={'236'}
              isInvalid={!!errors.sending_instruments}
              feedback={errors.sending_instruments}
              onChange={(e) => handleOnChange(e, 'set-instruments')}
            />
            <Form.Check
              inline
              label="246"
              type="checkbox"
              id={`sending_instruments_246`}
              name="sending_instruments"
              feedbackType="invalid"
              value={'246'}
              isInvalid={!!errors.sending_instruments}
              feedback={errors.sending_instruments}
              onChange={(e) => handleOnChange(e, 'set-instruments')}
            />
            <Form.Check
              inline
              label="290 III"
              type="checkbox"
              id={`sending_instruments_290_III`}
              name="sending_instruments"
              feedbackType="invalid"
              value={'290 III'}
              isInvalid={!!errors.sending_instruments}
              feedback={errors.sending_instruments}
              onChange={(e) => handleOnChange(e, 'set-instruments')}
            />
            <Form.Check
              inline
              label="290/295+"
              type="checkbox"
              id={`sending_instruments_290_295_plus`}
              name="sending_instruments"
              feedbackType="invalid"
              value={'290/295+'}
              isInvalid={!!errors.sending_instruments}
              feedback={errors.sending_instruments}
              onChange={(e) => handleOnChange(e, 'set-instruments')}
            />
            <Form.Check
              inline
              label="Other"
              type="checkbox"
              id={`sending_instruments_other`}
              name="sending_instruments"
              feedbackType="invalid"
              value={'other'}
              isInvalid={!!errors.sending_instruments}
              feedback={errors.sending_instruments}
              onChange={(e) => handleOnChange(e, 'set-instruments')}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>
            <strong>
              How many of each instrument(s) will you be sending in?
            </strong>
          </Form.Label>
          <div className="row mb-3">
            {/* TODO: WORKING ON QTY INSTRUMENTS */}
            <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <Form.Control
                type="text"
                placeholder="206"
                id={`quantity_instruments_206`}
                name="quantity_instruments"
                feedbackType="invalid"
                value={form.quantity_instruments['qty_206'] || ''}
                isInvalid={!!errors.quantity_instruments}
                feedback={errors.quantity_instruments}
                onChange={(e) =>
                  setSingleValue(
                    'qty_206',
                    e.target.value,
                    'set-instrument-qty'
                  )
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.quantity_instruments}
              </Form.Control.Feedback>
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <Form.Control
                type="text"
                placeholder="258"
                id={`quantity_instruments_258`}
                name="quantity_instruments"
                feedbackType="invalid"
                value={form.quantity_instruments['qty_258'] || ''}
                isInvalid={!!errors.quantity_instruments}
                feedback={errors.quantity_instruments}
                onChange={(e) =>
                  setSingleValue(
                    'qty_258',
                    e.target.value,
                    'set-instrument-qty'
                  )
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.quantity_instruments}
              </Form.Control.Feedback>
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <Form.Control
                type="text"
                placeholder="236"
                id={`quantity_instruments_236`}
                name="quantity_instruments"
                feedbackType="invalid"
                value={form.quantity_instruments['qty_236'] || ''}
                isInvalid={!!errors.quantity_instruments}
                feedback={errors.quantity_instruments}
                onChange={(e) =>
                  setSingleValue(
                    'qty_236',
                    e.target.value,
                    'set-instrument-qty'
                  )
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.quantity_instruments}
              </Form.Control.Feedback>
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <Form.Control
                type="text"
                placeholder="246"
                id={`quantity_instruments_246`}
                name="quantity_instruments"
                feedbackType="invalid"
                value={form.quantity_instruments['qty_246'] || ''}
                isInvalid={!!errors.quantity_instruments}
                feedback={errors.quantity_instruments}
                onChange={(e) =>
                  setSingleValue(
                    'qty_246',
                    e.target.value,
                    'set-instrument-qty'
                  )
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.quantity_instruments}
              </Form.Control.Feedback>
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <Form.Control
                type="text"
                placeholder="290 III"
                id={`quantity_instruments_290_III`}
                name="quantity_instruments"
                feedbackType="invalid"
                value={form.quantity_instruments['qty_290_III'] || ''}
                isInvalid={!!errors.quantity_instruments}
                feedback={errors.quantity_instruments}
                onChange={(e) =>
                  setSingleValue(
                    'qty_290_III',
                    e.target.value,
                    'set-instrument-qty'
                  )
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.quantity_instruments}
              </Form.Control.Feedback>
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <Form.Control
                type="text"
                placeholder="290/295+"
                id={`quantity_instruments_290_295_plus`}
                name="quantity_instruments"
                feedbackType="invalid"
                value={form.quantity_instruments['qty_290_295_plus'] || ''}
                isInvalid={!!errors.quantity_instruments}
                feedback={errors.quantity_instruments}
                onChange={(e) =>
                  setSingleValue(
                    'qty_290_295_plus',
                    e.target.value,
                    'set-instrument-qty'
                  )
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.quantity_instruments}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <Form.Control
                type="text"
                placeholder="other"
                id={`quantity_instruments_other`}
                name="quantity_instruments"
                feedbackType="invalid"
                value={form.quantity_instruments['qty_other'] || ''}
                isInvalid={!!errors.quantity_instruments}
                feedback={errors.quantity_instruments}
                onChange={(e) =>
                  setSingleValue(
                    'qty_other',
                    e.target.value,
                    'set-instrument-qty'
                  )
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.quantity_instruments}
              </Form.Control.Feedback>
            </div>
          </div>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>
            <strong>What are the serial number(s) for each instrument? </strong>
            (can be found on the sticker located on the back of the instrument).
          </Form.Label>
          <div className="row mb-3">
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <Form.Control
                type="text"
                placeholder="206"
                id={`serial_numbers_206`}
                name="serial_numbers"
                feedbackType="invalid"
                value={form.serial_numbers['206'] || ''}
                isInvalid={!!errors.serial_numbers}
                feedback={errors.serial_numbers}
                onChange={(e) =>
                  setSingleValue('206', e.target.value, 'set-serial-numbers')
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.serial_numbers}
              </Form.Control.Feedback>
            </div>
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <Form.Control
                type="text"
                placeholder="258"
                id={`serial_numbers_258`}
                name="serial_numbers"
                feedbackType="invalid"
                value={form.serial_numbers['258'] || ''}
                isInvalid={!!errors.serial_numbers}
                feedback={errors.serial_numbers}
                onChange={(e) =>
                  setSingleValue('258', e.target.value, 'set-serial-numbers')
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.serial_numbers}
              </Form.Control.Feedback>
            </div>
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <Form.Control
                type="text"
                placeholder="236"
                id={`serial_numbers_236`}
                name="serial_numbers"
                feedbackType="invalid"
                value={form.serial_numbers['236'] || ''}
                isInvalid={!!errors.serial_numbers}
                feedback={errors.serial_numbers}
                onChange={(e) =>
                  setSingleValue('236', e.target.value, 'set-serial-numbers')
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.serial_numbers}
              </Form.Control.Feedback>
            </div>
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <Form.Control
                type="text"
                placeholder="246"
                id={`serial_numbers_246`}
                name="serial_numbers"
                feedbackType="invalid"
                value={form.serial_numbers['246'] || ''}
                isInvalid={!!errors.serial_numbers}
                feedback={errors.serial_numbers}
                onChange={(e) =>
                  setSingleValue('246', e.target.value, 'set-serial-numbers')
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.serial_numbers}
              </Form.Control.Feedback>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <Form.Control
                type="text"
                placeholder="290 III"
                id={`serial_numbers_290_III`}
                name="serial_numbers"
                feedbackType="invalid"
                value={form.serial_numbers['290_III'] || ''}
                isInvalid={!!errors.serial_numbers}
                feedback={errors.serial_numbers}
                onChange={(e) =>
                  setSingleValue(
                    '290_III',
                    e.target.value,
                    'set-serial-numbers'
                  )
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.serial_numbers}
              </Form.Control.Feedback>
            </div>
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <Form.Control
                type="text"
                placeholder="290/295+"
                id={`serial_numbers_290_295_plus`}
                name="serial_numbers"
                feedbackType="invalid"
                value={form.serial_numbers['290_295_plus'] || ''}
                isInvalid={!!errors.serial_numbers}
                feedback={errors.serial_numbers}
                onChange={(e) =>
                  setSingleValue(
                    '290_295_plus',
                    e.target.value,
                    'set-serial-numbers'
                  )
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.serial_numbers}
              </Form.Control.Feedback>
            </div>
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <Form.Control
                type="text"
                placeholder="other"
                id={`serial_numbers_other`}
                name="serial_numbers"
                feedbackType="invalid"
                value={form.serial_numbers['other'] || ''}
                isInvalid={!!errors.serial_numbers}
                feedback={errors.serial_numbers}
                onChange={(e) =>
                  setSingleValue('other', e.target.value, 'set-serial-numbers')
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.serial_numbers}
              </Form.Control.Feedback>
            </div>
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="photo_upload">
          <Form.Label>
            <strong>
              Can you upload a photo of the instrument(s) that you wish to send
              in?{' '}
            </strong>
            (use the link below to upload the photo(s)).
          </Form.Label>
          <br />
          <span className="underline-link" onClick={goToPage}>
            Upload photo(s)
          </span>
        </Form.Group>

        <Form.Group className="mb-3" controlId="sending_sensors">
          <strong>Will you be sending any sensors?</strong> (skip if not
          applicable)
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              type="checkbox"
              id={`sending-sensors-inline-checkbox-yes`}
              name="sending_sensors"
              checked={form.sending_sensors}
              label={form.sending_sensors ? 'Yes' : 'Yes/No'}
              value={form.sending_sensors}
              feedbackType="invalid"
              isInvalid={!!errors.sending_sensors}
              errors={errors.sending_sensors}
              feedback={errors.sending_sensors}
              onChange={() =>
                setField('sending_sensors', !form.sending_sensors)
              }
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="sensors_to_be_sent">
          <Form.Label>
            <strong>What sensor(s) will you be sending in?</strong> (choose as
            many as you like, skip if not applicable)
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="546"
              type="checkbox"
              id={`sending_sensors_to_be_sent_546`}
              name="sensors_to_be_sent"
              feedbackType="invalid"
              value={'546'}
              isInvalid={!!errors.sensors_to_be_sent}
              feedback={errors.sensors_to_be_sent}
              onChange={(e) => handleOnChange(e, 'set-sensors')}
            />
            <Form.Check
              inline
              label="544"
              type="checkbox"
              id={`sending_sensors_to_be_sent_544`}
              name="sensors_to_be_sent"
              feedbackType="invalid"
              value={'544'}
              isInvalid={!!errors.sensors_to_be_sent}
              feedback={errors.sensors_to_be_sent}
              onChange={(e) => handleOnChange(e, 'set-sensors')}
            />
            <Form.Check
              inline
              label="544M"
              type="checkbox"
              id={`sending_sensors_to_be_sent_544M`}
              name="sensors_to_be_sent"
              feedbackType="invalid"
              value={'544M'}
              isInvalid={!!errors.sensors_to_be_sent}
              feedback={errors.sensors_to_be_sent}
              onChange={(e) => handleOnChange(e, 'set-sensors')}
            />
            <Form.Check
              inline
              label="9000a"
              type="checkbox"
              id={`sending_sensors_to_be_sent_9000a`}
              name="sensors_to_be_sent"
              feedbackType="invalid"
              value={'9000a'}
              isInvalid={!!errors.sensors_to_be_sent}
              feedback={errors.sensors_to_be_sent}
              onChange={(e) => handleOnChange(e, 'set-sensors')}
            />
            <Form.Check
              inline
              label="Other"
              type="checkbox"
              id={`sending_sensors_to_be_sent_other`}
              name="sensors_to_be_sent"
              feedbackType="invalid"
              value={'other'}
              isInvalid={!!errors.sensors_to_be_sent}
              feedback={errors.sensors_to_be_sent}
              onChange={(e) => handleOnChange(e, 'set-sensors')}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>
            <strong>How many of each sensor(s) will you be sending in?</strong>{' '}
            (skip if not applicable)
          </Form.Label>

          <div className="row">
            <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <Form.Control
                type="text"
                placeholder="546"
                id={`quantity_sensors_546`}
                name="quantity_sensors"
                feedbackType="invalid"
                value={form.quantity_sensors['qty_546'] || ''}
                isInvalid={!!errors.quantity_sensors}
                feedback={errors.quantity_sensors}
                onChange={(e) =>
                  setSingleValue('qty_546', e.target.value, 'set-sensor-qty')
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.quantity_sensors}
              </Form.Control.Feedback>
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <Form.Control
                type="text"
                placeholder="544"
                id={`quantity_sensors_544`}
                name="quantity_sensors"
                feedbackType="invalid"
                value={form.quantity_sensors['qty_544'] || ''}
                isInvalid={!!errors.quantity_sensors}
                feedback={errors.quantity_sensors}
                onChange={(e) =>
                  setSingleValue('qty_544', e.target.value, 'set-sensor-qty')
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.quantity_sensors}
              </Form.Control.Feedback>
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <Form.Control
                type="text"
                placeholder="544M"
                id={`quantity_sensors_544M`}
                name="quantity_sensors"
                feedbackType="invalid"
                value={form.quantity_sensors['qty_544M'] || ''}
                isInvalid={!!errors.quantity_sensors}
                feedback={errors.quantity_sensors}
                onChange={(e) =>
                  setSingleValue('qty_544M', e.target.value, 'set-sensor-qty')
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.quantity_sensors}
              </Form.Control.Feedback>
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <Form.Control
                type="text"
                placeholder="9000a"
                id={`quantity_sensors_9000a`}
                name="quantity_sensors"
                feedbackType="invalid"
                value={form.quantity_sensors['qty_9000a'] || ''}
                isInvalid={!!errors.quantity_sensors}
                feedback={errors.quantity_sensors}
                onChange={(e) =>
                  setSingleValue('qty_9000a', e.target.value, 'set-sensor-qty')
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.quantity_sensors}
              </Form.Control.Feedback>
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <Form.Control
                type="text"
                placeholder="other"
                id={`quantity_sensors_other`}
                name="quantity_sensors"
                feedbackType="invalid"
                value={form.quantity_sensors['qty_other'] || ''}
                isInvalid={!!errors.quantity_sensors}
                feedback={errors.quantity_sensors}
                onChange={(e) =>
                  setSingleValue('qty_other', e.target.value, 'set-sensor-qty')
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.quantity_sensors}
              </Form.Control.Feedback>
            </div>
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="sending_accessories">
          <Form.Label>
            <strong>
              Any accessories such as cables or photocells are tested at no
              additional cost to you. Would you like to send in any accessories
              as well?
            </strong>{' '}
            (skip if not applicable)
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              name="sending_accessories"
              type="checkbox"
              id={`sending-accessories-inline-checkbox`}
              checked={form.sending_accessories}
              label={form.sending_accessories ? 'Yes' : 'Yes/No'}
              value={form.sending_accessories}
              isInvalid={!!errors.sending_accessories}
              feedbackType="invalid"
              errors={errors.sending_accessories}
              onChange={() =>
                setField('sending_accessories', !form.sending_accessories)
              }
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="accessories_to_be_sent">
          <Form.Label>
            <strong>What accessories would you also like to send in?</strong>{' '}
            (skip if not applicable)
          </Form.Label>
          <Form.Label className="bold-text"></Form.Label>
          <Form.Control
            type="text"
            placeholder="accessories"
            name="accessories_to_be_sent"
            value={form.accessories_to_be_sent}
            isInvalid={!!errors.accessories_to_be_sent}
            errors={errors.accessories_to_be_sent}
            onChange={(e) => setField('accessories_to_be_sent', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.accessories_to_be_sent}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="disclaimer_one">
          <Form.Label>
            <em>
              IRD calibrations ensure that the National Institute of Standards
              and Technologies (NIST) are met in accordance with ANSI/NCSL
              Z540-1-1994. In addition, IRD will also provide a document
              providing a positive balance test, confirming the calibration of
              the unit. Each Certificate of Calibration will expire 12 months
              from the ship date. Please also be aware that IRD may need to
              change settings (i.e. rotor setup) in order to calibrate. IRD will
              attempt to return the unit with the customer’s settings, but is
              not liable if settings are changed or lost.
            </em>
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="Acknowledgement for the above disclaimer"
              name="disclaimer_one_acknowledged"
              type="checkbox"
              id={`disclaimer-one-acknowledgement-inline-checkbox`}
              checked={form.disclaimer_one_acknowledged}
              value={form.disclaimer_one_acknowledged}
              isInvalid={!!errors.disclaimer_one_acknowledged}
              feedbackType="invalid"
              feedback={errors.disclaimer_one_acknowledged}
              errors={errors.disclaimer_one_acknowledged}
              onChange={(e) =>
                setField(
                  'disclaimer_one_acknowledged',
                  !form.disclaimer_one_acknowledged
                )
              }
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="disclaimer_two">
          <Form.Label>
            <em>
              For all repairs, IRD will address any issues listed in this form
              as well as any issues that prevent the unit from completing
              calibration. While repairing equipment, IRD will try to preserve
              any settings or saved files when possible, but is not liable for
              the loss of any data not included in a new unit. This includes,
              but is not limited to memory files, banners, and database
              information.
            </em>
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="Acknowledgement for the above disclaimer"
              name="disclaimer_two_acknowledged"
              type="checkbox"
              id={`disclaime-two-acknowledgement-inline-checkbox`}
              checked={form.disclaimer_two_acknowledged}
              value={form.disclaimer_two_acknowledged}
              isInvalid={!!errors.disclaimer_two_acknowledged}
              feedbackType="invalid"
              feedback={errors.disclaimer_two_acknowledged}
              errors={errors.disclaimer_two_acknowledged}
              onChange={(e) =>
                setField(
                  'disclaimer_two_acknowledged',
                  !form.disclaimer_two_acknowledged
                )
              }
            />
          </div>
        </Form.Group>
        {/* TODO: INTERESTED IN SERVICES */}
        <Form.Group className="mb-3">
          <Form.Label>
            <strong>What services are you interested in?</strong> (skip if not
            applicable)
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="Calibration"
              type="checkbox"
              id={`interested_in_service_calibration`}
              name="interested_in_services"
              feedbackType="invalid"
              value={'calibration'}
              isInvalid={!!errors.interested_in_services}
              feedback={errors.interested_in_services}
              onChange={(e) => handleOnChange(e, 'set-service')}
            />
            <Form.Check
              inline
              label="Repair"
              type="checkbox"
              id={`interested_in_service_repair`}
              name="interested_in_services"
              feedbackType="invalid"
              value={'repair'}
              isInvalid={!!errors.interested_in_services}
              feedback={errors.interested_in_services}
              onChange={(e) => handleOnChange(e, 'set-service')}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="disclaimer_three">
          <Form.Label>
            <em>
              One last note: We charge a $250 evaluation fee if a unit is sent
              in and neither service is purchased/completed. If calibration is
              selected, but the unit fails to calibrate, we will contact you for
              approval to repair. Not checking the repair box can delay lead
              time on returning your unit to you. If repair is selected, and new
              repairs are not needed, you will only be charged for calibration.
            </em>
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="Acknowledgement for the above disclaimer"
              name="disclaimer_three_acknowledged"
              type="checkbox"
              id={`disclaime-three-acknowledgement-inline-checkbox`}
              checked={form.disclaimer_three_acknowledged}
              value={form.disclaimer_three_acknowledged}
              isInvalid={!!errors.disclaimer_three_acknowledged}
              feedbackType="invalid"
              feedback={errors.disclaimer_three_acknowledged}
              errors={errors.disclaimer_three_acknowledged}
              onChange={(e) =>
                setField(
                  'disclaimer_three_acknowledged',
                  !form.disclaimer_three_acknowledged
                )
              }
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="issues_or_concerns">
          <Form.Label className="bold-text">
            Please let us know what issues your unit is showing and any concerns
            you might have.
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            placeholder="issues or concerns"
            name="issues_or_concerns"
            value={form.issues_or_concerns}
            isInvalid={!!errors.issues_or_concerns}
            errors={errors.issues_or_concerns}
            onChange={(e) => setField('issues_or_concerns', e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="want_rental_unit">
          <Form.Label>
            <strong>
              Would you like a rental unit while we work on yours?
            </strong>{' '}
            (skip if not applicable)
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              name="want_rental_unit"
              type="checkbox"
              id={`want-rental-unit-inline-checkbox-yes`}
              checked={form.want_rental_unit}
              label={form.want_rental_unit ? 'Yes' : 'Yes/No'}
              value={form.want_rental_unit}
              isInvalid={!!errors.want_rental_unit}
              feedbackType="invalid"
              feedback={errors.want_rental_unit}
              errors={errors.want_rental_unit}
              onChange={() =>
                setField('want_rental_unit', !form.want_rental_unit)
              }
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>
            <strong>
              Would you prefer to get a rental model that matched your
              instrument(s), or try our new 295+ model*?
            </strong>
            <p>
              <em>
                *Renting our latest instrument is a great way to try out
                advanced features to see if it's a good fit to possibly upgrade
                from your older unit.
              </em>{' '}
              (skip if not applicable)
            </p>
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="Similar instrument(s)"
              type="checkbox"
              id={`rental_model_similar_instrument`}
              name="rental_model"
              feedbackType="invalid"
              value={'similar instruments'}
              isInvalid={!!errors.rental_model}
              feedback={errors.rental_model}
              onChange={(e) => handleOnChange(e, 'set-rental-model')}
            />
            <Form.Check
              inline
              label="295+ instrument"
              type="checkbox"
              id={`rental_model_similar_295_plus`}
              name="rental_model"
              feedbackType="invalid"
              value={'295+ instrument'}
              isInvalid={!!errors.rental_model}
              feedback={errors.rental_model}
              onChange={(e) => handleOnChange(e, 'set-rental-model')}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="want_accessory_kit">
          <Form.Label>
            <strong>
              Would you like a rental accessory kit for this/these unit(s)? The
              kit will come with all cables and sensors needed for balancing.
            </strong>{' '}
            (skip if not applicable)
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              name="want_accessory_kit"
              type="checkbox"
              id={`want-accessory-kit-inline-checkbox`}
              checked={form.want_accessory_kit}
              label={form.want_accessory_kit ? 'Yes' : 'Yes/No'}
              value={form.want_accessory_kit}
              isInvalid={!!errors.want_accessory_kit}
              feedbackType="invalid"
              feedback={errors.want_accessory_kit}
              errors={errors.want_accessory_kit}
              onChange={() =>
                setField('want_accessory_kit', !form.want_accessory_kit)
              }
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="rma_message">
          <Form.Label>
            <strong>Anything else you would like to add?</strong> (if not,
            simply click the submit button)
          </Form.Label>
          <Form.Label className="bold-text"></Form.Label>
          <Form.Control
            as="textarea"
            rows="4"
            placeholder="message"
            name="message"
            value={form.message}
            isInvalid={!!errors.message}
            errors={errors.message}
            onChange={(e) => setField('message', e.target.value)}
          />
        </Form.Group>

        <div className="contact-form-button-wrapper">
          <Button
            className="contact-submit-button"
            type="submit"
            disabled={loading}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
}
