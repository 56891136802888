/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import ModalWindow from '../Modal/ModalWindow';
import './newBalancingMachineForm.css';

import { NEW_BALANCE_MACHINE_FORM as content } from '../../data/PAGE_TEXT_CONTENT';

import rotorDetailsImg from '../../assets/images/rotor-details.png';

export default function NewBalancingMachineForm() {
  const today = new Date();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [randomId, setRandomId] = useState(uuidv4());
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [formReset, setFormReset] = useState(false);
  const [form, setForm] = useState({
    form_id: randomId,
    file_name: randomId + '.pdf',
    date: '',
    contact_person: '',
    title: '',
    email: '',
    phone: '',
    fax: '',
    company_name: '',
    company_address: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
    sec_2_1_fan: false,
    sec_2_1_armature: false,
    sec_2_1_turbine: false,
    sec_2_1_roll: false,
    sec_2_1_impeller: false,
    sec_2_1_spindle: false,
    sec_2_1_disk: false,
    sec_2_1_crankshaft: false,
    sec_2_1_flywheel: false,
    sec_2_1_other: false,
    sec_2_1_other_details: '',
    sec_2_2_between_bearings: false,
    sec_2_2_overhung: false,
    sec_2_2_between_bearings_and_overhung: false,
    sec_2_3_max_upward_force: '',
    sec_2_3_unit_kg: false,
    sec_2_3_unit_lb: false,
    sec_2_3_not_sure: false,
    sec_2_4_yes: false,
    sec_2_4_no: false,
    sec_2_4_both: false,
    sec_2_5_max_rotor_weight: '',
    sec_2_5_unit_kg: false,
    sec_2_5_unit_lb: false,
    sec_2_6_min_rotor_weight: '',
    sec_2_6_unit_kg: false,
    sec_2_6_unit_lb: false,
    sec_2_7_max_swing_diameter: '',
    sec_2_7_unit_mm: false,
    sec_2_7_unit_in: false,
    sec_2_8_max_rotor_length: '',
    sec_2_8_unit_mm: false,
    sec_2_8_unit_in: false,
    sec_2_9_min_rotor_length: '',
    sec_2_9_unit_mm: false,
    sec_2_9_unit_in: false,
    sec_2_10_max_dist_between_journals: '',
    sec_2_10_unit_mm: false,
    sec_2_10_unit_in: false,
    sec_2_11_min_dist_between_journals: '',
    sec_2_11_unit_mm: false,
    sec_2_11_unit_in: false,
    sec_2_12_own_bearing: false,
    sec_2_12_journal_surface: false,
    sec_2_13_max_journal_diam: '',
    sec_2_13_unit_mm: false,
    sec_2_13_unit_in: false,
    sec_2_14_min_journal_diam: '',
    sec_2_14_unit_mm: false,
    sec_2_14_unit_in: false,
    sec_2_15_min_journal_width: '',
    sec_2_15_unit_mm: false,
    sec_2_15_unit_in: false,
    sec_2_16_max_moment_of_inertia: '',
    sec_2_16_unit_kgm2: false,
    sec_2_16_unit_lbft2: false,
    sec_2_16_unit_not_sure: false,
    sec_2_17_speed_range_rotors: '',
    sec_2_18_max_power_absorbed: '',
    sec_2_18_unit_kw: false,
    sec_2_18_unit_hp: false,
    sec_2_18_unit_not_sure: false,
    sec_2_19_max_bal_speed_rolls: '',
    sec_2_19_unit_mmin: false,
    sec_2_19_unit_ftmin: false,
    sec_2_20_high_int_yes: false,
    sec_2_20_high_int_no: false,
    sec_3_1_one_plane: false,
    sec_3_1_two_plane: false,
    sec_3_1_both_planes: false,
    sec_3_2_tolerance_amount: '',
    sec_3_2_unit_iso: false,
    sec_3_2_unit_api: false,
    sec_3_2_unit_gmm: false,
    sec_3_2_unit_ozin: false,
    sec_3_3_max_init_unbal: '',
    sec_3_3_unit_gin: false,
    sec_3_3_unit_gmm: false,
    sec_3_3_unit_ozin: false,
    sec_3_3_unit_not_sure: false,
    sec_3_4_num_rotors_bal: '',
    sec_4_5_ac_volt: '',
    sec_4_5_phase: '',
    sec_4_5_hz: '',
    sec_4_6_belt: false,
    sec_4_6_end_drive: false,
    sec_4_7_drive_power_req: '',
    sec_4_7_unit_kw: false,
    sec_4_7_unit_hp: false,
    sec_4_7_unit_not_sure: false,
    sec_4_8_var_speed: false,
    sec_4_9_portable: false,
    sec_4_9_digital: false,
    sec_4_9_computer: false,
    sec_4_10_cert_yes: false,
    sec_4_10_cert_no: false,
    sec_4_10_cert_not_sure: false,
    sec_4_11_safety_none: false,
    sec_4_11_safety_perimeter_fence: false,
    sec_5_2_bearing_details: '',
    sec_5_3_other_info: '',
    sec_5_4_additional_req: '',
    createdAt: today,
  });
  const [errors, setErrors] = useState({});

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetForm = (field) => {
    if (field === 'form_id') {
      field = randomId;
    } else if (field === true) {
      form[field] = false;
    } else if (field === 'createdAt') {
      field = today;
    } else {
      form[field] = '';
    }

    setForm({
      ...form,
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  // console.log('form[file_name]', form['file_name']);

  const findFormErrors = () => {
    const newErrors = {};
    const unitErr = 'select one unit of measurement';

    if (!form.date || form.date === '') {
      newErrors.date = 'date cannot be blank';
    } else if (!form.contact_person || form.contact_person === '') {
      newErrors.contact_person = 'must enter a contact person';
    } else if (!form.email || form.email === '') {
      newErrors.email = 'must enter a valid email address';
    } else if (!form.email.includes('@')) {
      newErrors.email = 'email must contain and @ symbol';
    } else if (!form.phone || form.phone === '') {
      newErrors.phone = 'must enter phone number';
    } else if (!form.phone || form.phone === '') {
      newErrors.phone = 'must enter phone number';
    } else if (!form.fax || form.fax === '') {
      newErrors.fax = 'enter a fax number. if no fax number: enter 0';
    } else if (!form.company_name || form.company_name === '') {
      newErrors.company_name = 'must enter company name';
    } else if (!form.company_address || form.company_address === '') {
      newErrors.company_address = 'must enter company address';
    } else if (!form.city || form.city === '') {
      newErrors.city = 'must enter city';
    } else if (!form.state || form.state === '') {
      newErrors.state = 'must enter state';
    } else if (!form.postal_code || form.postal_code === '') {
      newErrors.postal_code = 'must enter postal code';
    } else if (!form.country || form.country === '') {
      newErrors.country = 'must enter country';
    }
    // SEC 2.3
    else if (form.sec_2_3_unit_kg && form.sec_2_3_unit_lb) {
      newErrors.sec_2_3_unit_kg = unitErr;
      newErrors.sec_2_3_unit_lb = unitErr;
    }

    // SEC 2.5
    else if (form.sec_2_5_unit_kg && form.sec_2_5_unit_lb) {
      newErrors.sec_2_5_unit_kg = unitErr;
      newErrors.sec_2_5_unit_lb = unitErr;
    }
    // SEC 2.6
    else if (form.sec_2_6_unit_kg && form.sec_2_6_unit_lb) {
      newErrors.sec_2_6_unit_kg = unitErr;
      newErrors.sec_2_6_unit_lb = unitErr;
    }
    // SEC 2.7
    else if (form.sec_2_7_unit_mm && form.sec_2_7_unit_in) {
      newErrors.sec_2_7_unit_mm = unitErr;
      newErrors.sec_2_7_unit_in = unitErr;
    }
    // SEC 2.8
    else if (form.sec_2_8_unit_mm && form.sec_2_8_unit_in) {
      newErrors.sec_2_8_unit_mm = unitErr;
      newErrors.sec_2_8_unit_in = unitErr;
    }
    // SEC 2.9
    else if (form.sec_2_9_unit_mm && form.sec_2_9_unit_in) {
      newErrors.sec_2_9_unit_mm = unitErr;
      newErrors.sec_2_9_unit_in = unitErr;
    }
    // SEC 2.10
    else if (form.sec_2_10_unit_mm && form.sec_2_10_unit_in) {
      newErrors.sec_2_10_unit_mm = unitErr;
      newErrors.sec_2_10_unit_in = unitErr;
    }
    // SEC 2.11
    else if (form.sec_2_11_unit_mm && form.sec_2_11_unit_in) {
      newErrors.sec_2_11_unit_mm = unitErr;
      newErrors.sec_2_11_unit_in = unitErr;
    }
    // SEC 2.13
    else if (form.sec_2_13_unit_mm && form.sec_2_13_unit_in) {
      newErrors.sec_2_13_unit_mm = unitErr;
      newErrors.sec_2_13_unit_in = unitErr;
    }
    // SEC 2.14
    else if (form.sec_2_14_unit_mm && form.sec_2_14_unit_in) {
      newErrors.sec_2_14_unit_mm = unitErr;
      newErrors.sec_2_14_unit_in = unitErr;
    }
    // SEC 2.15
    else if (form.sec_2_15_unit_mm && form.sec_2_15_unit_in) {
      newErrors.sec_2_15_unit_mm = unitErr;
      newErrors.sec_2_15_unit_in = unitErr;
    }
    // SEC 2.16
    else if (form.sec_2_16_unit_kgm2 && form.sec_2_16_unit_lbft2) {
      newErrors.sec_2_16_unit_kgm2 = unitErr;
      newErrors.sec_2_16_unit_lbft2 = unitErr;
    }
    // SEC 2.18
    else if (form.sec_2_18_unit_kw && form.sec_2_18_unit_hp) {
      newErrors.sec_2_18_unit_kw = unitErr;
      newErrors.sec_2_18_unit_hp = unitErr;
    }
    // SEC 2.19
    else if (form.sec_2_19_unit_mmin && form.sec_2_19_unit_ftmin) {
      newErrors.sec_2_19_unit_mmin = unitErr;
      newErrors.sec_2_19_unit_ftmin = unitErr;
    }
    // SEC 3.2
    else if (
      form.sec_3_2_unit_iso &&
      form.sec_3_2_unit_api &&
      form.sec_3_2_unit_gmm &&
      form.sec_3_2_unit_ozin
    ) {
      newErrors.sec_3_2_unit_iso = unitErr;
      newErrors.sec_3_2_unit_api = unitErr;
      newErrors.sec_3_2_unit_gmm = unitErr;
      newErrors.sec_3_2_unit_ozin = unitErr;
    }
    // SEC 3.3
    else if (
      form.sec_3_3_unit_gin &&
      form.sec_3_3_unit_gmm &&
      form.sec_3_3_unit_ozin
    ) {
      newErrors.sec_3_3_unit_gin = unitErr;
      newErrors.sec_3_3_unit_gmm = unitErr;
      newErrors.sec_3_3_unit_ozin = unitErr;
    }
    // SEC 4.7
    else if (form.sec_4_7_unit_kw && form.sec_4_7_unit_hp) {
      newErrors.sec_4_7_unit_kw = unitErr;
      newErrors.sec_4_7_unit_hp = unitErr;
    }

    return newErrors;
  };

  const showModal = () => {
    setShow(true);
  };

  const addAppToFirebase = async (data) => {
    setLoading(true);
    const appRef = doc(db, 'app_for_bal_mach', randomId);
    await setDoc(appRef, data);
    setMessage(`Thank you for your order, ${form.contact_person}`);
    showModal(true);
    setLoading(false);
  };

  // eslint-disable-next-line no-unused-vars
  const localServer = 'http://127.0.0.1:5000';
  const localServerWake = 'http://127.0.0.1:5000/wakeup';
  const irdServer = 'https://irdserver.onrender.com';
  const irdServerWake = 'https://irdserver.onrender.com/wakeup';

  async function postData(url = irdServer, data = form) {
    console.log('server:', url);
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      body: JSON.stringify(data),
    })
      .then((result) => {
        console.log(result.json());
      })
      .catch((error) => {
        console.log('Errors:', error);
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      addAppToFirebase(form).then(() => {
        postData();
      });
    }
  };

  async function wakeUpServer(url = irdServerWake, data = 'reply') {
    console.log('waking up server');
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      body: JSON.stringify(data),
    })
      .then((result) => {
        console.log(result.json());
      })
      .catch((error) => {
        console.log('Errors:', error);
      });
  }

  useEffect(() => {
    if (formReset) {
      for (const key in form) {
        resetForm(key);
      }
      setFormReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formReset]);

  useEffect(() => {
    wakeUpServer();
  }, []);

  return (
    <div className="new-balancing-machine-form-container">
      <Form className="new-balancing-machine-form" onSubmit={handleSubmit}>
        <div className="row new-balancing-machine-row">
          <h2 className="nbm-section-heading-text">Customer Information</h2>
        </div>
        <div className="row new-balancing-machine-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <Form.Group className="nbm-form-input" controlId="date">
              <Form.Control
                type="date"
                placeholder="Date"
                value={form.date}
                isInvalid={!!errors.date}
                errors={errors.date}
                onChange={(e) => setField('date', e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.date}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="nbm-form-input" controlId="contact-person">
              <Form.Control
                type="text"
                placeholder="Contact Person"
                value={form.contact_person}
                isInvalid={!!errors.contact_person}
                errors={errors.contact_person}
                onChange={(e) => setField('contact_person', e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.contact_person}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="nbm-form-input" controlId="title">
              <Form.Control
                type="text"
                placeholder="Title"
                value={form.title}
                isInvalid={!!errors.title}
                errors={errors.title}
                onChange={(e) => setField('title', e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.title}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="nbm-form-input" controlId="email">
              <Form.Control
                type="text"
                placeholder="Email"
                value={form.email}
                isInvalid={!!errors.email}
                errors={errors.email}
                onChange={(e) => setField('email', e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="nbm-form-input" controlId="phone">
              <Form.Control
                type="text"
                placeholder="Phone Number"
                value={form.phone}
                isInvalid={!!errors.phone}
                errors={errors.phone}
                onChange={(e) => setField('phone', e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.phone}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="nbm-form-input" controlId="fax">
              <Form.Control
                type="text"
                placeholder="Fax Number"
                value={form.fax}
                isInvalid={!!errors.fax}
                errors={errors.fax}
                onChange={(e) => setField('fax', e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.fax}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <Form.Group className="nbm-form-input" controlId="company-name">
              <Form.Control
                type="text"
                placeholder="Company Name"
                value={form.company_name}
                isInvalid={!!errors.company_name}
                errors={errors.company_name}
                onChange={(e) => setField('company_name', e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.company_name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="nbm-form-input" controlId="company-address">
              <Form.Control
                type="text"
                placeholder="Company Address"
                value={form.company_address}
                isInvalid={!!errors.company_address}
                errors={errors.company_address}
                onChange={(e) => setField('company_address', e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.company_address}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="nbm-form-input" controlId="city">
              <Form.Control
                type="text"
                placeholder="City"
                value={form.city}
                isInvalid={!!errors.city}
                errors={errors.city}
                onChange={(e) => setField('city', e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.city}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="nbm-form-input" controlId="state">
              <Form.Control
                type="text"
                placeholder="State"
                value={form.state}
                isInvalid={!!errors.state}
                errors={errors.state}
                onChange={(e) => setField('state', e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.state}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="nbm-form-input" controlId="postal-code">
              <Form.Control
                type="text"
                placeholder="Postal Code"
                value={form.postal_code}
                isInvalid={!!errors.postal_code}
                errors={errors.postal_code}
                onChange={(e) => setField('postal_code', e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.postal_code}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="nbm-form-input" controlId="country">
              <Form.Control
                type="text"
                placeholder="Country"
                value={form.country}
                isInvalid={!!errors.country}
                errors={errors.country}
                onChange={(e) => setField('country', e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.country}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>

        <div className="row new-balancing-machine-row nbm-text-content-row">
          <h2 className="nbm-section-heading-text">1. Introduction</h2>
          <span className="nbm-section-text">{content.intro}</span>
        </div>

        {/* ------ SECTION 2: ROTOR DETAILS -------- */}
        <div className="row new-balancing-machine-row">
          <h2 className="nbm-section-heading-text">
            2. Rotor Details (If necessary, reproduce this page and enter data
            for more rotors)
          </h2>
        </div>

        {/* SEC 2.1 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              1. List All Types of Rotors to Balance
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Check
                inline
                className="nbm-check-box"
                label="fan"
                name="sec_2_1_fan"
                id="sec_2_1_fan"
                feedbackType="invalid"
                checked={form.sec_2_1_fan}
                value={form.sec_2_1_fan}
                isInvalid={!!errors.sec_2_1_fan}
                feedback={errors.sec_2_1_fan}
                onChange={() => setField('sec_2_1_fan', !form.sec_2_1_fan)}
              />

              <Form.Check
                inline
                className="nbm-check-box"
                label="armature"
                name="sec_2_1_armature"
                id="sec_2_1_armature"
                feedbackType="invalid"
                checked={form.sec_2_1_armature}
                value={form.sec_2_1_armature}
                isInvalid={!!errors.sec_2_1_armature}
                feedback={errors.sec_2_1_armature}
                onChange={() =>
                  setField('sec_2_1_armature', !form.sec_2_1_armature)
                }
              />

              <Form.Check
                inline
                className="nbm-check-box"
                label="gas/steam turbine"
                name="sec_2_1_turbine"
                id="sec_2_1_turbine"
                feedbackType="invalid"
                checked={form.sec_2_1_turbine}
                value={form.sec_2_1_turbine}
                isInvalid={!!errors.sec_2_1_turbine}
                feedback={errors.sec_2_1_turbine}
                onChange={() =>
                  setField('sec_2_1_turbine', !form.sec_2_1_turbine)
                }
              />
            </div>

            <div className="nbm-grouping-wrapper">
              <Form.Check
                inline
                className="nbm-check-box"
                label="roll"
                name="sec_2_1_roll"
                id="sec_2_1_roll"
                feedbackType="invalid"
                checked={form.sec_2_1_roll}
                value={form.sec_2_1_roll}
                isInvalid={!!errors.sec_2_1_roll}
                feedback={errors.sec_2_1_roll}
                onChange={() => setField('sec_2_1_roll', !form.sec_2_1_roll)}
              />

              <Form.Check
                inline
                className="nbm-check-box"
                label="impeller"
                name="sec_2_1_impeller"
                id="sec_2_1_impeller"
                feedbackType="invalid"
                checked={form.sec_2_1_impeller}
                value={form.sec_2_1_impeller}
                isInvalid={!!errors.sec_2_1_impeller}
                feedback={errors.sec_2_1_impeller}
                onChange={() =>
                  setField('sec_2_1_impeller', !form.sec_2_1_impeller)
                }
              />

              <Form.Check
                inline
                className="nbm-check-box"
                label="spindle"
                name="sec_2_1_spindle"
                id="sec_2_1_spindle"
                feedbackType="invalid"
                checked={form.sec_2_1_spindle}
                value={form.sec_2_1_spindle}
                isInvalid={!!errors.sec_2_1_spindle}
                feedback={errors.sec_2_1_spindle}
                onChange={() =>
                  setField('sec_2_1_spindle', !form.sec_2_1_spindle)
                }
              />

              <Form.Check
                inline
                className="nbm-check-box"
                label="disk"
                name="sec_2_1_disk"
                id="sec_2_1_disk"
                feedbackType="invalid"
                checked={form.sec_2_1_disk}
                value={form.sec_2_1_disk}
                isInvalid={!!errors.sec_2_1_disk}
                feedback={errors.sec_2_1_disk}
                onChange={() => setField('sec_2_1_disk', !form.sec_2_1_disk)}
              />
            </div>

            <div className="nbm-grouping-wrapper">
              <Form.Check
                inline
                className="nbm-check-box"
                label="crankshaft"
                name="sec_2_1_crankshaft"
                id="sec_2_1_crankshaft"
                feedbackType="invalid"
                checked={form.sec_2_1_crankshaft}
                value={form.sec_2_1_crankshaft}
                isInvalid={!!errors.sec_2_1_crankshaft}
                feedback={errors.sec_2_1_crankshaft}
                onChange={() =>
                  setField('sec_2_1_crankshaft', !form.sec_2_1_crankshaft)
                }
              />

              <Form.Check
                inline
                className="nbm-check-box"
                label="flywheel"
                name="sec_2_1_flywheel"
                id="sec_2_1_flywheel"
                feedbackType="invalid"
                checked={form.sec_2_1_flywheel}
                value={form.sec_2_1_flywheel}
                isInvalid={!!errors.sec_2_1_flywheel}
                feedback={errors.sec_2_1_flywheel}
                onChange={() =>
                  setField('sec_2_1_flywheel', !form.sec_2_1_flywheel)
                }
              />

              <Form.Check
                inline
                className="nbm-check-box"
                label="other"
                name="sec_2_1_other"
                id="sec_2_1_other"
                feedbackType="invalid"
                checked={form.sec_2_1_other}
                value={form.sec_2_1_other}
                isInvalid={!!errors.sec_2_1_other}
                feedback={errors.sec_2_1_other}
                onChange={() => setField('sec_2_1_other', !form.sec_2_1_other)}
              />

              <Form.Group
                className="nbm-form-mini-input"
                controlId="sec_2_1_other_details"
              >
                <Form.Control
                  type="text"
                  placeholder="list other"
                  value={form.sec_2_1_other_details}
                  isInvalid={!!errors.sec_2_1_other_details}
                  errors={errors.sec_2_1_other_details}
                  onChange={(e) =>
                    setField('sec_2_1_other_details', e.target.value)
                  }
                />
              </Form.Group>
            </div>
          </div>
        </div>

        {/* SEC 2.2 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">2. Rotor Mass Symmetry</span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Check
                inline
                className="nbm-check-box"
                label="between bearings"
                name="sec_2_2_between_bearings"
                id="sec_2_2_between_bearings"
                feedbackType="invalid"
                checked={form.sec_2_2_between_bearings}
                value={form.sec_2_2_between_bearings}
                isInvalid={!!errors.sec_2_2_between_bearings}
                feedback={errors.sec_2_2_between_bearings}
                onChange={() =>
                  setField(
                    'sec_2_2_between_bearings',
                    !form.sec_2_2_between_bearings
                  )
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="overhung"
                name="sec_2_2_overhung"
                id="sec_2_2_overhung"
                feedbackType="invalid"
                checked={form.sec_2_2_overhung}
                value={form.sec_2_2_overhung}
                isInvalid={!!errors.sec_2_2_overhung}
                feedback={errors.sec_2_2_overhung}
                onChange={() =>
                  setField('sec_2_2_overhung', !form.sec_2_2_overhung)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="both"
                name="sec_2_2_between_bearings_and_overhung"
                id="sec_2_2_between_bearings_and_overhung"
                feedbackType="invalid"
                checked={form.sec_2_2_between_bearings_and_overhung}
                value={form.sec_2_2_between_bearings_and_overhung}
                isInvalid={!!errors.sec_2_2_between_bearings_and_overhung}
                feedback={errors.sec_2_2_between_bearings_and_overhung}
                onChange={() =>
                  setField(
                    'sec_2_2_between_bearings_and_overhung',
                    !form.sec_2_2_between_bearings_and_overhung
                  )
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 2.3 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              3. If Overhung Rotor, estimated max upward force
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-input"
                controlId="sec_2_3_max_upward_force"
              >
                <Form.Control
                  type="text"
                  placeholder="est. force"
                  value={form.sec_2_3_max_upward_force}
                  isInvalid={!!errors.sec_2_3_max_upward_force}
                  errors={errors.sec_2_3_max_upward_force}
                  onChange={(e) =>
                    setField('sec_2_3_max_upward_force', e.target.value)
                  }
                />
              </Form.Group>
              <Form.Check
                inline
                className="nbm-check-box"
                label="kg"
                name="sec_2_3_unit_kg"
                id="sec_2_3_unit_kg"
                feedbackType="invalid"
                checked={form.sec_2_3_unit_kg}
                value={form.sec_2_3_unit_kg}
                isInvalid={!!errors.sec_2_3_unit_kg}
                feedback={errors.sec_2_3_unit_kg}
                onChange={() =>
                  setField('sec_2_3_unit_kg', !form.sec_2_3_unit_kg)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="lb"
                name="sec_2_3_unit_lb"
                id="sec_2_3_unit_lb"
                feedbackType="invalid"
                checked={form.sec_2_3_unit_lb}
                value={form.sec_2_3_unit_lb}
                isInvalid={!!errors.sec_2_3_unit_lb}
                feedback={errors.sec_2_3_unit_lb}
                onChange={() =>
                  setField('sec_2_3_unit_lb', !form.sec_2_3_unit_lb)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="not sure of max force"
                name="sec_2_3_not_sure"
                id="sec_2_3_not_sure"
                feedbackType="invalid"
                checked={form.sec_2_3_not_sure}
                value={form.sec_2_3_not_sure}
                isInvalid={!!errors.sec_2_3_not_sure}
                feedback={errors.sec_2_3_not_sure}
                onChange={() =>
                  setField('sec_2_3_not_sure', !form.sec_2_3_not_sure)
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 2.4 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              4. Do Rotors have their own shaft with journals?
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Check
                inline
                className="nbm-check-box"
                label="yes"
                name="sec_2_4_yes"
                id="sec_2_4_yes"
                feedbackType="invalid"
                checked={form.sec_2_4_yes}
                value={form.sec_2_4_yes}
                isInvalid={!!errors.sec_2_4_yes}
                feedback={errors.sec_2_4_yes}
                onChange={() => setField('sec_2_4_yes', !form.sec_2_4_yes)}
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="no"
                name="sec_2_4_no"
                id="sec_2_4_no"
                feedbackType="invalid"
                checked={form.sec_2_4_no}
                value={form.sec_2_4_no}
                isInvalid={!!errors.sec_2_4_no}
                feedback={errors.sec_2_4_no}
                onChange={() => setField('sec_2_4_no', !form.sec_2_4_no)}
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="both"
                name="sec_2_4_both"
                id="sec_2_4_both"
                feedbackType="invalid"
                checked={form.sec_2_4_both}
                value={form.sec_2_4_both}
                isInvalid={!!errors.sec_2_4_both}
                feedback={errors.sec_2_4_both}
                onChange={() => setField('sec_2_4_both', !form.sec_2_4_both)}
              />
            </div>
          </div>
        </div>

        {/* SEC 2.5 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">5. Maximum Rotor Weight</span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-input"
                controlId="sec_2_5_max_rotor_weight"
              >
                <Form.Control
                  type="text"
                  placeholder="max weight"
                  value={form.sec_2_5_max_rotor_weight}
                  isInvalid={!!errors.sec_2_5_max_rotor_weight}
                  errors={errors.sec_2_5_max_rotor_weight}
                  onChange={(e) =>
                    setField('sec_2_5_max_rotor_weight', e.target.value)
                  }
                />
              </Form.Group>
              <Form.Check
                inline
                className="nbm-check-box"
                label="kg"
                name="sec_2_5_unit_kg"
                id="sec_2_5_unit_kg"
                feedbackType="invalid"
                checked={form.sec_2_5_unit_kg}
                value={form.sec_2_5_unit_kg}
                isInvalid={!!errors.sec_2_5_unit_kg}
                feedback={errors.sec_2_5_unit_kg}
                onChange={() =>
                  setField('sec_2_5_unit_kg', !form.sec_2_5_unit_kg)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="lb"
                name="sec_2_5_unit_lb"
                id="sec_2_5_unit_lb"
                feedbackType="invalid"
                checked={form.sec_2_5_unit_lb}
                value={form.sec_2_5_unit_lb}
                isInvalid={!!errors.sec_2_5_unit_lb}
                feedback={errors.sec_2_5_unit_lb}
                onChange={() =>
                  setField('sec_2_5_unit_lb', !form.sec_2_5_unit_lb)
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 2.6 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">6. Minimum Rotor Weight</span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-input"
                controlId="sec_2_6_min_rotor_weight"
              >
                <Form.Control
                  type="text"
                  placeholder="min weight"
                  value={form.sec_2_6_min_rotor_weight}
                  isInvalid={!!errors.sec_2_6_min_rotor_weight}
                  errors={errors.sec_2_6_min_rotor_weight}
                  onChange={(e) =>
                    setField('sec_2_6_min_rotor_weight', e.target.value)
                  }
                />
              </Form.Group>
              <Form.Check
                inline
                className="nbm-check-box"
                label="kg"
                name="sec_2_6_unit_kg"
                id="sec_2_6_unit_kg"
                feedbackType="invalid"
                checked={form.sec_2_6_unit_kg}
                value={form.sec_2_6_unit_kg}
                isInvalid={!!errors.sec_2_6_unit_kg}
                feedback={errors.sec_2_6_unit_kg}
                onChange={() =>
                  setField('sec_2_6_unit_kg', !form.sec_2_6_unit_kg)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="lb"
                name="sec_2_6_unit_lb"
                id="sec_2_6_unit_lb"
                feedbackType="invalid"
                checked={form.sec_2_6_unit_lb}
                value={form.sec_2_6_unit_lb}
                isInvalid={!!errors.sec_2_6_unit_lb}
                feedback={errors.sec_2_6_unit_lb}
                onChange={() =>
                  setField('sec_2_6_unit_lb', !form.sec_2_6_unit_lb)
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 2.7 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              7. (D) Maximum Swing Diameter of Rotors
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-input"
                controlId="sec_2_7_max_swing_diameter"
              >
                <Form.Control
                  type="text"
                  placeholder="max swing diameter"
                  value={form.sec_2_7_max_swing_diameter}
                  isInvalid={!!errors.sec_2_7_max_swing_diameter}
                  errors={errors.sec_2_7_max_swing_diameter}
                  onChange={(e) =>
                    setField('sec_2_7_max_swing_diameter', e.target.value)
                  }
                />
              </Form.Group>
              <Form.Check
                inline
                className="nbm-check-box"
                label="mm"
                name="sec_2_7_unit_mm"
                id="sec_2_7_unit_mm"
                feedbackType="invalid"
                checked={form.sec_2_7_unit_mm}
                value={form.sec_2_7_unit_mm}
                isInvalid={!!errors.sec_2_7_unit_mm}
                feedback={errors.sec_2_7_unit_mm}
                onChange={() =>
                  setField('sec_2_7_unit_mm', !form.sec_2_7_unit_mm)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="in"
                name="sec_2_7_unit_in"
                id="sec_2_7_unit_in"
                feedbackType="invalid"
                checked={form.sec_2_7_unit_in}
                value={form.sec_2_7_unit_in}
                isInvalid={!!errors.sec_2_7_unit_in}
                feedback={errors.sec_2_7_unit_in}
                onChange={() =>
                  setField('sec_2_7_unit_in', !form.sec_2_7_unit_in)
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 2.8 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">8. Maximum Rotor Length</span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-input"
                controlId="sec_2_8_max_rotor_length"
              >
                <Form.Control
                  type="text"
                  placeholder="max rotor length"
                  value={form.sec_2_8_max_rotor_length}
                  isInvalid={!!errors.sec_2_8_max_rotor_length}
                  errors={errors.sec_2_8_max_rotor_length}
                  onChange={(e) =>
                    setField('sec_2_8_max_rotor_length', e.target.value)
                  }
                />
              </Form.Group>
              <Form.Check
                inline
                className="nbm-check-box"
                label="mm"
                name="sec-2-pt-8-group-1"
                id="sec-2-pt-8-unit-mm"
                feedbackType="invalid"
                checked={form.sec_2_8_unit_mm}
                value={form.sec_2_8_unit_mm}
                isInvalid={!!errors.sec_2_8_unit_mm}
                feedback={errors.sec_2_8_unit_mm}
                onChange={() =>
                  setField('sec_2_8_unit_mm', !form.sec_2_8_unit_mm)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="in"
                name="sec_2_8_unit_in"
                id="sec_2_8_unit_in"
                feedbackType="invalid"
                checked={form.sec_2_8_unit_in}
                value={form.sec_2_8_unit_in}
                isInvalid={!!errors.sec_2_8_unit_in}
                feedback={errors.sec_2_8_unit_in}
                onChange={() =>
                  setField('sec_2_8_unit_in', !form.sec_2_8_unit_in)
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 2.9 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">9. Minimum Rotor Length</span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-input"
                controlId="sec_2_9_min_rotor_length"
              >
                <Form.Control
                  type="text"
                  placeholder="min rotor length"
                  value={form.sec_2_9_min_rotor_length}
                  isInvalid={!!errors.sec_2_9_min_rotor_length}
                  errors={errors.sec_2_9_min_rotor_length}
                  onChange={(e) =>
                    setField('sec_2_9_min_rotor_length', e.target.value)
                  }
                />
              </Form.Group>
              <Form.Check
                inline
                className="nbm-check-box"
                label="mm"
                name="sec_2_9_unit_mm"
                id="sec_2_9_unit_mm"
                feedbackType="invalid"
                checked={form.sec_2_9_unit_mm}
                value={form.sec_2_9_unit_mm}
                isInvalid={!!errors.sec_2_9_unit_mm}
                feedback={errors.sec_2_9_unit_mm}
                onChange={() =>
                  setField('sec_2_9_unit_mm', !form.sec_2_9_unit_mm)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="in"
                name="sec_2_9_unit_in"
                id="sec_2_9_unit_in"
                feedbackType="invalid"
                checked={form.sec_2_9_unit_in}
                value={form.sec_2_9_unit_in}
                isInvalid={!!errors.sec_2_9_unit_in}
                feedback={errors.sec_2_9_unit_in}
                onChange={() =>
                  setField('sec_2_9_unit_in', !form.sec_2_9_unit_in)
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 2.10 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              10. (B) Maximum Distance between Journals
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-input"
                controlId="sec_2_10_max_dist_between_journals"
              >
                <Form.Control
                  type="text"
                  placeholder="max distance"
                  value={form.sec_2_10_max_dist_between_journals}
                  isInvalid={!!errors.sec_2_10_max_dist_between_journals}
                  errors={errors.sec_2_10_max_dist_between_journals}
                  onChange={(e) =>
                    setField(
                      'sec_2_10_max_dist_between_journals',
                      e.target.value
                    )
                  }
                />
              </Form.Group>
              <Form.Check
                inline
                className="nbm-check-box"
                label="mm"
                name="sec_2_10_unit_mm"
                id="sec_2_10_unit_mm"
                feedbackType="invalid"
                checked={form.sec_2_10_unit_mm}
                value={form.sec_2_10_unit_mm}
                isInvalid={!!errors.sec_2_10_unit_mm}
                feedback={errors.sec_2_10_unit_mm}
                onChange={() =>
                  setField('sec_2_10_unit_mm', !form.sec_2_10_unit_mm)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="in"
                name="sec-2-pt-10-group-1"
                id="sec_2_10_unit_in"
                feedbackType="invalid"
                checked={form.sec_2_10_unit_in}
                value={form.sec_2_10_unit_in}
                isInvalid={!!errors.sec_2_10_unit_in}
                feedback={errors.sec_2_10_unit_in}
                onChange={() =>
                  setField('sec_2_10_unit_in', !form.sec_2_10_unit_in)
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 2.11 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              11. (B) Minimum Distance between Journals
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-input"
                controlId="sec_2_11_min_dist_between_journals"
              >
                <Form.Control
                  type="text"
                  placeholder="min distance"
                  value={form.sec_2_11_min_dist_between_journals}
                  isInvalid={!!errors.sec_2_11_min_dist_between_journals}
                  errors={errors.sec_2_11_min_dist_between_journals}
                  onChange={(e) =>
                    setField(
                      'sec_2_11_min_dist_between_journals',
                      e.target.value
                    )
                  }
                />
              </Form.Group>
              <Form.Check
                inline
                className="nbm-check-box"
                label="mm"
                name="sec_2_11_unit_mm"
                id="sec_2_11_unit_mm"
                feedbackType="invalid"
                checked={form.sec_2_11_unit_mm}
                value={form.sec_2_11_unit_mm}
                isInvalid={!!errors.sec_2_11_unit_mm}
                feedback={errors.sec_2_11_unit_mm}
                onChange={() =>
                  setField('sec_2_11_unit_mm', !form.sec_2_11_unit_mm)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="in"
                name="sec_2_11_unit_in"
                id="sec_2_11_unit_in"
                feedbackType="invalid"
                checked={form.sec_2_11_unit_in}
                value={form.sec_2_11_unit_in}
                isInvalid={!!errors.sec_2_11_unit_in}
                feedback={errors.sec_2_11_unit_in}
                onChange={() =>
                  setField('sec_2_11_unit_in', !form.sec_2_11_unit_in)
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 2.12 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">12. Rotor Support Method</span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Check
                inline
                className="nbm-check-box"
                label="own bearings"
                name="sec_2_12_own_bearing"
                id="sec_2_12_own_bearing"
                feedbackType="invalid"
                checked={form.sec_2_12_own_bearing}
                value={form.sec_2_12_own_bearing}
                isInvalid={!!errors.sec_2_12_own_bearing}
                feedback={errors.sec_2_12_own_bearing}
                onChange={() =>
                  setField('sec_2_12_own_bearing', !form.sec_2_12_own_bearing)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="journal surface"
                name="sec_2_12_journal_surface"
                id="sec_2_12_journal_surface"
                feedbackType="invalid"
                checked={form.sec_2_12_journal_surface}
                value={form.sec_2_12_journal_surface}
                isInvalid={!!errors.sec_2_12_journal_surface}
                feedback={errors.sec_2_12_journal_surface}
                onChange={() =>
                  setField(
                    'sec_2_12_journal_surface',
                    !form.sec_2_12_journal_surface
                  )
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 2.13 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              13. (A) Maximum Journal Diameter
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-input"
                controlId="sec_2_13_max_journal_diam}"
              >
                <Form.Control
                  type="text"
                  placeholder="max diam"
                  value={form.sec_2_13_max_journal_diam}
                  isInvalid={!!errors.sec_2_13_max_journal_diam}
                  errors={errors.sec_2_13_max_journal_diam}
                  onChange={(e) =>
                    setField('sec_2_13_max_journal_diam', e.target.value)
                  }
                />
              </Form.Group>
              <Form.Check
                inline
                className="nbm-check-box"
                label="mm"
                name="sec_2_13_unit_mm"
                id="sec_2_13_unit_mm"
                feedbackType="invalid"
                checked={form.sec_2_13_unit_mm}
                value={form.sec_2_13_unit_mm}
                isInvalid={!!errors.sec_2_13_unit_mm}
                feedback={errors.sec_2_13_unit_mm}
                onChange={() =>
                  setField('sec_2_13_unit_mm', !form.sec_2_13_unit_mm)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="in"
                name="sec_2_13_unit_in"
                id="sec_2_13_unit_in"
                feedbackType="invalid"
                checked={form.sec_2_13_unit_in}
                value={form.sec_2_13_unit_in}
                isInvalid={!!errors.sec_2_13_unit_in}
                feedback={errors.sec_2_13_unit_in}
                onChange={() =>
                  setField('sec_2_13_unit_in', !form.sec_2_13_unit_in)
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 2.14 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              14. (A) Minimum Journal Diameter
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-input"
                controlId="sec_2_14_min_journal_diam"
              >
                <Form.Control
                  type="text"
                  placeholder="min diam"
                  value={form.sec_2_14_min_journal_diam}
                  isInvalid={!!errors.sec_2_14_min_journal_diam}
                  errors={errors.sec_2_14_min_journal_diam}
                  onChange={(e) =>
                    setField('sec_2_14_min_journal_diam', e.target.value)
                  }
                />
              </Form.Group>
              <Form.Check
                inline
                className="nbm-check-box"
                label="mm"
                name="sec_2_14_unit_mm"
                id="sec_2_14_unit_mm"
                feedbackType="invalid"
                checked={form.sec_2_14_unit_mm}
                value={form.sec_2_14_unit_mm}
                isInvalid={!!errors.sec_2_14_unit_mm}
                feedback={errors.sec_2_14_unit_mm}
                onChange={() =>
                  setField('sec_2_14_unit_mm', !form.sec_2_14_unit_mm)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="in"
                name="sec_2_14_unit_in"
                id="sec_2_14_unit_in"
                feedbackType="invalid"
                checked={form.sec_2_14_unit_in}
                value={form.sec_2_14_unit_in}
                isInvalid={!!errors.sec_2_14_unit_in}
                feedback={errors.sec_2_14_unit_in}
                onChange={() =>
                  setField('sec_2_14_unit_in', !form.sec_2_14_unit_in)
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 2.15 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              15. (E) Minimum Journal Width
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-input"
                controlId="sec_2_15_min_journal_width"
              >
                <Form.Control
                  type="text"
                  placeholder="min width"
                  value={form.sec_2_15_min_journal_width}
                  isInvalid={!!errors.sec_2_15_min_journal_width}
                  errors={errors.sec_2_15_min_journal_width}
                  onChange={(e) =>
                    setField('sec_2_15_min_journal_width', e.target.value)
                  }
                />
              </Form.Group>
              <Form.Check
                inline
                className="nbm-check-box"
                label="mm"
                name="sec_2_15_unit_mm"
                id="sec_2_15_unit_mm"
                feedbackType="invalid"
                checked={form.sec_2_15_unit_mm}
                value={form.sec_2_15_unit_mm}
                isInvalid={!!errors.sec_2_15_unit_mm}
                feedback={errors.sec_2_15_unit_mm}
                onChange={() =>
                  setField('sec_2_15_unit_mm', !form.sec_2_15_unit_mm)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="in"
                name="sec_2_15_unit_in"
                id="sec_2_15_unit_in"
                feedbackType="invalid"
                checked={form.sec_2_15_unit_in}
                value={form.sec_2_15_unit_in}
                isInvalid={!!errors.sec_2_15_unit_in}
                feedback={errors.sec_2_15_unit_in}
                onChange={() =>
                  setField('sec_2_15_unit_in', !form.sec_2_15_unit_in)
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 2.16 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              16. Maximum Moment of Inertia
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-input"
                controlId="sec_2_16_max_moment_of_inertia"
              >
                <Form.Control
                  type="text"
                  placeholder="max inertia"
                  value={form.sec_2_16_max_moment_of_inertia}
                  isInvalid={!!errors.sec_2_16_max_moment_of_inertia}
                  errors={errors.sec_2_16_max_moment_of_inertia}
                  onChange={(e) =>
                    setField('sec_2_16_max_moment_of_inertia', e.target.value)
                  }
                />
              </Form.Group>
              <Form.Check
                inline
                className="nbm-check-box"
                label="kgm^2"
                name="sec_2_16_unit_kgm2"
                id="sec_2_16_unit_kgm2"
                feedbackType="invalid"
                checked={form.sec_2_16_unit_kgm2}
                value={form.sec_2_16_unit_kgm2}
                isInvalid={!!errors.sec_2_16_unit_kgm2}
                feedback={errors.sec_2_16_unit_kgm2}
                onChange={() =>
                  setField('sec_2_16_unit_kgm2', !form.sec_2_16_unit_kgm2)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="lb ft^2"
                name="sec_2_16_unit_lbft2"
                id="sec_2_16_unit_lbft2"
                feedbackType="invalid"
                checked={form.sec_2_16_unit_lbft2}
                value={form.sec_2_16_unit_lbft2}
                isInvalid={!!errors.sec_2_16_unit_lbft2}
                feedback={errors.sec_2_16_unit_lbft2}
                onChange={() =>
                  setField('sec_2_16_unit_lbft2', !form.sec_2_16_unit_lbft2)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="not sure"
                name="sec_2_16_unit_not_sure"
                id="sec_2_16_unit_not_sure"
                feedbackType="invalid"
                checked={form.sec_2_16_unit_not_sure}
                value={form.sec_2_16_unit_not_sure}
                isInvalid={!!errors.sec_2_16_unit_not_sure}
                feedback={errors.sec_2_16_unit_not_sure}
                onChange={() =>
                  setField(
                    'sec_2_16_unit_not_sure',
                    !form.sec_2_16_unit_not_sure
                  )
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 2.17 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              17. Operating Speed Range of Rotors
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-input"
                controlId="sec_2_17_speed_range_rotors"
              >
                <Form.Control
                  type="text"
                  placeholder="speed range"
                  value={form.sec_2_17_speed_range_rotors}
                  isInvalid={!!errors.sec_2_17_speed_range_rotors}
                  errors={errors.sec_2_17_speed_range_rotors}
                  onChange={(e) =>
                    setField('sec_2_17_speed_range_rotors', e.target.value)
                  }
                />
              </Form.Group>
              <span className="nbm-form-text">RPM</span>
            </div>
          </div>
        </div>

        {/* SEC 2.18 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              18. Max. Power Absorbed at Operating Speed
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-input"
                controlId="sec_2_18_max_power_absorbed"
              >
                <Form.Control
                  type="text"
                  placeholder="max absorbed"
                  value={form.sec_2_18_max_power_absorbed}
                  isInvalid={!!errors.sec_2_18_max_power_absorbed}
                  errors={errors.sec_2_18_max_power_absorbed}
                  onChange={(e) =>
                    setField('sec_2_18_max_power_absorbed', e.target.value)
                  }
                />
              </Form.Group>
              <Form.Check
                inline
                className="nbm-check-box"
                label="kW"
                name="sec_2_18_unit_kw"
                id="sec_2_18_unit_kw"
                feedbackType="invalid"
                checked={form.sec_2_18_unit_kw}
                value={form.sec_2_18_unit_kw}
                isInvalid={!!errors.sec_2_18_unit_kw}
                feedback={errors.sec_2_18_unit_kw}
                onChange={() =>
                  setField('sec_2_18_unit_kw', !form.sec_2_18_unit_kw)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="hp"
                name="sec_2_18_unit_hp"
                id="sec_2_18_unit_hp"
                feedbackType="invalid"
                checked={form.sec_2_18_unit_hp}
                value={form.sec_2_18_unit_hp}
                isInvalid={!!errors.sec_2_18_unit_hp}
                feedback={errors.sec_2_18_unit_hp}
                onChange={() =>
                  setField('sec_2_18_unit_hp', !form.sec_2_18_unit_hp)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="not sure"
                name="sec_2_18_unit_not_sure"
                id="sec_2_18_unit_not_sure"
                feedbackType="invalid"
                checked={form.sec_2_18_unit_not_sure}
                value={form.sec_2_18_unit_not_sure}
                isInvalid={!!errors.sec_2_18_unit_not_sure}
                feedback={errors.sec_2_18_unit_not_sure}
                onChange={() =>
                  setField(
                    'sec_2_18_unit_not_sure',
                    !form.sec_2_18_unit_not_sure
                  )
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 2.19 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              19. Roll Data: Maximum Balancing Speed for Rolls
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-input"
                controlId="sec_2_19_max_bal_speed_rolls"
              >
                <Form.Control
                  type="text"
                  placeholder="max speed"
                  value={form.sec_2_19_max_bal_speed_rolls}
                  isInvalid={!!errors.sec_2_19_max_bal_speed_rolls}
                  errors={errors.sec_2_19_max_bal_speed_rolls}
                  onChange={(e) =>
                    setField('sec_2_19_max_bal_speed_rolls', e.target.value)
                  }
                />
              </Form.Group>
              <Form.Check
                inline
                className="nbm-check-box"
                label="m/min"
                name="sec_2_19_unit_mmin"
                id="sec_2_19_unit_mmin"
                feedbackType="invalid"
                checked={form.sec_2_19_unit_mmin}
                value={form.sec_2_19_unit_mmin}
                isInvalid={!!errors.sec_2_19_unit_mmin}
                feedback={errors.sec_2_19_unit_mmin}
                onChange={() =>
                  setField('sec_2_19_unit_mmin', !form.sec_2_19_unit_mmin)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="ft/min"
                name="sec_2_19_unit_ftmin"
                id="sec_2_19_unit_ftmin"
                feedbackType="invalid"
                checked={form.sec_2_19_unit_ftmin}
                value={form.sec_2_19_unit_ftmin}
                isInvalid={!!errors.sec_2_19_unit_ftmin}
                feedback={errors.sec_2_19_unit_ftmin}
                onChange={() =>
                  setField('sec_2_19_unit_ftmin', !form.sec_2_19_unit_ftmin)
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 2.20 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              20. Roll Data: High Internal Resistance? (Suction)
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Check
                inline
                className="nbm-check-box"
                label="yes"
                name="sec_2_20_high_int_yes"
                id="sec_2_20_high_int_yes"
                feedbackType="invalid"
                checked={form.sec_2_20_high_int_yes}
                value={form.sec_2_20_high_int_yes}
                isInvalid={!!errors.sec_2_20_high_int_yes}
                feedback={errors.sec_2_20_high_int_yes}
                onChange={() =>
                  setField('sec_2_20_high_int_yes', !form.sec_2_20_high_int_yes)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="no"
                name="sec_2_20_high_int_no"
                id="sec_2_20_high_int_no"
                feedbackType="invalid"
                checked={form.sec_2_20_high_int_no}
                value={form.sec_2_20_high_int_no}
                isInvalid={!!errors.sec_2_20_high_int_no}
                feedback={errors.sec_2_20_high_int_no}
                onChange={() =>
                  setField('sec_2_20_high_int_no', !form.sec_2_20_high_int_no)
                }
              />
            </div>
          </div>
        </div>
        {/* ------ SECTION 2-B: ROTOR DETAILS IMAGE -------- */}
        <div className="row new-balancing-machine-row flex-center-row">
          <img src={rotorDetailsImg} alt="rotor details" className="nbm-img" />
        </div>

        {/* ------ SECTION 3: BALANCE DETAILS -------- */}
        <div className="row new-balancing-machine-row">
          <h2 className="nbm-section-heading-text">3. Balance Details</h2>
        </div>

        {/* SEC 3.1 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              1. Number of Balance Planes
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Check
                inline
                className="nbm-check-box"
                label="one plane"
                name="sec_3_1_one_plane"
                id="sec_3_1_one_plane"
                feedbackType="invalid"
                checked={form.sec_3_1_one_plane}
                value={form.sec_3_1_one_plane}
                isInvalid={!!errors.sec_3_1_one_plane}
                feedback={errors.sec_3_1_one_plane}
                onChange={() =>
                  setField('sec_3_1_one_plane', !form.sec_3_1_one_plane)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="two plane"
                name="sec_3_1_two_plane"
                id="sec_3_1_two_plane"
                feedbackType="invalid"
                checked={form.sec_3_1_two_plane}
                value={form.sec_3_1_two_plane}
                isInvalid={!!errors.sec_3_1_two_plane}
                feedback={errors.sec_3_1_two_plane}
                onChange={() =>
                  setField('sec_3_1_two_plane', !form.sec_3_1_two_plane)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="both"
                name="sec_3_1_both_planes"
                id="sec_3_1_both_planes"
                feedbackType="invalid"
                checked={form.sec_3_1_both_planes}
                value={form.sec_3_1_both_planes}
                isInvalid={!!errors.sec_3_1_both_planes}
                feedback={errors.sec_3_1_both_planes}
                onChange={() =>
                  setField('sec_3_1_both_planes', !form.sec_3_1_both_planes)
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 3.2 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              2. Balance Tolerance Level – Amount/Grade Value and Units Required
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-input"
                controlId="sec_3_2_tolerance_amount"
              >
                <Form.Control
                  type="text"
                  placeholder="tolerance & amount"
                  value={form.sec_3_2_tolerance_amount}
                  isInvalid={!!errors.sec_3_2_tolerance_amount}
                  errors={errors.sec_3_2_tolerance_amount}
                  onChange={(e) =>
                    setField('sec_3_2_tolerance_amount', e.target.value)
                  }
                />
              </Form.Group>
              <Form.Check
                inline
                className="nbm-check-box"
                label="ISO Grade"
                name="sec_3_2_unit_iso"
                id="sec_3_2_unit_iso"
                feedbackType="invalid"
                checked={form.sec_3_2_unit_iso}
                value={form.sec_3_2_unit_iso}
                isInvalid={!!errors.sec_3_2_unit_iso}
                feedback={errors.sec_3_2_unit_iso}
                onChange={() =>
                  setField('sec_3_2_unit_iso', !form.sec_3_2_unit_iso)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="API"
                name="sec_3_2_unit_api"
                id="sec_3_2_unit_api"
                feedbackType="invalid"
                checked={form.sec_3_2_unit_api}
                value={form.sec_3_2_unit_api}
                isInvalid={!!errors.sec_3_2_unit_api}
                feedback={errors.sec_3_2_unit_api}
                onChange={() =>
                  setField('sec_3_2_unit_api', !form.sec_3_2_unit_api)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="g-mm"
                name="sec_3_2_unit_gmm"
                id="sec_3_2_unit_gmm"
                feedbackType="invalid"
                checked={form.sec_3_2_unit_gmm}
                value={form.sec_3_2_unit_gmm}
                isInvalid={!!errors.sec_3_2_unit_gmm}
                feedback={errors.sec_3_2_unit_gmm}
                onChange={() =>
                  setField('sec_3_2_unit_gmm', !form.sec_3_2_unit_gmm)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="oz-in"
                name="sec_3_2_unit_ozin"
                id="sec_3_2_unit_ozin"
                feedbackType="invalid"
                checked={form.sec_3_2_unit_ozin}
                value={form.sec_3_2_unit_ozin}
                isInvalid={!!errors.sec_3_2_unit_ozin}
                feedback={errors.sec_3_2_unit_ozin}
                onChange={() =>
                  setField('sec_3_2_unit_ozin', !form.sec_3_2_unit_ozin)
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 3.3 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              3. Maximum Initial Unbalance
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-input"
                controlId="sec_3_3_max_init_unbal"
              >
                <Form.Control
                  type="text"
                  placeholder="max initial"
                  value={form.sec_3_3_max_init_unbal}
                  isInvalid={!!errors.sec_3_3_max_init_unbal}
                  errors={errors.sec_3_3_max_init_unbal}
                  onChange={(e) =>
                    setField('sec_3_3_max_init_unbal', e.target.value)
                  }
                />
              </Form.Group>
              <Form.Check
                inline
                className="nbm-check-box"
                label="g-in"
                name="sec_3_3_unit_gin"
                id="sec_3_3_unit_gin"
                feedbackType="invalid"
                checked={form.sec_3_3_unit_gin}
                value={form.sec_3_3_unit_gin}
                isInvalid={!!errors.sec_3_3_unit_gin}
                feedback={errors.sec_3_3_unit_gin}
                onChange={() =>
                  setField('sec_3_3_unit_gin', !form.sec_3_3_unit_gin)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="g-mm"
                name="sec_3_3_unit_gmm"
                id="sec_3_3_unit_gmm"
                feedbackType="invalid"
                checked={form.sec_3_3_unit_gmm}
                value={form.sec_3_3_unit_gmm}
                isInvalid={!!errors.sec_3_3_unit_gmm}
                feedback={errors.sec_3_3_unit_gmm}
                onChange={() =>
                  setField('sec_3_3_unit_gmm', !form.sec_3_3_unit_gmm)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="oz-in"
                name="sec_3_3_unit_ozin"
                id="sec_3_3_unit_ozin"
                feedbackType="invalid"
                checked={form.sec_3_3_unit_ozin}
                value={form.sec_3_3_unit_ozin}
                isInvalid={!!errors.sec_3_3_unit_ozin}
                feedback={errors.sec_3_3_unit_ozin}
                onChange={() =>
                  setField('sec_3_3_unit_ozin', !form.sec_3_3_unit_ozin)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="not sure"
                name="sec_3_3_unit_not_sure"
                id="sec_3_3_unit_not_sure"
                feedbackType="invalid"
                checked={form.sec_3_3_unit_not_sure}
                value={form.sec_3_3_unit_not_sure}
                isInvalid={!!errors.sec_3_3_unit_not_sure}
                feedback={errors.sec_3_3_unit_not_sure}
                onChange={() =>
                  setField('sec_3_3_unit_not_sure', !form.sec_3_3_unit_not_sure)
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 3.4 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              4. Number of Rotors to Balance in 8 hours
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-input"
                controlId="sec_3_4_num_rotors_bal"
              >
                <Form.Control
                  type="text"
                  placeholder="number of rotors"
                  value={form.sec_3_4_num_rotors_bal}
                  isInvalid={!!errors.sec_3_4_num_rotors_bal}
                  errors={errors.sec_3_4_num_rotors_bal}
                  onChange={(e) =>
                    setField('sec_3_4_num_rotors_bal', e.target.value)
                  }
                />
              </Form.Group>
            </div>
          </div>
        </div>

        {/* ------ SECTION 4: SYSTEM DETAILS -------- */}
        <div className="row new-balancing-machine-row">
          <h2 className="nbm-section-heading-text">4. System Details</h2>
        </div>

        {/* SEC 4.5 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              5. Available Electrical Mains Supply
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-mini-input"
                controlId="sec_4_5_ac_volt"
              >
                <Form.Control
                  type="text"
                  placeholder="AC volts"
                  value={form.sec_4_5_ac_volt}
                  isInvalid={!!errors.sec_4_5_ac_volt}
                  errors={errors.sec_4_5_ac_volt}
                  onChange={(e) => setField('sec_4_5_ac_volt', e.target.value)}
                />
              </Form.Group>

              <Form.Group
                className="nbm-form-mini-input"
                controlId="sec_4_5_phase"
              >
                <Form.Control
                  type="text"
                  placeholder="Phase"
                  value={form.sec_4_5_phase}
                  isInvalid={!!errors.sec_4_5_phase}
                  errors={errors.sec_4_5_phase}
                  onChange={(e) => setField('sec_4_5_phase', e.target.value)}
                />
              </Form.Group>

              <Form.Group
                className="nbm-form-mini-input"
                controlId="sec_4_5_hz"
              >
                <Form.Control
                  type="text"
                  placeholder="Hz"
                  value={form.sec_4_5_hz}
                  isInvalid={!!errors.sec_4_5_hz}
                  errors={errors.sec_4_5_hz}
                  onChange={(e) => setField('sec_4_5_hz', e.target.value)}
                />
              </Form.Group>
            </div>
          </div>
        </div>

        {/* SEC 4.6 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">6. Rotor Drive Type</span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Check
                inline
                className="nbm-check-box"
                label="belt"
                name="sec_4_6_belt"
                id="sec_4_6_belt"
                feedbackType="invalid"
                checked={form.sec_4_6_belt}
                value={form.sec_4_6_belt}
                isInvalid={!!errors.sec_4_6_belt}
                feedback={errors.sec_4_6_belt}
                onChange={() => setField('sec_4_6_belt', !form.sec_4_6_belt)}
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="end drive"
                name="sec_4_6_end_drive"
                id="sec_4_6_end_drive"
                feedbackType="invalid"
                checked={form.sec_4_6_end_drive}
                value={form.sec_4_6_end_drive}
                isInvalid={!!errors.sec_4_6_end_drive}
                feedback={errors.sec_4_6_end_drive}
                onChange={() =>
                  setField('sec_4_6_end_drive', !form.sec_4_6_end_drive)
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 4.7 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              7. Desired Drive Power Requirements
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-input"
                controlId="sec-4-pt-7-requirements"
              >
                <Form.Control
                  type="text"
                  placeholder="requirements"
                  value={form.sec_4_7_drive_power_req}
                  isInvalid={!!errors.sec_4_7_drive_power_req}
                  errors={errors.sec_4_7_drive_power_req}
                  onChange={(e) =>
                    setField('sec_4_7_drive_power_req', e.target.value)
                  }
                />
              </Form.Group>
              <Form.Check
                inline
                className="nbm-check-box"
                label="kW"
                name="sec_4_7_unit_kw"
                id="sec_4_7_unit_kw"
                feedbackType="invalid"
                checked={form.sec_4_7_unit_kw}
                value={form.sec_4_7_unit_kw}
                isInvalid={!!errors.sec_4_7_unit_kw}
                feedback={errors.sec_4_7_unit_kw}
                onChange={() =>
                  setField('sec_4_7_unit_kw', !form.sec_4_7_unit_kw)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="hp"
                name="sec_4_7_unit_hp"
                id="sec_4_7_unit_hp"
                feedbackType="invalid"
                checked={form.sec_4_7_unit_hp}
                value={form.sec_4_7_unit_hp}
                isInvalid={!!errors.sec_4_7_unit_hp}
                feedback={errors.sec_4_7_unit_hp}
                onChange={() =>
                  setField('sec_4_7_unit_hp', !form.sec_4_7_unit_hp)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="not sure"
                name="sec_4_7_unit_not_sure"
                id="sec_4_7_unit_not_sure"
                feedbackType="invalid"
                checked={form.sec_4_7_unit_not_sure}
                value={form.sec_4_7_unit_not_sure}
                isInvalid={!!errors.sec_4_7_unit_not_sure}
                feedback={errors.sec_4_7_unit_not_sure}
                onChange={() =>
                  setField('sec_4_7_unit_not_sure', !form.sec_4_7_unit_not_sure)
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 4.8 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">8. Balance Speed Control</span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Check
                inline
                className="nbm-check-box"
                label="variable speed"
                name="sec_4_8_var_speed"
                id="sec_4_8_var_speed"
                feedbackType="invalid"
                checked={form.sec_4_8_var_speed}
                value={form.sec_4_8_var_speed}
                isInvalid={!!errors.sec_4_8_var_speed}
                feedback={errors.sec_4_8_var_speed}
                onChange={() =>
                  setField('sec_4_8_var_speed', !form.sec_4_8_var_speed)
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 4.9 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">9. Instrument Type</span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Check
                inline
                className="nbm-check-box"
                label="portable"
                name="ec_4_9_portable"
                id="ec_4_9_portable"
                feedbackType="invalid"
                checked={form.sec_4_9_portable}
                value={form.sec_4_9_portable}
                isInvalid={!!errors.sec_4_9_portable}
                feedback={errors.sec_4_9_portable}
                onChange={() =>
                  setField('sec_4_9_portable', !form.sec_4_9_portable)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="digital"
                name="sec_4_9_digital"
                id="sec_4_9_digital"
                feedbackType="invalid"
                checked={form.sec_4_9_digital}
                value={form.sec_4_9_digital}
                isInvalid={!!errors.sec_4_9_digital}
                feedback={errors.sec_4_9_digital}
                onChange={() =>
                  setField('sec_4_9_digital', !form.sec_4_9_digital)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="computer"
                name="sec_4_9_computer"
                id="sec_4_9_computer"
                feedbackType="invalid"
                checked={form.sec_4_9_computer}
                value={form.sec_4_9_computer}
                isInvalid={!!errors.sec_4_9_computer}
                feedback={errors.sec_4_9_computer}
                onChange={() =>
                  setField('sec_4_9_computer', !form.sec_4_9_computer)
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 4.10 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              10. Printed Balance Certificates Required
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Check
                inline
                className="nbm-check-box"
                label="yes"
                name="sec_4_10_cert_yes"
                id="sec_4_10_cert_yes"
                feedbackType="invalid"
                checked={form.sec_4_10_cert_yes}
                value={form.sec_4_10_cert_yes}
                isInvalid={!!errors.sec_4_10_cert_yes}
                feedback={errors.sec_4_10_cert_yes}
                onChange={() =>
                  setField('sec_4_10_cert_yes', !form.sec_4_10_cert_yes)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="no"
                name="sec_4_10_cert_no"
                id="sec_4_10_cert_no"
                feedbackType="invalid"
                checked={form.sec_4_10_cert_no}
                value={form.sec_4_10_cert_no}
                isInvalid={!!errors.sec_4_10_cert_no}
                feedback={errors.sec_4_10_cert_no}
                onChange={() =>
                  setField('sec_4_10_cert_no', !form.sec_4_10_cert_no)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="not sure"
                name="sec_4_10_cert_not_sure"
                id="sec_4_10_cert_not_sure"
                feedbackType="invalid"
                checked={form.sec_4_10_cert_not_sure}
                value={form.sec_4_10_cert_not_sure}
                isInvalid={!!errors.sec_4_10_cert_not_sure}
                feedback={errors.sec_4_10_cert_not_sure}
                onChange={() =>
                  setField(
                    'sec_4_10_cert_not_sure',
                    !form.sec_4_10_cert_not_sure
                  )
                }
              />
            </div>
          </div>
        </div>

        {/* SEC 4.11 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              11. Safety Device (see note below)
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Check
                inline
                className="nbm-check-box"
                label="none"
                name="sec_4_11_safety_none"
                id="sec_4_11_safety_none"
                feedbackType="invalid"
                checked={form.sec_4_11_safety_none}
                value={form.sec_4_11_safety_none}
                isInvalid={!!errors.sec_4_11_safety_none}
                feedback={errors.sec_4_11_safety_none}
                onChange={() =>
                  setField('sec_4_11_safety_none', !form.sec_4_11_safety_none)
                }
              />
              <Form.Check
                inline
                className="nbm-check-box"
                label="perimeter fence"
                name="sec_4_11_safety_perimeter_fence"
                id="sec_4_11_safety_perimeter_fence"
                feedbackType="invalid"
                checked={form.sec_4_11_safety_perimeter_fence}
                value={form.sec_4_11_safety_perimeter_fence}
                isInvalid={!!errors.sec_4_11_safety_perimeter_fence}
                feedback={errors.sec_4_11_safety_perimeter_fence}
                onChange={() =>
                  setField(
                    'sec_4_11_safety_perimeter_fence',
                    !form.sec_4_11_safety_perimeter_fence
                  )
                }
              />
            </div>
          </div>
        </div>

        {/* ------ SAFETY NOTICE ROW -------- */}
        <div className="row new-balancing-machine-row nbm-text-content-row">
          <h2 className="nbm-section-heading-text italic-text">
            * Safety Device Note
          </h2>
          <span className="nbm-section-text italic-text">
            {content.safety_notice}
          </span>
        </div>

        {/* ------ SECTION 5: ADDITIONAL INFORMATION -------- */}
        <div className="row new-balancing-machine-row">
          <h2 className="nbm-section-heading-text">
            5. Additional Information
          </h2>
        </div>

        {/* SEC 5.1 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              Attach drawings, sketches, or pictures of rotors to be balanced,
              if available.
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            {/* TODO: MAKE FILE INPUT */}
            _____________
          </div>
        </div>

        {/* SEC 5.2 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              If rotor is to be balanced in its own bearings, please attach
              details of bearings (Type, OD, Width, self aligning, etc.)
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-input"
                controlId="sec_5_2_bearing_details"
              >
                <Form.Control
                  type="text"
                  placeholder="details of bearings"
                  value={form.sec_5_2_bearing_details}
                  isInvalid={!!errors.sec_5_2_bearing_details}
                  errors={errors.sec_5_2_bearing_details}
                  onChange={(e) =>
                    setField('sec_5_2_bearing_details', e.target.value)
                  }
                />
              </Form.Group>
            </div>
          </div>
        </div>

        {/* SEC 5.3 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              Other information that may be helpful in determining the
              appropriate balancing system.
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-input"
                controlId="sec_5_3_other_info"
              >
                <Form.Control
                  type="text"
                  placeholder="other information"
                  value={form.sec_5_3_other_info}
                  isInvalid={!!errors.sec_5_3_other_info}
                  errors={errors.sec_5_3_other_info}
                  onChange={(e) =>
                    setField('sec_5_3_other_info', e.target.value)
                  }
                />
              </Form.Group>
            </div>
          </div>
        </div>

        {/* SEC 5.4 */}
        <div className="row new-balancing-machine-row nbm-indent-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <span className="nbm-section-text">
              Other additional System Requirements not listed above.
            </span>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 new-balancing-machine-col">
            <div className="nbm-grouping-wrapper">
              <Form.Group
                className="nbm-form-input"
                controlId="sec_5_4_additional_req"
              >
                <Form.Control
                  type="text"
                  placeholder="additional requirements"
                  value={form.sec_5_4_additional_req}
                  isInvalid={!!errors.sec_5_4_additional_req}
                  errors={errors.sec_5_4_additional_req}
                  onChange={(e) =>
                    setField('sec_5_4_additional_req', e.target.value)
                  }
                />
              </Form.Group>
            </div>
          </div>
        </div>

        <div className="row new-balancing-machine-row button-row">
          <Button className="nbm-submit-btn btn" type="submit">
            Submit
          </Button>
        </div>
      </Form>
      <ModalWindow
        show={show}
        modalMode="nbm-form"
        setShow={setShow}
        setFormReset={setFormReset}
        message={message}
      />
    </div>
  );
}
