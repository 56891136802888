import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowMenu } from '../redux/controls';
import SideMenu from '../components/SideMenu/SideMenu';
import TopMenu from '../components/TopMenu/TopMenu';
import ContentBlock from '../components/ContentBlock/ContentBlock';
import Footer from '../components/Footer/Footer';
import '../styles/standardPageStyle.css';

export default function BalancingEquipment() {
  const { has_paid } = useSelector((state) => state.controls);
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  return (
    <div className="container-fluid stnd-container">
      {has_paid && <SideMenu />}
      {has_paid && <TopMenu />}

      <div className="row stnd-content" onClick={toggleMenu}>
        <div className="row stnd-top-content">
          <ContentBlock title={'Balancing Equipment'} text={null} />
        </div>

        <div className="row stnd-middle-content-flex-col"></div>

        <div className="row stnd-bottom-content"></div>
      </div>

      <Footer />
    </div>
  );
}
