import React from 'react';
import { Modal } from 'react-bootstrap';
import PDF from '../PDFcomponent/PDF';
import './largeModalWindow.css';

export default function LargeModalWindow({
  showLargeModal,
  setShowLargeModal,
  form_id,
  date,
  contact_person,
  title,
  email,
  phone,
  fax,
  company_name,
  company_address,
  city,
  state,
  postal_code,
  country,
  sec_2_1_fan,
  sec_2_1_armature,
  sec_2_1_turbine,
  sec_2_1_roll,
  sec_2_1_impeller,
  sec_2_1_spindle,
  sec_2_1_disk,
  sec_2_1_crankshaft,
  sec_2_1_flywheel,
  sec_2_1_other,
  sec_2_1_other_details,
  sec_2_2_between_bearings,
  sec_2_2_overhung,
  sec_2_2_between_bearings_and_overhung,
  sec_2_3_max_upward_force,
  sec_2_3_unit_kg,
  sec_2_3_unit_lb,
  sec_2_3_not_sure,
  sec_2_4_yes,
  sec_2_4_no,
  sec_2_4_both,
  sec_2_5_max_rotor_weight,
  sec_2_5_unit_kg,
  sec_2_5_unit_lb,
  sec_2_6_min_rotor_weight,
  sec_2_6_unit_kg,
  sec_2_6_unit_lb,
  sec_2_7_max_swing_diameter,
  sec_2_7_unit_mm,
  sec_2_7_unit_in,
  sec_2_8_max_rotor_length,
  sec_2_8_unit_mm,
  sec_2_8_unit_in,
  sec_2_9_min_rotor_length,
  sec_2_9_unit_mm,
  sec_2_9_unit_in,
  sec_2_10_max_dist_between_journals,
  sec_2_10_unit_mm,
  sec_2_10_unit_in,
  sec_2_11_min_dist_between_journals,
  sec_2_11_unit_mm,
  sec_2_11_unit_in,
  sec_2_12_own_bearing,
  sec_2_12_journal_surface,
  sec_2_13_max_journal_diam,
  sec_2_13_unit_mm,
  sec_2_13_unit_in,
  sec_2_14_min_journal_diam,
  sec_2_14_unit_mm,
  sec_2_14_unit_in,
  sec_2_15_min_journal_width,
  sec_2_15_unit_mm,
  sec_2_15_unit_in,
  sec_2_16_max_moment_of_inertia,
  sec_2_16_unit_kgm2,
  sec_2_16_unit_lbft2,
  sec_2_16_unit_not_sure,
  sec_2_17_speed_range_rotors,
  sec_2_18_max_power_absorbed,
  sec_2_18_unit_kw,
  sec_2_18_unit_hp,
  sec_2_18_unit_not_sure,
  sec_2_19_max_bal_speed_rolls,
  sec_2_19_unit_mmin,
  sec_2_19_unit_ftmin,
  sec_2_20_high_int_yes,
  sec_2_20_high_int_no,
  sec_3_1_one_plane,
  sec_3_1_two_plane,
  sec_3_1_both_planes,
  sec_3_2_tolerance_amount,
  sec_3_2_unit_iso,
  sec_3_2_unit_api,
  sec_3_2_unit_gmm,
  sec_3_2_unit_ozin,
  sec_3_3_max_init_unbal,
  sec_3_3_unit_gin,
  sec_3_3_unit_gmm,
  sec_3_3_unit_ozin,
  sec_3_3_unit_not_sure,
  sec_3_4_num_rotors_bal,
  sec_4_5_ac_volt,
  sec_4_5_phase,
  sec_4_5_hz,
  sec_4_6_belt,
  sec_4_6_end_drive,
  sec_4_7_drive_power_req,
  sec_4_7_unit_kw,
  sec_4_7_unit_hp,
  sec_4_7_unit_not_sure,
  sec_4_8_var_speed,
  sec_4_9_portable,
  sec_4_9_digital,
  sec_4_9_computer,
  sec_4_10_cert_yes,
  sec_4_10_cert_no,
  sec_4_10_cert_not_sure,
  sec_4_11_safety_none,
  sec_4_11_safety_perimeter_fence,
  sec_5_2_bearing_details,
  sec_5_3_other_info,
  sec_5_4_additional_req,
  checked,
  square,
  trashIcon,
  newBalMachIntro,
  newBalMachSafetyDeviceNote,
  processDeleteBalMachApp,
}) {
  const formatPhoneNum = (value) => {
    const formattedPhone =
      '(' + value.slice(0, 3) + ') ' + value.slice(3, 6) + '-' + value.slice(6);

    return formattedPhone;
  };
  const dateArr = date.split('-');
  const year = dateArr[0];
  const month = dateArr[1];
  const day = dateArr[2];

  const formattedDate = `${month}/${day}/${year}`;

  return (
    <Modal
      size="fullscreen"
      show={showLargeModal}
      onHide={() => setShowLargeModal(false)}
      aria-labelledby="example-modal-sizes-title-lg"
      id={form_id}
    >
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body>
        <div className="container-fluid balance-machine-app-data-container pdf-wrapper">
          <div className="row lm-row">
            <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 lm-col">
              <div className="lm-flex-row-opt-one">
                <h2 className="lm-h2">Customer: </h2>
                <span className="lm-text">{company_name}</span>
              </div>
              <div className="lm-flex-row-opt-one">
                <h2 className="lm-h2">Form ID: </h2>
                <span className="lm-text">{form_id}</span>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 lm-col">
              <div className="lm-img-wrapper">
                <img
                  src={trashIcon}
                  alt="trash"
                  className="lm-trash-icon"
                  onClick={() => processDeleteBalMachApp(form_id)}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

      <PDF
        form_id={form_id}
        date={formattedDate}
        contact_person={contact_person}
        title={title}
        email={email}
        phone={formatPhoneNum(phone)}
        fax={formatPhoneNum(fax)}
        company_name={company_name}
        company_address={company_address}
        city={city}
        state={state}
        postal_code={postal_code}
        country={country}
        sec_2_1_fan={sec_2_1_fan}
        sec_2_1_armature={sec_2_1_armature}
        sec_2_1_turbine={sec_2_1_turbine}
        sec_2_1_roll={sec_2_1_roll}
        sec_2_1_impeller={sec_2_1_impeller}
        sec_2_1_spindle={sec_2_1_spindle}
        sec_2_1_disk={sec_2_1_disk}
        sec_2_1_crankshaft={sec_2_1_crankshaft}
        sec_2_1_flywheel={sec_2_1_flywheel}
        sec_2_1_other={sec_2_1_other}
        sec_2_1_other_details={sec_2_1_other_details}
        sec_2_2_between_bearings={sec_2_2_between_bearings}
        sec_2_2_overhung={sec_2_2_overhung}
        sec_2_2_between_bearings_and_overhung={
          sec_2_2_between_bearings_and_overhung
        }
        sec_2_3_max_upward_force={sec_2_3_max_upward_force}
        sec_2_3_unit_kg={sec_2_3_unit_kg}
        sec_2_3_unit_lb={sec_2_3_unit_lb}
        sec_2_3_not_sure={sec_2_3_not_sure}
        sec_2_4_yes={sec_2_4_yes}
        sec_2_4_no={sec_2_4_no}
        sec_2_4_both={sec_2_4_both}
        sec_2_5_max_rotor_weight={sec_2_5_max_rotor_weight}
        sec_2_5_unit_kg={sec_2_5_unit_kg}
        sec_2_5_unit_lb={sec_2_5_unit_lb}
        sec_2_6_min_rotor_weight={sec_2_6_min_rotor_weight}
        sec_2_6_unit_kg={sec_2_6_unit_kg}
        sec_2_6_unit_lb={sec_2_6_unit_lb}
        sec_2_7_max_swing_diameter={sec_2_7_max_swing_diameter}
        sec_2_7_unit_mm={sec_2_7_unit_mm}
        sec_2_7_unit_in={sec_2_7_unit_in}
        sec_2_8_max_rotor_length={sec_2_8_max_rotor_length}
        sec_2_8_unit_mm={sec_2_8_unit_mm}
        sec_2_8_unit_in={sec_2_8_unit_in}
        sec_2_9_min_rotor_length={sec_2_9_min_rotor_length}
        sec_2_9_unit_mm={sec_2_9_unit_mm}
        sec_2_9_unit_in={sec_2_9_unit_in}
        sec_2_10_max_dist_between_journals={sec_2_10_max_dist_between_journals}
        sec_2_10_unit_mm={sec_2_10_unit_mm}
        sec_2_10_unit_in={sec_2_10_unit_in}
        sec_2_11_min_dist_between_journals={sec_2_11_min_dist_between_journals}
        sec_2_11_unit_mm={sec_2_11_unit_mm}
        sec_2_11_unit_in={sec_2_11_unit_in}
        sec_2_12_own_bearing={sec_2_12_own_bearing}
        sec_2_12_journal_surface={sec_2_12_journal_surface}
        sec_2_13_max_journal_diam={sec_2_13_max_journal_diam}
        sec_2_13_unit_mm={sec_2_13_unit_mm}
        sec_2_13_unit_in={sec_2_13_unit_in}
        sec_2_14_min_journal_diam={sec_2_14_min_journal_diam}
        sec_2_14_unit_mm={sec_2_14_unit_mm}
        sec_2_14_unit_in={sec_2_14_unit_in}
        sec_2_15_min_journal_width={sec_2_15_min_journal_width}
        sec_2_15_unit_mm={sec_2_15_unit_mm}
        sec_2_15_unit_in={sec_2_15_unit_in}
        sec_2_16_max_moment_of_inertia={sec_2_16_max_moment_of_inertia}
        sec_2_16_unit_kgm2={sec_2_16_unit_kgm2}
        sec_2_16_unit_lbft2={sec_2_16_unit_lbft2}
        sec_2_16_unit_not_sure={sec_2_16_unit_not_sure}
        sec_2_17_speed_range_rotors={sec_2_17_speed_range_rotors}
        sec_2_18_max_power_absorbed={sec_2_18_max_power_absorbed}
        sec_2_18_unit_kw={sec_2_18_unit_kw}
        sec_2_18_unit_hp={sec_2_18_unit_hp}
        sec_2_18_unit_not_sure={sec_2_18_unit_not_sure}
        sec_2_19_max_bal_speed_rolls={sec_2_19_max_bal_speed_rolls}
        sec_2_19_unit_mmin={sec_2_19_unit_mmin}
        sec_2_19_unit_ftmin={sec_2_19_unit_ftmin}
        sec_2_20_high_int_yes={sec_2_20_high_int_yes}
        sec_2_20_high_int_no={sec_2_20_high_int_no}
        sec_3_1_one_plane={sec_3_1_one_plane}
        sec_3_1_two_plane={sec_3_1_two_plane}
        sec_3_1_both_planes={sec_3_1_both_planes}
        sec_3_2_tolerance_amount={sec_3_2_tolerance_amount}
        sec_3_2_unit_iso={sec_3_2_unit_iso}
        sec_3_2_unit_api={sec_3_2_unit_api}
        sec_3_2_unit_gmm={sec_3_2_unit_gmm}
        sec_3_2_unit_ozin={sec_3_2_unit_ozin}
        sec_3_3_max_init_unbal={sec_3_3_max_init_unbal}
        sec_3_3_unit_gin={sec_3_3_unit_gin}
        sec_3_3_unit_gmm={sec_3_3_unit_gmm}
        sec_3_3_unit_ozin={sec_3_3_unit_ozin}
        sec_3_3_unit_not_sure={sec_3_3_unit_not_sure}
        sec_3_4_num_rotors_bal={sec_3_4_num_rotors_bal}
        sec_4_5_ac_volt={sec_4_5_ac_volt}
        sec_4_5_phase={sec_4_5_phase}
        sec_4_5_hz={sec_4_5_hz}
        sec_4_6_belt={sec_4_6_belt}
        sec_4_6_end_drive={sec_4_6_end_drive}
        sec_4_7_drive_power_req={sec_4_7_drive_power_req}
        sec_4_7_unit_kw={sec_4_7_unit_kw}
        sec_4_7_unit_hp={sec_4_7_unit_hp}
        sec_4_7_unit_not_sure={sec_4_7_unit_not_sure}
        sec_4_8_var_speed={sec_4_8_var_speed}
        sec_4_9_portable={sec_4_9_portable}
        sec_4_9_digital={sec_4_9_digital}
        sec_4_9_computer={sec_4_9_computer}
        sec_4_10_cert_yes={sec_4_10_cert_yes}
        sec_4_10_cert_no={sec_4_10_cert_no}
        sec_4_10_cert_not_sure={sec_4_10_cert_not_sure}
        sec_4_11_safety_none={sec_4_11_safety_none}
        sec_4_11_safety_perimeter_fence={sec_4_11_safety_perimeter_fence}
        sec_5_2_bearing_details={sec_5_2_bearing_details}
        sec_5_3_other_info={sec_5_3_other_info}
        sec_5_4_additional_req={sec_5_4_additional_req}
        checked={checked}
        square={square}
        trashIcon={trashIcon}
        newBalMachIntro={newBalMachIntro}
        newBalMachSafetyDeviceNote={newBalMachSafetyDeviceNote}
      />
    </Modal>
  );
}
