import React from 'react';
import './contentBlock.css';

export default function ReversibleContentBlock({
  blockVersion,
  title,
  text,
  image,
  caption,
}) {
  let reverse = false;
  if (blockVersion === 'standard') {
    reverse = false;
  } else {
    reverse = true;
  }

  return (
    <div className="container-fluid reversible-content-block">
      <div className="row reversible-content-block-row">
        <div
          className={
            reverse
              ? 'col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 reversible-content-block-col reversible-content-block-img-col'
              : 'col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 reversible-content-block-col reversible-content-block-text-col'
          }
        >
          {reverse ? (
            <>
              <img
                src={image}
                alt="equipment"
                className="reversible-content-block-image"
              />
              <div className="reversible-content-box-caption-container">
                {caption}
              </div>
            </>
          ) : (
            <>
              <h2 className="reversible-content-block-title">{title}</h2>
              <p className="content-block-text">{text}</p>
            </>
          )}
        </div>
        <div
          className={
            reverse
              ? 'col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 reversible-content-block-col reversible-content-block-text-col'
              : 'col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 reversible-content-block-col reversible-content-block-img-col'
          }
        >
          {reverse ? (
            <>
              <h2 className="reversible-content-block-title">{title}</h2>
              <p className="content-block-text">{text}</p>
            </>
          ) : (
            <>
              <img
                src={image}
                alt="equipment"
                className="reversible-content-block-image"
              />
              <div className="reversible-content-box-caption-container">
                {caption}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
