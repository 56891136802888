import React from 'react';
import { useDispatch } from 'react-redux';
import { setShowMenu } from '../redux/controls';
import TopMenu from '../components/TopMenu/TopMenu';
import SideMenu from '../components/SideMenu/SideMenu';
import Footer from '../components/Footer/Footer';
import '../styles/standardPageStyle.css';

export default function DisclaimerPrivacyAgreement() {
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  return (
    <div className="container-fluid stnd-container">
      <SideMenu />
      <TopMenu />
      <div className="stnd-header">
        <h1 className="stnd-h1 margin-left-two-percent">
          Disclaimer & Privacy Agreement
        </h1>
      </div>
      <div className="stnd-content" onClick={toggleMenu}>
        <div className="stnd-top-content-col stnd-width-fifty-percent">
          <h2 className="stnd-h2">Disclaimer</h2>
          <span className="stnd-text">
            The information contained within this Website, including both text
            and images, is copyrighted and may not be distributed, modified, or
            reproduced in whole or part without the prior advance and written
            consent of IRD LLC. Any links to third party Websites are not
            endorsements. IRD LLC is not responsible for content on those sites.
            Links are provided for your convenience.
          </span>
          <h2 className="stnd-h2 margin-top-thirty">Privacy Agreement</h2>
          <span className="stnd-text">
            Personal information collected through our forms and e-mail is not
            subject to sale, trade, or barter. We may contact you with
            information about IRD LLC. You will always have the option to be
            removed from our mailing list. Google Analytics is utilized to
            collect information automatically about your visit to our Website.
            The information obtained includes only demographic data and browsing
            patterns.
          </span>
        </div>

        <div className="stnd-middle-content"></div>

        <div className="stnd-bottom-content"></div>
      </div>

      <Footer />
    </div>
  );
}
