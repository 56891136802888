import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowMenu } from '../redux/controls';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import SideMenu from '../components/SideMenu/SideMenu';
import FeaturedProductForm from '../components/FeaturedProductForm/FeaturedProductForm';
import ProductDocumentsForm from '../components/ProductDocumentsForm/ProductDocumentsForm';
import JobPostingForm from '../components/JobPostingForm/JobPostingForm';
import VideoPostingForm from '../components/VideoPostingForm/VideoPostingForm';
import AdminInfoViewer from '../components/AdminInfoViewer/AdminInfoViewer';
import ReminderForm from '../components/ReminderForm/ReminderForm';
import Footer from '../components/Footer/Footer';
import '../styles/adminPageStyle.css';

export default function Admin() {
  const [mode, setMode] = useState('bal-mach-apps');
  const [pageTitle, setPageTitle] = useState('Update Featured Product');
  const {
    featured_product,
    job_postings,
    new_balance_machine_form_data,
    ird_videos,
    customer_util_videos,
    rma_requests,
    extended_surveys,
    feedback_surveys,
    shop_ird_surveys,
    product_service_reminders,
  } = useSelector((state) => state.controls);

  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  const handleSetFormMode = (e) => {
    setMode(e);
  };

  useEffect(() => {
    if (mode === 'featured') {
      setPageTitle('Add/Update Featured Product');
    } else if (mode === 'job-postings') {
      setPageTitle('Add/Update Job Postings');
    } else if (mode === 'bal-mach-apps') {
      setPageTitle('View Balance Machine Applications');
    } else if (mode === 'customer-youtube-videos') {
      setPageTitle('Add/Update Customer Utilization YouTube Videos');
    } else if (mode === 'ird-youtube-videos') {
      setPageTitle('Add/Update IRD Videos from YouTube');
    } else if (mode === 'extended-surveys') {
      setPageTitle('View Extended Surveys');
    } else if (mode === 'feedback-surveys') {
      setPageTitle('View Feedback Surveys');
    } else if (mode === 'rma-requests') {
      setPageTitle('View RMA Requests');
    } else if (mode === 'shop-ird-surveys') {
      setPageTitle('View IRD eCommerce Surveys');
    } else if (mode === 'product-service-reminders') {
      setPageTitle('Product/Service Reminders');
    }
  }, [mode]);

  return (
    <div className="admin-container">
      <SideMenu />
      <div className="admin-form-title-wrapper">
        <h2 className="admin-form-wrapper-text">{pageTitle}</h2>
      </div>

      <div className="admin-page-form-wrapper" onClick={toggleMenu}>
        <div className="row admin-page-row">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 admin-page-col">
            <div className="admin-form-selection-wrapper">
              <DropdownButton
                id="form-selection"
                title="Select What You Want To Do"
                onSelect={handleSetFormMode}>
                <Dropdown.Item eventKey="featured">
                  Add/Update Featured Product
                </Dropdown.Item>

                <Dropdown.Item eventKey="job-postings">
                  Add/Update Job Postings
                </Dropdown.Item>
                <Dropdown.Item eventKey="bal-mach-apps">
                  View Balance Machine Applications
                </Dropdown.Item>
                <Dropdown.Item eventKey="customer-youtube-videos">
                  Edit Customer Utilization YouTube Videos
                </Dropdown.Item>
                <Dropdown.Item eventKey="ird-youtube-videos">
                  Add/Update IRD Videos From YouTube
                </Dropdown.Item>
                <Dropdown.Item eventKey="extended-surveys">
                  View Extended Surveys
                </Dropdown.Item>
                <Dropdown.Item eventKey="feedback-surveys">
                  View Feedback Surveys
                </Dropdown.Item>
                <Dropdown.Item eventKey="rma-requests">
                  View RMA Requests
                </Dropdown.Item>
                <Dropdown.Item eventKey="shop-ird-surveys">
                  View IRD eCommerce Surveys
                </Dropdown.Item>
                <Dropdown.Item eventKey="product-service-reminders">
                  Product Service Reminders
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>

          {/* FORM SECTION */}
          {mode !== 'bal-mach-apps' &&
            mode !== 'extended-surveys' &&
            mode !== 'feedback-surveys' &&
            mode !== 'rma-requests' &&
            mode !== 'shop-ird-surveys' && (
              <div className="one col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 admin-page-col admin-page-form-col">
                {mode === 'featured' && <FeaturedProductForm />}
                {mode === 'prod-doc' && <ProductDocumentsForm />}
                {mode === 'job-postings' && <JobPostingForm />}
                {mode === 'customer-youtube-videos' && (
                  <VideoPostingForm irdVideo={false} />
                )}
                {mode === 'ird-youtube-videos' && (
                  <VideoPostingForm irdVideo={true} />
                )}
                {mode === 'bal-mach-apps' && <div>View Applications</div>}
                {mode === 'product-service-reminders' && <ReminderForm />}
              </div>
            )}
          {/* VIEWING SECTION */}
          {mode !== 'bal-mach-apps' &&
            mode !== 'extended-surveys' &&
            mode !== 'feedback-surveys' &&
            mode !== 'rma-requests' &&
            mode !== 'shop-ird-surveys' && (
              <div className="two col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 admin-page-col admin-page-info-col">
                {mode === 'featured' && featured_product && (
                  <AdminInfoViewer
                    mode={mode}
                    data={featured_product}
                    key={mode}
                  />
                )}
                {mode === 'job-postings' && job_postings && (
                  <AdminInfoViewer mode={mode} data={job_postings} key={mode} />
                )}

                {mode === 'ird-youtube-videos' && ird_videos && (
                  <AdminInfoViewer mode={mode} data={ird_videos} key={mode} />
                )}

                {mode === 'customer-youtube-videos' && customer_util_videos && (
                  <AdminInfoViewer
                    mode={mode}
                    data={customer_util_videos}
                    key={mode}
                  />
                )}

                {mode === 'product-service-reminders' &&
                  product_service_reminders && (
                    <AdminInfoViewer
                      mode={mode}
                      data={product_service_reminders}
                      key={mode}
                    />
                  )}
              </div>
            )}

          {mode === 'bal-mach-apps' && (
            <div className="three col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 admin-page-col admin-page-info-col">
              {mode === 'bal-mach-apps' && new_balance_machine_form_data && (
                <AdminInfoViewer
                  mode={mode}
                  data={new_balance_machine_form_data}
                  key={mode}
                />
              )}
            </div>
          )}

          {mode === 'extended-surveys' && (
            <div className="four col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 admin-page-col admin-page-info-col">
              {mode === 'extended-surveys' && extended_surveys && (
                <AdminInfoViewer
                  mode={mode}
                  data={extended_surveys}
                  key={mode}
                />
              )}
            </div>
          )}

          {mode === 'feedback-surveys' && (
            <div className="four col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 admin-page-col admin-page-info-col">
              {mode === 'feedback-surveys' && feedback_surveys && (
                <AdminInfoViewer
                  mode={mode}
                  data={feedback_surveys}
                  key={mode}
                />
              )}
            </div>
          )}

          {mode === 'rma-requests' && (
            <div className="four col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 admin-page-col admin-page-info-col">
              {mode === 'rma-requests' && rma_requests && (
                <AdminInfoViewer mode={mode} data={rma_requests} key={mode} />
              )}
            </div>
          )}

          {mode === 'shop-ird-surveys' && (
            <div className="four col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 admin-page-col admin-page-info-col">
              {mode === 'shop-ird-surveys' && shop_ird_surveys && (
                <AdminInfoViewer
                  mode={mode}
                  data={shop_ird_surveys}
                  key={mode}
                />
              )}
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}
