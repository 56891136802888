/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
// import { useSelector, useDispatch } from 'react-redux';
// import { setContactType } from '../../redux/controls';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { v4 as uuidv4 } from 'uuid';
// import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './contactForm.css';

import logo from '../../assets/images/ird-logo-shop.png';

export default function InDepthSurvey({ usingOnPage }) {
  // const { contact_type } = useSelector((state) => state.controls);
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState('Extended Survey');
  const [template, setTemplate] = useState('');
  const [formReset, setFormReset] = useState(false);
  const [randomId, setRandomId] = useState(uuidv4());
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({
    form_id: randomId,
    file_name: randomId + '.pdf',
    form_type: '',
    first_name: '',
    company_name: '',
    job_details: '',
    email: '',
    equipment: '',
    like_before: '',
    problems_to_solve: '',
    stand_out: '',
    obstacles: '',
    made_happiest: '',
    exceeded_expectations: '',
    achievements: '',
    recommended_reasons: '',
    anywhere_to_improve: '',
    start_search_and_hear_about_us: '',
    message: '',
    created_on: new Date().toLocaleDateString('en-US'),
  });
  const formRef = useRef();
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const today = `${month}-${day}-${year}`;
  // const dispatch = useDispatch();
  const emailJSconfig = {
    service_id: 'ird_email',
    in_depth_survey: 'other_dvdmkwf',
  };
  const notifySendSuccess = () =>
    toast.success(`Thank you ${form['first_name']} for your feedback`, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

  const notifyFormError = () =>
    toast.error('Uh oh, please check the form for errors', {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const findFormErrors = () => {
    const newErrors = {};

    // CONTACT INFO
    if (!form.first_name || form.first_name === '') {
      newErrors.first_name = 'please enter a first name';
    } else if (!form.company_name || form.company_name === '') {
      newErrors.company_name = 'please enter company name';
    } else if (!form.email || form.email === '') {
      newErrors.email = 'please enter a valid email address';
    } else if (!form.email.includes('@')) {
      newErrors.email = 'email must contain an @ symbol';
    }

    return newErrors;
  };

  const resetForm = (field, value) => {
    if (field === 'form_id') {
      field = randomId;
    } else if (field === 'file_name') {
      field = randomId + '.pdf';
    } else if (value === true) {
      form[field] = false; // setting the DOM object value
      field = false; // setting the form object value
    } else if (field === 'created_on') {
      field = today;
    } else if (field === 'rental_model') {
      form[field] = false;
      field = false;
    } else {
      form[field] = '';
    }

    setForm({
      ...form,
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  function handleReset() {
    setFormReset(true);
  }

  const addSurveyToFirebase = async (data) => {
    setLoading(true);
    const localSurveyRef = doc(db, 'extended_surveys', randomId);
    await setDoc(localSurveyRef, data);
    setLoading(false);
  };

  function handleSubmit(e) {
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      notifyFormError();
      // console.log('if newErrors', newErrors);
    } else {
      // console.log('else newErrors', newErrors);
      addSurveyToFirebase(form).then(
        (result) => {
          console.log('result', result);
          notifySendSuccess();
          handleReset();
          formRef.current.reset();
          // emailjs
          //   .sendForm(
          //     emailJSconfig.service_id,
          //     template,
          //     formRef.current,
          //     'ASfyRsTYNICgAod9p'
          //   )
          //   .then(
          //     (result) => {
          //       console.log(result.text);
          //       notifySendSuccess();
          //       handleReset();
          //       formRef.current.reset();
          //     },
          //     (error) => {
          //       console.log(error.text);
          //     }
          //   );
        },
        (error) => {
          console.log('error', error);
        }
      );
    }
  }

  useEffect(() => {
    setTemplate(emailJSconfig.in_depth_survey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setField('created_on', today);
    setField('form_id', randomId);
    setField('file_name', `extended-survey-${form.first_name}-${today}.pdf`);
    setField('form_type', 'extended_survey');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.first_name]);

  useEffect(() => {
    if (formReset) {
      for (const key in form) {
        let value = form[key];
        resetForm(key, value);
      }
      setFormReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formReset]);

  return (
    <div className="contact-form-container form-width-30-percent">
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="contact-header-section">
        <img src={logo} alt="logo" className="contact-logo" />
        <div className="contact-header-text-wrapper">
          <p className="contact-header-text">
            Would you be willing to take a few minutes to tell us about your
            experience with IRD?
          </p>
        </div>
      </div>
      <Form className="contact-form" ref={formRef}>
        <Form.Group className="hidden" controlId="subject">
          <Form.Label>Subject</Form.Label>
          <Form.Control placeholder="Subject" name="subject" value={subject} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="first_name">
          <Form.Label>
            <strong>What is your first name?</strong>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="first name"
            name="first_name"
            value={form.first_name}
            isInvalid={!!errors.first_name}
            errors={errors.first_name}
            onChange={(e) => setField('first_name', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.first_name}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="company-name">
          <Form.Label>
            <strong>What is the name of your company?</strong>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="company name"
            name="company_name"
            value={form.company_name}
            isInvalid={!!errors.company_name}
            errors={errors.company_name}
            onChange={(e) => setField('company_name', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.company_name}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="job-details">
          <Form.Label>
            <strong>
              {' '}
              What do you do there? (Your job title; how long you've been there;
              any other information you'd like to provide)
            </strong>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="job details"
            name="job_details"
            value={form.job_details}
            isInvalid={!!errors.job_details}
            errors={errors.job_details}
            onChange={(e) => setField('job_details', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.job_details}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="email">
          <Form.Label>
            <strong>What is your email address?</strong>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="email"
            name="email"
            value={form.email}
            isInvalid={!!errors.email}
            errors={errors.email}
            onChange={(e) => setField('email', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="job-details">
          <Form.Label>
            <strong>What IRD equipment do you have and use?</strong>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="4"
            placeholder="equipment"
            name="equipment"
            value={form.equipment}
            isInvalid={!!errors.equipment}
            errors={errors.equipment}
            onChange={(e) => setField('equipment', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.equipment}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="job-details">
          <Form.Label>
            <strong>
              What was it like before you had our products or services?
            </strong>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="4"
            placeholder="type answer here"
            name="like_before"
            value={form.like_before}
            isInvalid={!!errors.like_before}
            errors={errors.like_before}
            onChange={(e) => setField('like_before', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.like_before}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="job-details">
          <Form.Label>
            <strong>
              What problem(s) were you trying to solve with our products or
              services?
            </strong>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="4"
            placeholder="type answer here"
            name="problems_to_solve"
            value={form.problems_to_solve}
            isInvalid={!!errors.problems_to_solve}
            errors={errors.problems_to_solve}
            onChange={(e) => setField('problems_to_solve', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.problems_to_solve}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="job-details">
          <Form.Label>
            <strong>
              What made our product(s) or services stand out from other options?
              What features sold you or made you most excited about our products
              or services?
            </strong>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="4"
            placeholder="type answer here"
            name="stand_out"
            value={form.stand_out}
            isInvalid={!!errors.stand_out}
            errors={errors.stand_out}
            onChange={(e) => setField('stand_out', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.stand_out}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="job-details">
          <Form.Label>
            <strong>
              Where there any obstacles that almost prevented you from buying
              our products or services?
            </strong>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="4"
            placeholder="type answer here"
            name="obstacles"
            value={form.obstacles}
            isInvalid={!!errors.obstacles}
            errors={errors.obstacles}
            onChange={(e) => setField('obstacles', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.obstacles}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="job-details">
          <Form.Label>
            <strong>
              What made you happiest about working with our company?
            </strong>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="4"
            placeholder="type answer here"
            name="made_happiest"
            value={form.made_happiest}
            isInvalid={!!errors.made_happiest}
            errors={errors.made_happiest}
            onChange={(e) => setField('made_happiest', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.made_happiest}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="job-details">
          <Form.Label>
            <strong>
              What has exceeded your expectations since working with us? What
              about IRD surprised you the most?
            </strong>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="4"
            placeholder="type answer here"
            name="exceeded_expectations"
            value={form.exceeded_expectations}
            isInvalid={!!errors.exceeded_expectations}
            errors={errors.exceeded_expectations}
            onChange={(e) => setField('exceeded_expectations', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.exceeded_expectations}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="job-details">
          <Form.Label>
            <strong>
              What has have you been able to achieve since using our product or
              services?
            </strong>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="4"
            placeholder="type answer here"
            name="achievements"
            value={form.achievements}
            isInvalid={!!errors.achievements}
            errors={errors.achievements}
            onChange={(e) => setField('achievements', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.achievements}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="job-details">
          <Form.Label>
            <strong>
              What's the main reason you recommend our product or services? What
              would you tell someone who's considering working with us?
            </strong>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="4"
            placeholder="type answer here"
            name="recommended_reasons"
            value={form.recommended_reasons}
            isInvalid={!!errors.recommended_reasons}
            errors={errors.recommended_reasons}
            onChange={(e) => setField('recommended_reasons', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.recommended_reasons}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="job-details">
          <Form.Label>
            <strong>Is there anywhere you think we could improve?</strong>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="4"
            placeholder="type answer here"
            name="anywhere_to_improve"
            value={form.anywhere_to_improve}
            isInvalid={!!errors.anywhere_to_improve}
            errors={errors.anywhere_to_improve}
            onChange={(e) => setField('anywhere_to_improve', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.anywhere_to_improve}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="job-details">
          <Form.Label>
            <strong>
              Where did you start your search, and how did you hear about IRD?
            </strong>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="4"
            placeholder="type answer here"
            name="start_search_and_hear_about_us"
            value={form.start_search_and_hear_about_us}
            isInvalid={!!errors.start_search_and_hear_about_us}
            errors={errors.start_search_and_hear_about_us}
            onChange={(e) =>
              setField('start_search_and_hear_about_us', e.target.value)
            }
          />
          <Form.Control.Feedback type="invalid">
            {errors.start_search_and_hear_about}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="job-details">
          <Form.Label>
            <strong>
              Thank you so much for your time! Is there anything else that you
              would like to comment or say before you go?
            </strong>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="4"
            placeholder="type answer here"
            name="message"
            value={form.message}
            isInvalid={!!errors.message}
            errors={errors.message}
            onChange={(e) => setField('message', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.message}
          </Form.Control.Feedback>
        </Form.Group>

        <div className="contact-form-button-wrapper">
          <Button
            className="contact-submit-button"
            type="submit"
            disabled={loading}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
}
