import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from '../routes/ProtectedRoute';
import Login from '../pages/Login';
import Home from '../pages/Home';
import About from '../pages/About';
import LexsecoProducts from '../pages/LexsecoProducts';
import Admin from '../pages/Admin';
import IrdBalancing from '../pages/IrdBalancing';
import IrdBalancingProducts from '../pages/IrdBalancingProducts';
import Service from '../pages/Service';
import Blog from '../pages/Blog';
import Contact from '../pages/Contact';
import CoreLossTesting from '../pages/CoreLossTesting';
import Refurbished from '../pages/Refurbished';
import Rentals from '../pages/Rentals';
import Utilization from '../pages/Utilization';
import NewProducts from '../pages/NewProducts';
import Careers from '../pages/Careers';
import NewBalanceMachineApp from '../pages/NewBalanceMachineApp';
import SoftBearingBalanceMachines from '../pages/SoftBearingBalanceMachines';
import LexsecoAdvantage from '../pages/LexsecoAdvantage';
import BalancingInstruments from '../pages/BalancingInstruments';
import Sensors from '../pages/Sensors';
import PartsAndAccessories from '../pages/PartsAndAccessories';
import DisclaimerPrivacyAgreement from '../pages/DisclaimerPrivacyAgreement';
import BalancingMachinesInfo from '../pages/BalancingMachinesInfo';
import Unbalance from '../pages/Unbalance';
import CoreEvaluation from '../pages/CoreEvaluation';
import BalancingEqupment from '../pages/BalancingEquipment';
import MeterExchange from '../pages/MeterExchange';
import BalancingTraining from '../pages/BalancingTraining';
import RefurbishedMachines from '../pages/RefurbishedMachines';
import CltTraining from '../pages/CltTraining';
import FAQ from '../pages/FAQ';
import TrainingVideos from '../pages/TrainingVideos';
import LegacyProducts from '../pages/LegacyProducts';
import LexsecoCltUpgrades from '../pages/LexsecoCltUpgrades';
import FeedbackSurveyPage from '../pages/FeedbackSurveyPage';
import InDepthSurveyPage from '../pages/InDepthSurveyPage';
import ShopIrdSurveyPage from '../pages/ShopIrdSurveyPage';
import PDF from './PDFcomponent/PDF';
import RmaFormPage from '../pages/RmaFormPage';

export default function Nav() {
  return (
    <Routes>
      <Route
        path="/admin"
        element={
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        }
      />
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/ird-balancing" element={<IrdBalancing />} />
      <Route
        path="/ird-balancing-products"
        element={<IrdBalancingProducts />}
      />
      <Route path="/about" element={<About />} />
      <Route path="/lexseco-products" element={<LexsecoProducts />} />
      <Route path="/service" element={<Service />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/core-loss-testing" element={<CoreLossTesting />} />
      <Route path="/refurbished" element={<Refurbished />} />
      <Route path="/rentals" element={<Rentals />} />
      <Route path="/customer-utilization" element={<Utilization />} />
      <Route path="/new-products" element={<NewProducts />} />
      <Route path="/careers" element={<Careers />} />
      <Route
        path="/new-balance-machine-app"
        element={<NewBalanceMachineApp />}
      />
      <Route
        path="/soft-bearing-balance-machines"
        element={<SoftBearingBalanceMachines />}
      />
      <Route path="/lexseco-advantage" element={<LexsecoAdvantage />} />
      <Route path="/balancing-instruments" element={<BalancingInstruments />} />
      <Route path="/parts-and-accessories" element={<PartsAndAccessories />} />
      <Route path="/sensors" element={<Sensors />} />
      <Route
        path="/disclaimer-and-privacy-agreement"
        element={<DisclaimerPrivacyAgreement />}
      />
      <Route
        path="/balancing-machines-info"
        element={<BalancingMachinesInfo />}
      />
      <Route path="/unbalance" element={<Unbalance />} />
      <Route path="/core-evaluation" element={<CoreEvaluation />} />
      <Route path="/balancing-equipment" element={<BalancingEqupment />} />
      <Route path="/meter-exchange" element={<MeterExchange />} />
      <Route path="/balancing-training" element={<BalancingTraining />} />
      <Route path="/refurbished-machines" element={<RefurbishedMachines />} />
      <Route path="/clt-training" element={<CltTraining />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/training-videos" element={<TrainingVideos />} />
      <Route path="/legacy-products" element={<LegacyProducts />} />
      <Route path="/lexseco-clt-upgrades" element={<LexsecoCltUpgrades />} />
      <Route path="/shop-ird-survey" element={<ShopIrdSurveyPage />} />
      <Route path="/extended-survey" element={<InDepthSurveyPage />} />
      <Route path="/feedback-survey" element={<FeedbackSurveyPage />} />
      <Route path="/rma-request" element={<RmaFormPage />} />

      <Route path="/pdf" element={<PDF />} />
    </Routes>
  );
}
