/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import './updatingService.css';

import eMeterBrochure from '../../assets/pdf-docs/IRD-E-Meter-Brochure.pdf';

export default function UpdatingService() {
  return (
    <div className="us-container">
      <div className="us-top-content">
        <h2 className="us-header-text">Update & Upgrade Existing Equipment</h2>
        <span className="us-text">
          Technology advances everyday, so why are you using an obsolete device?
          Every change IRD® has made to its products has been with the user in
          mind. Our 295 Instrument interface makes it easier to navigate and
          provides more precise balancing. The Core Loss Testers are now fully
          automated and can determine hot spots to aid in motor repair.
          Equipment that works more efficiently saves time. Time is money!
        </span>
      </div>
      <div className="us-middle-content">
        <h3 className="us-sub-header-text">Updating Balancing Equipment</h3>
        <span className="us-text">
          Update equipment from any manufacturer by selectively adding new IRD®
          Balancing instrumentation, suspension and bearing assemblies to your
          existing machine bases, pedestals and drive systems. You'll get the
          leading-edge performance of a brand new balancing system at a fraction
          of the cost.
        </span>
      </div>
      <div className="us-bottom-content">
        <h3 className="us-sub-header-text">Updating a Core Loss Tester</h3>
        <span className="us-text">
          LEXSECO®'s comprehensive upgrade utilizes our unique Flux Metering
          System which is certified with the National Institute of Standards and
          Technology (NIST) to be of the highest accuracy. In conjunction with
          the Flux Metering System, the MP7TM Multiparameter Hardware/Software
          system enhances tests.
        </span>
        <span className="us-text">
          Mounted in a rugged steel enclosure with a locking lid, the new
          upgrade package can be retrofitted by the LEXSECO® engineering staff
          to any LEXSECO® Core Loss Tester. Also included in the package is a
          one year free membership in the LEXSECO® MPTM Software Users Group,
          new control components, refurbishment of existing cabinet, and a
          thorough checkout/evaluation of the key tester components. This
          quality LEXSECO® product comes with a 1 year warranty, top quality
          components, and an accessible engineering staff for after-market
          support. Upgrading your device also qualifies you for our Flux Meter
          Calibration Exchange Program.{' '}
          <a href={eMeterBrochure} target="_blank" className="us-link">
            Learn more about the components of an upgrade here.
          </a>
        </span>
      </div>
    </div>
  );
}
