import React from 'react';
import './pagination.css';

export default function Pagination({
  itemsPerPage,
  totalItems,
  paginate,
  currentPage,
}) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="pagination-container">
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={
              number === currentPage
                ? 'active-pagination-item'
                : 'pagination-item'
            }
          >
            <span
              className={
                number === currentPage
                  ? 'active-pagination-link'
                  : 'pagination-link'
              }
              onClick={() => paginate(number)}
            >
              {number}
            </span>
          </li>
        ))}
      </ul>
    </nav>
  );
}
