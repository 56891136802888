/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setContactType } from '../../redux/controls';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { v4 as uuidv4 } from 'uuid';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import './contactForm.css';

import logo from '../../assets/images/ird-logo-shop.png';

export default function FeedbackSurvey() {
  // const { contact_type } = useSelector((state) => state.controls);
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState('Feedback Survey');
  const [template, setTemplate] = useState('');
  const [formReset, setFormReset] = useState(false);
  const [randomId, setRandomId] = useState(uuidv4());
  const [errors, setErrors] = useState({});
  const [likelyToRecommend, setLikelyToRecommend] = useState(null);
  const [howSatisfied, setHowSatisfied] = useState(null);
  const [describeProducts, setDescribeProducts] = useState(null);
  const [productsMeetNeeds, setProductsMeetNeeds] = useState(null);
  const [moneyValueOfProducts, setMoneyValueOfProducts] = useState(null);
  const [responsive, setResponsive] = useState(null);
  const [howLongACustomer, setHowLongACustomer] = useState(null);
  const [howLikelyToPurchaseAgain, setHowLikelyToPurchaseAgain] =
    useState(null);
  const [form, setForm] = useState({
    form_id: randomId,
    file_name: randomId + '.pdf',
    form_type: 'feedback_survey',
    likely_to_recommend: '',
    satisfaction: '',
    describe_products: '',
    products_meet_needs: '',
    money_value_of_products: '',
    responsive: '',
    how_long_a_customer: '',
    how_likely_to_purchase_again: '',
    message: '',
    created_on: new Date().toLocaleDateString('en-US'),
  });

  const formRef = useRef();
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const today = `${month}-${day}-${year}`;
  // const dispatch = useDispatch();
  const emailJSconfig = {
    service_id: 'ird_email',
    in_depth_survey: 'other_dvdmkwf',
  };

  const notifySendSuccess = () =>
    toast.success(`Thank you for your feedback`, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });

  const notifyFormError = () =>
    toast.error('Uh oh, please check the form for errors', {
      position: 'top-center',
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const findFormErrors = () => {
    const newErrors = {};

    return newErrors;
  };

  const resetForm = (field, value) => {
    if (field === 'form_id') {
      field = randomId;
    } else if (field === 'file_name') {
      field = randomId + '.pdf';
    } else if (value === true) {
      form[field] = false; // setting the DOM object value
      field = false; // setting the form object value
    } else if (field === 'created_on') {
      field = today;
    } else if (field === 'rental_model') {
      form[field] = false;
      field = false;
    } else {
      form[field] = '';
    }

    setForm({
      ...form,
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });

    setLikelyToRecommend(null);
    setHowSatisfied(null);
    setDescribeProducts(null);
    setProductsMeetNeeds(null);
    setMoneyValueOfProducts(null);
    setResponsive(null);
    setHowLongACustomer(null);
    setHowLikelyToPurchaseAgain(null);
  };

  function handleReset() {
    setFormReset(true);
  }

  const addSurveyToFirebase = async (data) => {
    setLoading(true);
    const localSurveyRef = doc(db, 'feedback_surveys', randomId);
    await setDoc(localSurveyRef, data);
    setLoading(false);
  };

  function handleSubmit(e) {
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      notifyFormError();
      // console.log('if newErrors', newErrors);
    } else {
      // console.log('else newErrors', newErrors);
      addSurveyToFirebase(form).then(
        (result) => {
          console.log('result', result);
          notifySendSuccess();
          handleReset();
          formRef.current.reset();
          // emailjs
          //   .sendForm(
          //     emailJSconfig.service_id,
          //     template,
          //     formRef.current,
          //     'ASfyRsTYNICgAod9p'
          //   )
          //   .then(
          //     (result) => {
          //       console.log(result.text);
          //       notifySendSuccess();
          //       handleReset();
          //       formRef.current.reset();
          //     },
          //     (error) => {
          //       console.log(error.text);
          //     }
          //   );
        },
        (error) => {
          console.log('error', error);
        }
      );
    }
  }

  useEffect(() => {
    setTemplate(emailJSconfig.feedback_survey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setField('created_on', today);
    setField('form_id', randomId);
    setField('form_type', 'feedback_survey');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setField('likely_to_recommend', likelyToRecommend);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [likelyToRecommend]);

  useEffect(() => {
    setField('satisfaction', howSatisfied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [howSatisfied]);

  useEffect(() => {
    setField('products_meet_needs', productsMeetNeeds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsMeetNeeds]);

  useEffect(() => {
    setField('money_value_of_products', moneyValueOfProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moneyValueOfProducts]);

  useEffect(() => {
    setField('products_meet_needs', productsMeetNeeds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsMeetNeeds]);

  useEffect(() => {
    setField('responsive', responsive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsive]);

  useEffect(() => {
    setField('how_long_a_customer', howLongACustomer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [howLongACustomer]);

  useEffect(() => {
    setField('how_likely_to_purchase_again', howLikelyToPurchaseAgain);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [howLikelyToPurchaseAgain]);

  useEffect(() => {
    if (formReset) {
      for (const key in form) {
        let value = form[key];
        resetForm(key, value);
      }
      setFormReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formReset]);

  // console.log(formRef.current);
  // console.log('contact_type', contact_type);
  // console.log('template', template);
  console.log('form', form);

  return (
    <div className="contact-form-container form-width-50-percent">
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="contact-header-section">
        <img src={logo} alt="logo" className="contact-logo" />
        <div className="contact-header-text-wrapper">
          <p className="contact-header-text">
            Thank you for taking the time to help us improve. <br />
            We'd love to know a bit more about experience with us.
          </p>
        </div>
      </div>
      <Form className="contact-form" ref={formRef}>
        <Form.Group className="mb-3 hidden" controlId="subject">
          <Form.Label className="hidden">Subject</Form.Label>
          <Form.Control
            placeholder="Subject"
            name="subject"
            value={subject}
            className="hidden"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="likely_to_recommend">
          <Form.Label className="bold-text">
            How likely is it that you would recommend IRD to a friend or
            colleague? (1 being not at all, 3 being maybe, 6 being 100%)
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="1"
              type="checkbox"
              id={`likely_to_recommend_1`}
              name="likely_to_recommend"
              checked={likelyToRecommend === '1'}
              feedbackType="invalid"
              value={'1'}
              isInvalid={!!errors.likely_to_recommend}
              feedback={errors.likely_to_recommend}
              onChange={(e) => setLikelyToRecommend(e.target.value)}
            />
            <Form.Check
              inline
              label="2"
              type="checkbox"
              id={`likely_to_recommend_2`}
              name="likely_to_recommend"
              checked={likelyToRecommend === '2'}
              feedbackType="invalid"
              value={'2'}
              isInvalid={!!errors.likely_to_recommend}
              feedback={errors.likely_to_recommend}
              onChange={(e) => setLikelyToRecommend(e.target.value)}
            />
            <Form.Check
              inline
              label="3"
              type="checkbox"
              id={`likely_to_recommend_3`}
              name="likely_to_recommend"
              checked={likelyToRecommend === '3'}
              feedbackType="invalid"
              value={'3'}
              isInvalid={!!errors.likely_to_recommend}
              feedback={errors.likely_to_recommend}
              onChange={(e) => setLikelyToRecommend(e.target.value)}
            />
            <Form.Check
              inline
              label="4"
              type="checkbox"
              id={`likely_to_recommend_4`}
              name="likely_to_recommend"
              checked={likelyToRecommend === '4'}
              feedbackType="invalid"
              value={'4'}
              isInvalid={!!errors.likely_to_recommend}
              feedback={errors.likely_to_recommend}
              onChange={(e) => setLikelyToRecommend(e.target.value)}
            />
            <Form.Check
              inline
              label="5"
              type="checkbox"
              id={`likely_to_recommend_5`}
              name="likely_to_recommend"
              checked={likelyToRecommend === '5'}
              feedbackType="invalid"
              value={'5'}
              isInvalid={!!errors.likely_to_recommend}
              feedback={errors.likely_to_recommend}
              onChange={(e) => setLikelyToRecommend(e.target.value)}
            />
            <Form.Check
              inline
              label="6"
              type="checkbox"
              id={`likely_to_recommend_6`}
              name="likely_to_recommend"
              checked={likelyToRecommend === '6'}
              feedbackType="invalid"
              value={'6'}
              isInvalid={!!errors.likely_to_recommend}
              feedback={errors.likely_to_recommend}
              onChange={(e) => setLikelyToRecommend(e.target.value)}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="satisfaction">
          <Form.Label className="bold-text">
            Overall how satisfied or dissatisfied with IRD?
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="Very satisfied"
              type="checkbox"
              id={`satisfaction_very_satisfied`}
              name="satisfaction"
              checked={howSatisfied === 'very satisfied'}
              feedbackType="invalid"
              value={'very satisfied'}
              isInvalid={!!errors.satisfaction}
              feedback={errors.satisfaction}
              onChange={(e) => setHowSatisfied(e.target.value)}
            />
            <Form.Check
              inline
              label="Somewhat satisfied"
              type="checkbox"
              id={`satisfaction_somewhat_satisfied`}
              name="satisfaction"
              checked={howSatisfied === 'somewhat satisfied'}
              feedbackType="invalid"
              value={'somewhat satisfied'}
              isInvalid={!!errors.satisfaction}
              feedback={errors.satisfaction}
              onChange={(e) => setHowSatisfied(e.target.value)}
            />
            <Form.Check
              inline
              label="Neither satisfied or dissatisfied"
              type="checkbox"
              id={`satisfaction_neither_satisfied_or_dissatisfied`}
              name="satisfaction"
              checked={howSatisfied === 'neither satisfied or dissatisfied'}
              feedbackType="invalid"
              value={'neither satisfied or dissatisfied'}
              isInvalid={!!errors.satisfaction}
              feedback={errors.satisfaction}
              onChange={(e) => setHowSatisfied(e.target.value)}
            />
            <Form.Check
              inline
              label="Somewhat dissatisfied"
              type="checkbox"
              id={`satisfaction_somewhat_dissatisfied`}
              name="satisfaction"
              checked={howSatisfied === 'somewhat dissatisfied'}
              feedbackType="invalid"
              value={'somewhat dissatisfied'}
              isInvalid={!!errors.satisfaction}
              feedback={errors.satisfaction}
              onChange={(e) => setHowSatisfied(e.target.value)}
            />
            <Form.Check
              inline
              label="Very dissatisfied"
              type="checkbox"
              id={`satisfaction_very_dissatisfied`}
              name="satisfaction"
              checked={howSatisfied === 'very dissatisfied'}
              feedbackType="invalid"
              value={'very dissatisfied'}
              isInvalid={!!errors.satisfaction}
              feedback={errors.satisfaction}
              onChange={(e) => setHowSatisfied(e.target.value)}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="describe_products">
          <Form.Label className="bold-text">
            Which of the following words would you use to describe our products?
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="Reliable"
              type="checkbox"
              id={`describe_products_reliable`}
              name="describe_products"
              checked={describeProducts === 'reliable'}
              feedbackType="invalid"
              value={'reliable'}
              isInvalid={!!errors.describe_products}
              feedback={errors.describe_products}
              onChange={(e) => setDescribeProducts(e.target.value)}
            />
            <Form.Check
              inline
              label="High quality"
              type="checkbox"
              id={`describe_products_high_quality`}
              name="describe_products"
              checked={describeProducts === 'high quality'}
              feedbackType="invalid"
              value={'high quality'}
              isInvalid={!!errors.describe_products}
              feedback={errors.describe_products}
              onChange={(e) => setDescribeProducts(e.target.value)}
            />
            <Form.Check
              inline
              label="Useful"
              type="checkbox"
              id={`describe_products_useful`}
              name="describe_products"
              checked={describeProducts === 'useful'}
              feedbackType="invalid"
              value={'useful'}
              isInvalid={!!errors.describe_products}
              feedback={errors.describe_products}
              onChange={(e) => setDescribeProducts(e.target.value)}
            />
            <Form.Check
              inline
              label="Unique"
              type="checkbox"
              id={`describe_products_unique`}
              name="describe_products"
              checked={describeProducts === 'unique'}
              feedbackType="invalid"
              value={'unique'}
              isInvalid={!!errors.describe_products}
              feedback={errors.describe_products}
              onChange={(e) => setDescribeProducts(e.target.value)}
            />
            <Form.Check
              inline
              label="Good value for money"
              type="checkbox"
              id={`describe_products_good_value_for_money`}
              name="describe_products"
              checked={describeProducts === 'good value for money'}
              feedbackType="invalid"
              value={'good value for money'}
              isInvalid={!!errors.describe_products}
              feedback={errors.describe_products}
              onChange={(e) => setDescribeProducts(e.target.value)}
            />
            <Form.Check
              inline
              label="Overpriced"
              type="checkbox"
              id={`describe_products_overpriced`}
              name="describe_products"
              checked={describeProducts === 'overpriced'}
              feedbackType="invalid"
              value={'overpriced'}
              isInvalid={!!errors.describe_products}
              feedback={errors.describe_products}
              onChange={(e) => setDescribeProducts(e.target.value)}
            />
            <Form.Check
              inline
              label="Impractical"
              type="checkbox"
              id={`describe_products_impractical`}
              name="describe_products"
              checked={describeProducts === 'impractical'}
              feedbackType="invalid"
              value={'impractical'}
              isInvalid={!!errors.describe_products}
              feedback={errors.describe_products}
              onChange={(e) => setDescribeProducts(e.target.value)}
            />
            <Form.Check
              inline
              label="Ineffective"
              type="checkbox"
              id={`describe_products_ineffective`}
              name="describe_products"
              checked={describeProducts === 'ineffective'}
              feedbackType="invalid"
              value={'ineffective'}
              isInvalid={!!errors.describe_products}
              feedback={errors.describe_products}
              onChange={(e) => setDescribeProducts(e.target.value)}
            />
            <Form.Check
              inline
              label="Poor quality"
              type="checkbox"
              id={`describe_products_poor_quality`}
              name="describe_products"
              checked={describeProducts === 'poor quality'}
              feedbackType="invalid"
              value={'poor quality'}
              isInvalid={!!errors.describe_products}
              feedback={errors.describe_products}
              onChange={(e) => setDescribeProducts(e.target.value)}
            />
            <Form.Check
              inline
              label="Unreliable"
              type="checkbox"
              id={`describe_products_unreliable`}
              name="describe_products"
              checked={describeProducts === 'unreliable'}
              feedbackType="invalid"
              value={'unreliable'}
              isInvalid={!!errors.describe_products}
              feedback={errors.describe_products}
              onChange={(e) => setDescribeProducts(e.target.value)}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="meet_needs">
          <Form.Label className="bold-text">
            How well do our products meet your needs?
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="Extremely well"
              type="checkbox"
              id={`product_meet_needs_extremely_well`}
              name="product_meet_needs"
              checked={productsMeetNeeds === 'extremely well'}
              feedbackType="invalid"
              value={'extremely well'}
              isInvalid={!!errors.product_meets_needs}
              feedback={errors.product_meets_needs}
              onChange={(e) => setProductsMeetNeeds(e.target.value)}
            />
            <Form.Check
              inline
              label="Very well"
              type="checkbox"
              id={`product_meet_needs_very_well`}
              name="product_meet_needs"
              checked={productsMeetNeeds === 'very well'}
              feedbackType="invalid"
              value={'very well'}
              isInvalid={!!errors.product_meets_needs}
              feedback={errors.product_meets_needs}
              onChange={(e) => setProductsMeetNeeds(e.target.value)}
            />
            <Form.Check
              inline
              label="Somewhat well"
              type="checkbox"
              id={`product_meet_needs_somewhat_well`}
              name="product_meet_needs"
              checked={productsMeetNeeds === 'somewhat well'}
              feedbackType="invalid"
              value={'somewhat well'}
              isInvalid={!!errors.product_meets_needs}
              feedback={errors.product_meets_needs}
              onChange={(e) => setProductsMeetNeeds(e.target.value)}
            />
            <Form.Check
              inline
              label="Not so well"
              type="checkbox"
              id={`product_meet_needs_not_so_well`}
              name="product_meet_needs"
              checked={productsMeetNeeds === 'not so well'}
              feedbackType="invalid"
              value={'not so well'}
              isInvalid={!!errors.product_meets_needs}
              feedback={errors.product_meets_needs}
              onChange={(e) => setProductsMeetNeeds(e.target.value)}
            />
            <Form.Check
              inline
              label="Not at all well"
              type="checkbox"
              id={`product_meet_needs_not_at_all_well`}
              name="product_meet_needs"
              checked={productsMeetNeeds === 'not at all well'}
              feedbackType="invalid"
              value={'not at all well'}
              isInvalid={!!errors.product_meets_needs}
              feedback={errors.product_meets_needs}
              onChange={(e) => setProductsMeetNeeds(e.target.value)}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="rate_value">
          <Form.Label className="bold-text">
            How would you rate the value for money of the product?
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="Excellent"
              type="checkbox"
              id={`money_value_of_product_excellent`}
              name="money_value_of_product"
              checked={moneyValueOfProducts === 'excellent'}
              feedbackType="invalid"
              value={'excellent'}
              isInvalid={!!errors.money_value_of_products}
              feedback={errors.money_value_of_products}
              onChange={(e) => setMoneyValueOfProducts(e.target.value)}
            />
            <Form.Check
              inline
              label="Above average"
              type="checkbox"
              id={`money_value_of_product_above_average`}
              name="money_value_of_product"
              checked={moneyValueOfProducts === 'above average'}
              feedbackType="invalid"
              value={'above average'}
              isInvalid={!!errors.money_value_of_products}
              feedback={errors.money_value_of_products}
              onChange={(e) => setMoneyValueOfProducts(e.target.value)}
            />
            <Form.Check
              inline
              label="Average"
              type="checkbox"
              id={`money_value_of_product_average`}
              name="money_value_of_product"
              checked={moneyValueOfProducts === 'average'}
              feedbackType="invalid"
              value={'average'}
              isInvalid={!!errors.money_value_of_products}
              feedback={errors.money_value_of_products}
              onChange={(e) => setMoneyValueOfProducts(e.target.value)}
            />
            <Form.Check
              inline
              label="Below average"
              type="checkbox"
              id={`money_value_of_product_below_average`}
              name="money_value_of_product"
              checked={moneyValueOfProducts === 'below_average'}
              feedbackType="invalid"
              value={'below_average'}
              isInvalid={!!errors.money_value_of_products}
              feedback={errors.money_value_of_products}
              onChange={(e) => setMoneyValueOfProducts(e.target.value)}
            />
            <Form.Check
              inline
              label="Poor"
              type="checkbox"
              id={`money_value_of_product_poor`}
              name="money_value_of_product"
              checked={moneyValueOfProducts === 'poor'}
              feedbackType="invalid"
              value={'poor'}
              isInvalid={!!errors.money_value_of_products}
              feedback={errors.money_value_of_products}
              onChange={(e) => setMoneyValueOfProducts(e.target.value)}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="responsive">
          <Form.Label className="bold-text">
            How responsive have we been to your questions or concerns about our
            products?
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="Excellent"
              type="checkbox"
              id={`responsive_excellent`}
              name="responsive"
              checked={responsive === 'excellent'}
              feedbackType="invalid"
              value={'excellent'}
              isInvalid={!!errors.responsive}
              feedback={errors.responsive}
              onChange={(e) => setResponsive(e.target.value)}
            />
            <Form.Check
              inline
              label="Extremely responsive"
              type="checkbox"
              id={`responsive_extremely_responsive`}
              name="responsive"
              checked={responsive === 'extremely responsive'}
              feedbackType="invalid"
              value={'extremely responsive'}
              isInvalid={!!errors.responsive}
              feedback={errors.responsive}
              onChange={(e) => setResponsive(e.target.value)}
            />
            <Form.Check
              inline
              label="Very responsive"
              type="checkbox"
              id={`responsive_very_responsive`}
              name="responsive"
              checked={responsive === 'very responsive'}
              feedbackType="invalid"
              value={'very responsive'}
              isInvalid={!!errors.responsive}
              feedback={errors.responsive}
              onChange={(e) => setResponsive(e.target.value)}
            />
            <Form.Check
              inline
              label="Somewhat responsive"
              type="checkbox"
              id={`responsive_somewhat_responsive`}
              name="responsive"
              checked={responsive === 'somewhat responsive'}
              feedbackType="invalid"
              value={'somewhat responsive'}
              isInvalid={!!errors.responsive}
              feedback={errors.responsive}
              onChange={(e) => setResponsive(e.target.value)}
            />
            <Form.Check
              inline
              label="Not so responsive"
              type="checkbox"
              id={`responsive_not_so_responsive`}
              name="responsive"
              checked={responsive === 'not so responsive'}
              feedbackType="invalid"
              value={'not so responsive'}
              isInvalid={!!errors.responsive}
              feedback={errors.responsive}
              onChange={(e) => setResponsive(e.target.value)}
            />
            <Form.Check
              inline
              label="Not at all responsive"
              type="checkbox"
              id={`responsive_not_responsive_at_all`}
              name="responsive"
              checked={responsive === 'not responsive at all'}
              feedbackType="invalid"
              value={'not responsive at all'}
              isInvalid={!!errors.responsive}
              feedback={errors.responsive}
              onChange={(e) => setResponsive(e.target.value)}
            />
            <Form.Check
              inline
              label="Not applicable"
              type="checkbox"
              id={`responsive_not_applicable`}
              name="responsive"
              checked={responsive === 'not applicable'}
              feedbackType="invalid"
              value={'not applicable'}
              isInvalid={!!errors.responsive}
              feedback={errors.responsive}
              onChange={(e) => setResponsive(e.target.value)}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="how_long_a_customer">
          <Form.Label className="bold-text">
            How long have you been a customer of ours?
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="This is my first purchase"
              type="checkbox"
              id={`how_long_a_customer_this_is_my_first_purchase`}
              name="how_long_a_customer"
              checked={howLongACustomer === 'this is my first purchase'}
              feedbackType="invalid"
              value={'this is my first purchase'}
              isInvalid={!!errors.how_long_a_customer}
              feedback={errors.how_long_a_customer}
              onChange={(e) => setHowLongACustomer(e.target.value)}
            />
            <Form.Check
              inline
              label="Less than 6 months"
              type="checkbox"
              id={`how_long_a_customer_less_than_6_months`}
              name="how_long_a_customer"
              checked={howLongACustomer === 'less than 6 months'}
              feedbackType="invalid"
              value={'less than 6 months'}
              isInvalid={!!errors.how_long_a_customer}
              feedback={errors.how_long_a_customer}
              onChange={(e) => setHowLongACustomer(e.target.value)}
            />
            <Form.Check
              inline
              label="Six months to a year"
              type="checkbox"
              id={`how_long_a_customer_six_months_to_a_year`}
              name="how_long_a_customer"
              checked={howLongACustomer === 'six months to a year'}
              feedbackType="invalid"
              value={'six months to a year'}
              isInvalid={!!errors.how_long_a_customer}
              feedback={errors.how_long_a_customer}
              onChange={(e) => setHowLongACustomer(e.target.value)}
            />
            <Form.Check
              inline
              label="1 - 5 years"
              type="checkbox"
              id={`how_long_a_customer_one_to_five_years`}
              name="how_long_a_customer"
              checked={howLongACustomer === '1-5 years'}
              feedbackType="invalid"
              value={'1-5 years'}
              isInvalid={!!errors.how_long_a_customer}
              feedback={errors.how_long_a_customer}
              onChange={(e) => setHowLongACustomer(e.target.value)}
            />
            <Form.Check
              inline
              label="6+ years"
              type="checkbox"
              id={`how_long_a_customer_6_plus_years`}
              name="how_long_a_customer"
              checked={howLongACustomer === '6+ years'}
              feedbackType="invalid"
              value={'6+ years'}
              isInvalid={!!errors.how_long_a_customer}
              feedback={errors.how_long_a_customer}
              onChange={(e) => setHowLongACustomer(e.target.value)}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="purchase_again">
          <Form.Label className="bold-text">
            How likely are you to purchase any of our products again? (1 being
            not at all, 3 being maybe, 6 being 100%)
          </Form.Label>
          <div key={`inline-checkbox`}>
            <Form.Check
              inline
              label="1"
              type="checkbox"
              id={`how_likely_to_purchase_again_1`}
              name="how_likely_to_purchase_again"
              checked={howLikelyToPurchaseAgain === '1'}
              feedbackType="invalid"
              value={'1'}
              isInvalid={!!errors.how_likely_to_purchase_again}
              feedback={errors.how_likely_to_purchase_again}
              onChange={(e) => setHowLikelyToPurchaseAgain(e.target.value)}
            />
            <Form.Check
              inline
              label="2"
              type="checkbox"
              id={`how_likely_to_purchase_again_2`}
              name="how_likely_to_purchase_again"
              checked={howLikelyToPurchaseAgain === '2'}
              feedbackType="invalid"
              value={'2'}
              isInvalid={!!errors.how_likely_to_purchase_again}
              feedback={errors.how_likely_to_purchase_again}
              onChange={(e) => setHowLikelyToPurchaseAgain(e.target.value)}
            />
            <Form.Check
              inline
              label="3"
              type="checkbox"
              id={`how_likely_to_purchase_again_3`}
              name="how_likely_to_purchase_again"
              checked={howLikelyToPurchaseAgain === '3'}
              feedbackType="invalid"
              value={'3'}
              isInvalid={!!errors.how_likely_to_purchase_again}
              feedback={errors.how_likely_to_purchase_again}
              onChange={(e) => setHowLikelyToPurchaseAgain(e.target.value)}
            />
            <Form.Check
              inline
              label="4"
              type="checkbox"
              id={`how_likely_to_purchase_again_4`}
              name="how_likely_to_purchase_again"
              checked={howLikelyToPurchaseAgain === '4'}
              feedbackType="invalid"
              value={'4'}
              isInvalid={!!errors.how_likely_to_purchase_again}
              feedback={errors.how_likely_to_purchase_again}
              onChange={(e) => setHowLikelyToPurchaseAgain(e.target.value)}
            />
            <Form.Check
              inline
              label="5"
              type="checkbox"
              id={`how_likely_to_purchase_again_5`}
              name="how_likely_to_purchase_again"
              checked={howLikelyToPurchaseAgain === '5'}
              feedbackType="invalid"
              value={'5'}
              isInvalid={!!errors.how_likely_to_purchase_again}
              feedback={errors.how_likely_to_purchase_again}
              onChange={(e) => setHowLikelyToPurchaseAgain(e.target.value)}
            />
            <Form.Check
              inline
              label="6"
              type="checkbox"
              id={`how_likely_to_purchase_again_6`}
              name="how_likely_to_purchase_again"
              checked={howLikelyToPurchaseAgain === '6'}
              feedbackType="invalid"
              value={'6'}
              isInvalid={!!errors.how_likely_to_purchase_again}
              feedback={errors.how_likely_to_purchase_again}
              onChange={(e) => setHowLikelyToPurchaseAgain(e.target.value)}
            />
          </div>
        </Form.Group>

        <Form.Group
          className="mb-3 stnd-width-fifty-percent"
          controlId="feedback_message"
        >
          <Form.Label className="bold-text">
            We are always looking for ways we can improve. Can you leave us any
            other comments, questions, or concerns?
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="4"
            placeholder="type answer here"
            name="message"
            value={form.message}
            isInvalid={!!errors.message}
            errors={errors.message}
            onChange={(e) => setField('message', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.message}
          </Form.Control.Feedback>
        </Form.Group>

        <div className="contact-form-button-wrapper">
          <Button
            className="contact-submit-button"
            type="submit"
            disabled={loading}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
}
