/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setContactType } from '../../redux/controls';
import ShopIrdSurvey from './ShopIrdSurvey';
import emailjs from '@emailjs/browser';
import './contactForm.css';

export default function ContactForm({ usingOnPage }) {
  const { contact_type } = useSelector((state) => state.controls);
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState('');
  const [template, setTemplate] = useState('');
  const formRef = useRef();
  const dispatch = useDispatch();

  const emailJSconfig = {
    service_id: 'ird_email',
    general_contact_template: 'general_br61297',
    on_site_training_template: 'other_dvdmkwf',
    calibration_training_template: 'other_dvdmkwf',
  };

  function handleReset() {
    setSubject('');
  }

  const handleDropdownSelect = (e) => {
    setSubject(e);
  };

  function onSwitchAction() {
    // setAbleToPurchaseOnline(!ableToPurchaseOnline);
  }

  function handleSubmit(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        emailJSconfig.service_id,
        template,
        formRef.current,
        'ASfyRsTYNICgAod9p'
      )
      .then(
        (result) => {
          console.log(result.text);
          alert('Thank you for your message.');
          handleReset();
          formRef.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  useEffect(() => {
    if (subject === 'On-Site Training') {
      dispatch(setContactType('on-site-training'));
    } else if (subject === 'Calibration') {
      dispatch(setContactType('calibration'));
    } else {
      dispatch(setContactType('general-contact'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject]);

  useEffect(() => {
    if (contact_type === 'on-site-training') {
      setTemplate(emailJSconfig.on_site_training_template);
    } else if (contact_type === 'calibration') {
      setTemplate(emailJSconfig.calibration_training_template);
    } else {
      setTemplate(emailJSconfig.general_contact_template);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact_type]);

  // console.log(form);
  // console.log('contact_type', contact_type);
  // console.log('template', template);

  return (
    <div className="contact-form-container">
      <Form className="contact-form" ref={formRef}>
        <DropdownButton
          id="contact-form-dropdown"
          title="Select Your Subject"
          variant="dark"
          className="contact-form-dropdown"
          onSelect={handleDropdownSelect}>
          {usingOnPage !== 'clt-training' &&
            usingOnPage !== 'balancing-training' && (
              <Dropdown.Item eventKey="General Contact Request">
                General Contact Request
              </Dropdown.Item>
            )}
          <Dropdown.Item eventKey="On-Site Training">
            On-Site Training/CLT Training
          </Dropdown.Item>

          {usingOnPage !== 'clt-training' && (
            <Dropdown.Item eventKey="Calibration">Calibration</Dropdown.Item>
          )}
        </DropdownButton>

        <Form.Group className="mb-3" controlId="full-name">
          <Form.Label>Subject</Form.Label>
          <Form.Control
            placeholder="Subject"
            name="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="first_name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="enter your full name"
            name="first_name"
            // onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="email"
            name="email"
            // onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="phone">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="phone number"
            name="phone"
            // onChange={(e) => setPhone(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="company_name">
          <Form.Label>Company</Form.Label>
          <Form.Control
            type="text"
            placeholder="name of your company"
            name="company_name"
            // onChange={(e) => setCompany(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="shipping_info">
          <Form.Label>Address</Form.Label>
          <Form.Control
            type="text"
            placeholder="enter your full address"
            name="shipping_info"
            // onChange={(e) => setCompany(e.target.value)}
          />
        </Form.Group>

        {contact_type === 'calibration' && (
          <Form.Group className="mb-3" controlId="po">
            <Form.Label>PO Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="enter PO number if applicable"
              name="po"
            />
          </Form.Group>
        )}

        {contact_type === 'calibration' && (
          <Form.Group className="mb-3" controlId="sending_instruments">
            <Form.Label>Instruments/Machines to be calibrated</Form.Label>
            <Form.Control
              type="text"
              placeholder="model number of the instrument(s)/machines you would like calibrated (separated by comma)"
              name="sending_instruments"
            />
          </Form.Group>
        )}

        {contact_type === 'calibration' && (
          <Form.Group className="mb-3" controlId="serial_numbers">
            <Form.Label>Serial Numbers of instruments/machines</Form.Label>
            <Form.Control
              type="text"
              placeholder="serial number of the instrument(s)/machines (separated by comma). n/a if unknown"
              name="serial_numbers"
            />
          </Form.Group>
        )}

        {contact_type === 'on-site-training' && (
          <Form.Group className="mb-3" controlId="issues_or_concerns">
            <Form.Label>
              Have you had any problems with your instrument or machine?
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="list any problems with your instrument or machine. n/a if none"
              name="issues_or_concerns"
            />
          </Form.Group>
        )}

        {contact_type === 'on-site-training' && (
          <Form.Group className="mb-3" controlId="problem_items">
            <Form.Label>
              What instrument(s) and/or machine(s) are you having trouble with?
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="list instrument(s)/machine(s). n/a if none"
              name="problem_items"
            />
          </Form.Group>
        )}
        {contact_type === 'on-site-training' && (
          <Form.Check
            type="switch"
            checked={null}
            id="purchase-online-toggles"
            label="Product Available to Purchase Online?"
            onChange={onSwitchAction}
          />
        )}
        {contact_type === 'on-site-training' && (
          <Form.Group className="mb-3" controlId="experiencing_issues">
            <Form.Label>
              Tell us what issues you may be experiencing with your equipment?
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="list any issues. n/a if none"
              name="experiencing_issues"
            />
          </Form.Group>
        )}

        <Form.Group className="mb-3" controlId="message">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows="4"
            placeholder="message"
            name="message"
            // onChange={(e) => setMessage(e.target.value)}
          />
        </Form.Group>

        <div className="contact-form-button-wrapper">
          <Button
            className="contact-submit-button"
            type="submit"
            disabled={loading}
            onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
}
