import b01_b02s from '../assets/images/complete-machine-images/b01-b02s.png';
import b5_b20 from '../assets/images/complete-machine-images/b5-b20.png';
import b50_b200 from '../assets/images/complete-machine-images/b50-b200.png';
import b140_110k_280k from '../assets/images/complete-machine-images/b140-110k-280k.png';
import b750rr from '../assets/images/complete-machine-images/b750rr.png';
import b5u_b200U from '../assets/images/machine-upgrades/b5u-b200u.png';
import msws500_msws5000_msws25000 from '../assets/images/modular-suspension-work-supports/msws500_msws5000_msws25000.png';
import rws100 from '../assets/images/roller-work-supports/rws100.png';
import rws220 from '../assets/images/roller-work-supports/rws220.png';
import rws1500 from '../assets/images/roller-work-supports/rws1500.png';
import rws5000 from '../assets/images/roller-work-supports/rws5000.png';
import rws15000_rws25000 from '../assets/images/roller-work-supports/rws15000_rws25000.png';
import b5f_through_b200f from '../assets/images/user-fabricated/b5f_through_b200f.png';
import imageNotAvailable from '../assets/images/image-not-available.png';

// ----- PDF DOCS
import b01doc from '../assets/pdf-docs/complete-machines/b01-b02s.pdf';
import b5_b20doc from '../assets/pdf-docs/complete-machines/b5-b20.pdf';
import b50_b200doc from '../assets/pdf-docs/complete-machines/b50-b200.pdf';
import b140_110kdoc from '../assets/pdf-docs/complete-machines/b140-110k.pdf';
import b140_200kdoc from '../assets/pdf-docs/complete-machines/b140-200k.pdf';
import b140_280kdoc from '../assets/pdf-docs/complete-machines/b140-280k.pdf';
import b750rrdoc from '../assets/pdf-docs/complete-machines/b750rr.pdf';
import b5udoc from '../assets/pdf-docs/machine-upgrades/b5u.pdf';
import b10udoc from '../assets/pdf-docs/machine-upgrades/b10u.pdf';
import b50udoc from '../assets/pdf-docs/machine-upgrades/b50u.pdf';
import b150udoc from '../assets/pdf-docs/machine-upgrades/b150u.pdf';
import b200udoc from '../assets/pdf-docs/machine-upgrades/b200u.pdf';
import b5fdoc from '../assets/pdf-docs/user-fabricated/b5f.pdf';
import b10fdoc from '../assets/pdf-docs/user-fabricated/b10f.pdf';
import b50fdoc from '../assets/pdf-docs/user-fabricated/b50f.pdf';
import b150fdoc from '../assets/pdf-docs/user-fabricated/b150f.pdf';
import b200fdoc from '../assets/pdf-docs/user-fabricated/b200f.pdf';
import msws500doc from '../assets/pdf-docs/modular-suspension/msws-500.pdf';
import msws5000doc from '../assets/pdf-docs/modular-suspension/msws-5000.pdf';
import msws25000doc from '../assets/pdf-docs/modular-suspension/msws-25000.pdf';
import rws100doc from '../assets/pdf-docs/roller-work/rws-100.pdf';
import rws220doc from '../assets/pdf-docs/roller-work/rws-220.pdf';
import rws1500doc from '../assets/pdf-docs/roller-work/rws-1500.pdf';
import rws5000doc from '../assets/pdf-docs/roller-work/rws-5000.pdf';
import rws15000doc from '../assets/pdf-docs/roller-work/rws-15000.pdf';
import rws25000doc from '../assets/pdf-docs/roller-work/rws-25000.pdf';

// MODELS THAT ARE COMMENTED OUT HAVE BEEN DISCONTINUED

export const COMPLETE_MACHINES = [
  {
    model: 'Model B01',
    product_image: b01_b02s,
    description: 'Tabletop',
    doc_link: b01doc,
    link_text: 'B01 Data Sheet',
    drive: 'Drive',
    drive_type: 'belt',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 7,
    capacity_pair_kg: 3,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 10,
    max_rotor_dia_mm: 254,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 1.2,
    max_journal_dia_mm: 30,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0.0006,
    max_sensitivity_mm: 0.015,
    drive_motor_size: 'Drive Motor Size',
    drive_motor_size_hp: 0.13,
    drive_motor_size_kw: 0.095,
    max_journal_separation: 'Max Journal Separation',
    max_journal_separation_inch: 15.7,
    max_journal_separation_mm: 400,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 17.7,
    standard_bed_length_mm: 450,
  },
  // {
  //   model: 'Model B01/S',
  //   product_image: b01_b02s,
  //   description: 'Tabletop w/Instrument',
  //   doc_link: b01doc,
  //   link_text: 'B01 Data Sheet',
  //   drive: 'Drive',
  //   drive_type: 'belt',
  //   capacity_pair: 'Capacity/Pair',
  //   capacity_pair_lb: 7,
  //   capacity_pair_kg: 3,
  //   max_rotor_dia: 'Max Rotor Diameter',
  //   max_rotor_dia_inch: 10,
  //   max_rotor_dia_mm: 254,
  //   max_journal_dia: 'Max Journal Diameter',
  //   max_journal_dia_inch: 1.2,
  //   max_journal_dia_mm: 30,
  //   max_sensitivity: 'Max Sensitivity',
  //   max_sensitivity_inch: 0.0006,
  //   max_sensitivity_mm: 0.015,
  //   drive_motor_size: 'Drive Motor Size',
  //   drive_motor_size_hp: 0.13,
  //   drive_motor_size_kw: 0.095,
  //   max_journal_separation: 'Max Journal Separation',
  //   max_journal_separation_inch: 15.7,
  //   max_journal_separation_mm: 400,
  //   standard_bed_length: 'Standard Bed Length',
  //   standard_bed_length_inch: 17.7,
  //   standard_bed_length_mm: 450,
  // },
  {
    model: 'Model B02',
    product_image: b01_b02s,
    description: 'Tabletop',
    doc_link: b01doc,
    link_text: 'B02 Data Sheet',
    drive: 'Drive',
    drive_type: 'belt',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 15,
    capacity_pair_kg: 7,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 16.3,
    max_rotor_dia_mm: 415,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 2.4,
    max_journal_dia_mm: 60,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0.0008,
    max_sensitivity_mm: 0.0018,
    drive_motor_size: 'Drive Motor Size',
    drive_motor_size_hp: 0.13,
    drive_motor_size_kw: 0.095,
    max_journal_separation: 'Max Journal Separation',
    max_journal_separation_inch: 47.2,
    max_journal_separation_mm: 400,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 17.7,
    standard_bed_length_mm: 450,
  },
  // {
  //   model: 'Model B02/S',
  //   product_image: b01_b02s,
  //   description: 'Tabletop w/ Instrument',
  //   doc_link: b01doc,
  //   link_text: 'B02/S Data Sheet',
  //   drive: 'Drive',
  //   drive_type: 'belt',
  //   capacity_pair: 'Capacity/Pair',
  //   capacity_pair_lb: 15,
  //   capacity_pair_kg: 7,
  //   max_rotor_dia: 'Max Rotor Diameter',
  //   max_rotor_dia_inch: 16.3,
  //   max_rotor_dia_mm: 415,
  //   max_journal_dia: 'Max Journal Diameter',
  //   max_journal_dia_inch: 2.4,
  //   max_journal_dia_mm: 60,
  //   max_sensitivity: 'Max Sensitivity',
  //   max_sensitivity_inch: 0.0008,
  //   max_sensitivity_mm: 0.0018,
  //   drive_motor_size: 'Drive Motor Size',
  //   drive_motor_size_hp: 0.13,
  //   drive_motor_size_kw: 0.095,
  //   max_journal_separation: 'Max Journal Separation',
  //   max_journal_separation_inch: 47.2,
  //   max_journal_separation_mm: 400,
  //   standard_bed_length: 'Standard Bed Length',
  //   standard_bed_length_inch: 17.7,
  //   standard_bed_length_mm: 450,
  // },
  {
    model: 'Model B5',
    product_image: b5_b20,
    description: 'General Purpose',
    doc_link: b5_b20doc,
    link_text: 'B5 Data Sheet',
    drive: 'Drive',
    drive_type: 'belt',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 500,
    capacity_pair_kg: 230,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 36,
    max_rotor_dia_mm: 914,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 8,
    max_journal_dia_mm: 203,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0.06,
    max_sensitivity_mm: 1.5,
    drive_motor_size: 'Drive Motor Size',
    drive_motor_size_hp: 1,
    drive_motor_size_kw: 0.75,
    max_journal_separation: 'Max Journal Separation',
    max_journal_separation_inch: 47.2,
    max_journal_separation_mm: 1200,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 55,
    standard_bed_length_mm: 1400,
  },
  {
    model: 'Model B10',
    product_image: b5_b20,
    description: 'General Purpose',
    doc_link: b5_b20doc,
    link_text: 'B10 Data Sheet',
    drive: 'Drive',
    drive_type: 'belt',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 1000,
    capacity_pair_kg: 450,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 40,
    max_rotor_dia_mm: 1016,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 8,
    max_journal_dia_mm: 203,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0.08,
    max_sensitivity_mm: 2,
    drive_motor_size: 'Drive Motor Size',
    drive_motor_size_hp: 2,
    drive_motor_size_kw: 1.5,
    max_journal_separation: 'Max Journal Separation',
    max_journal_separation_inch: 49.5,
    max_journal_separation_mm: 1250,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 55,
    standard_bed_length_mm: 1400,
  },
  {
    model: 'Model B20',
    product_image: b5_b20,
    description: 'General Purpose',
    doc_link: b5_b20doc,
    link_text: 'B20 Data Sheet',
    drive: 'Drive',
    drive_type: 'belt',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 2000,
    capacity_pair_kg: 900,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 45,
    max_rotor_dia_mm: 1144,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 13,
    max_journal_dia_mm: 330,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0.16,
    max_sensitivity_mm: 4,
    drive_motor_size: 'Drive Motor Size',
    drive_motor_size_hp: 3,
    drive_motor_size_kw: 2.2,
    max_journal_separation: 'Max Journal Separation',
    max_journal_separation_inch: 49.5,
    max_journal_separation_mm: 1257,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 55,
    standard_bed_length_mm: 1400,
  },
  {
    model: 'Model B50',
    product_image: b50_b200,
    description: 'General Purpose',
    doc_link: b50_b200doc,
    link_text: 'B50 Data Sheet',
    drive: 'Drive',
    drive_type: 'belt',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 5000,
    capacity_pair_kg: 2300,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 94,
    max_rotor_dia_mm: 2388,
    max_journal_dia: 'Max Journal Dia',
    max_journal_dia_inch: 13,
    max_journal_dia_mm: 330,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0.16,
    max_sensitivity_mm: 4,
    drive_motor_size: 'Drive Motor Size',
    drive_motor_size_hp: 5,
    drive_motor_size_kw: 3.7,
    max_journal_separation: 'Max Journal Separation',
    max_journal_separation_inch: 80,
    max_journal_separation_mm: 2030,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 85.5,
    standard_bed_length_mm: 2170,
  },
  {
    model: 'Model B100',
    product_image: b50_b200,
    description: 'General Purpose',
    doc_link: b50_b200doc,
    link_text: 'B100 Data Sheet',
    drive: 'Drive',
    drive_type: 'belt',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 10000,
    capacity_pair_kg: 4500,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 115,
    max_rotor_dia_mm: 2921,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 15,
    max_journal_dia_mm: 381,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0.4,
    max_sensitivity_mm: 10,
    drive_motor_size: 'Drive Motor Size',
    drive_motor_size_hp: 10,
    drive_motor_size_kw: 7.5,
    max_journal_separation: 'Max Journal Separation',
    max_journal_separation_inch: 75,
    max_journal_separation_mm: 1905,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 84,
    standard_bed_length_mm: 2134,
  },
  {
    model: 'Model B150',
    product_image: b50_b200,
    description: 'General Purpose',
    doc_link: b50_b200doc,
    link_text: 'B150 Data Sheet',
    drive: 'Drive',
    drive_type: 'belt',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 15000,
    capacity_pair_kg: 7000,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 115,
    max_rotor_dia_mm: 2921,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 15,
    max_journal_dia_mm: 381,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0.4,
    max_sensitivity_mm: 10,
    drive_motor_size: 'Drive Motor Size',
    drive_motor_size_hp: 15,
    drive_motor_size_kw: 11.2,
    max_journal_separation: 'Max Journal Separation',
    max_journal_separation_inch: 132,
    max_journal_separation_mm: 3124,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 132,
    standard_bed_length_mm: 3353,
  },
  {
    model: 'Model B200',
    product_image: b50_b200,
    description: 'General Purpose',
    doc_link: b50_b200doc,
    link_text: 'B200 Data Sheet',
    drive: 'Drive',
    drive_type: 'belt',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 25000,
    capacity_pair_kg: 11000,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 124,
    max_rotor_dia_mm: 3150,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 24,
    max_journal_dia_mm: 610,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0.6,
    max_sensitivity_mm: 14.5,
    drive_motor_size: 'Drive Motor Size',
    drive_motor_size_hp: 25,
    drive_motor_size_kw: 18.6,
    max_journal_separation: 'Max Journal Separation',
    max_journal_separation_inch: 124,
    max_journal_separation_mm: 3150,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 132,
    standard_bed_length_mm: 3353,
  },
  {
    model: 'Model B500RR',
    product_image: imageNotAvailable,
    description: 'Large Capacity',
    doc_link: '',
    link_text: 'B500RR Data Sheet',
    drive: 'Drive',
    drive_type: 'end',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 50000,
    capacity_pair_kg: 23000,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 128,
    max_rotor_dia_mm: 3250,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 20,
    max_journal_dia_mm: 508,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0,
    max_sensitivity_mm: 0,
    drive_motor_size: 'Drive Motor Size',
    drive_motor_size_hp: 0,
    drive_motor_size_kw: 0,
    max_journal_separation: 'Max Journal Separation',
    max_journal_separation_inch: 0,
    max_journal_separation_mm: 0,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 0,
    standard_bed_length_mm: 0,
  },
  {
    model: 'Model B750RR**',
    product_image: b750rr,
    description: 'Large Capacity',
    doc_link: b750rrdoc,
    link_text: 'B750RR Data Sheet',
    drive: 'Drive',
    drive_type: 'end',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 75000,
    capacity_pair_kg: 34000,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 128,
    max_rotor_dia_mm: 3250,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 20,
    max_journal_dia_mm: 508,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 4,
    max_sensitivity_mm: 100,
    drive_motor_size: 'Drive Motor Size',
    drive_motor_size_hp: 60,
    drive_motor_size_kw: 45,
    max_journal_separation: 'Max Journal Separation',
    max_journal_separation_inch: 6,
    max_journal_separation_mm: 152,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 111111,
    standard_bed_length_mm: 111111,
  },
  {
    model: 'Model B140-110K**',
    product_image: b140_110k_280k,
    description: 'Large Capacity, Transportable',
    doc_link: b140_110kdoc,
    link_text: 'B140-110K Data Sheet',
    drive: 'Drive',
    drive_type: 'end',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 110000,
    capacity_pair_kg: 50000,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 154,
    max_rotor_dia_mm: 3900,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 32,
    max_journal_dia_mm: 813,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 7,
    max_sensitivity_mm: 180,
    drive_motor_size: 'Drive Motor Size',
    drive_motor_size_hp: 60,
    drive_motor_size_kw: 45,
    max_journal_separation: 'Max Journal Separation',
    max_journal_separation_inch: 9,
    max_journal_separation_mm: 229,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 111111,
    standard_bed_length_mm: 111111,
  },
  {
    model: 'Model B140-200K**',
    product_image: b140_110k_280k,
    description: 'Large Capacity, Transportable',
    doc_link: b140_200kdoc,
    link_text: 'B140-200K Data Sheet',
    drive: 'Drive',
    drive_type: 'end',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 200000,
    capacity_pair_kg: 90000,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 154,
    max_rotor_dia_mm: 3900,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 32,
    max_journal_dia_mm: 813,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 7,
    max_sensitivity_mm: 180,
    drive_motor_size: 'Drive Motor Size',
    drive_motor_size_hp: 75,
    drive_motor_size_kw: 56,
    max_journal_separation: 'Max Journal Separation',
    max_journal_separation_inch: 9,
    max_journal_separation_mm: 229,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 111111,
    standard_bed_length_mm: 111111,
  },
  {
    model: 'Model B140-280K**',
    product_image: b140_110k_280k,
    description: 'Large Capacity, Transportable',
    doc_link: b140_280kdoc,
    link_text: 'B140-280K Data Sheet',
    drive: 'Drive',
    drive_type: 'end',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 280000,
    capacity_pair_kg: 127000,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 154,
    max_rotor_dia_mm: 3900,
    // max_rotor_inch: 212,
    // max_rotor_mm: 5400,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 32,
    max_journal_dia_mm: 813,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 7,
    max_sensitivity_mm: 180,
    drive_motor_size: 'Drive Motor Size',
    drive_motor_size_hp: 100,
    drive_motor_size_kw: 75,
    max_journal_separation: 'Max Journal Separation',
    max_journal_separation_inch: 9,
    max_journal_separation_mm: 229,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 111111,
    standard_bed_length_mm: 111111,
  },
  {
    model: 'Model B140-500K',
    product_image: imageNotAvailable,
    description: 'Large Capacity, Transportable',
    doc_link: '',
    link_text: 'B140-500K Data Sheet',
    drive: 'Drive',
    drive_type: 'end',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 500000,
    capacity_pair_kg: 227000,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_inch: 212,
    max_rotor_mm: 5400,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_inch: 32,
    max_journal_mm: 813,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0,
    max_sensitivity_mm: 0,
    drive_motor_size: 'Drive Motor Size',
    drive_motor_size_hp: 0,
    drive_motor_size_kw: 0,
    max_journal_separation: 'Max Journal Separation',
    max_journal_separation_inch: 0,
    max_journal_separation_mm: 0,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 0,
    standard_bed_length_mm: 0,
  },

  {
    model: 'Model B200R',
    product_image: imageNotAvailable,
    description: 'Roll Balancing',
    doc_link: '',
    link_text: 'B200R Data Sheet',
    drive: 'Drive',
    drive_type: 'belt',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 25000,
    capacity_pair_kg: 11000,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 100,
    max_rotor_dia_mm: 2500,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: '-',
    max_journal_dia_mm: '-',
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0,
    max_sensitivity_mm: 0,
    drive_motor_size: 'Drive Motor Size',
    drive_motor_size_hp: 0,
    drive_motor_size_kw: 0,
    max_journal_separation: 'Max Journal Separation',
    max_journal_separation_inch: 0,
    max_journal_separation_mm: 0,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 0,
    standard_bed_length_mm: 0,
  },
  {
    model: 'Model B500R',
    product_image: imageNotAvailable,
    description: 'Roll Balancing',
    doc_link: '',
    link_text: 'B500R Data Sheet',
    drive: 'Drive',
    drive_type: 'belt',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 50000,
    capacity_pair_kg: 23000,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 112,
    max_rotor_dia_mm: 2850,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: '-',
    max_journal_dia_mm: '-',
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0,
    max_sensitivity_mm: 0,
    drive_motor_size: 'Drive Motor Size',
    drive_motor_size_hp: 0,
    drive_motor_size_kw: 0,
    max_journal_separation: 'Max Journal Separation',
    max_journal_separation_inch: 0,
    max_journal_separation_mm: 0,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 0,
    standard_bed_length_mm: 0,
  },
  {
    model: 'Model B800R',
    product_image: imageNotAvailable,
    description: 'Roll Balancing',
    doc_link: '',
    link_text: 'B800R Data Sheet',
    drive: 'Drive',
    drive_type: 'belt',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 80000,
    capacity_pair_kg: 36000,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 112,
    max_rotor_dia_mm: 2850,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: '-',
    max_journal_dia_mm: '-',
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0,
    max_sensitivity_mm: 0,
    drive_motor_size: 'Drive Motor Size',
    drive_motor_size_hp: 0,
    drive_motor_size_kw: 0,
    max_journal_separation: 'Max Journal Separation',
    max_journal_separation_inch: 0,
    max_journal_separation_mm: 0,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 0,
    standard_bed_length_mm: 0,
  },
  {
    model: 'Model B1500R',
    product_image: imageNotAvailable,
    description: 'Roll Balancing',
    doc_link: '',
    link_text: 'B1500R Data Sheet',
    drive: 'Drive',
    drive_type: 'belt',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 150000,
    capacity_pair_kg: 68000,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 124,
    max_rotor_dia_mm: 3150,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: '-',
    max_journal_dia_mm: '-',
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0,
    max_sensitivity_mm: 0,
    drive_motor_size: 'Drive Motor Size',
    drive_motor_size_hp: 0,
    drive_motor_size_kw: 0,
    max_journal_separation: 'Max Journal Separation',
    max_journal_separation_inch: 0,
    max_journal_separation_mm: 0,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 0,
    standard_bed_length_mm: 0,
  },
];

// ------------------- NEW ITEM -------------------------
export const MACHINE_UPGRADES = [
  {
    model: 'Model B5U',
    product_image: b5u_b200U,
    description: '',
    doc_link: b5udoc,
    link_text: 'B5U Data Sheet',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 500,
    capacity_pair_kg: 230,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 8,
    max_journal_dia_mm: 203,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0.06,
    max_sensitivity_mm: 1.5,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_desc: 'depends on existing machine',
  },
  {
    model: 'Model B10U',
    product_image: b5u_b200U,
    description: '',
    doc_link: b10udoc,
    link_text: 'B10U Data Sheet',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 1000,
    capacity_pair_kg: 450,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 8,
    max_journal_dia_mm: 203,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0.08,
    max_sensitivity_mm: 2,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_desc: 'depends on existing machine',
  },
  {
    model: 'Model B50U',
    product_image: b5u_b200U,
    description: '',
    doc_link: b50udoc,
    link_text: 'B50U Data Sheet',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 5000,
    capacity_pair_kg: 2300,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 13,
    max_journal_dia_mm: 330,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0.16,
    max_sensitivity_mm: 4,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_desc: 'depends on existing machine',
  },
  {
    model: 'Model B150U',
    product_image: b5u_b200U,
    description: '',
    doc_link: b150udoc,
    link_text: 'B150U Data Sheet',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 15000,
    capacity_pair_kg: 7000,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 15,
    max_journal_dia_mm: 381,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0.4,
    max_sensitivity_mm: 10,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_desc: 'depends on existing machine',
  },
  {
    model: 'Model B200U',
    product_image: b5u_b200U,
    description: '',
    doc_link: b200udoc,
    link_text: 'B200U Data Sheet',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 25000,
    capacity_pair_kg: 11000,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 24,
    max_journal_dia_mm: 610,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0.6,
    max_sensitivity_mm: 14.5,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_desc: 'depends on existing machine',
  },
];

// ------------------- NEW ITEM -------------------------
export const USER_FABRICATED = [
  {
    model: 'Model B5F',
    product_image: b5f_through_b200f,
    description: '500 lb User Fabricated',
    doc_link: b5fdoc,
    link_text: 'B5F Data Sheet',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 500,
    capacity_pair_kg: 230,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 77.25,
    max_rotor_dia_mm: 1962,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 8,
    max_journal_dia_mm: 203,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0.06,
    max_sensitivity_mm: 1.5,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 85.4,
    standard_bed_length_mm: 2170,
    drive_motor_size: 'Drive Motor Recommended',
    drive_motor_size_hp: 1,
    drive_motor_size_kw: 0.75,
  },
  {
    model: 'Model B10F',
    product_image: b5f_through_b200f,
    description: '1,000 lb User Fabricated',
    doc_link: b10fdoc,
    link_text: 'B10F Data Sheet',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 1000,
    capacity_pair_kg: 450,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 77.25,
    max_rotor_dia_mm: 1962,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 8,
    max_journal_dia_mm: 203,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0.8,
    max_sensitivity_mm: 2,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 85.4,
    standard_bed_length_mm: 2170,
    drive_motor_size: 'Drive Motor Recommended',
    drive_motor_size_hp: 2,
    drive_motor_size_kw: 1.5,
  },
  {
    model: 'Model B50F',
    product_image: b5f_through_b200f,
    description: '5,000 lb User Fabricated',
    doc_link: b50fdoc,
    link_text: 'B50F Data Sheet',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 5000,
    capacity_pair_kg: 2300,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 85,
    max_rotor_dia_mm: 2160,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 13,
    max_journal_dia_mm: 330,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0.16,
    max_sensitivity_mm: 4,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 85.4,
    standard_bed_length_mm: 2170,
    drive_motor_size: 'Drive Motor Recommended',
    drive_motor_size_hp: 5,
    drive_motor_size_kw: 3.7,
  },
  {
    model: 'Model B150F',
    product_image: b5f_through_b200f,
    description: '15,000 lb User Fabricated',
    doc_link: b150fdoc,
    link_text: 'B150F Data Sheet',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 15000,
    capacity_pair_kg: 7000,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 110,
    max_rotor_dia_mm: 2800,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 15,
    max_journal_dia_mm: 381,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0.4,
    max_sensitivity_mm: 10,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 132,
    standard_bed_length_mm: 3350,
    drive_motor_size: 'Drive Motor Recommended',
    drive_motor_size_hp: 15,
    drive_motor_size_kw: 11,
  },
  {
    model: 'Model B200F',
    product_image: b5f_through_b200f,
    description: '25,000 lb User Fabricated',
    doc_link: b200fdoc,
    link_text: 'B200F Data Sheet',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 25000,
    capacity_pair_kg: 11000,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 110,
    max_rotor_dia_mm: 2800,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 24,
    max_journal_dia_mm: 610,
    max_sensitivity: 'Max Sensitivity',
    max_sensitivity_inch: 0.6,
    max_sensitivity_mm: 14.5,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_inch: 132,
    standard_bed_length_mm: 3350,
    drive_motor_size: 'Drive Motor Recommended',
    drive_motor_size_hp: 25,
    drive_motor_size_kw: 18.6,
  },
];

// ------------------- NEW ITEM -------------------------
export const MODULAR_SUSPENSION_WORK_SUPPORTS = [
  {
    model: 'Model MSWS 500',
    product_image: msws500_msws5000_msws25000,
    description: '500 lb Capacity Modular Suspension Work Supports',
    doc_link: msws500doc,
    link_text: 'MSWS 500 Technical Data Sheet',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 500,
    capacity_pair_kg: 230,
    max_sensitivity: 'Maximum Sensitivity',
    max_sensitivity_inch: 0.06,
    max_sensitivity_mm: 1.5,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_desc: 'depends on machine',
  },
  {
    model: 'Model MSWS 5,000',
    product_image: msws500_msws5000_msws25000,
    description: '5,000 lb Capacity Modular Suspension Work Supports',
    doc_link: msws5000doc,
    link_text: 'MSWS 5,000 Technical Data Sheet',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 5000,
    capacity_pair_kg: 2300,
    max_sensitivity: 'Maximum Sensitivity',
    max_sensitivity_inch: 0.000005,
    max_sensitivity_mm: 0.000127,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_desc: 'depends on machine',
  },
  {
    model: 'Model MSWS 25,000',
    product_image: msws500_msws5000_msws25000,
    description: '25,000 lb Capacity Modular Suspension Work Supports',
    doc_link: msws25000doc,
    link_text: 'MSWS 25,000 Technical Data Sheet',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 25000,
    capacity_pair_kg: 11000,
    max_sensitivity: 'Maximum Sensitivity',
    max_sensitivity_inch: 0.000005,
    max_sensitivity_mm: 0.000127,
    standard_bed_length: 'Standard Bed Length',
    standard_bed_length_desc: 'depends on machine',
  },
];

// ------------------- NEW ITEM -------------------------
export const ROLLER_WORK_SUPPORTS = [
  {
    model: 'Model RWS 100',
    product_image: rws100,
    description: '100 lb Capacity Roller Work Supports',
    doc_link: rws100doc,
    link_text: 'RWS 100 Data Sheet',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 100,
    capacity_pair_kg: 45,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 3,
    max_journal_dia_mm: 76,
    work_support_width: 'Work Support Width',
    work_support_width_inch: 0.375,
    work_support_width_mm: 10,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 9,
    max_rotor_dia_mm: 229,
    can_be_mounted_on: 'Can Be Mounted On',
    can_be_mounted_on_val: 'B5, B10, B20 or B50 Machines',
  },
  {
    model: 'Model RWS 220',
    product_image: rws220,
    description: '220 lb Capacity Roller Work Supports',
    doc_link: rws220doc,
    link_text: 'RWS 220 Data Sheet',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 220,
    capacity_pair_kg: 100,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 0,
    max_journal_dia_mm: 0,
    work_support_width: 'Work Support Width',
    work_support_width_inch: 0,
    work_support_width_mm: 0,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 0,
    max_rotor_dia_mm: 0,
    can_be_mounted_on: 'Can Be Mounted On',
    can_be_mounted_on_val: '',
  },
  {
    model: 'Model RWS 1,500',
    product_image: rws1500,
    description: '1,500 lb Capacity Roller Work Supports',
    doc_link: rws1500doc,
    link_text: 'RWS 1,500 Data Sheet',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 1500,
    capacity_pair_kg: 700,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 0,
    max_journal_dia_mm: 0,
    work_support_width: 'Work Support Width',
    work_support_width_inch: 0,
    work_support_width_mm: 0,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 0,
    max_rotor_dia_mm: 0,
    can_be_mounted_on: 'Can Be Mounted On',
    can_be_mounted_on_val: '',
  },
  {
    model: 'Model RWS 5,000',
    product_image: rws5000,
    description: '5,000 lb Capacity Roller Work Supports',
    doc_link: rws5000doc,
    link_text: 'RWS 5,000 Data Sheet',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 5000,
    capacity_pair_kg: 2300,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 0,
    max_journal_dia_mm: 0,
    work_support_width: 'Work Support Width',
    work_support_width_inch: 0,
    work_support_width_mm: 0,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 0,
    max_rotor_dia_mm: 0,
    can_be_mounted_on: 'Can Be Mounted On',
    can_be_mounted_on_val: '',
  },
  {
    model: 'Model RWS 15,000',
    product_image: rws15000_rws25000,
    description: '15,000 lb Capacity Roller Work Supports',
    doc_link: rws15000doc,
    link_text: 'RWS 15,000 Data Sheet',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 15000,
    capacity_pair_kg: 7000,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 0,
    max_journal_dia_mm: 0,
    work_support_width: 'Work Support Width',
    work_support_width_inch: 0,
    work_support_width_mm: 0,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 0,
    max_rotor_dia_mm: 0,
    can_be_mounted_on: 'Can Be Mounted On',
    can_be_mounted_on_val: '',
  },
  {
    model: 'Model RWS 25,000',
    product_image: rws15000_rws25000,
    description: '25,000 lb Capacity Roller Work Supports',
    doc_link: rws25000doc,
    link_text: 'RWS 25,000 Data Sheet',
    capacity_pair: 'Capacity/Pair',
    capacity_pair_lb: 25000,
    capacity_pair_kg: 11000,
    max_journal_dia: 'Max Journal Diameter',
    max_journal_dia_inch: 0,
    max_journal_dia_mm: 0,
    work_support_width: 'Work Support Width',
    work_support_width_inch: 0,
    work_support_width_mm: 0,
    max_rotor_dia: 'Max Rotor Diameter',
    max_rotor_dia_inch: 0,
    max_rotor_dia_mm: 0,
    can_be_mounted_on: 'Can Be Mounted On',
    can_be_mounted_on_val: '',
  },
];
