import React from 'react';
import './jobPosting.css';

export default function JobPosting({ jobTitle, jobDescription }) {
  return (
    <div className="job-posting-container">
      <div className="jp-title">{jobTitle}</div>
      <div className="jp-description">{jobDescription}</div>
    </div>
  );
}
