import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowMenu } from '../redux/controls';
import SideMenu from '../components/SideMenu/SideMenu';
import JobPosting from '../components/JobPosting/JobPosting';
import Footer from '../components/Footer/Footer';
import '../styles/careersPageStyle.css';

export default function Careers() {
  const { job_postings } = useSelector((state) => state.controls);
  const [sortedPostings, setSortedPostings] = useState(null);
  const dispatch = useDispatch();
  let length;
  if (job_postings) {
    length = job_postings.length;
  }

  const toggleMenu = () => {
    dispatch(setShowMenu(false));
  };

  useEffect(() => {
    if (job_postings) {
      const dataForSorting = [...job_postings];
      if (dataForSorting) {
        const sorted = dataForSorting.sort((a, b) => {
          return b.date - a.date;
        });
        setSortedPostings(sorted);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length]);

  return (
    <div className="careers-page-container">
      <SideMenu />
      <div className="careers-page-header">
        <h1 className="careers-page-header-text">Careers</h1>
      </div>
      <div className="careers-page-content" onClick={toggleMenu}>
        <div className="careers-page-left"></div>
        <div className="careers-page-right">
          <h2 className="careers-page-subtitle">Openings</h2>
          {sortedPostings &&
            sortedPostings.map((job) => {
              return (
                <JobPosting
                  key={job.id}
                  jobTitle={job.job_title}
                  jobDescription={job.job_description}
                />
              );
            })}
          <div className="careers-page-contact-info-wrapper">
            <span className="background-color-white">
              Email resumes to:{' '}
              <a href="mailto: hr@irdbalancing.com">hr@irdbalancing.com</a>
            </span>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
